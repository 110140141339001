/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase se encarga de realizar el cierre de sesión
*/


import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class LogoutComponent implements OnInit {

    constructor(private _router: Router,
        private _authService: AuthenticationService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        // Helpers.setLoading(true);
        this.spinner.show();
        // reset login status
        this._authService.logout();
        this._router.navigate(['/login']);
    }
}
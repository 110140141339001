import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from "./auth/auth.module";

import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { DataTablesModule } from 'angular-datatables';
import { InputSearchDirective } from './_directives/input-search.directive';

import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,
        InputSearchDirective
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        DataTablesModule,
        NgxSpinnerModule
    ],
    providers: [ScriptLoaderService],
    bootstrap: [AppComponent]
})
export class AppModule { }
/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase valida si el usuario es valido o no para permitir el inicio de sesión
*/


import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_services/user.service";
import { Observable } from "rxjs";
import { DycpService } from "../_services/dycp";

@Injectable()
export class AuthGuard implements CanActivate {

    private encript: DycpService = new DycpService();
    constructor(private _router: Router, private _userService: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        

        return this._userService.verify().map((res) => {
            // debugger;
            try {
                if (res.validado == true) {
                    return true;
                } else {
                    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
            } catch (x) {

                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }

        },
            error => {
                // error when verify so redirect to login page with the return url
                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            });




        // return this._userService.verify().map(
        //     data => {
        //         if (data.menssage !== "Error") {
        //             // logged in so return true
        //             return true;
        //         }
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     },
        //     error => {
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     });
    }
}
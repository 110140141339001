/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase se encarga de realizar las acciones relacionadas con la autenticación de la aplicación
*/


import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { textoModuloAdm } from '../texto';
import { NgForm } from '@angular/forms'
import { AjaxService } from '../_services/ajax.service';
import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert2';
import { DycpService } from './_services/dycp';
import { CompanyServiceService } from '../_services/company-service.service';


declare let $: any;
declare let mUtil: any;

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./auth.component.css']
})

export class AuthComponent implements OnInit {
    model: any = {};
    model2: any = {
        newPasswd: ''
        , confirmPasswd: ''
    };
    loading = false;
    returnUrl: string;
    //pantalla:any=screen.width
    pantalla: boolean = true;
    prueba: any;
    idIdioma: any = 1;
    idiomas: any = [];
    _showConfirmPassword: boolean = true;
    _showPassword: boolean = true;
    usuario: any;
    public encript: DycpService = new DycpService();

    @ViewChild('alertSignin',
        { read: ViewContainerRef }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup',
        { read: ViewContainerRef }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass',
        { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;

    constructor(
        public _router: Router,
        public _script: ScriptLoaderService,
        public _userService: UserService,
        public _route: ActivatedRoute,
        public _authService: AuthenticationService,
        public _alertService: AlertService,
        public cfr: ComponentFactoryResolver,
        public text: textoModuloAdm,
        public ajax: AjaxService,
        public spinner: NgxSpinnerService
        , public company: CompanyServiceService
    ) {

    }

    ngOnInit() {
        // console.log("ngOnInit ==> " + this.idIdioma);
        sessionStorage.setItem('idiomaApp', this.idIdioma);
        this.selectIdioma(this.idIdioma);
        this.model.remember = true;

        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                // Helpers.setLoading(false);
                this.spinner.hide();
                this.handleFormSwitch();
                this.handleSignInFormSubmit();
                this.handleSignUpFormSubmit();
                this.handleForgetPasswordFormSubmit();
            });


        //Attache for me
        this.style();
        this.prueba = window;
        if (this.prueba) {
            this.prueba.addEventListener('resize', this.style, false);
        }
        this.traerIdiomas();
    }

    style() {

        if (screen.width > 1400) {
            $('#loginForm').css('padding-top', '11rem');
            //$("form_singup").removeClass('');
        }
        else {
            $('#loginForm').css('padding-top', '0.1rem');
            //$("form_singup").addClass('');
        }


    }

    signin() {
        // console.log('signin')
        this.loading = true;

        if (this.idIdioma > 0) {
            this._authService.login(this.model.emailUser, this.model.passwordUser, this.idIdioma).then((result) => {
                // console.log(result);

                if (result) {
                    this.usuario = this.encript.desencriptar();
                    if (this.usuario.idEstadoUsuario == 4) {
                        localStorage.removeItem('currentUser');
                        sessionStorage.removeItem('currentUser');
                        $('#modalChangePasswd').modal({ backdrop: 'static', keyboard: false })
                        $("#modalChangePasswd").modal('show');
                    } else {
                        this.company.isExistsCompany().then((resultCompany) => {
                            if (resultCompany) {
                                this._router.navigate([this.returnUrl]);
                            } else {
                                this.company.validateCompany(this.returnUrl);
                            }
                        })
                        // this._router.navigate([this.returnUrl]);

                    }
                    // this._router.navigate([this.returnUrl]);    
                } else {
                    this.showAlert('alertSignin');
                    this._alertService.error(this.text.errorLogin);
                    this.loading = false;
                }
            });
        } else {
            swal({
                title: this.text.login[1].alertaIngresIdioa[0],
                text: this.text.login[1].alertaIngresIdioa[1],
                type: 'error',
                confirmButtonText: this.text.login[1].alertaIngresIdioa[2]
            })
        }

    }

    signup() {
        console.log('signup')
        this.loading = true;
        this._userService.create(this.model).subscribe(
            data => {
                this.showAlert('alertSignin');
                this._alertService.success(
                    'Thank you. To complete your registration please check your email.',
                    true);
                this.loading = false;
                this.displaySignInForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertSignup');
                this._alertService.error(error);
                this.loading = false;
            });
    }

    forgotPass() {
        // console.log('ssss');

        this.loading = true;
        this._authService.forgotPassword(this.model.email).subscribe(
            data => {
                // console.log(data._body);

                let body = JSON.parse(data._body)

                if (body.status == 1) {
                    console.log(data);
                    this.showAlert('alertSignin');
                    this._alertService.success(
                        this.text.login[1].alertaSuccess[0],
                        true);
                    this.loading = false;
                    this.displaySignInForm();
                    this.model = {};
                    $("#modalForgotPassword").modal('hide');
                } else {
                    this.showAlert('alertForgotPass');
                    this._alertService.error(this.text.login[1].alertError[0]);
                    this.loading = false;
                }
            }, error => {
                this.showAlert('alertForgotPass');
                this._alertService.error(this.text.login[1].alertError[0]);
                this.loading = false;
            });
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }

    handleSignInFormSubmit() {
        $('#m_login_signin_submit').click((e) => {
            let form = $(e.target).closest('form');
            form.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                    password: {
                        required: true,
                    },
                },
            });
            if (!form.valid()) {
                e.preventDefault();
                return;
            }
        });
    }

    displaySignUpForm() {
        let login = document.getElementById('m_login');
        mUtil.removeClass(login, 'm-login--forget-password');
        mUtil.removeClass(login, 'm-login--signin');

        mUtil.addClass(login, 'm-login--signup');
        mUtil.animateClass(login.getElementsByClassName('m-login__signup')[0], 'flipInX animated');
    }

    displaySignInForm() {
        let login = document.getElementById('m_login');
        mUtil.removeClass(login, 'm-login--forget-password');
        mUtil.removeClass(login, 'm-login--signup');
        try {
            $('form').data('validator').resetForm();
        } catch (e) {
        }

        mUtil.addClass(login, 'm-login--signin');
        mUtil.animateClass(login.getElementsByClassName('m-login__signin')[0], 'flipInX animated');
    }

    displayForgetPasswordForm() {
        let login = document.getElementById('m_login');
        mUtil.removeClass(login, 'm-login--signin');
        mUtil.removeClass(login, 'm-login--signup');
        mUtil.addClass(login, 'm-login--forget-password');
        // mUtil.animateClass(login.getElementsByClassName('m-login__forget-password')[0], 'flipInX animated');
    }

    handleFormSwitch() {
        document.getElementById('m_login_forget_password').addEventListener('click', (e) => {
            e.preventDefault();
            this.displayForgetPasswordForm();
        });

        // document.getElementById('btn-exit-forget-password').addEventListener('click', (e) => {
        //     e.preventDefault();
        //     this.displaySignInForm();
        // });

        document.getElementById('m_login_forget_password_cancel').addEventListener('click', (e) => {
            e.preventDefault();
            this.displaySignInForm();
        });

        // document.getElementById('m_login_signup').addEventListener('click', (e) => {
        //     e.preventDefault();
        //     this.displaySignUpForm();
        // });

        document.getElementById('m_login_signup_cancel').addEventListener('click', (e) => {
            e.preventDefault();
            this.displaySignInForm();
        });
    }

    handleSignUpFormSubmit() {
        document.getElementById('m_login_signup_submit').addEventListener('click', (e) => {
            let btn = $(e.target);
            let form = $(e.target).closest('form');
            form.validate({
                rules: {
                    idUser: {
                        required: true,
                    },
                    idStateSesion: {
                        required: true,
                    },
                    idStateUser: {
                        required: true,
                    },
                    idRol: {
                        required: true,
                    },
                    idDocumentType: {
                        required: true,
                    },
                    numberDocumentUser: {
                        required: true,
                    },
                    nameUser: {
                        required: true,
                    },
                    idPais: {
                        required: true,
                    },
                    idDepartamento: {
                        required: true,
                    },
                    idMunicipio: {
                        required: true,
                    },
                    idPostalCode: {
                        required: true,
                    },
                    lastnameUser: {
                        required: true,
                    },
                    emailUser: {
                        required: true,
                        email: true,
                    },
                    passwordUser: {
                        required: true,
                    },
                    passwordValidation: {
                        required: true,
                    },
                    addressUser: {
                        required: true,
                    },
                    phoneUser: {
                        required: true,
                    },
                    extUser: {
                        required: true,
                    },
                    movilUser: {
                        required: true,
                    },
                    empresaUser: {
                        required: true,
                    },
                    postUser: {
                        required: true,
                    },
                    imageUser: {
                        required: true,
                    },
                    lastUserAction: {
                        required: true,
                    },
                    authorizePerson: {
                        required: true,
                    },
                    emailAuthorizePerson: {
                        required: true,
                        email: true,
                    },
                    agree: {
                        required: true,
                    },
                },
            });
            if (!form.valid()) {
                e.preventDefault();
                return;
            }
        });
    }

    handleForgetPasswordFormSubmit() {
        document.getElementById('m_login_forget_password_submit').addEventListener('click', (e) => {
            let btn = $(e.target);
            let form = $(e.target).closest('form');
            form.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                },
            });
            if (!form.valid()) {
                e.preventDefault();
                return;
            }
        });
    }

    traerIdiomas() {
        let url = 'free/idiomas'
        this.ajax.getInfo(url, '', true).subscribe((data: any) => {
            // console.log(data);
            try {
                this.idiomas = data.result[0];
            } catch (x) {
                console.log(x);
            }
        })
    }

    selectIdioma(idIdioma) {
        sessionStorage.setItem('reload', '1');
        sessionStorage.setItem('idiomaApp', idIdioma);
        this.text.cambiarIdioma(idIdioma);
    }

    cancelResetPassword() {
        this.loading = false;
        $("#modalChangePasswd").modal('hide');
    }

    guardarNewPassword() {
        let newPassword: any = document.querySelector('#new-password');
        let confirmPasword: any = document.querySelector('#password-confirm');

        if (newPassword.value == confirmPasword.value) {
            this.spinner.show();
            let url: string = 'private/postResetPasword';
            this.ajax.postInfo(url, this.usuario.token, {
                idUsuario: this.usuario.idUsuario
                , email: this.usuario.emailUsuario
                , passwd1: this._authService.encriptarPasswd(newPassword.value)
                , passwd2: this._authService.encriptarPasswd(newPassword.value)
            }, false, this.usuario).subscribe((res) => {
                console.log('Responde=> ', res)
                this.spinner.hide();
                if (res.status == 1) {
                    this.encript.encriptar(this.usuario);
                    newPassword.value = '';
                    confirmPasword.value = '';
                    this._router.navigate([this.returnUrl]);
                } else {
                    this.loading = false;
                    this.showAlert('alertSignin');
                    this._alertService.error(this.text.login[1].modalResetPasswod[5]);
                    this.loading = false;
                }
            })
        } else {
            this.loading = false;
            this.showAlert('alertSignin');
            this._alertService.error(this.text.login[1].modalResetPasswod[6]);
            this.loading = false;

        }
    }

    hidePassword(element) {
        $("#" + element).attr('type', 'text');
        if (element != 'new-password') {
            this._showConfirmPassword = true;
        } else {

            this._showPassword = true;
        }
    }
    showPassword(element) {
        $("#" + element).attr('type', 'password');
        if (element != 'new-password') {
            this._showConfirmPassword = false;
        } else {
            this._showPassword = false;
        }
    }

    cancelModal() {
        this.loading = false;
    }
}
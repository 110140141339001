export class textoModuloAdm {

    // tabla log
    public titleTableLog: string;
    public formEmpresa: any;
    public formEmpresaArray: any;

    //Campos login
    public titulo: string;
    public inpEmail: string;
    public inpPasswd: string;
    public remember: string;
    public forgetPswd: string;
    public signIn: string;
    public signUp: string;
    public stepSignUp: string;
    public agree: string;
    public termsAndCond: string;
    public regainPaswd: string;
    public enterEmailRecovery: string;
    public requestButton: string;
    public cancelButton: string;
    public dontAccount: string;
    public primaryText: string;
    public secondaryText: string;
    public alertaInactividad: any;

    //Campos del formulario de registro
    public inpDocumentType: string;
    public inpNumberDocumentUser: string;
    public inpNameUser: string;
    public inpLastnameUser: string;
    public inpEmailUsuario: string;
    public inpPasswordUser: string;
    public inpPasswordValidation: string;
    public inpAddressUser: string;
    public inpPhoneUser: string;
    public inpExtUser: string;
    public inpMovilUser: string;
    public inpCompanyUser: string;
    public inpAppointmentUser: string;
    public inpAuthorizePerson: string;
    public inpEmailAuthorizePerson: string;


    //Opciones genericas
    public selectDocumentType: string;
    public fieldSelect: string;
    public selectCountry: string
    public selectDepartament: string;
    public selectMunicipaly: string;
    public selectPostalCode: string;
    public fieldRequire: string;
    public btnBack: string;


    //Mensajes de error
    public errorLogin: string;

    //Campos wizard carga empresa
    public wizardcargaempresa: any;

    //Campos wizard carga usuario
    public wizardcargausuario: any;

    //Campos wizard asignación
    public wizardasignacion: any;

    //Campos wizard carga de tóxicos
    public wizardtoxicos: any;

    //Campos wizard carga de tóxicos individual
    public wizardtoxicoIndividual: any;

    //texto tabla Rol
    public tablarol: any;

    //vista treeview rol
    public treeviewrol: any;

    //Tabla usuario
    public tablausuario: any;

    //Tabla custodio
    public tablacustodio: any;

    //Campos formGroupArticulo Usuario
    public formulario: any;

    //TablaEmpresa
    public tablaempresa: any;

    //Campos formulario empresa
    public formularioempresa: any;

    //Campos menu lateral
    public nombremodulos: any;

    //Opciones genericas
    public opcionesgen: any;

    public modCatalogacion: any;

    public solicituddiadia: any;
    //Consulta materiales
    public consultaMaeriales: any;

    public editarUsuario: any;

    public crearUsuario: any;
    public asignarEmpresaUsuario: any;
    public editarEmpresa: any;

    public indicadoresCatalogacion: any;
    public valueSeleccion: any;
    public arrMenu: any;

    public asociarIdiomaEmpresa: any;
    public arrSerResImpoMas: any;// Mensajes de respuesta del servidor de un importe masívo
    public login: any;

    public arrRolFrms: any;
    public arrUsuarioFrms: any;
    public arrUMBFrms: any;
    public arrInputFrmSolicitud: any;
    public wizardMaterial: any;
    public dataTableText: any;

    //Modulo Inventario
    public inventarioModulo: any;

    //Tags custom search
    public tagsCustomSearch: any;
    //Tags Inspeccion maestro materiales
    public arrInspeccionMMateriales: any = [];
    public arrIndicatorsInspection: any = [];
    public arrEstadoMMaterial: any = [];
    public arrInspeccionDinamicoSel: any = [];
    public arrTituloSolicitudToxico: any = [];
    public arrMestroToxico: any = [];
    public arrAsignar: any;
    public mensajeInicioSesion1: any;
    public mensajeInicioSesion2: any;

    //Arreglo de titulo tipo administración
    public arrTipoAdministracion: any = [];

    public tituloSelectInspeccion: any;
    public arrCreadorAprobador: any = [];
    public rolValidacion: any;
    public arrAsignacionAlmacen: any;
    public arrAtencionReserva: any;
    public arrAtencionReservaHerramientas: any;

    public arrActBtn: any = [];

    //Arreglo de titulos de cargue masivo
    public arrTituloImpMas: any = [];
    public arrImporteSolicitud: any = [];
    public arrTituloImpCarac: any = [];
    public arrImporteCaracteristica: any = [];

    public cargaMasivaSocket: any = [];
    public arrSalidaReservaProducto: any = [];
    public tipoMovimientoBaja: any = [];
    public arrSalidaReporteProducto: any = [];
    public arrTextMotivoBaja: any = [];
    public arrTextoBaja: any = [];
    public arrTextoTipoMov: any = [];
    public arrTextoDevolucion: any = [];
    public arrReporteActTub: any = [];
    public permission: any = [];
    public modules: {};
    public stateUser: {};
    public associate_divisa_to_company: any;
    public currencies: any;
    public physical_state: any = [];

    public textReferencia: any = "";
    public textFabricante: any = "";

    public arrTextTomaInvTub: any = [];
    public arrTextTomaInvTubExt: any = [];
    public arrEstadoConteoInvTuberia: any = [];
    public arrRepoInvtExtFile: any = [];
    public arrConciliacionExtTuberia: any = [];
    public arrReporteConciInvExtTuberia: any = [];
    public correcionSli: any = [];
    public arrTextConteoIntTuberia: any = [];
    public arrReajusteTuberia: any = [];
    public arrReajusteActivo: any = [];
    public arrReporteTomaInventario: any = [];
    public arrTextEstadoReajuste: any = [];
    public arrTextEmail: any = [];
    public indicators: any = {};
    public stateRequest: any = {};
    public index: any = [];
    public arrEstadoFisicoMaterial: any = [];
    public modMaintenance: any = {};
    public stateInspection: any = [];
    public typeMovements: any = {};

    constructor() {

        let idIdioma = +sessionStorage.getItem(`idiomaApp`);
        // idIdioma = 1;
        // console.log(idIdioma);
        if (idIdioma > 0) {
            this.cambiarIdioma(idIdioma);
        } else {
            this.idiomaEspanol();
        }

    }

    cambiarIdioma(idIdoma) {
        // console.log('changing language');

        switch (+idIdoma) {
            case 1:
                this.idiomaEspanol();
                break;
            case 2:
                this.idiomaIngles();
                break;
            // case 3:
            //     this.idiomaFrances();
            //     break;
            default:
                // console.log(`Default!`);
                this.idiomaEspanol();
                break;
        }
    }

    idiomaEspanol() {
        this.arrEstadoFisicoMaterial = [
            "-Vacio-"
            , "Nuevo"
            , "Usado"
            , "Reparado"
            , "Por reparar"
            , "Dañado"
        ];

        this.arrTextEmail = [
            {
                asunto: "Creación de Usuario SLI.", titulo: "Creación de Usuario SLI.", body: `
                <p> <h3> !Hola¡ <b> [name], </h3></b></p>
                <br>
                <p> Se ha creado su usuario satisfactoriamente, los datos para ingresar son los siguientes: </p>
                <p><b> URL:</b> [url] </p>
                <p><b> Usuario:</b> [user] </p>
                <p><b> Contraseña:</b> [password] </p>
                <p> Si llega a tener alguna duda o inconveniente con el ingreso a la plataforma favor comunicarlo respondiendo a este mismo correo <a href="sli.support@stockgi.com.">sli.support@stockgi.com</a>. </p>
                <br>
                <p> Saludos,</p>
                <p> Area de soporte SLI.</p>
            `}
        ]

        this.arrTextEstadoReajuste = ["Sí", "No"];

        this.arrReporteTomaInventario = [
            "Reporte toma inventario"
            , "Seleccione toma inventario"
            , "Archivos agregados a la herramienta"
            , "El material no fue conciliado"
        ];

        this.arrReajusteActivo = [
            "Reajuste inventario activo" //1
        ];
        this.arrReajusteTuberia = [
            "Reajuste inventario" // 0
            , "Seleccione conciliación" //1
            , "Información general de la toma de inventario" //2
            , "Toma inventario" //3
            , "de conteos" //4
            , "de conteos" //5
            , "Fecha creación" //6
            , "Usuario responsable" //7
            , "Listado de productos" //8
            , "Diferencia" //9
            , "Conciliación" //10
            , "Archivos" //11
            , "Rechazar" //12
            , "Observación reajuste" //13
            , "¿Está seguro de hacer el reajuste?" //14
            , "Se registró la información correctamente" //15
            , "Error al intentar insertar el material rechazado a la conciliación" //16
            , "Error al actualizar el estado del material en conciliación" //17
            , "Error al intentar actualizar el estado de la conciliación" //18
            , "No hay materiales conciliados para insertar" //19
            , "Error al crear el movimiento" //20
            , "Error al agreagr el movimiento del tubo" //21
            , "Error al actualizar el estado" //22
            , "Error desconocido" //23
            , "Usuario aprobador" //24
        ];
        this.arrTextConteoIntTuberia = [
            "Conteo toma inventario" // 0
            , "Almacén" // 1
            , "Conteo N°" // 2
            , 'Para seguir con el conteo da click en el botón "Mostrar conteos" y seleccione el conteo' // 3
            , 'Mostrar conteos' // 4
            , 'No hay conteos pendientes para ti' // 5
            , 'Cód. SLI' // 6
            , 'N° tubo' // 7
            , 'Agregar a faltantes' // 8
            , 'Leer código' // 9
            , 'Listado de productos contados' // 10
            , "Código SLI" // 11
            , "Desc. corta" // 12
            , "Umb" //13
            , "Metros" //14
            , "Pies" //15
            , "Pulgadas" //16
            , "Proveedor" //17
            , "Eliminar" //18
            , "Conteos en proceso" //19
            , "Conteo" //20
            , "Almacén" //21
            , "Fecha creación" //22
            , "Aprobador" //23
            , "Seguir conteo" //24
            , "¿Está seguro de eliminar el tubo del conteo?" //25
            , "El número de tubo debe ser único" //26
            , "Todos los campos son obligatorios" //27
            , "¿Está seguro de guardar el conteo?" //28
            , "¿Está seguro de enviar el conteo?" //29
            , "Se registró la información correctamente" //30
            , "Error al registrar conteo" //31
            , "Debe agregar al menos un tubo" //32
            , "No se ha seleccionado el conteo a modificar" //33
            , "Seleccione la divisa" //34
            , "Precio" //35
            , "Estado físico" //36
            , "Divisa" //37
            , "No se puede crear el conteo. Puede ser porque la toma ya cuenta con el número limite de conteos o hay uno en proceso" //38
            , "Placa - Serial" //39
        ];
        this.arrReporteConciInvExtTuberia = [
            "Reporte conciliacion inv. ext. tubería"
            , "Descargar"
            , "Estado"
        ];
        this.arrConciliacionExtTuberia = [
            "Conciliación toma inventario" // 0
            , "Seleccione conciliación" // 1
            , "Agregar material" // 2
            , "Existencia" // 3
            , "Archivos agregados al tubo" // 4
            , "Nombre del archivo" // 5
            , "Tipo de archivo" // 6
            , "Eliminar" // 7
            , "Error al cambiar el estado del tubo" // 8
            , "Debe habilitar la conciliación" // 9
            , "Tipo de archivo no admitido, sólo se admiten los siguientes tipos: xlsx, png, pdf, jpg, docx, csv, txt, jpga, jpeg." // 10
            , "Error al convertir archivo, puede que el archivo esté defectuoso" // 11
            , "Conciliar" // 12
            , "Está seguro de envíar la conciliación" // 13
            , "Está seguro de guardar la conciliación" // 14
            , "¡Se guardó la conciliacion con exito!" // 15
            , "Error al intentar guardar la conciliación" // 16
            , "Cerrar" // 17
            , "El número de tubo " // 18
            , " ya se encuentra registrado para el material " // 19
            , "El número de tubo debe ser único" // 20
            , "Todos los campos son obligatorios" // 21
        ];
        this.arrRepoInvtExtFile = [
            "Reporte de conteo tubería" //0
            , "Toma inventario" //1
            , "Responsable" //2
            , "Crear conteo" //3
            , "Listado general" //4
            , "Debe seleccionar la toma física de inventario" //5
            , "¿Está seguro de cancelar el conteo?" //6
            , "¿Está seguro de crear el conteo?" //7
            , "Debe llenar los campos marcados con: *" //8
            , "Se canceló el conteo correctamente" //9
            , "Error al cancelar conteo" //10
            , "Se creó el conteo correctamente" //11
            , "La toma física de inventario ya cuenta con el máximo de conteos" //12
            , "Error al crear conteo" //13
            , "Reporte de conteo en toma de inventario físico" //14
            , "¿Está seguro de cancelar la toma física de inventario?" //15
            , "Debe seleccionar la toma física de inventario" //16
            , "Se canceló la toma física de inventario correctamente" //17
            , "Error al cancelar la toma física de inventario" //18
            , "Conciliación" //19
            , "Limpiar" //20
            , "La toma física de inventario tiene conteo en proceso" //21
            , "Importe toma inv. ext. tubería" //22
            , "Limpiar formulario" //23
            , "Conciliación N°" //24
            , "No se puede crear el conteo porque la toma de inventario está en proceso de conciliación" //25
            , "¿Está seguro de crear la conciliación?" //26
            , "¡Se registró la información correctamente¡" //27
            , "No se puede crear la conciliación porque ya está registrada para la toma de inventario" //28
            , "No se puede crear la conciliación porque tiene conteos en proceso" //29
            , "Error al crear conciliación" //30
        ];
        this.arrEstadoConteoInvTuberia = ["En proceso", "Finalizado", "Cancelado"];
        this.textReferencia = "Referencia(s)";
        this.textFabricante = "Fabricante";
        this.correcionSli = [
            'Filtrar',
            'Guardar',
            'Cancelar',
            'Cerrar',
            'Seleccione catalogador',
            'Buscar código antiguo',
            'Buscar código SLI',
            'Buscar ERP',
            '¿Recordar?',
            'Crear',
            'Modificar',
            'Inactivar',
            'Creada',
            'Rechazada',
            'Tratada',
            'Enviada', // 15
            'Cancelada',
            'Seleccionar',
            'Texto', // 19
            'Lista desplegable',
            'Grupo, única opción',
            'Grupo multiple selección',
            'Fecha',
            'Texto amplio'
            , `Solicitudes`
            , `Buscar solicitud`
            , `Actualizar`
        ];

        this.arrTextTomaInvTub = [
            "Toma físico de inventario interno" // 0
            , "Responsable" // 1
            , "El almacén cuenta con el siguiente conteo en progreso" // 2
            , "Conteo" // 3
            , "Responsable" // 4
            , "Fecha" // 5
            , "Cancelar" // 6
            , "Guardar" // 7
            , "Debe seleccionar almacén" // 8
            , "Debe seleccionar Responsable del conteo" // 9
            , "Hay conteo en progreso, para crear un nuevo conteo debe terminar o cancelar el actual" // 10
            , "¿Está seguro de crear la toma física de inventario?" // 11
            , "¿Está seguro de cancelar la toma física de inventario?" // 12
            , "Se creó el procedimiento para toma física de inventario con éxito" // 13
            , "Error al intentar guardar el conteo" // 14
            , "Se canceló el conteo con éxito" // 15
            , "Error al intentar cancelar el conteo" // 16
            , "El almacén seleccionado no cuenta con inventario" // 17
            , "Toma física de inventario N°" // 18
            , "Eliminar" // 19
            , "¿Está seguro de cancelar el conteo?" // 20
            , "Filtre en almacén para consultar la toma de inventario físico que quiere cancelar" // 21
            , "Se canceló la toma física de inventario con éxito" // 22
            , "Toma inventario externo tubería" // 23
            , "Abra el archivo con una aplicación de hojas de culculo y llénelo con la información requerida" // 24
            , "Seleccione el usuario responsable del primer conteo" // 25
            , "En la línea" // 26
            , "se necesita el valor del código ERP" // 27
            , "se necesita el valor de cualquiera de las dos descripciones" // 28
            , "se necesita el valor la unidad de medida base" // 29
            , "se necesita cualquier valor en planta, centro o almacén" // 30
            , "se necesita el valor número de tubo" // 31
            , "se necesita el valor de medida en metro, pie o pulgada" // 32
            , "el número de tubo ya se ingresó" // 33
            , "hubo un error desconocido" // 34
            , "Conteo toma inv. interno" // 35
            , "Conciliación toma inv. int. tubería" // 36
            , "Crear conteo" // 37
            , "Reporte general" // 38
            , "No se puede crear un conteo ni una nueva toma porque el almacén tiene tomas en proceso o tiene asignado el número máximo de conteos" // 39
            , "Error al intentar cancelar la toma física de iventario" // 40
            , "Cancelar toma iventario" // 41
            , "Limpiar formulario" // 42
        ];

        this.arrTextTomaInvTubExt = [
            "Toma de inventario físico externo tuberia" // 0
            , "Conteo toma inventario" // 1
            , "Conteo N°" // 2
            , 'Para seguir con el conteo da click en el botón "Mostrar conteos" y seleccione el conteo' // 3
            , "Mostrar conteos" // 4
            , "No hay conteos pendientes para ti" // 5
            , "Filtre el material" // 6
            , "Filtre el material por todos los valores separados por *" // 7
            , "Limpiar" // 8
            , "Leer código" // 9 
            , "Agregar adicional" // 10
            , "Contar" // 11
            , "Código ERP" // 12
            , "N° tubo" // 13
            , "Metro" // 14
            , "Pie" // 15
            , "Pulgada" // 16
            , "Descripción" // 17
            , "Buscar" // 18
            , "Fabricante" // 19
            , "Referencia" // 20
            , "UMB" // 21
            , "Divisa" // 22
            , "Costo unitario" // 23
            , "Impuesto" // 24
            , "Proveedor" // 25
            , "Valoración" // 26
            , "Planta" // 27
            , "Centro" // 28
            , "Almacén" // 29
            , "Ubi. archivo" // 30
            , "Observación" // 31
            , "Ubi. conteo"//En íngles sería field de celda // 32
            , "Campo 2"//En íngles sería field de celda // 33
            , "Campo 3"//En íngles sería field de celda // 34
            , "Aprobador"// 35
            , "Conteos en proceso" // 36
            , "Fecha creación" // 37
            , "Fecha creación" // 38
            , "Seguir conteo" // 39
            , "Agregar material" // 40
            , "Atención" // 41
            , "Debe ingresar el código ERP" // 42
            , "Debe ingresar el número de tubo" // 43
            , "El número de tubo ya está registrado para esta toma de inventario" // 44
            , "Debe ingresar al menos una descripción" // 45
            , "Debe ingresar la unidad de medida base" // 46
            , "Debe agregar planta, centro o almacén" // 47
            , "Debe ingresar el metro, pie, o pulgada" // 48
            , "¿Está seguro de guardar el conteo?" // 49
            , "¿Está seguro de enviar el conteo?" // 50
            , "Se registró la información correctamente" // 51
            , "Error al registrar conteo" // 52
            , "Debe agregar al menos un tubo" // 53
            , "No se ha seleccionado el conteo a modificar" // 54
            , "N° toma inventario físico" // 55
        ]

        this.arrReporteActTub = [
            `Num. tubo` //0
            , `Metros` //1
            , `Pies` //2
            , `Pulgadas` //3
            , `REPORTE DEVOLUCION TUBERÍA` //4
            , `REPORTE DEVOLUCION HERRAMIENTA` //5
            , `Placa` //6
            , `Serial` //7
            , `Impuesto` //8
            , `Descuento` //9
            , `Fecha creacion` //10
            , `Remisión` //11
            , `Reporte devolución tubería` //12
            , `Reporte devolución herramienta` //13
            , `Devolución Herramienta/Activo` //14
            , `La placa o el serial ya está registrado para el material` //15
        ];

        this.arrTextoDevolucion = [
            `Tipo movimiento`
            , `Productos del movimiento`
            , `Almacén origen`
            , `Almacén destino`
            , `Centro de costos`
            , `Sucursal`
            , `Destino`
            , `Factura`
            , `Nota crédito`
            , `Cant. mov.`
            , `Cant. dev.`
            , `Cant. stock`
            , `No se encontraron resultados con los parámetros indicados`
            , `Debes seleccionar tipo de movimiento y almacén`
            , `Error al ingresar movimiento. Esto puede ser causado por que no hay suficiente inventario, la cantidad devuelta es mayor a la cantidad del movimiento o la suma de las cantidades devuelta es mayor a la cantidad del movimiento en los siguientes códigos`
            , `Devolución`
            , `¿Está seguro de realizar la devolución?`
            , `Crear devolución`
            , `Reporte devolución`
            , `de` /* En inglés sería OF */
            , `Proveedor`
            , `Tipo Mov.`
            , `Planta origen`
            , `Centro origen`
            , `Planta destino`
            , `Centro destino`
            , `Reserva`
            , `Cant. mov.`
            , `Cant. dev.`
            , `Cant. nuevo`
            , `Cant. usado` //29
            , `Cant. reparado` //30
            , `Cantidad del movimiento` //31
            , `Cantidad devuelta` //32
        ];

        this.arrTextoBaja = [
            `Motivo de baja`
            , `Crear baja`
            , `Modificar baja`
            , `Reporte de bajas`
        ];
        this.arrTextMotivoBaja = [``, `Venta`, `Obsolesencia`, `Perdida`];
        this.arrTextoTipoMov = [``, `Entrada`, `Salida`, `Devolución`, `Traslado`, `Baja`, `Asignación`];

        this.arrSalidaReporteProducto = [
            `Reporte de salidas`
            , `Crear salida`
            , `Cód. movimiento`
            , `Almacén`
            , `Desc. corta`
            , `Cantidad`
            , `Estado`
            , `Centro costo`
            , `Sucursal`
            , `Destino`
            , `Fecha creación`
            , `Solicitud`
            , `Observación`
        ];

        this.cargaMasivaSocket = [
            "Advertencia",
            'Para cancelar y guardar los registros creados hasta ahora presione "SI", en caso de no querer guardarlos presione "NO" y en caso de querer salir sin realizar ninguna acción presione la "X" en la parte superior de la ventada. ¿Qué acción desea realizar?'
        ];

        this.tipoMovimientoBaja =
            [
                "Producto vendido (Venta)",
                "Producto obsoleto (Obsolensencia)",
                "Producto perdido (Perdida)",
            ];

        this.alertaInactividad = [
            `¡Cierre por inactividad!`
            , `La aplicación se cerrara en `
            , `segundos.`
        ]

        this.arrSalidaReservaProducto = [
            "Salida reserva"
            , "Almacén origen"
            , "Centro de costo"
            , "Sucursal"
            , "Destino"
            , "Solicitante"
            , "Aprobador"
            , "Observaciones del aprobador"
            , "Código ERP"
            , "Cant. solicitada"
            , "Cant. entregada"
            , "Cant. faltante"
            , "No hay una reserva con la información ingresada, puede que la reserva ya no esté disponible"
            , "Info. inventario"
            , "Liberar"
            , "Proveedor"
            , "Precio"
            , "Cant. disp."
            , "Despachar"
            , "No se encontró ninguna reserva con los parámetros indicados"
            , "REPORTE DE LA INFORMACIÓN DE LA RESERVA"
            , "La cantidad ingresada supera la solicitada por la reserva"
            , "No se ha realizado ningún cambio"
            , "¿Está seguro de realizar la salida?"
            , "¿Está seguro de crear la salida?"
            , "No se realizó ninguna acción debido al(los) siguiente(s) error(es)."
            , "La cantidad que ingresó es mayor a la solicitada en el código SLI:"
            , "No hay inventario suficiente para la cantidad ingresada en el código SLI:"
            , "Se registró el movimiento correctamente con el código:"
            , "Crear"
            , "Almacen destino"
            , "Debe diligenciar información necesaria para realizar el movimiento"
            , "Campos necesarios para realizar la atención a la reserva"
            , {
                salidaReservaHerramientas: [
                    `Serial`
                    , `Placa`
                    , `Proveedor`
                    , `Precio`
                    , `Despachar`
                ]
                , salidaReservaTuberia: [
                    `No. Tubo`
                    , `Medidas`
                    , `Proveedor`
                    , `Precio`
                    , `Despachar`
                    , `Metro(s)`
                    , `Pie(s)`
                    , `Pulgada(s)`

                ]
            }
            , "La reserva no cuenta con materiales o ya se atendieron todos"
        ];

        this.arrImporteCaracteristica = [
            "Cargue masivo característica"
            , "Lista de caracteristicas"
            , "Generar con todos los productos"
            , "Clase"
            , "Código"
            , "Abreviación"
            , "Grupo"
            , "Tipo material"
            , "Generar archivo"
            , "Seleccione archivo"
            , "Cargar"
            , "Cancelar"
            , "Resultados de la importación"
            , "Imprimir"
            , "Volver a importar"
            , "Error en guardar la importación"
            , "Se descartaron todos los cambios debido a los siguientes errores: <br>"
            , "• Hay observaciones incompletas de la característica de la línea "
            , "• Hay característica con las traducciones incompletas en la línea "
            , "• Error desconocido al insertar característica, puede ser provocado por caracteres especiales en el texto o modificaciones del archivo en la línea "
            , "• Error al intentar consultar índex de la característica en la línea "
            , "• Error al insertar la característica en la línea "
            , " Advertencias "
            , "• La característica de la línea "
            , " ya está registrada. <br>"
            , "¡Excelente!"
            , "Se han registrado ("
            , "). Las siguientes líneas no se insertaron:<br>"
            , "• La característica de la línea "
            , " ya está registrada. <br>"
            , "Se han registrado todas las característica con éxito"
            , `Reiniciar búsqueda`
            , "Errores encontrados" // 32
            , "Posición" // 33
            , "Registro" // 34
            , "Error" // 35
        ];

        this.arrTituloImpMas = [
            "idTipoSolicitud"
            , "codigoToxico"
            , "codigoERP"
            , "codigoSLI"
            , "descripcionProducto"
            , "aplicacionEspecifica"
            , "parteNumero"
            , "tipoEquipoAplica"
            , "fabricante"
            , "modeloAplica"
            , "clasificacionNFPA"
            , "umb"
            , "otraInfo"
            , "ubicacion"
        ];

        this.arrTituloImpCarac = [
            "idClase"
            , "clase"
            , "codigo"
            , "abreviacion"
            , "grupo"
            // , "tipoMaterial"
            , "multivalor"
        ];

        this.arrImporteSolicitud = [
            "Carga masiva solicitud"
            , "Reporte solicitud"
            , "Genere y descargue el archivo haciendo clic"
            , "aquí"
            , "Ingrese la información necesaria teniendo en cuenta cual es la información de tipo id proporcionada en el mismo archivo con su respectiva descripción e información "
            , "IMPORTANTE:"
            , " • No modifique la estructura básica del archivo."
            , " Genere el archivo cada vez que vaya a importar, porque los campos dinámicos pueden ser desactivados o eliminados."
            , "Error"
            , "Se descartaron las modificaciones debido a errores en las siguientes líneas: <br/>"
            , " • Error al insertar el producto de la línea: "
            , " • El id en los valores dinámicos no existe en la línea: "
            , " • En los campos dinámicos obligatorios no se adicionó valores en la línea: "
            , "¡Excelente!"
            , "Se realizó el cargue correctamente"
            , "registros"
            , " • El archivo que se genera tiene dos pestañas para obtener información: Catálogo y ubicación"
        ]

        this.arrActBtn = [
            "Editar"
            , "Habilitar/Deshabilitar"
            , "Eliminar"
            , "Cancelar"
            , "Información básica"
        ];
        this.arrAsignacionAlmacen = [
            "Asignar almacén"
            , "Selección de usuario"
            , "Asignar almacenes principales"
            , "Asignar almacenes destino"
            , "Agregar almacén"
            , "Guardar"
            , "¿Está seguro de eliminar la asignación al almacén?, estos cambios se tomaran cuando des clic en guardar"
            , "El almacén ya está registrado"
            , "REPORTE DE ALMACENES ASIGNADOS"
            , "Seleccione un usuario"
            , "Debe asignar al menos un almacén al usuario"
            , "¿Está seguro de guardar el registro?"
            , "Error al eliminar la asignación"
            , "Se Registró la información correctamente"
            , "¿Está seguro de cancelar el registro?"
        ];

        this.arrAtencionReserva = [
            "Atención reserva"
            , "Lista de reservas"
            , "Planta"
            , "Centro"
            , "Almacén"
        ];
        this.arrAtencionReservaHerramientas = [
            "Atención reserva"
            , "Lista de reservas"
            , "Planta"
            , "Centro"
            , "Almacén"
            , {
                alertas: [
                    `No hay inventario en el siguiente lugar de origen: `
                    , `No hay inventario del material `
                    , `Ya ha completado la cantidad solicitada por la reserva`
                ]
                , modal: [
                    `Realizado`
                ]
            }
        ];
        this.arrCreadorAprobador = [
            "Asignar creadores y aprobadores "
            , "Selección de usuario"
            , "Agregar creadores"
            , "Agregar aprobadores"
            , "Agregar almacén"
            , "Contabilidad"
            , "Creador"
            , "Aprobador"
            , "Agreagar usuario"
            , "Usuarios agregados"
            , "Seleccione los almacenes a los cuales quiere dar permiso"
            , "Planta"
            , "Centro"
            , "Tipo adm."
            , "Almacén"
            , "Acción"
            , "Agregar centro de costo"
            , "Agregar sucursal"
            , "Agregar destino"
            , "Debe seleccionar un centro de costo"
            , "El Centro de costo ya está agregado"
            , "Debe seleccionar una sucursal"
            , "La sucursal ya está agregada"
            , "Debe seleccionar un destino"
            , "El destino ya está agregado"
            , "El usuario ya está agregado"
            , "¿Está seguro de guardar o actualizar el registro?"
            , "Debe adicionar un aprobador como mínimo"
            , "Debe adicionar un almacén como mínimo"
            , "Debe adicionar un centro de costo como mínimo"
            , "Debe adicionar una sucursal como mínimo"
            , "Debe adicionar un destino como mínimo"
            , "Debe adicionar un creador como mínimo"
            , "Debe seleccionar el usuario"
            , "Debe seleccionar creador o aprovador"
            , "Error al actualizar la información"
            , "Se actualizó la información correctamente"
            , "¿Está seguro de cancelar el proceso, los cambios no se han guardado?"
        ];
        this.rolValidacion = [
            `!Error¡`,
            `El usuario `,
            ` no tiene acceso a esta funcionalidad`
            , "Error al validar el acceso al usuario"
            , "Token no valido o expirado"
        ]

        this.tituloSelectInspeccion = "Seleccione inspección";

        this.arrTipoAdministracion = {
            AM: `Administración de productos`
            , AA: `Administración de activos`
            , AT: `Administración de tuberias`
            , AH: `Administración de herramientas/activos`
            , AS: `Administración de servicios`
        };

        this.login = [
            `Seleccionar`,
            {
                modalSelect: [
                    `Seleccione una empresa`,
                    `Seleccionar`,
                    `Aceptar`,
                    `Cancel`,
                    `Empresa`,
                    `Idioma`
                ],
                alertBuscarIdioma: [
                    `¡Idiomas no relacionados!`,
                    `La empresa seleccionada no cuenta con idiomas asociados`,
                    `Aceptar`
                ],
                alertaIngresIdioa: [
                    `Idioma no seleccionado`,
                    `Para ingresar es necesario seleccionar un idioma`,
                    `Aceptar`
                ],
                alertaSuccess: [
                    `Contraseña restaurada, las instrucciones llegaran a su correo.`
                ],
                alertError: [
                    `Error al realizar al restaurar la contraseña, comuniquese con el administrador del sistema.`
                ]
                , modalResetPasswod: [
                    'Cambio de contraseña'
                    , `Nueva contraseña`
                    , `Repita la contraseña`
                    , `Guardar`
                    , `Cancelar`
                    , `Error al restaurar la contraseña, por favor comuniquese con el administrador`
                    , `Las contraseñas ingresadas no coinciden`
                ]


            }
        ]

        this.tagsCustomSearch = [
            `Consulta de catálogo` // 0
            , `Escriba varios parámetros separados por *` // 1
            , `Consulte por cualquier campo de maestro de producto` // 2
            , `Crear maestro Producto` // 3
            , `Código SLI` // 4
            , `Información` // 5
            , `Código ERP` // 6
            , `Producto` // 7
            , `Código Producto` // 8
            , `Código Tipo` // 9
            , `Tipo` // 10
            , `Código grupo Productos` // 11
            , `Grupo` // 12
            , `Abreviación Gr. M.` // 13
            , `Código unidad medida` // 14
            , `Unidad medida` // 15
            , `Valor` // 16
            , `Característica` // 17
            , `Fabricante` // 18
            , `Abre. Fabricante` // 19
            , `Referencia` // 20
            , `Buscando . . .` // 21
            , `Clic en mostrar en tabla para listar todos los resultados` // 22
            , `Únicos resultados` // 23
            , `Código tóxico` // 24
            , `Desc. larga` // 25
            , `Desc. corta` // 26
            , `Inspección` // 27
            , `Comentarios` // 28
            , `Maestro tóxico` // 29
            , `Solicitud (tóxico)` // 30
            , `Listar resultado` // 31
            , `Codigo Producto` // 32
            , `Codigo Grupo` // 33
            , `Unidad` // 34
            , `Catalogador creador` // 35
            , `Fecha de creación` // 36
            , `Catalogador aprobador` // 37
            , `Fecha de aprobación` // 38
            , `Desc. antigua` // 39
            , `Comentario de Inspeccion` // 40
            , `Estado calidad` // 41
            , `Gestion de calidad` // 42
            , `Ver en formulario` // 43
            , `Tipo producto` // 44
            , `Tipo administración` // 45
            , `Creador/Modificador` // 46
            , {
                modal: [
                    `Actualizar código ERP`
                    , `Guardar`
                    , `Cancelar`
                    , `Dígite el código ERP`
                    , `¿Está seguro de que cambia el estado de inspección al material `
                    , `Sí`
                    , `No`
                    , `Confirmación`
                ]
                , tableModal: [
                    `Cod. SLI`
                    , `Descripción corta`
                    , `Inspección`
                    , `Cod. Antiguo`
                    , `Creador/Modificador`
                    , `Código ERP`
                    , `Limite de resultados`
                    , "Fecha inicio"
                    , 'Fecha fin'
                ]
                , alerts: [
                    `Buen trabajo`
                    , `Error`
                    , `¿Desea actualizar el código ERP?`
                    , `Sí`
                    , `No`
                    , `Se actualizo el código ERP satisfactoriamente`
                    , `Se genero un error al actualizar el código SLI`
                    , `Confirmación`
                    , `La solicitud seleccionada cuenta con un código SLI asociado, ¿desea obtenerlo?`
                    , `Ha cambiado el producto/solicitud asociado a este codigo SLI, ¿Desea actualizar el material?`
                    , `Se actualizo la información correctamente`
                    , `Error al actualizar la información`
                ]
            } // 47
            , `UMB` // 48
            , `Consulte por códiglo SLI, código ERP, nombre o cód. material, nombre o cód grupo, fabricante o referencia` // 49
            , `Descripciones adicionales` // 50
            , `Cod. Anitiguo` // 51
            , ` Desc. Anitiguo` // 52
            , `Buscar material` // 53
            , `Características vacias` // 54
            , "Observación Maestro Material" // 55
            , "Cod. Tipo material" // 56
            , "Cod. UMB" // 57
        ];

        this.arrTituloSolicitudToxico = [`parteNumero`
            , `descripcionProducto`
            , `aplicacionEspecifica`
            , `tipoEquipoAplica`
            , `fabricante`
            , `modeloAplica`
            , `Código toxico`
            , `CódigoErp`
            , `CódigoSli`
            , `clasificacionNfpa`
            , `otraInformacion`
            , `codificacionUMB`];

        this.arrMestroToxico = [
            `datoAdicional`
            , `referencia`
            , `observación`
        ];

        // this.arrInspeccionMMateriales = [`Duplicado`, `Pendiente`, `Falto de información`, `Enviado a calidad`, `Seleccione inspección`];
        // this.arrEstadoMMaterial = [`Aprobado`, `Rechazado`];
        this.arrInspeccionMMateriales = ["Duplicado", "Pendiente", "Falto de información", "Enviado a calidad", "Aprobado", "Rechazado"];
        this.stateInspection = {
            "0": "Seleccionar"
            , "1": "Duplicado"
            , "2": "Pendiente"
            , "3": "Falto de información"
            , "4": "Enviado a calidad"
            , "5": "Aprobado"
            , "6": "Rechazado"
        }

        this.arrEstadoMMaterial = ["Completado", "Incompleto"];
        this.arrInspeccionDinamicoSel = ["Aprobado", "Rechazado"];

        this.arrAsignar = [
            {
                solicitud: [
                    `Asignar solicitud`, `Haga clic `, `aquí`, ` para generar y descargar el archivo`
                ]
            },
            {
                tóxicos: [
                    `Asignar tóxicos`
                ]
            }
        ];

        this.arrRolFrms = [
            {
                eliminarRol: [`Eliminar rol`, `Seleccione rol a eliminar`, `Buscar por: nombre rol, módulo o permisos`
                    , `Nombre rol`, ` Módulos `, ` Permisos `, `No se ha encontrado ningún registro con el parámetro: `, `El rol indicado no existe`, `El rol indicado no se puede eliminar porque está asignado a un usuario`
                    , `¿Está seguro de eliminar el rol?`]
            },
            {
                habilitarInhabilitarRol: [`Habilitar o Inhabilitar rol`, `Seleccione el rol`
                    , `El rol no se encuentra registrado`, `Se realizaron los cambios correctamente`
                    , `Debe definir el estado del rol`, `Error al editar rol`, `Está seguro de `, ` el rol`
                    , ` el rol, Este rol tiene asignado `, ` usuarios`, ` <b class="cursor-pointer" id="imprimirLog">Ver usuarios</b>`]
            },
            {
                editarRol: [`Editar rol`, `Seleccione el rol`, `Seleccionar otro rol`
                    , `Información básica`, `Nombre del rol`, `Estado del rol`, `Permisos del rol`
                    , `Seleccione permiso`, `Agregar permiso`, `Nombre permiso`, `Acción`
                    , `Eliminar`, `No eliminar`, `Guardar`, `Eliminar rol`, `Cancelar`, `Rol seleccionado`, `¿Está seguro del guardar los cambios?`
                    , `Hubo un error, se descartaron los cambios`, `Se actualizó el registro con éxito!`
                    , `Al inhabilitar este rol afectará a `, ` usuarios.`, `Ver usuarios`
                    , `Permiso`, `Este rol no tiene ningún permiso`, `Adicionar todo`]
            },
            {
                imprimirListaUsuario: [`Reporte de usuario asignados al rol`
                    , `Los siguientes usuarios serán afectados por la acción ya que se encuentran asignados al rol`
                    , `Nombre completo`, `Email`]
            },
            {
                crearRol: [`Crear rol`, `Debe seleccionar un permiso`, `El rol no puede estar vacío`, `Seleccione el estado del rol`
                    , `Debe agregar por lo menos un permiso`, `Error al crear rol, este error puede ser porque el hay un rol con el mismo nombre`, `Error al asignar permiso al rol`
                    , `Se registró el rol con éxito`]
            },
            {
                form_previsualizar_Rol: [
                    `Rol`,
                    `Estado`,
                    `Cantidad de permisos`,
                    `Ver más...`,
                    `Editar`,
                    `Habilitar`,
                    `Eliminar`,
                    `Cancelar`,
                    `Registrado`,
                    `Habilitado`,
                    `Inhabilitado`,
                    `Inhabilitar`,
                    `¿Está seguro de habilitar el rol `,
                    `¿Está seguro inhabilitar el rol `,
                    `Información básica`,
                    `Módulo`,
                    `Permisos`,
                    `Esté rol tiene usuarios asignados`,
                    `El rol se elimino satisfactoriamente.`
                ]

            }
        ];
        this.arrUsuarioFrms = [
            {
                eliminarUsuario: [
                    `Eliminar Usuario`,
                    `Seleccione usuario a eliminar`,
                    `Buscar por: nombre, apellido, documento o correo`
                    , `Nombres`
                    , `Apellidos`
                    , `# documento`
                    , `Correo`
                    , `¿Está seguro de eliminar el usuario `
                    , `No puede eliminar el usuario porque no existe en base de datos`
                    , `No se puede eliminar el usuario `
                    , ` porque está asignado a una empresa`
                    , `Él usuario `
                    , ` se elimino satisfactoriamente`
                    , `¿Está seguro de eliminar el custodio `
                    , `Error al eliminar el custodio`]
            },
            {
                habilitarInhabilitarUsuario: [
                    `Habilitar o Inhabilitar usuario`
                    , `Seleccione el campo a Habilitar/Inhabilitar`
                    , ` el usuario `
                    , `Seleccione usuario`
                    , `Debe definir el estado del usuario`
                    , `El usuario no se puede eliminar porque está asignado a una empresa`
                    , `el usuario`, `No puede guardar los cambios porque el usuario no existe`
                    , `¿Está seguro de`
                    , ``
                ],
                alertError: [
                    `El usuario `
                    , ` está asígnado a una empresa`
                    , `El usuario no existe`
                    , `El estado ingresado es nulo o no valido`
                ],
                alertSuccess: [
                    `El usuario `
                    , ` se `
                    , ` satisfactoriamente`
                ]
            },
            {
                editarUsuario: [`Seleccione usuario`, `Seleccionar otro usuario`]
            }
        ];

        this.arrUMBFrms = [
            {
                general: [
                    `Unidad de medida base`,
                    `Reporte unidad de medida base`,
                    `Crear unidad de medida base`,
                    `Editar unidad de medida base`,
                    `Eliminar unidad de medida base`]
            },
            {
                crearUMB: [
                    `Codificación UMB`,
                    `UMB`,
                    `Observación`,
                    `Traducciones`,
                    `Idioma`,
                    `Traducción UMB`,
                    `Traducción observación`,
                    `Idioma`,
                    `Traducción en `,
                    `Debe llenar los campos marcados con asterísco`,
                    `Debe completar las traducciones para continuar`,
                    `Se registró la unidad de medida base`,
                    `La unidad de medida no se puede crear debido a que ya existe un registro con el mismo código y/o descripción.`,
                    `Atención`,
                    `¿Está seguro de crear la unidad de medida?`,
                ]
            },
            {
                modificarUMB: [
                    `Editar unidad`,
                    `Seleccione la unidad a editar`,
                    `Buscar por: código o nombre`,
                    `Código`,
                    `Nombre`,
                    `Se actualizó la unidad de medida correctamente`,
                    `Error al actualizar la unidad de medida, este error puede ser provocado por la duplicidad de códigos`,
                    `Seleccionar otra UMB`,
                    `Seleccione la unidad a eliminar`,
                    `¿Está seguro de modificar la unidad de medida?`
                ]
            },
            {
                eliminarUMB: [
                    `Código UMB`,
                    `UMB y traducciones`,
                    `Observación y traducciones`,
                    `Se eliminó la unidad de medida correctamente`,
                    `Hubo un error desconocido al intentar eliminar la unidad de medida base`,
                    `La unidad de medida se está utilizando en solicitud de catalogación`,
                    `La unidad de medida se está utilizando en maestro de materiales`,
                    `La unidad de medida no existe`,
                    `¿Está seguro de eliminar la unidad de medida?`,
                ]
            },
            {
                tablaUMB: [
                    `Id`,
                    `Codificación UMB`,
                    `Unidad de medida base`,
                    `Observación`,
                    `Idioma`,
                    `Reporte de Unidad Medida Base`
                ]
            }
        ];

        this.arrInputFrmSolicitud = [
            {
                crearCampo: [
                    `Crear campo solicitud`,
                    `Agregar campo`,
                    `Reporte de campos`,
                    `Seleccione tipo de campo`,
                    `Características del campo`,
                    `Título del campo`,
                    `Ítems del campo`,
                    `Título del campo Ej.: Nombres`,
                    `Nombre del ítem Ej.: Cédula de ciudadanía`,
                    `Ingrese título del campo`,
                    `Debe agregar por lo menos un ítem al campo, si el campo no es de selección múltiple escoja otro`,
                    `Ingrese nombre del ítem`,
                    `Ya hay un ítem para el campo con el mismo nombre`,
                    `Tipo campos`,
                    `Título campo`,
                    `Es obligatorio?`,
                    `Previsualización`,
                    `Acción`,
                    `No hay campos agregados`,
                    `Listo`,
                    `Reporte Campos Solicitud`,
                    `¿Está seguro de crear el campo?`,
                    `¡No hay ningún campo agregado para guardar!`,
                    `¿No se han guardado los cambios actuales, desea descartarlos?`,
                    `No se puede guardar el campo, puede que y haya registrado un campo con el mismo nombre`,
                    `Se registró el campo correctamente`,
                    `Se encontraron algunos campos vacíos, modifíquelos e intente nuevamente`,
                    `Desc. informativa`,
                ]
            },
            {
                tablaCampos: [
                    `Reporte de campos solicitud`,
                    `Id`,
                    `Fecha creación`,
                    `Hora creación`
                ]
            },
            {
                modificarCampos: [
                    `Editar campo solicitud`,
                    `Seleccione el campo`,
                    `Buscar por: título o tipo de campo`,
                    `Guardar`,
                    `Deshabilitar`,
                    `Habilitar`,
                    `Eliminar`,
                    `Cancelar`,
                    `Lista campos de solicitud`,
                    `¿Está seguro de modificar el campo?`,
                    `¿Está seguro de deshabilitar el campo?`,
                    `¿Está seguro de activar el campo?`,
                    `¿Está seguro de eliminar el campo?`,
                    `No se puede adicionar el ítem, actualice la página y vuelva a intentarlo`,
                    `Error`,
                    `Ya existe un ítem con el mismo nombre`,
                    `Atención`,
                    `Debe agregar un ítem al campo, los que están van a ser eliminados o inactivados`,
                    `Debe agregar todas las traducciones del campo`,
                    `Debe agregar todas las traducciones de los ítems del campo`,
                    `Debe agregar al menos un ítem al campo`,
                    `Error al modificar el campo, puede que este campo o sus ítems estén en uso`,
                    `Se modificó el campo correctamente`,
                    `Error al actualizar estado del campo`,
                    `Se actualizó el estado del campo correctamente`,
                    `Error al eliminar el campo, puede que esté en uso`,
                    `Se eliminó el campo correctamente`,
                ]
            }
        ];

        this.arrSerResImpoMas = [
            `Este error puede ser provocado por la siguientes razones.<br>&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;Asignación equívoca de valores en el archivo.<br><br>&nbsp;&nbsp;&nbsp;&nbsp;Si estás seguro de que estos errores no aplican por favor comuniquese con StockGI.`
            , `Error en la lectura del archivo`
            , `Este error puede ser provocado por la siguientes razones.<br>○&nbsp;&nbsp;Modificación en la estructura del archivo.<br>○&nbsp;&nbsp;Asignación equívoca de valores en el archivo.<br><br>Si estás seguro de que estos errores no aplican por favor comuniquese con StockGI.`
            , ``
        ];

        this.arrMenu = [
            `Planeación`,
            `Inventarios`,
            `Compras`,
            `Contratación`,
            `Proveedores`,
            `Logística`,
            `QA/QC`,
            `Mantenimiento`
        ];
        this.valueSeleccion = ` -- Seleccione -- `;
        this.indicadoresCatalogacion = [
            `Indicativos Catalogación`,
            `Buscar por:`,
            {
                steps: [
                    `Cliente`,
                    `Usuario`,
                    `Fecha desde`,
                    `Fecha hasta`,
                    `Buscar`,
                    `Resultados`
                ],
            },
            {
                steps1: [
                    `1. Instructivo`,
                    `2. Cargue y validación`,
                    `3. Log de cargue`
                ],
            },
        ];

        this.opcionesgen = [``

        ];

        this.asignarEmpresaUsuario = [
            {
                title1: `Atención`,
                title2: `Información`,
                title3: `Hubo un error`,
                title4: `Excelente`,
                text1: `Al usuario ya le asignaron empresa`,
                text2: `Debe seleccionar empresa`,
                text3: `Debe seleccionar usuario`,
                text4: `No se han guardado los cambios actuales, ¿desea descartarlos?`,
                text5: `No ha realizado ningún cambio!`,
                text6: `¿Esta seguro que desea guardar los cambios realizados?`,
                text7: `Se guardaron los cambios!`,
                type1: `info`,
                type2: `question`,
                type3: `error`,
                type4: `success`,
                confirmButtonText: `Ok`,
                errorInfo1: `. Si no puede solucionar el error como usuario, comuniquese con nosotros`,
                errorInfo2: `No se realizó ningún cambio, debido a que `,
                errorInfo3: `ocurrieron `,
                errorInfo4: `ocurrió `,
                errorInfo5: ` errores en los #`,
                errorInfo6: ` error en el #`,


            }
        ];

        //Campos menu lateral
        this.nombremodulos = [`Administrativo`, {
            submod: [
                `Rol`,
                `Empresa`,
                `Usuario`,
                `Reporte`,
                // `Consulta clases`,
                `Consulta de catálogo`,
                `Asignar usuario`,
                `Asignar empresa a usuario`
                , `Indicadores`
                , `Reporte GEB`
            ],
            reporsub: [`log`]
        },
            {
                subEmpresa: [
                    `Reporte empresas`,
                    `Crear empresa`,
                    `Editar empresa`,
                    `Eliminar empresa`,
                    `Habilitar/Inhabilitar empresa`,
                    `Carga masíva empresa`,
                    `Asignar idioma`
                    , `Asignar divisa`
                ]
            },
            {
                subUsuario: [
                    `Reporte de usuarios`,
                    `Crear usuario`,
                    `Editar usuario`,
                    `Eliminar usuario`,
                    `Habilitar/Inhabilitar usuario`,
                    `Carga masíva usuario`,
                    `Asignar usuario`
                ]
            },
            {
                subRol: [
                    `Reporte de roles`,
                    `Crear rol`,
                    `Editar rol`,
                    `Eliminar rol`,
                    `Habilitar/Inhabilitar rol`,
                    `Carga masíva rol`
                ]
            }
        ];

        //Campos formulario empresa
        this.formularioempresa = [
            // `Formulario de Empresa`,
            `Crear Empresa`,
            `Regresar`,
            {
                sec1: [`Datos de empresa`, `Datos de ubicación`, `Datos de contacto`]

            },
            `NIT`,
            `Nombre empresa`,
            `Actividad comercial`,
            `Código CIIU`,
            {
                modciiu: [`Actividad Economica`, `Seccion`, `Division`, `Grupo`, `Clase`, `Buscar por Código CIIU`, `Cerrar`, `Guardar`],
                sec2: [`Pais`, `Departamento`, `Municipio`, `Código Postal`, `Dirección`, `Complemento`],
                sec3: [`Persona de contacto`, `Correo electrónico`, `Teléfono`, `Extensión`, `Celular`, `Divisa`, `Idioma`, `Seleccionar`],
                acciones: [`Añadir empresa`, `Cancelar`],
                modalemp: [`Añadir Usuarios`, `Cargue de usuarios por elección`, `Cargue de archivos por archivo plano`, `Seleccione los usuarios a asignar`, `Descargue el archivo guía`, `aquí`]
            },
            {
                modalUsuario: [`Guardar`, `Cerrar`],
                error_dosModos: [`¡Error!`, `Solo debe usar una de las dos formas de cargar usuarios`],
                error_usuarioIndividual: [`¡Error!`, `Se ha generado un error al registrar los usuarios`]
            },
            {
                eliminarEmpresa: [
                    `Eliminar empresa`,
                    `Selecciona la empresa a eliminar`,
                    `Buscar por: nit, nombre o correo`,
                    `No se encontró ningún registro con el parámetro: `,
                    `Nit`,
                    `Nombre`,
                    `Correo`,
                    `Nit: `,
                    `Nombre: `,
                    `Correo: `,
                    `Eliminar`,
                    `Cancelar`,
                    `¿Está seguro de eliminar la empresa?`,
                    `Buen trabajo`,
                    `Se elimino correctamente la empresa `,
                    `¡Error!`,
                    `No existe esta empresa en la base de datos`,
                    `No se puede eliminar esta empresa porque está asignada a un usuario`,
                    `Seleccionar otra empresa`,
                    `Selecciona la empresa`,
                    `Editar`,
                    `Habilitar`,
                    `Inhabilitar`,
                    // `Se realizaron los cambios correctamente a la empresa `
                ]
            },
            {
                habilitarInhabilitarEmpresa: [
                    `Habilitar o inhabilitar empresa`,
                    `Selecciona la empresa`,
                    `¿Está seguro de `,
                    ` la empresa `,
                    ` Habilitar `,
                    ` Inhabilitar `,
                    `Se produjo un error desconocido`,
                    `La empresa `,
                    ` tiene usuarios asociados`,
                    `El nuevo estado de la empresa `,
                    ` es`
                    , `Error al cambiar el estado de la empresa `
                ],
            },
            {
                form_previsualizar_empresa: [
                    `Empresa`,
                    `Nit`,
                    `E-Mail`,
                    `Persona de contacto`,
                    `Dirección`,
                    `Teléfono`,
                    `Editar`,
                    `Habilitar`,
                    `Eliminar`,
                    `Cancelar`,
                    `Estado`,
                    `Registrado`,
                    `Habilitado`,
                    `Inhabilitado`,
                    `Inhabilitar`,
                    `habilitar`,
                    `inhabilitar`,
                    `Información básica`
                ]
            }
        ];

        //Tabla empresa
        this.tablaempresa = [
            `Reporte de empresas`,
            `Cargar empresa`,
            `Crear empresa`, {
                col: [
                    `Id empresa`,
                    `Nit`,
                    `Nombre empresa`,
                    `Dirección`,
                    `Ciudad`,
                    `Contacto`,
                    `Correo electronico`,
                    `Teléfono`,
                    `Extensión`,
                    `Celular`,
                    `Acción`,
                    `Estado`,
                    `Idiomas`,
                    `Divisas`
                    , `Creador/Modificador`
                ]
            }];

        //Tabla usuario
        this.tablausuario = [`Reporte de Usuarios`, `Cargar usuario`, `Crear usuario`, {
            col: [`Id`, `Nombre completo`, `Tipo documento`, `Número documento`, `Correo`, `Dirección`, `Municipio`, `Teléfono`, `Extensión`, `Teléfono Móvil`, `Nombre empresa`, `Cargo`, `Acción`, `Estado`, `Rol`]
        }];

        //Tabla custodio
        this.tablacustodio = [`Reporte de Custodio`, `Cargar custodio`, `Crear custodio`, {
            col: [`Id`, `Nombre completo`, `Cargo`, `Tipo documento`, `Número documento`, `Correo`, `Dirección`, `Teléfono`, `Teléfono Móvil`]
        }];

        //Treeview Rol
        this.treeviewrol = [`Creación de roles`, `Añadir Rol`, `Cerrar`, `Guardar`];

        this.consultaMaeriales = [`Consulta de clases`,];
        //Tabla Roll
        this.tablarol = [`Reporte de Roles`, `Crear Rol`,
            {
                col: [`Id Rol`, `Rol`, `Módulo`, `Permiso`, `Acción`, `Estado`]
            },
        ];

        this.formEmpresaArray = { textoInpSearchCIIU: `Buscar por código CIIU` };

        //Formulario usuario
        this.formulario = [`Formulario de Usuario`, {
            sec1: [`Informacion Personal`, `Datos de Contacto`, `Permisos`],
        },
            `Nombre`,
            `Apellido`,
            `Tipo de Documento`,
            `Numero de Documento`,
            `Contraseña`, {
                sec2: [`Correo`, `Dirección`, `Pais`, `Departamento`, `Municipio`, `Código postal`, `Celular`, `Teléfono`, `Extensión`, `Contraseña`],
                sec3: [`Empresa`, `Cargo`, `Rol`],
            }];

        //Wizard Cargue empresa
        this.wizardcargaempresa = [`Carga masiva de empresas`,
            {
                steps: [`1. Instructivo`, `2. Cargue y validación`, `3. Log de cargue`],
            },
            `Descargue el archivo`, `empresas.xls`,
            {
                step1: [`Abrir el archivo "empresas.xls" con una aplicación de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta los que son obligatorios:`,
                    `a. El campo "nombreEmpresa" es obligatorio. `,
                    `b. El campo "nitEmpresa" es obligatorio. `,
                    `c. El campo "direccionEmpresa" es obligatorio. `,
                    `d. El campo "complementoDireccion" es obligatorio. `,
                    `e. El campo "idCodigoPostal" es obligatorio. `,
                    `f. El campo "contactoEmpresa" es obligatorio. `,
                    `g. El campo "emailEmpresa" es obligatorio. `,
                    `h. El campo "telefonoEmpresa o celularEmpresa" es obligatorio. `,
                    `i. El campo "idDivisa" es obligatorio. `,
                ]
            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Seleccionar archivo`, `Información básica del archivo`, `Archivo`, `# filas`, `Modificación`, `Tamaño`, `Acción`, `Click en el + para adjuntar el archivo`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: [`A continuación se mostrará datos necesarios para la carga de la información.`, `Información de`, `Control`, `Id a registrar`, `Ids código postal`, `Ids divisa`, `Seleccione código postal`, `Seleccione divisa`]
            }];

        this.wizardtoxicos = [`Carga Catálogo Antiguo`,
            {
                steps: [
                    `1. Instructivo`,
                    `2. Cargue y validación`,
                    `3. Log de cargue`,
                    `Lista de descripciones`
                ],
            },
            `Descargue el archivo`, `materialesAngituos.xls`,
            {
                step1: [`Abrir el archivo "materialesAngituos.xls" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`,
                    `a. El campo idCatalogador es obligatorio`,
                    `b. El campo codToxico es obligatorio`,
                    `c. El campo referencia es obligatorio`],
                step2: [
                    `a. El campo codigoEmpresa es obligatorio`,
                    `b. El campo fechaCreacion es obligatorio`,
                    `c. El campo usuarioCreacion es obligatorio`,
                    `c. El campo idUsuarioCatalogador es obligatorio`
                ],
                step3: [
                    `a. El campo idCatalogador es obligatorio`,
                    `b. El campo codigoToxico es obligatorio`,
                    `c. El campo referencia es obligatorio`
                ]
            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Seleccionar archivo`, `Información básica del archivo`, `Archivo`, `# filas`, `Modificación`, `Tamaño`, `Acción`, `Click en el + para adjuntar el archivo`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: [`Carga Individual de código antiguo`]
            }];

        this.wizardtoxicoIndividual = [`Cargue toxico individual`,
            {
                steps: [`1. Instructivo`, `2. Cargue y validación`, `3. Log de cargue`],
            },
            `Descargue el archivo`, `materialesAngituos.xls`,
            {
                step1: [`Abrir el archivo "materialesAngituos.xls" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`, `a. Nombre del campo, Longitud: X, Tipo de variable: Y`, `b. Nombre del campo, Longitud: X, Tipo de variable: Y`, `c. Nombre del campo, Longitud: X, Tipo de variable: Y`]
            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Seleccionar archivo`, `Información básica del archivo`, `Archivo`, `# filas`, `Modificación`, `Tamaño`, `Acción`, `Click en el + para adjuntar el archivo`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: [`Carga Individual de código antiguo`]
            }];
        this.wizardMaterial = [`Carga masiva de clases`,
            {
                steps: [`1. Instructivo`, `2. Cargue y validación`, `3. Log de cargue`],
            },
            `Descargue el archivo`, `clases.xls`,
            {
                step1: [`Abrir el archivo "clases.xls" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`, `a. Nombre del campo, Longitud: X, Tipo de variable: Y`, `b. Nombre del campo, Longitud: X, Tipo de variable: Y`, `c. Nombre del campo, Longitud: X, Tipo de variable: Y`]
            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Seleccionar archivo`, `Información básica del archivo`, `Archivo`, `# filas`, `Modificación`, `Tamaño`, `Acción`, `Click en el + para adjuntar el archivo`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: []
            }];


        this.wizardcargausuario = [`Carga masiva de usuarios`,
            {
                steps: [`1. Instructivo`, `2. Cargue y validación`, `3. Log de cargue`],
            },
            `Descargue el archivo`, `usuario.xls`,
            {
                step1: [
                    `Abrir el archivo "usuario.xls" con una aplicación de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`,
                    `a. El campo "idRol" es obligatorio.`,
                    `b. El campo "idTipoDocumento" es obligatorio.`,
                    `c. El campo "idCodigoPostal" es obligatorio.`,
                    `d. El campo "nombres" es obligatorio.`,
                    `e. El campo "apellidos" es obligatorio.`,
                    `f. El campo "númeroDocumento" es obligatorio.`,
                    `g. El campo "email" es obligatorio.`,
                    `h. El campo "clave" es obligatorio.`,
                    `i. El campo "dirección" es obligatorio.`,
                    `j. El campo "teléfono o celular" es obligatorio.`,
                    `k. El campo "clave" es obligatorio.`,
                ]

            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Carga de Archivos`, `Multiples Archivos`, `Fecha de registro:`, `Cantidad de registros encontrados:`, `Nombre`, `Tamaño`, `Progreso`, `Adjuntar archivo`, `Acciones`, `Progreso:`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: [`A continuación se mostrará datos necesarios para la carga de la información.`, `Ids estado sesión`, `Ids estados usuario`, `Ids rol`, `Ids tipo documento`, `Ids código postal`, `Información de`, `Control`, `Id a registrar`, ` Seleccione estado sesión `, ` Seleccione estado usuario `, ` Seleccione rol `, ` Seleccione T.D. `, ` Seleccione código postal `]
            }];

        this.wizardasignacion = [`Carga masiva de tóxicos`,
            {
                steps: [`1. Instructivo`, `2. Cargue y validación`, `3. Log de cargue`],
            },
            `Descargue el archivo`, `materialesAngituos.xls`,
            {
                step1: [`Abrir el archivo "materialesAngituos.xls" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`, `a. Nombre del campo, Longitud: X, Tipo de variable: Y`, `b. Nombre del campo, Longitud: X, Tipo de variable: Y`, `c. Nombre del campo, Longitud: X, Tipo de variable: Y`]
            },
            `Guardar el archivo en su equipo`,
            {
                step2: [`Seleccionar archivo`, `Información básica del archivo`, `Archivo`, `# filas`, `Modificación`, `Tamaño`, `Acción`, `Click en el + para adjuntar el archivo`, `Subir`, `Cancelar`, `Remover`, `Subir todos`, `Cancelar todo`, `Remover todo`]
            },
            {
                step3: []
            }];

        //form Empresa
        this.formEmpresa = [
            {
                modal:
                    [
                        `Añadir Usuarios`,
                        `Cargue de usuarios por elección`,
                        `Cargue de archivos por archivo plano`,
                        `Seleccione los usuarios a asignar`
                    ],
                alerts: [
                    `Confirmación`,
                    `¿Desea crear la empresa `,
                    `Sí`,
                    `No`,
                    `Acción realizada satisfactoriamente`,
                    `Error`,
                    `Operación exitosa`,
                    `Ok`

                ]
            }];



        //tabla log
        this.titleTableLog = `tabla log`

        //login
        this.titulo = `Inicio de Sesión`;
        this.remember = `Recordarme`;
        this.forgetPswd = `¿Olvido su contraseña?`;
        this.signIn = `Acceder`;
        this.signUp = `Registrarse`;
        this.stepSignUp = `Sigá los siguientes pasos para el registro`;
        this.agree = `Yo acepto los`;
        this.termsAndCond = `terminos y condiciones`;
        this.regainPaswd = `Recuperar contraseña`;
        this.enterEmailRecovery = `Ingrese un correo para recuperar su contraseña`;
        this.inpEmail = `Correo electronico`;
        this.inpPasswd = `Contraseña`;
        this.requestButton = `Solicitar`
        this.cancelButton = `Cancelar`
        this.dontAccount = `¿Aun no tienes una cuenta?`;
        this.primaryText = `Join Our Community`;
        this.secondaryText = `Lorem ipsum dolor sit amet, coectetuer adipiscing<br>
        elit sed diam nonummy et nibh euismod`;
        this.mensajeInicioSesion1 = `Somos un equipo...`;
        this.mensajeInicioSesion2 = `y juntos podemos cambiar el mundo.`;

        this.errorLogin = `El usuario o la constraseña ingresados no son correctos, por favor rectifiquelos!`;

        //Input from register
        this.inpNumberDocumentUser = `Número documento`;
        this.inpNameUser = `Nombres`;
        this.inpLastnameUser = `Apellidos`;
        this.inpEmailUsuario = `Correo electronico`;
        this.inpPasswordUser = `Contraseña`;
        this.inpPasswordValidation = `Confirme su contraseña`;
        this.inpAddressUser = `Dirección`;
        this.inpPhoneUser = `Teléfono`;
        this.inpExtUser = `Ext`;
        this.inpMovilUser = `Número celular`;
        this.inpCompanyUser = `Nombre empresa`;
        this.inpAppointmentUser = `Cargo`;
        this.inpAuthorizePerson = `Persona que autoriza`;
        this.inpEmailAuthorizePerson = `Correo electronico persona que autoriza`;

        //Opciones genericas
        this.selectDocumentType = `Tipo documento`;
        this.fieldSelect = `[Seleccionar...]`;
        this.selectCountry = `País`;
        this.selectDepartament = `Departamento`;
        this.selectMunicipaly = `Municipio`;
        this.selectPostalCode = `Código postal`;
        this.fieldRequire = `Campo requerido o inválido`
        this.btnBack = `Regresar`;

        this.asociarIdiomaEmpresa = [
            `Asociar idioma con empresas`,
            {
                formulario: [
                    `Empresas`,
                    `Idiomas`,
                    `Guardar`,
                    `Cancelar`,
                    `Seleccionar`,
                    `Acciones`,
                    `Regresar`
                ],
                alertDuplicado: [
                    `Error de duplicidad`,
                    `La asociación que desea realizar ya se encuentra en la lista`,
                    `Aceptar`
                ],
                alertaConfirmacion: [
                    `Confirmación`,
                    `¿Está seguro de guardar los datos?`,
                    `Aceptar`,
                    `Cancelar`
                ],
                alertaConfirmarEliminar: [
                    `Confirmación`,
                    `¿Está seguro que desea eliminar este registro?`,
                    `Aceptar`,
                    `Cancelar`
                ],
                alertSuccess: [
                    `Buen trabajo`,
                    `Se realizaron los registros satisfactoriamente`,
                    `Aceptar`
                ],
                tablaAsignaciones: [
                    `Relaciones por empresa`,
                    `Asociaciones por realizar`,
                    `Registros no encontrados`
                ],
                alertVerificarIdioma: [
                    `Error`,
                    `Esta empresa ya cuenta con este idioma registrado`,
                    `Aceptar`
                ],
                alertaEliminarIdiomaActual: [
                    `Error`,
                    `El idioma que desea eliminar se encuentrá en uso, favor cambiarlo`,
                    `Aceptar`
                ],
                alertEliminarSuccess: [
                    `Buen trabajo`,
                    `Se eliminó el registro satisfactoriamente`,
                    `Aceptar`
                ]
            }
        ]


        this.associate_divisa_to_company = [
            `Asociar divisa empresa`,
            {
                formulario: [
                    `Empresas`,
                    `Divisas`,
                    `Guardar`,
                    `Cancelar`,
                    `Seleccionar`,
                    `Acciones`,
                    `Regresar`
                ],
                tablaAsignaciones: [
                    `Relaciones por empresa`,
                    `Asociaciones por realizar`,
                    `Registros no encontrados`
                ]
                , alerts: [
                    `Error general`
                    , `No se logró obtener la información del servidor`
                    , `Error al adicionar la divisa seleccioanda`
                    , `No se ha realizado ninguna modificación`
                    , `Buen trabajo`
                    , `Acciones realizadas satisfactoriamente`
                    , `No se puede eliminar la totalidad de las divisas asociadas a una empresa`
                    , `No se logro realizar alguna de las acciones solicitadas`
                ]
                , table_log: [
                    `Registros en conflicto`
                ]
            }
        ]

        // Modulo Catalogación
        this.modCatalogacion = [
            `Catalogación`,
            {
                subMenu: [
                    `Panel de Control`,
                    {
                        menu: [
                            `Usuario`,
                            `Crear Tipo`,
                            `Crear Grupo`,
                            `Crear Producto`,
                            `Crear Unidad de Medida`,
                            `Crear Fabricante`,
                            `Textos`,
                            `Día a Día`,
                            {
                                subMenu: [`Campos solicitud`]
                            },
                            `Campos Solicitud`,
                            `Editar tipo`,
                            `Editar Grupo`,
                            `Editar Producto`,
                            `Editar Unidad de Medida`,
                            `Editar Fabricante`,
                            `Tipo`,
                            `Grupo`,
                            `Unidad de Medida`,
                            `Fabricante`,
                            `Producto`,
                            `Maestro Producto`,
                            `Editar Producto`,
                            `Prueba`,
                            `Eliminar Grupo`,
                            `Reporte de Grupo`,
                            `Carga de Grupo`,
                            `Carga Grupo`,
                            `Eliminar tipo`,
                            `Reporte tipo`,
                            `Características`,
                            `Reporte Producto`,
                            `Cargue masivo Producto`,
                            `Eliminar Producto`,
                            `Valor`,
                            `Crear Características`,
                            `Eliminar Característica`,
                            `Crear Valor`,
                            `Reporte Valor`,
                            `Eliminar Fabricante`,
                            `Reporte Fabricante`,
                            `Reporte Característica`,
                            `Editar Característica`,
                            `Editar Valor`,
                            `Eliminar Valor`,
                            `Carga masiva fabricante`,
                            `Carga masiva`,
                            `Estado de la carga masiva`,
                            , `Crear entrada`
                            , `Modificar entrada `
                            , `Reporte entrada `
                            , `Crear despacho`
                            , `Modificar despacho`
                            , `Reporte despacho`
                            , `Crear Baja`
                            , `Modificar Baja`
                            , `Reporte Baja`
                            , `Crear Traslado`
                            , `Modificar Traslado`
                            , `Reporte Traslado`
                        ]
                    },
                    `Catálogar`,
                    {
                        menu: [
                            `Cargar Catálogo`,
                            `Crear Catálogo`,
                            `Calidad`,
                            `Asignar`,
                            `Indicadores`,
                            `Cargar Catálogo`,
                            `Cargar Descripción Antiguo`,
                            `Reporte de Descripciones Antiguas`,
                            `Asignar solicitud`,
                            `Asignar Descripciones`,
                            `Gestión calidad`,
                            `Reporte gestión calidad`
                        ]
                    },
                    `Día a Día`,
                    {
                        menu: [
                            `Solicitud catalogación`,
                            `Reporte de solicitudes de catalogación`,
                            `Crear solicitud catalogación`,
                            `Editar solicitud catalogación`,
                            `Atención`,
                            `Informe`,
                            `Informe General Solicitudes`,
                        ]
                    },
                    `Reportes`,
                ],
                formTipoMaterial: [
                    `Crear Tipo`,
                    {
                        formulario: [
                            `Empresa`,
                            `Código tipo`,
                            `Seleccionar ...`,
                            `Nombre Tipo`,
                            `Rango`,
                            `Descripción`,
                            `Observación`,
                            `Guardar`,
                            `Cancelar`,
                            `Desde`,
                            `Hasta`,
                            `Traducciones`,
                            `Este campo debe ser mayor`,
                            `Este campo debe tener como mínimo 4 dígitos`,
                            `Reporte tipo`,
                            `Administración`,
                            `Activos y herramientas`,
                            `Tuberías`,
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Tipo`,
                            `Observación`
                        ],
                        alertIdiomasError: [
                            `Idiomas no encontrados`,
                            `La empresa seleccionada no cuenta con idiomas asociados`,
                            `Aceptar`
                        ],
                        alertErrorGuardar: [
                            `Error al cargar traducciones`,
                            `¡Debe ingresar las traducciones requeridas por la empresa`,
                            `Aceptar`
                        ],
                        alertErrorRepetido: [
                            `Error al crear el registro`,
                            `La información que desea ingresar genera duplicidad con el siguiente registro:`,
                            `Id Tipo: `,
                            `Código: `,
                            `Rango mínimo: `,
                            `Rango máximo: `,
                            `Nombre tipo: `
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `El tipo `,
                            `Aceptar`,
                            ` se ha registrado satisfactoriamente`
                        ],
                        alertConfirmación: [
                            `Confirmación`,
                            `¿Está seguro de crear el tipo `,
                            `Sí`,
                            `No`
                        ],
                        alertNegativo: [
                            `Error`,
                            `Rangos ingresados no validos `
                        ]
                    }
                ],
                elimiarTipoMaterial: [
                    `Eliminar tipo`,
                    `Regresar`,
                    `Buscar por: Código tipo,tipo u observación`,
                    `Información Basica`,
                    `Código tipo`,
                    `Nombre tipo`,
                    `Observación`,
                    `Rango mínimo`,
                    `Rango máximo`,
                    `Eliminar`,
                    `Cancelar`,
                    `Seleccione el tipo a eliminar`,
                    {
                        alertaConfirmar: [
                            `Confirmación`,
                            `¿Está seguro de eliminar el tipo `,
                            `Sí`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El tipo `
                            , ` fue eliminado satisfactoriamente`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `El tipo `
                            , ` no se puede eliminar ya que se encuentra asignado a una clase`,
                            `Ok`
                        ],
                    }
                ],
                editarTipoMaterial: [
                    `Editar tipo`,
                    `Regresar`,
                    `Buscar por: Código tipo,tipo,rango u observación`,
                    `Código tipo`,
                    `Nombre tipo`,
                    `Observación`,
                    `Rango mínimo`,
                    `Rango máximo`,
                    `Editar`,
                    `Cancelar`,
                    `Seleccione el tipo a editar`,
                    `Seleccionar otro tipo`,
                    {
                        alertaConfirmar: [
                            `Confirmación`,
                            `¿Está seguro de editar este Tipo?`,
                            `Sí`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `Tipo editado satisfactoriamente`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `El registro que desea insertar se encuentra duplicado con la siguiente información: `,
                            `Id Tipo: `,
                            `Código: `,
                            `Tipo: `,
                            `Observación: `
                        ],
                        alertDatosNoActualizados: [
                            `¡Error!`,
                            `El formulario que desea guardar no registra ningún cambio`,
                            `ok`
                        ]

                    }
                ],
                reporteTipoMaterial: [
                    `Id`,
                    `Código tipo`,
                    `Tipo`,
                    `Rango mínimo`,
                    `Rango máximo`,
                    `Observación`,
                    `Reporte de Tipo`,
                    `Crear tipo`,
                    `Reporte de tipo`,
                ],
                formGroupArticulo: [
                    `Crear Grupo`,
                    {
                        formulario: [
                            `Código grupo`,
                            `Nombre grupo`,
                            `Abreviación`,
                            `Observación`,
                            `Guardar`,
                            `Cancelar`,
                            `Reporte de Grupos`,
                            `Eliminar`,
                            `Regresar`,
                            `Buscar por: código, nombre grupo o abreviación`,
                            `Eliminar Grupo`,
                            `Editar Grupo`,
                            `Seleccione el grupo a editar`,
                            `Seleccione grupo`,
                            `Código`,
                            `Nombre Grupo`,
                            `No se encontro resultados de la busqueda`,
                            `Seleccionar otro grupo`,
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            `Seleccione el idioma`,
                            `Idiomas`,
                            `Idiomas`,
                            `Id idioma`,
                            `Carga masiva de grupo`,
                            `Descargue el archivo`,
                            `grupo.xls`,
                            `Abrir el archivo "grupoclase.xls" con una aplicación de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones:`,
                            `a. El campo cod_GrpMaterial es obligatorio`,
                            `b. El campo grupoMaterial es obligatorio`,
                            `c. El campo abreviacionGrupoMaterial es obligatorio`,
                            `Guardar el archivo en su equipo`,
                            `Seleccionar archivo`,
                            `Información básica del archivo`,
                            `Archivo`,
                            `# filas`,
                            `Modificación`,
                            `Tamaño`,
                            `Acción`,
                            `Click en el + para adjuntar el archivo`,
                            `Subir`,
                            `Cancelar`,
                            `Remover`,
                            `Subir todos`,
                            `Cancelar todo`,
                            `Remover todo`,
                            `1. Instructivo`,
                            `2. Cargue y validación`,
                            `3. Log de cargue`,
                            `d. El campo idIdioma es obligatorio`
                        ],
                        eliminar: [
                            `No se encontro ningun registro con el valor: `,
                            `¿Está seguro de eliminar el grupo?`,
                        ],
                        alertErrorGuardar: [
                            `Error al cargar traducciones`,
                            `¡Debe ingresar las traducciones requeridas por la empresa`,
                            `Aceptar`
                        ],
                        alertErrorRepetido: [
                            `Error al crear el registro`,
                            `¡La información que desea ingresar ya se encuentra registrada en el sistema!`,
                            `Aceptar`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `Registro realizado satisfactorialmente`,
                            `Aceptar`
                        ],
                        editar: [
                            `Buscar`,
                        ],
                        tabla: [
                            `Id`,
                            `Código`,
                            `Grupo clase`,
                            `Abreviación`,
                            `Observación`,
                            `Reporte de grupos`,
                            `Crear grupo`,
                            `Reporte de Grupos`,
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Grupo clase`,
                            `Abreviación`,
                            `Observación`
                        ],
                        mensajeCargaGA: [
                            `Error al analizar archivo, puede que el archivo esté dañado o no sea el correcto`,
                            `Se han registrado los `,
                            `registros.`,
                            `Exito!`,
                            `Error!`,
                            `No se han guardado los datos.`,
                            `○ Al código de grupo clase `,
                            ` falta "Id idioma" o hay errores en los campos`,
                            `• `,
                            `Hay otros errores en los registros: `,
                            `○ Hay un error con el código del grupo o la información no es la correcta, verifique si el archivo es el correcto.`,
                            `○ Ya existe una traduccion para este Código en la base de datos `,
                            `○ Hay un error con el Código del grupo clase `,
                            `○ Hay un error al validar la duplicidad. Este error puede ser ocacionado por la calocación equivocada de datos, verifique los datos. `,
                            `Advertencia`,
                            `No ha seleccionado un archivo para ser cargado`,
                            `Ok`,
                            "El archivo cargado supera el tamaño máximo permitido de 7.5MB " // 17

                            // {{texto.modCatalogacion[1].formGroupArticulo[1].cargaMasiva[12]}}
                        ],
                        alertSuccessModificar: [
                            `Buen trabajo`,
                            `Grupo actualizado satisfactoriamente`,
                            `Aceptar`
                        ],
                        alertErrorModificar: [
                            `Error`,
                            `No se puede editar el grupo debido a que este se duplica con uno ya existente`,
                            `Aceptar`
                        ],
                        alertConfirmar: [
                            `Confirmacion`,
                            `¿Está seguro de editar el grupo clase?`,
                            `Aceptar`,
                            `Cancelar`,
                            `¿Está seguro de guardar el grupo material?`,
                        ],


                    }
                ],
                eliminarGrupoMaterial: [
                    `Eliminar grupo`,
                    {
                        formulario: [
                            `Código Grupo`
                            , `Grupo`
                            , `Abreviación`
                            , `Seleccione el grupo a eliminar`
                            , `Buscar por: código, nombre grupo o abreviación`
                            , `Información básica`
                            , `Código grupo`
                            , `Nombre Grupo`
                            , `Abreviación`
                            , `Observación`
                            , `Eliminar`
                            , `Cancelar`
                        ],
                        alertError: [
                            `¡Error!`
                            , `No se puede eliminar el grupo porque está asignado a una clase`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `El registro se elimino satisfactoriamente`
                        ]
                    }
                ],
                formProductoUNSPSC: [
                    `Crear Producto`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                            `Tipo`,
                            `Grupo`,
                            `Nombre Producto`,
                            `Código Producto`,
                            `Abreviación`,
                            `Observación`,
                            `Guardar`,
                            `Cancelar`,
                            `No se encontro ningun grupo`,
                            `No cuenta con ningun tipo`,
                            `Reporte de Productos`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Producto`,
                            `Abreviación`,
                            `Observación`
                        ],
                        alertaConfirmacion: [
                            `Confirmación`,
                            `¿Está seguro de crear el Producto `,
                            `Sí`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `Atención`,
                            `Algunos campos de traducción se encuentran vacios`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `¡Error al realizar el registro!`,
                            `Se genero un error que no permitio que se guardara el registro`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `El Producto `
                            , ` se creo satisfactoriamente`,
                            `Ok`
                        ],
                        alertErrorDB: [
                            `¡Error!`, // 0
                            `El Producto ` // 1
                            , ` se encuentra duplicada con la siguiente información: [`, // 2
                            `Ok` // 3
                            , "Error generado al editar la clase: " // 4
                        ],

                        ErrorGeneral: [
                            `¡Error!`,
                            `Problema de conexión con base de datos, favor comunicarse con el administrador`,
                            `ok`
                        ]
                    }
                ],
                reporteMaterial: [
                    `ID`,
                    `Cod. Producto`,
                    `Producto`,
                    `Tipo`,
                    `Grupo`,
                    `Abreviación`,
                    `Observación`,
                    `Codigo Grupo`
                ],
                editarMaterial: [
                    `Editar Producto`,
                    {
                        formulario: [
                            `Seleccionar`,
                            `Buscar por: Código, Producto, abreviación u observación`,
                            `Regresar`,
                            `Código Producto`,
                            `Nombre Producto`,
                            `Abreviación`,
                            `Observación`,
                            `Tipo`,
                            `Grupo de Producto`,
                            `No se encontro ningun grupo de Producto`,
                            `No cuenta con ningun Tipo`,
                            `Guardar`,
                            `Cancelar`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Producto`,
                            `Abreviación`,
                            `Observación`
                        ],
                        alertaConfirmacion: [
                            `Confirmación`,
                            `¿Está seguro de editar el Producto `,
                            `Sí`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `¡Error al realizar el registro!`,
                            `No se ha actualizado ningun valor en el formulario`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `¡Error al realizar el registro!`,
                            `Se genero un error que no permitio que se guardara el registro`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `El Producto `,
                            ` se actualizó satisfacoriamente`
                        ],
                        alertErrorDB: [
                            `¡Error!`,
                            `El Producto `,
                            `genera duplicidad con las siguiente información: [`

                        ],
                        ErrorGeneral: [
                            `¡Error!`,
                            `Problema de conexión con base de datos, favor comunicarse con el administrador`,
                            `Ok`
                        ],
                        ErrorTipoMaterial: [
                            `¡No se guardo el Producto seleccionado!`,
                            `Este error se debe a que el Producto seleccionado ya cuenta con este código asígnado`,
                            `Ok`
                        ]
                    }
                ],
                elimiarMaterial: [
                    `Eliminar Producto`,
                    `Regresar`,
                    `Buscar por: Código, Producto , Abreviación u observación`,
                    `Información Basica`,
                    `Código Producto`,
                    `Nombre Producto`,
                    `Código grupo`,
                    `Nombre grupo`,
                    `Código Tipo`,
                    `Tipo`,
                    `Abreviación`,
                    `Observación`,
                    `Eliminar`,
                    `Cancelar`,
                    `Seleccione el Producto a eliminar`,
                    {
                        alertaConfirmar: [
                            `Confirmación`,
                            `¿Está seguro de eliminar el Producto `,
                            `Sí`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El Producto `,
                            ` se elimino satisfactoriamente`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `El Producto `,
                            ` que intenta eliminar ya ha sido asignado a un Producto`,
                            `No se puede eliminar el Producto `,
                            ` porque tiene características asignadas`,
                            `No se puede eliminar el Producto `,
                            ` por errores desconocidos`
                        ],
                    }
                ],
                cargueMasivoMaterial: [
                    `Cargue masivo Producto`,
                    `Regresar`,
                    {
                        paso1: [
                            `clase.xlsx`,
                            `Abrir el archivo "clase.xlsx" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones: `,
                            `Grupo Producto`,
                            `Tipo`,
                            `Características`,
                            `Opciones`,
                            `Valor`,
                            `Idioma`,
                            `Grupo Producto`,
                            `Tipo`,
                            `[Seleccionar]`,
                            `Buscar Grupo Producto`,
                            "Seleccione Idioma",
                            "Seleccione Grupo Producto"
                        ],
                        paso3: [
                            `El cargue se realizo satisfactoriamente.`,
                            `Registros en conflicto`,

                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El cargue se realizo satisfactoriamente.`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`, // 0
                            `Se encontraron errores que no permitieron que se realizara el registro de la información, a continuación se mostraran los registros en conflicto`, // 1
                            `Ok` // 2
                            , "Se genero un error al guardar la información" // 3
                            , "Se información de traducciones ingresada es incorrecta" // 4
                            , "Se encontraron algunos valores en conflicto, por favor rectifiquelos e intente nuevamente" // 5
                            , "Error, registro duplicado" // 6
                            , "Error, registro no validol" // 7
                            , "Error, identificadores de traducción invalidos" // 8
                            , "Error general al ingresar la información, algunos datos dentro del archivo son erroneos o no coinciden con la información de la empresa en la cual desea ingresar los valores" // 9
                        ],
                        errorColumnas: [
                            `¡Error!`,
                            `¡El archivo cargado no es valido!`,
                            `Ok`
                        ],
                        alertErrorCamposIncompletos: [
                            `¡Error!`,
                            `Algunos campos obligatorios en el archivo estan vacios`
                        ],
                        paso2: [
                            `a. El campo codMaterial es obligatorio`,
                            `b. El campo nomMaterial es obligatorio`,
                            `c. El campo abreviacionMat es obligatorio`,
                            `d. El campo IdIdioma es obligatorio`,
                            `e. El campo idGrupoMaterial es obligatorio`,
                            `f. El campo idTipoMaterial es obligatorio`
                        ]
                    }
                ],
                unidadMedida: [
                    `Crear Unidad de medida`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                            `Empresa`,
                            `Unidad de medida`,
                            `Símbolo`,
                            `Descripción`,
                            `Guardar`,
                            `Cancelar`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ]
                    }
                ],
                fabricante: [
                    `Crear Fabricante`,
                    {
                        formulario: [
                            `Fabricante`,
                            `Abreviación`,
                            `Agregar fabricante`,
                            `No.`,
                            `Fabricante`,
                            `Abreviación`,
                            `Acciones`,
                            `Guardar`,
                            `Cancelar`,
                            `Reporte de fabricantes`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        fabricanteExistente: [
                            `¡Error!`,
                            `Este fabricante ya se encuentra pre-cargado`,
                            `Ok`
                        ],
                        camposVacios: [
                            `¡Error!`,
                            `Los campos de modificación se encuentran vacíos`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El fabricante fue creado satisfactoriamente`,
                            `Ok`

                        ],
                        alertaError: [
                            `¡Error!`,
                            `Se ha generado un error al realizar el registro por duplicidad de valores`,
                            `Ok`
                        ],
                        alertaConfirmacion: [
                            `Confirmación`,
                            `¿Desea actualizar el registro?`,
                            `Si`,
                            `No`
                        ],
                        alertConfirmación: [
                            `Confirmación`,
                            // `¿Está seguro de crear el fabricante `,
                            `¿Está seguro de crear los fabricantes pre-cargados?`,
                            `Sí`,
                            `No`
                        ],
                    }
                ],
                modificarFabricante: [
                    `Editar Fabricante`,
                    {
                        formulario: [
                            `Fabricante`,
                            `Seleccionar fabricante`,
                            `Buscar por: Id, nombre fabricante o Abreviación`,
                            `Id`,
                            `Fabricante`,
                            `Abreviado`,
                            `Seleccionar otro fabricante`,
                            `Observación`,
                            `Reporte de fabricantes`,
                            `Fabricantes:`,
                            `Buscar por: Nombre fabricante o Abreviación`,
                            `Editar Fabricante`,
                            `Reporte de fabricantes`,
                            `Crear Fabricante`,
                            `Guardar`,
                            `Cancelar`

                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El fabricante `,
                            ` se actualizo satisfactoriamente`,

                        ],
                        alertaFormulario: [
                            `¡Error!`,
                            `No ha modificado ningun campo del formulario`,

                        ],
                        alertaDuplicado: [
                            `¡Error!`,
                            `La actualización que desea realizar genera ducplicidad con la siguiente información: [ `,

                        ],
                        alertaError: [
                            `¡Error!`,
                            `Se ha generado un error al realizar el registro`,

                        ],
                        alertaConfirmacion: [
                            `Confirmación`,
                            `¿Desea actualizar el registro?`,
                            `Si`,
                            `No`
                        ]
                    }
                ],
                eliminarFacricante: [
                    `Eliminar Fabricante`,
                    {
                        formulario: [
                            `Fabricante`,
                            `Seleccionar fabricante`,
                            `Buscar por: Id,fabricante o abreviación`,
                            `Id`,
                            `Fabricante`,
                            `Abreviado`,
                            `Seleccionar otro fabricante`,
                            `Observación`,
                            `Reporte de fabricantes`,
                            `Fabricantes:`,
                            `Buscar por: Nombre de fabricante o Abreviación`,
                            `Eliminar`,
                            `Cancelar`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El fabricante se elimino correctamente`,
                            `Ok`

                        ],
                        alertaConfirmacion: [
                            `Confirmación`,
                            `¿Está seguro de eliminar este fabricante?`,
                            `Si`,
                            `No`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `Se ha generado un error al eliminar el registro`,
                            `Ok`
                        ]
                    }
                ],
                Reportefabricante: [
                    `Id`,
                    `Fabricante`,
                    `Abreviación`,
                    `Crear fabricante`,
                    `Reporte Fabricante`,
                    `Reporte de Fabricantes`
                ],
                cargueMasivoFabricante: [
                    `Cargue masivo Fabricante`,
                    `Regresar`,
                    {
                        paso1: [
                            `Fabricantes.xlsx`,
                            `Abrir el archivo "Fabricantes.xlsx" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones: `,
                            `Empresas`,
                            `[Seleccionar]`,
                            `Campó`,
                            `Valor`,
                            `Valor para el excel`,
                            `Valor para el excel`,
                            `Valor para el excel`,
                            'Buscar empresa',
                            'Seleccione Empresa'
                        ],
                        paso3: [
                            `El cargue se realizo satisfactoriamente.`,
                            `Registros en conflicto`,

                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `El cargue se realizo satisfactoriamente.`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `Se encontraron errores que no permitieron que se realizara el registro de la información, a continuación se mostraran los registros en conflicto`,
                            `Ok`
                        ],
                        errorColumnas: [
                            `¡Error!`,
                            `¡El archivo cargado no es valido!`,
                            "Se ha generado un error al cargar el archivo",
                            `Ok`, 
                            "El archivo cargado tiene valores duplicados"
                        ],
                        tablaLog: [
                            `Resultado de errores` //0
                            , `Posición` //1
                            , `Fabricante` //2
                            , `Abreviación` //3
                            , `Nombre Empresa` //4
                            , `Nit` //5
                            , `Email` //6
                            , `Continuar` //7
                            , `Atras` //8
                            , `Nombre` //9
                            , `Numero de Documento` //10
                            , `El archivo supera la cantidad de errores soportada (20).` //11
                            , `error` //12
                        ],
                        paso2: [
                            `a. El campo Fabricante es obligatorio`,
                            `b. El campo Abreviacion es obligatorio`,
                            `c. El campo idEmpresa es obligatorio`
                        ]
                    }
                ],
                ajustesCatalogacion: [
                    `Ajuste general de textos`,
                    {
                        formulario: [
                            `Inspeccion:`,
                            `Usuario:`,
                            `Inspeccion`,
                            `Usuario`,
                            `Buscar`,
                            `Longitud descripción corta:`,
                            `Incluir fabricante en descripción corta`,
                            `Incluir referencia en descripción corta`,
                            `Guardar`,
                            `Cancelar`,
                            `Caracter de separación de características:`,
                            `Longitud descripción corta`,
                            `Caracter de separación de características`,
                            `¿Está seguro de guardar los datos?`,
                            `-- Seleccione estado --`,
                            `Seleccione catalogador`,
                            `Rango de fechas de la catalogación`,
                            `Desde`,
                            `Hasta`,
                            `Guardar`,
                            'Catalogador',
                            'Buscar Catalogador',
                            'Resultados de la operación',
                            'Observación',
                            'Id',
                            'Código a tratar',
                            'Descripción',
                            'Referencia',
                            'Estado',
                            'Catalogador asignado',
                            'Atención',
                            'Debe agregar el archivo'
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        alertErrorRepetido: [
                            `Error al guardar los ajustes de textos`,
                            `¡Los ajustes generales que desea ingresar ya se encuentran registrados en el sistema!`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `Los ajustes generales de textos se actualizaron correctamente`,
                            `Ok`
                        ],
                        alertInfo: [
                            `Atención`,
                            `Ingrese los campos obligatorios marcados con (*)`,
                            `Ok`
                        ]
                    }
                ],
                catalogoToxico: [
                    `Catálogo antiguo`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ]
                    }
                ],
                crearMaterial: [
                    `Crear Catálogo`,
                    {
                        filtroToxico: [
                            `Código de consulta`,
                            `Código antiguo`,
                            `Código SLI`,
                            `ERP`,
                            `Descripción antigua`,
                            `Códigos duplicados`,
                            `Descripción Antigua`
                            , `Código ERP`
                            , `Materiales con los que se genera duplicidad por referencia`
                        ],
                        formulario: [
                            `Seleccionar ...`, // 0
                            `Empresa`, // 1
                            `Código Stockat`, // 2
                            `Grupo`, // 3
                            `Código antiguo`, // 4
                            `Tipo`, // 5
                            `Producto`, // 6
                            `Unidad de medida`, // 7
                            `Fabricante `, // 8
                            `Código ERP`, // 9
                            `Complemento información técnica`, // 10
                            `Referencia(s)`, // 11
                            `Comentario de inspección`, // 12
                            `Actualizar clasificación `, // 13
                            `Cancelar`, // 14
                            `Actualizar clasificación`, // 15
                            `Texto largo`, // 16
                            `Texto Abreviado`, // 17
                            `Inspección`, // 18
                            `Comentario inspección`, // 19
                            `Actualizar descripción`, // 20
                            `Texto alternativo`, // 21
                            `Ingles`, // 22
                            `Tipo`, // 23
                            `Grupo`, // 24
                            `Clase`, // 25
                            `Guardar inspección`, // 26
                            `Imagen`, // 27
                            `Cargar`, // 28
                            `No se encontro información`, // 29
                            `Crear clasificación`, // 30
                            `Crear características`, // 31
                            `Texto largo`, // 32
                            `Texto Abreviado`, // 33
                            `Referencias(s)`, // 34
                            `Limpiar formulario` // 35
                            , // 36
                            , `Tipo de administración` // 37
                            , `Material` // 38
                            , `Activo` // 39
                            , `Herramienta/Activos` // 40
                            , `Tuberia` // 41
                            , `Servicio` // 42
                            , `Tipo de producto` // 43
                            , `Regresar` // 44
                            , "No se encontraron valores"  // 45
                            , "Información adicional" // 46
                            , "Obtener descripción" // 47
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        modalCaracteristicas: [
                            `Carácteristicas e información adicional`,
                            `Orden`,
                            `Carácteristica`,
                            `Valor`
                        ],
                        error: [
                            `Error`,
                            `¡Esta referencia ya fue ingresada`
                            , `Error al validar la información ingresada`
                        ],
                        success: [
                            `Buen trabajo`,
                            `Clasificación realizada satisfactoriamente`,
                            `Inspección enviada satisfactoriamente`
                        ],
                        envio: [
                            `Se envío a QA`,
                            ``
                        ],
                        alertConfirmacion: [
                            `Confirmación`,
                            `¿Desea crear la clasificación?`,
                            `Sí`,
                            `No`
                            , `El código `
                            , ` esta asociado a este código antiguo, ¿Desea obtenerlo?`
                        ],
                        alertConfirmacionActualizacion: [
                            `Confirmación`,
                            `¿Desea actualizar la clasificación?`,
                            `Sí`,
                            `No`
                        ],
                        alertConfirmacionCaracteristicas: [
                            `Confirmación`,
                            `¿Desea guardar las características seleccionadas?`,
                            `Sí`,
                            `No`
                        ],
                        alertError: [
                            `¡Error!`,
                            `Se genero un error al validar el registro`,
                        ],
                        alertNoCaracteristicas: [
                            `¡Error!`,
                            `¡Esta clase no cuenta con características creadas!`,
                        ],
                        alertrRegistroRepetido: [
                            `¡Error!`,
                            `Esta clase se encuentra registrado con el código: `,
                            `nombre de la clase: `,
                            `grupo: `,
                            `Tipo: `,
                            "Se generó un error al guardar la información" //5
                        ],
                        alertCamposNoValidos: [
                            `¡Error!`,
                            `No se ha validado correctamentel el clase seleccionado`
                        ],
                        alertCaracteristicaVacia: [
                            `¡Error`,
                            `Algunas características aun no tienen asignado níngun valor`
                        ],
                        errorBuscar: [
                            `¡Error!`,
                            `Registro no encontrado`
                        ],
                        errorConexion: [
                            `¡Error!`,
                            `No se logro la conexión con el servidor`
                            , `No se han encontrado coincidencias`
                        ],
                        descripcionToxica: [
                            `Código no tratado`,
                            `Información adicional `,
                            `Referencia`,
                            `Observación`,
                            `Estado de solciitud código no tratado`,
                            `Tratado`
                            , `No tratado`
                        ],
                        errorCrearCaracteristicas: [
                            `¡Error!`,
                            `Se ha generado un error al crear las características para esta clase`,
                            `Ok`
                            , `Esta clase se encuentra duplicada por referencia y/o fabricante con los siguientes códigos SLI`
                            , `Esta clase se encuentra duplicada por caractersiticas con los siguientes códigos SLI`
                            , `Esta clase se encuentra duplicada por caractersiticas y fabricante con los siguientes códigos SLI`
                            , `Error al guardar la imagen`
                            , `Error general, por favor comuniquese con alguien de soporte técnico`
                            , `¿Desea continuar?`
                            , `Esta clase se encuentra duplicada por referencia y fabricante con los siguientes códigos SLI`
                            , "Error de duplicidad: " // 10
                            , "Codigos duplicados: "

                        ],
                        errorNoCaracteristicas: [
                            `¡Error!`,
                            `No ha seleccionado ningun valor para esta clase`,
                            `Ok`
                        ],
                        confirmacionComentario: [
                            `Confirmación`,
                            `¿Desea guardar este comentario?`,
                            `Sí`,
                            `No`
                        ],
                        errorInspeccionNoAgregada: [
                            `¡Error!` // 0
                            , `Debe seleccionar una acción de inspección`  // 1
                            , `Ok` // 2
                            , `Se genero un error al guardar el comentario` // 3
                        ],
                        errorActualzarIncompleto: [
                            `Advertencia!`,
                            `Algunos datos no se actualizaron ya que generan conflicto con los demas registros`,
                        ],
                        errorActualizarAll: [
                            `¡Error!`,
                            `Se genero un error al actualizar la información`
                        ],
                        alertaWarning: [
                            `Advertencia`,
                            `¡Esta clase no cuenta con características creadas!`
                        ],
                        advertenciaDuplicado: [
                            `Material duplicado, ¿Desea continuar?`,
                            `La clase que intenta catalogar ya se encuentra registrada con el código SLI: `,
                            `, el fabricante registrado para esta clase es: `,
                            ` y las referencias en conflicto son: `,
                            `Sí`,
                            `No`
                        ],
                        advertenciaDuplicadoCaracteristicas: [
                            `Error de duplicidad`,
                            `La clase que intenta catalogar ya se encuentra registrada con el código SLI: `,
                            ` y las características duplicadas son: `,
                            `Sí`,
                            `No`
                        ],
                        caracteristicasDuplicadas: [
                            `Error de duplicidad`,
                            `Las caracteristicas que ha seleccionado para la clase actual ya se encuentran registradas con el código SLI: `,
                            ` y el nombre de la clase es: `,
                            `Sí`,
                            `No`
                        ],
                        alertErrorCodInspeccion: [
                            '¡Error!',
                            'No se logró obtener el código de inspección'
                        ],
                        alertInfoNoCaracteristicas: [
                            'Información!',
                            'La clase que desea catalogar no cuenta con caracteristicas'
                        ],
                        alertInfoNoValores: [
                            'Información!',
                            'La clase que desea catalogar no cuenta con valores asígnados'
                        ],
                        alertInfoNoReferencias: [
                            'Información!',
                            'La clase que desea catalogar no cuenta con referencias'
                        ],
                        confirmacionDuplicar: [
                            'Confirmación',
                            '¿Desea importar este material?',
                            'Sí',
                            'No'
                        ],
                        caracteristicasNoValor: [
                            `Advertencia`,
                            `La clase seleccionada no cuenta con valores asignados en algunas de sus características`
                        ],
                        errorObtenerCaracteristicas: [
                            `¡Error!`,
                            `Se ha generado un error al obtener las características y valores de esta clase`
                        ],
                        errorCaracterSeparacion: [
                            `!Error!`,
                            `La empresa en la cual esta catalogando, no cuenta con caracter de separación o limite de caracteres, asígne algun valor para poder visualizar las descripciones`
                        ]
                        , referenciaDuplicada: [
                            `Referencia duplicada`
                            , `Códigos con los se genera duplicidad: `
                        ]
                        , errorLoagin: [
                            `Se genero un error al cargar la información, favor comunicarse con el administrador del sistema`
                        ]
                        , getCloned: [
                            `Confirmación`
                            , `El material que desea obtener ya fue catalogado, ¿Desea obtenerlo?`
                        ]
                    }
                ],
                calidadQA: [
                    `Calidad Q.A.`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                            `Empresa`,
                            `Estado inspección`,
                            `Usuario`,
                            `Con código`,
                            `Guardar`,
                            `Cancelar`
                        ],
                    }
                ],
                formModificarTipoMaterial: [
                    `Editar Tipo`,
                    {
                        formulario: [
                            `Empresa`,
                            `Código`,
                            `Seleccionar ...`,
                            `Nombre Tipo`,
                            `Rango`,
                            `Descripción`,
                            `Observación`,
                            `Guardar`,
                            `Cancelar`,
                            `Desde`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                        ],
                        alertas: [
                            {
                                title: `Error`,
                                texto: `¡Debe seleccionar una empresa!`,
                            },
                            {
                                title: `Error`,
                                texto: `¡Debe seleccionar un Tipo!`
                            }


                        ],
                        botones: [
                            `Ok`,
                            ``
                        ],
                        alertIdiomasError: [
                            `¡Error`,
                            `No se encuentran idiomas asociados a este registro`,
                            `Ok`
                        ]
                    }
                ],
                formModificarGroupArticulo: [
                    `Editar Grupo de Articulo`,
                    {
                        formulario: [
                            `Empresa`,
                            `Código`,
                            `Seleccionar ...`,
                            `Grupo árticulo`,
                            `Descripción`,
                            `Observación`,
                            `Abreviado`,
                            `Guardar`,
                            `Cancelar`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ]
                    }
                ],
                modificarUnidadMedida: [
                    `Editar Unidad de medida`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                            `Empresa`,
                            `Unidad de medida`,
                            `Símbolo`,
                            `Descripción`,
                            `Guardar`,
                            `Cancelar`,
                            `Observación`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ]
                    }
                ],
                modificarProductoUNSPSC: [
                    `Editar Producto`,
                    {
                        formulario: [
                            `Seleccionar ...`,
                            `Empresa`,
                            `Producto a Editar`,
                            `Tipo`,
                            `Grupo articulo`,
                            `Código`,
                            `Descripción`,
                            `Abreviado`,
                            `Observación`,
                            `Guardar`,
                            `Cancelar`,
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ]
                    }
                ],

                caracteristicas: [
                    `Crear característica`,
                    {
                        formulario: [
                            "Productos", // 0
                            "Característica", // 1
                            "Observación", // 2
                            "Guardar Características", // 3
                            "Cancelar", // 4
                            "Editar", // 5
                            "Actualizar Orden", // 6
                            "Posición", // 7
                            "Acción", // 8
                            "Editar característica", // 9
                            "Guardar", // 10
                            "Traducciones", // 11
                            "Agregar Característica", // 12
                            "Cancelar característica", // 13
                            "Información", // 14
                            "Cuenta con características en pre-visualización que no han sido guardadas.", // 15
                            "Multivalor", // 16
                            "Reporte de Características", // 17
                            "Crear Característica", // 18
                            "Características", // 19
                            "Sí", // 20
                            "No", //21
                            "Duplicado", // 22
                            "Error general", // 23
                            "Error", // 24
                            "Registro de errores", // 25
                            "Se ha generado un error al crear las características" // 26

                        ],
                        claseEnUso: [
                            `Producto en uso`
                        ],
                        alertErrorDB: [
                            `¡Error!`,
                            `Error al comunicarse con el servidor`,
                            `Ok`
                        ],
                        caracteristicaExistente: [
                            `¡Error!`,
                            `Esta característica ya se encuentra pre-cargada`,
                            `Ok`
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Característica`,
                            `Observación`
                        ],
                        menu: [
                            `Crear característica`,
                            `Pre-visualización`
                        ],
                        alertTraducciones: [
                            `Atención`,
                            `Debe completar las traducciones para continuar`,
                            `Ok`
                        ],
                        alertCaracteristicaAdd: [
                            `¡Característica agregada!`,
                            `Para guardar permanentemente las características por favor vaya a Pre-visualización`,
                            `Ok`
                        ],
                        alertIdiomaMaterial: [
                            `¡Error!`,
                            `Clase seleccionada no es valida`,
                            `Ok`
                        ],
                        alertNoCaracteristicas: [
                            `¡Error!`,
                            `No cuenta con características`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `Se han registrado las características satisfactoriamente`,
                            `Ok`
                        ],
                        alertaRepetido: [
                            `¡Error!`,
                            `Se han encontrado registros repetidos para esta empresa`,
                            `Ok`,
                            `Id Característica`,
                            `Característica`,
                            `Observación`
                        ],
                        alertaConfirmacion: [
                            `¡Confirmación!`,
                            `¿Desea guardar los registros?`,
                            `Sí`,
                            `No`
                        ],
                        alertaMaterialDireferente: [
                            `Error!`,
                            `Error en las clases seleccionadas`
                        ]
                        , alerts:[
                            "Error al guardar las características" // 0
                            , "Se perderá la información ingresada" // 1
                            , "¿Desea continuar?" // 2
                            , "Se guardaro la información con éxito."// 3
                        ]
                    }

                ],
                editarCaracteristica: [
                    `Editar característica`,
                    {
                        formulario: [
                            `Seleccione la característica a editar`,
                            `Buscar por: Id, clase u observación`,
                            `Regresar`,
                            `Característica`,
                            `Observación`,
                            `Posición`,
                            `Acciones`,
                            `Multivalor`,
                            `Idioma`,
                            `Acciones`,
                            `¡Advertencia!`,
                            `Cuenta con elementos que se eliminaran tan pronto guarde sus acciones, si desea deshacer estas acciones de click`,
                            `aquí`
                            , `Este producto no cuenta con característica`
                        ],
                        cargaMasiva: [
                            `Cargar`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Característica`,
                            `Observación`,
                        ],
                        alertaConfirmacion: [
                            `¿Desea actualizar el registro?`,
                            `para continuar seleccione si`,
                            `Sí`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `¡Error al realizar el registro!`,
                            `No se ha actualizado ningun valor en el formulario`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `¡Error al realizar el registro!`,
                            `Se genero un error que no permitio que se guardara el registro`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `Los registros se editaron satisfactoriamente`,
                            `Ok`
                        ],
                        alertErrorDB: [
                            `¡Error!`,
                            `Se genero un error al realizar el registro, verfique que el Tipo que intenta ingresar no tenga asociado ese mismo Código con diferentes características`,
                            `Ok`
                        ],
                        ErrorGeneral: [
                            `¡Error!`,
                            `Problema de conexión con base de datos, favor comunicarse con el administrador`,
                            `Ok`
                        ],
                        ErrorTipoMaterial: [
                            `¡No se guardo el Tipo seleccionado!`,
                            `Este error se debe a que el Tipo seleccionado ya cuenta con este código asígnado`,
                            `Ok`
                        ],
                        edicionCaracteristica: [
                            `Buen trabajo`,
                            `El registro se edito satisfactoriamente`,
                            `Ok`
                        ],
                        valoresNoEditados: [
                            `¡Error!`,
                            `No ha realizado ningun cambio`
                        ],
                        ErrorEliminar: [
                            `¡Error!`,
                            `Las características que desea eliminar ya cuentan con clases o valores relacionados`
                        ],
                        ErrorEliminar2: [
                            `¡Error!`,
                            `No puede eliminar características que ha editado`
                            , "Se genero el siguiente error: "
                        ]
                    }
                ],
                reporteCaracteristica: [
                    `Cod. Producto`,
                    `Producto`,
                    `Características`,
                    `Regresar`,
                    `Reporte Características`
                ],
                elimiarCaracteristica: [
                    `Eliminar característica`,
                    `Regresar`,
                    `Buscar por: Id, característica u observación`,
                    `Información Basica`,
                    `Producto`,
                    `Código Producto`,
                    `Característica`,
                    `Observación`,
                    `Eliminar`,
                    `Cancelar`,
                    `Seleccione la característica a eliminar`,
                    {
                        alertaConfirmar: [
                            `Confirmación`,
                            `¿Está seguro de eliminar este Tipo?`,
                            `Sí`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `Tipo eliminado satisfactoriamente`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `Error al intentar eliminar el Tipo por que ya se encuentra asignado a material catalogado.`,
                            `Ok`
                        ],
                    }
                ],
                valor: [
                    `Valores`,
                    {
                        formulario: [
                            `Productos`,
                            `Característica`,
                            `Nombre valor`,
                            `Abreviación`,
                            `Cancelar`,
                            `Editar`,
                            `Guardar`,
                            `Posición`,
                            `Acción`,
                            `Modificar característica`,
                            'Guardar edición',
                            `Traducciones`,
                            `Crear Valor`,
                            `Cancelar Valor`,
                            `Información`,
                            `Cuenta con características en pre-visualización que no han sido guardadas.`,
                            `No se encontraron Clases asociadas a esta empresa`,
                            `No se encontraron características asociadas a esta clase`,
                            `Editar Valor`,
                            `[Seleccionar]`,
                            `Crear valor`,
                            `Clase y característica en uso`
                            , "Error" // 22
                            , "Error generado: " // 23

                        ],
                        alertErrorDB: [
                            `¡Error!`,
                            `Error al comunicarse con el servidor`,
                            `Ok`
                        ],
                        valorExistente: [
                            `¡Error!`,
                            `Esta característica ya se encuentra pre-cargada`,
                            `Ok`
                        ],
                        tablaTraduccion: [
                            `Idioma`,
                            `Valor`,
                            `Abreviación`
                        ],
                        menu: [
                            `Crear valor`,
                            `Pre-visualización`
                        ],
                        alertTraducciones: [
                            `Atención`,
                            `Algunos campos de traducciones se encuentran vacíos`,
                            `Ok`
                        ],
                        alertValorAdd: [
                            `Valores agregados`,
                            `Para guardar permanentemente los valores por favor diríjase a la pestaña de Pre-visualización`,
                            `Ok`
                        ],
                        alertIdiomaMaterial: [
                            `¡Error!`,
                            `La clase seleccionada no es valida`,
                            `Ok`
                        ],
                        alertNoCaracteristicas: [
                            `¡Error!`,
                            `No cuenta con características`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `Se han registrado los valores satisfactoriamente`,
                            `Ok`
                        ],
                        alertaRepetido: [
                            `¡Error!`,
                            `Se genero un error al insertar los siguientes valores: `,
                            `Los siguientes valores se encuentran duplicados: `
                        ],
                        alertaDB: [
                            `¡Error!`,
                            `Se ha generado un error al realizar la creación de valores.`,
                            "El archivo ingresado cuenta con valores duplicados" 
                        ],
                        alertaConfirmacion: [
                            `¡Confirmación!`,
                            `¿Desea guardar los registros?`,
                            `Sí`,
                            `No`
                        ]
                        , tableLog: [
                            `Tabla de resultados`
                            , `Valor en conflicto`
                            , `Abreviación`
                            , `Idioma`
                            , "Valor español"
                            , "Valor ingles"
                            , "Duplicados en el archivo"

                        ]
                    }

                ],
                editarValor: [
                    `Editar valores`,
                    {
                        formulario: [
                            `Productos`,
                            `Característica`,
                            `Valor`,
                            `Abreviación`,
                            `Cancelar`,
                            `Editar`,
                            `Guardar`,
                            `Posición`,
                            `Acción`,
                            `Modificar característica`,
                            'Guardar edición',
                            `Traducciones`,
                            `Agregar Valor`,
                            `Cancelar Valor`,
                            `Información`,
                            `Cuenta con características en pre-visualización que no han sido guardadas.`,
                            `No se encontraron Productos asociados a esta empresa`,
                            `No se encontraron características asociadas a este Producto`,
                            `Seleccione el valor a editar`,
                            `Buscar por: Id valor,Producto,característica,valor u observación`,
                            `[Seleccionar]`,
                            `Idioma`
                        ],
                        filtro: [
                            `Producto`,
                            `Característica`,
                            `Valor`,
                            `Abreviación`
                        ],
                        alertErrorDB: [
                            `¡Error!`,
                            `Error al comunicarse con el servidor`,
                            `Ok`
                        ],
                        alertaConfirmacion: [
                            `¿Desea actualizar el registro?`,
                            `para continuar seleccione si`,
                            `Sí`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `¡Error al realizar el registro!`,
                            `No se ha actualizado ningun valor en el formulario`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `¡Error al realizar el registro!`,
                            `Se genero un error que no permitio que se guardara el registro`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Buen trabajo`,
                            `Se realizo el registro satisfactoriamente`,
                            `Ok`
                        ],
                        ErrorGeneral: [
                            `¡Error!`,
                            `Problema de conexión con base de datos, favor comunicarse con el administrador`,
                            `Ok`
                        ],
                    }
                ],
                reporteValor: [
                    `Lista de valores`,
                    {
                        camposTablas: [
                            `Producto`,
                            `Id característica`,
                            `Característica`,
                            `Valores`,
                            `Abreviación`,
                            `Codigo Producto`
                        ],
                        botones: [
                            'Crear valor'
                        ]

                    }
                ],
                elimiarValor: [
                    `Eliminar Valor`,
                    `Regresar`,
                    `Buscar por: Id valor,Producto,característica,valor u observación`,
                    `Información Basica`,
                    `Producto`,
                    `Código Producto`,
                    `Característica`,
                    `Observación`,
                    `Eliminar`,
                    `Cancelar`,
                    `Seleccione el valor a eliminar`,
                    `Valor`,
                    {
                        alertaConfirmar: [
                            `Confirmación`,
                            `¿Está seguro de eliminar este valor?`,
                            `Sí`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Buen trabajo`,
                            `Valor eliminado satisfactoriamente`,
                            `Ok`
                        ],
                        alertaError: [
                            `¡Error!`,
                            `El valor que desea eliminar esta asociado a un  código SLI`,
                            `Ok`
                        ],
                    }
                ],
                cargaMasivaValor: [
                    'Carga masiva de valores'
                    , `Reporte valores`
                    , `Ver material`
                    , `Seleccionar otro material`
                    , `Material`
                    , `Código`
                    , `Abreviación`
                    , `Observación`
                    , `Grupo material`
                    , `Tipo material`
                    , `Generar archivo`
                    , {
                        alertaConfirmacionGeneral: [
                            `Confirmación`
                            , `¿Desea generar un archivo con todos los productos asociadas a esta empresa?`
                            , `¿Desea generar el formato de cargue con el Producto seleccionado?`
                            , `Sí`
                            , `No`
                            , `¿Desea cargar el archivo seleccionado?`
                        ]
                        , alertaError: [
                            `Error`
                            , `Se genero un error al crear el formato para la carga de valores, favor comunicarse con el administrador del sitema`
                        ]
                        , tablaInfo: [
                            'Archivo'
                            , `# Registros`
                            , `Fecha Modificación`
                            , `Tamaño`
                            , `Acción`
                            , `Información del archivo`
                        ]
                        , alertSuccess: [
                            `¡Buen trabajo!`
                            , `El registro se realizo satisfactoriamente`
                        ]
                        , alertError: [
                            `¡Error!`
                            , `Se generaron algunos errores durante el carge, favor revisar el log`
                        ]
                        , tablaLog: [
                            `Posición` // 0
                            , `Código` // 1
                            , `Clase` // 2
                            , `Caracteristica` // 3
                            , `Valor` // 4
                            , `Abreviación` // 5
                            , `Registro de acciones` // 6
                            , `Error` // 7
                            , `Error de BD` // 8
                            , `Registro duplicado` // 9
                            , `Error de ejecución` // 10
                            , `Idioma` // 11
                            , "Tabla duplicados" // 12
                            , "Ingles" // 13
                            , "Español" // 14
                            , "Valores no cargado debido a que presentaron conflictos" // 15
                        ]

                    }
                    , `Valor`
                    , `Abreviación`
                    , `Seleccione el archivo`
                    , `Cargar`
                    , `Buscador`
                    , `Cargar`
                    , `Cancelar`
                    , `Idioma`
                    , `Reiniciar bùsqueda`
                    , "Validando valores" // 21
                    , "Creando valores" // 22
                    , "Cargando valores" // 23
                    , "Verificando archivo..." // 24
                    , "Se verifica que la información del archivo cumpla con los requisitos para ser cargada." // 25
                    , "Cancelar" // 26
                    , "Se encontraron registros en conflicto, por favor corrijalos e intente de nuevo" // 27
                    , "Posición de registros en conflicto: " // 28
                    , "La cantidad de valores en el archivo es erronea" // 29
                    , "Por favor espere..." // 30
                ],
                cargatoxicoindividual: [
                    `Cargue Descripción Antigua`,
                    `Código antiguo`,
                    `Descripción`,
                    `Referencia`,
                    `Observación`,
                    `Asignar Usuario`,
                    `Crear Descripción Antigua`,
                    `Regresar`,
                    `Reporte de descripciones`,
                ],
                reportetoxico: [
                    `Reporte de descripciones`,
                    `Cargar Toxico`,
                    `Se creo el Código satisfactoriamente`,
                    `Buen Trabajo`,
                    `Ok`,
                    `Error`,
                    `Error al crear código a tratar`,
                    `Ok`,
                    `Reporte de descripciones`,
                    `¿Está seguro de crear la descripción antigua?`,
                ],
            },
            `Idioma`,

        ];

        //Modulo dia a dia
        this.solicituddiadia = [
            `Formulario Solicitud de Catalogación`,
            `Atención de solicitudes de catalogación`,
            {
                solicitudDia: [
                    `Tipo de solicitud`,
                    `Descripción del producto`,
                    `Aplicación especifica`,
                    `Parte número`,
                    `Tipo de Equipo al que aplica`,
                    `Fabricante`,
                    `Modelo al que aplica`,
                    `Clasificación NFPA`,
                    `UMB`,
                    `Otra información`,
                    `Adicionar`,
                    `Acción`,
                    `Editar`,
                    `Eliminar`,
                    `Enviar Solicitud`,
                    `Solicitud de Catalogacion:`,
                    `Solicitud de Catalogacion Nº:`,
                    `Estado de la solicitud:`,
                    `Código a tratar`,
                    `Código ERP`,
                    `Código SLI`,
                    `Advertencia`,
                    `¡Esta solicitud se creará más no se enviará directamente, para enviarla después de creada debe modificarla en su respectivo formulario!. ¿Desea continuar?`,
                    `¡Esta solicitud se guardará y enviará directamente por lo cual no podra modificarse después!. ¿Desea continuar?`,
                    `Debe agregar al menos un producto`,
                    `Hubo un error al registrar la solicitud. Los cambios fueron cancelados`,
                    `Se registró la solicitud correctamente`,
                    `Debe agregar un código`,
                    `Debe seleccionar almenos una opción en el campo `,
                    `El campo `,
                    ` está vacío`,
                    `¿Está seguro de eliminar el producto, los cambios no se realizarán si no registra la solicitud?`,
                    `El número de la solicitud es: `,
                    `Agregar producto `,
                    `Tipo solicitud`,
                    `Unidad de medida base`,
                    `Guardar`,
                    `¿Está seguro de cancelar la solicitud?`
                    , `Guardar`
                    , `Cargar archivos`
                    , `Seleccionar archivos`
                    , `Extensiones de archivos permitidas`
                    , `El tamaño maximo permitido en archivos  es de `
                    , `Número solicitud`
                    , `Id producto solicitado`
                ],
                alertaError: [
                    `Error`
                    , `Archivos existentes`
                    , `La extención del archivo no es permitida o el archivo es demasiado grande para ser guardado`
                    , `Error al leer el archivo`
                ]
            },
            {
                atencionDeSolicitudes: [`Cliente`, `Estado`, `Consecutivo de seguimiento`, `resultados por pagina`, `Buscar`]
            },
            {
                informeCatalogacion: [`Informe de catalogacion de clases`, `Consecutivo`, `Buscar`]
            },
            {
                modificarSolicitudCatalogacion:
                    [
                        `Editar solicitud catalogación`,
                        `Seleccione el estado de la solicitud`,
                        `Seleccione el número de la solicitud`,
                        `Error al guardar el registro`,
                        `Se guardó el registro correctamente`,
                        `Cancelar solicitud`,
                    ]
            },
            {
                tablaSolicitudCatalogacion: [
                    `Reporte de solicitudes de catalogación`,
                    `Crear solicitud`,
                    `Seleccione el número de la solicitud`,
                    `Id`,
                    `Número solicitud`,
                    `Estado solicitud`,
                    `Número items`,
                    `Fecha creación`,
                    `Catalogador encargado`,
                    `Volver a lista de solicitudes`,
                    `Fecha tratado`,
                    `Acción`,
                    `Fecha calidad`,
                    `Usuario creador`,
                    `Tipo de solicitud`, //14
                    `Inspección`,
                    `Comentario de Inspección`,
                    `Tipo material`,
                    `Código grupo`,
                    `Nombre grupo`,
                    `Código clase`,
                    `Nombre clase`,
                    `Archivos`,
                    `Informe General de Solicitudes`
                ]
            },
            {
                tablaSolicitudCatalogacionItems: [
                    `Id producto`,
                    `Descripción producto`,
                    `Aplicación especifica`,
                    `Parte número`,
                    `Tipo equipo aplic.`,
                    `Fabricante`,
                    `Modelo`,
                    `Código a revisar`,
                    `Código ERP`,
                    `Código SLI`,
                    `Clasificación NFPA`,
                    `Información adicional`,
                    `Tipo solicitud`,
                    `Unidad medida base`,
                    `REPORTE PRODUCTO SOLICITUD CATALOGACIÓN`,
                    `REPORTE SOLICITUD CATALOGACIÓN`,
                    `Acción`
                    , `Archivos`
                    , `Usuario Creador`
                ]
            }];

        this.editarUsuario = [
            `Editar Usuario`,
            `Información Personal`,
            `Datos de Contacto`,
            `Permisos`,
            {
                formulario: [
                    `Nombre`,
                    `Apellido`,
                    `Tipo documento`,
                    `Número de documento`,
                    `Correo electrónico`,
                    `Contraseña`,
                    `Dirección`,
                    `País`,
                    `Departamento`,
                    `Municipio`,
                    `Código postal`,
                    `Teléfono`,
                    `Celular`,
                    `Extensión`,
                    `Empresa donde labora`,
                    `Cargo en la empresa`,
                    `Rol en la plataforma`,
                    `Seleccionar`,
                    `Guardar`,
                    `Cancelar`,
                    `Cambiar`,
                    `Regresar`,
                    `Eliminar`
                ],
                modal: [
                    `Buscar usuario por documento o id`,
                    `Número de documento, nombre completo o id`,
                    `Buscar`,
                    `Cancelar`
                ],
                error: [
                    `Usuario no encontrado`,
                    `Usuario no encontrado`,
                    `Entiendo!`
                ],
                alertaContrasena: [
                    `Usuario no encontrado`,
                    `Usuario no encontrado`,
                    `Entiendo!`
                ],
                cambioContrasena: [
                    `¿Desea cambiar la contraseña?`,
                    ``,
                    `Continuar`,
                    `Cancelar`
                ],
                alertConfirmacion: [
                    `¿Desea editar el usuario:`,
                    `?`,
                    `Sí`,
                    `No`
                    , `Confirmación`
                ],
                alertaSuccess: [
                    `Registro editado`,
                    `Ok`,
                ]
            }
        ]

        this.crearUsuario = [
            `Crear Usuario`,
            `Información Personal`,
            `Datos de Contacto`,
            `Permisos`,
            {
                formulario: [
                    `Nombre`,
                    `Apellido`,
                    `Tipo documento`,
                    `Número de documento`,
                    `Correo electrónico`,
                    `Contraseña`,
                    `Dirección`,
                    `País`,
                    `Departamento`,
                    `Municipio`,
                    `Código postal`,
                    `Teléfono`,
                    `Celular`,
                    `Extensión`,
                    `Empresa donde labora`,
                    `Cargo en la empresa`,
                    `Rol en la plataforma`,
                    `Seleccionar`,
                    `Guardar`,
                    `Cancelar`,
                    `Cambiar`,
                    `Regresar`
                ],
                modal: [
                    `Buscar usuario por documento o id`,
                    `Número de documento`,
                    `Buscar`,
                    `Cancelar`
                ],
                error: [
                    `Error de registro`,
                    `El correo o número de documento que ingreso ya se encuentra registrado`,
                    `Entiendo!`
                ],
                alertConfirmacion: [
                    `¿Desea crear el usuario: `,
                    `?`,
                    `Sí`,
                    `No`
                ],
                alertaSuccess: [
                    `Registro Creado`,
                    `Para ingresar revise su correo electrónico`,
                    `Ok`,
                ],
                textosRequeridos: [
                    `La contraseña debe tener mínimo 8 caracteres`
                ],
                alertNoContacto: [
                    `¡Error!`,
                    `Debe ingresar algun número de contacto`
                ]
            }
        ]

        this.editarEmpresa = [
            `Editar Empresa`,
            `Información básica`,
            `Información de localización`,
            `Información de contacto `,
            {
                formulario: [
                    `Nit`,
                    `Nombre empresa`,
                    `Actividad comercial`,
                    `País`,
                    `Departamento`,
                    `Municipio`,
                    `Código postal`,
                    `Dirección`,
                    `Complemento`,
                    `Persona de contacto`,
                    `Correo electroníco`,
                    `Teléfono`,
                    `Extensión`,
                    `Celular`,
                    `Divisa`,
                    `Seleccionar`,
                    `Guardar`,
                    `Cancelar`,
                    `Regresar`,
                    `CIIU`,
                    `Eliminar`

                ],
                modalBuscar: [
                    `Buscar empresa por nit o id`,
                    `Nit o id`,
                    `Buscar`,
                    `Cancelar`
                ],
                modalCIIU: [
                    `Buscar actividad CIIU`,
                    `Nit, nombre o id`,
                    `Buscar`,
                    `Cancelar`,
                    `Sección CIIU`,
                    `División CIIU`,
                    `Grupo CIIU`,
                    `Clase CIIU`,
                    `Actividad Seleccionada: `,
                    `Armar codígo`,
                    `Buscar por código`
                    , `Busqueda avanzada por codigo`
                ],
                error: [
                    `Empresa no encontrada`,
                    `Empresa no encontrada`,
                    `Entiendo!`
                ],
                alertaContrasena: [
                    `Empresa no encontrada`,
                    `Empresa no encontrada`,
                    `Entiendo!`
                ],
                cambioContrasena: [
                    `¿Desea cambiar la contraseña?`,
                    ``,
                    `Continuar`,
                    `Cancelar`
                ],
                alertaConfirmacion: [
                    `Confirmación`,
                    `¿Desea asociar los usuarios con la empresa `,
                    `Sí`,
                    `No`
                ]
                ,
                alertConfirmacion: [
                    `¿Desea actualizar el registro  `,
                    `?`,
                    `Sí`,
                    `No`
                ],
                alertaSuccess: [
                    `Registro editado`,
                    `Aceptar`,
                ],
                errorActividad: [
                    `Error al guardar el registro`,
                    `Usted no ha seleccionado una actividad`,
                    `Aceptar`
                ],
                alertCIIU: [
                    `Error`,
                    `El código CIIU que ingreso no es valido, por favor verifiquelo!`,
                    `Aceptar`
                ],
                alertUsuarios: [
                    `Error`,
                    `Error general, comuniquese con el administrador: `,
                    `Aceptar`
                ],
                alertGuardar: [
                    `Error`,
                    `El nit, correo o nombre de la empresa que desea crear ya existe en nuestra BD`,
                    `Aceptar`
                ],
                alertSuccess: [
                    `Operación exitosa`,
                    `Aceptar`
                ],
                alertIdiomaError1: [
                    `Error`,
                    `El idioma seleccionado ya fue ingresado!`,
                    `Aceptar`
                ],
                alertIdiomaError2: [
                    `Advertencia`,
                    `!El idioma seleccionado ya fue ingresado!`,
                    `Aceptar`
                ],
                errorNoContacto: [
                    `¡Error!`,
                    `No ha ingresado un teléfono de contácto`
                ],
                alertErrorCargueMasivo: [
                    `Error`,
                    `Los siguientes usuarios no se lograron asociar con con la empresa `
                ],
                logErrores: [
                    `Registro de errores: `,
                    `Nombre: `,
                    `Posición: `
                ],
                alertSuccessCargueMasivo: [
                    `Operación exitosa`,
                    `Los usuarios se asociaron satisfactoriamente con la empresa `
                ]

            }
        ]

        this.dataTableText = [
            `Columnas visibles`
            , `Imprimir`
            , `Copiar`
            , `Excel`
            , `PDF`
            , `CSV`
        ]

        this.inventarioModulo = {
            movInventarios: [
                `Crear entrada`,// 0
                `Remisión`,// 1
                `Orden de Compra`,// 2
                `Factura`,// 3
                `Observación`,// 4
                `Consultar Productos`,// 5
                `Diligenciar formulario`,// 6
                `Eliminar`,// 7
                `Limpiar`,// 8
                `Codigo SLI`,// 9
                `Desc. Corta`,// 10
                `Impuesto`,// 11
                `Estado del material`,// 12
                `Serial`,// 13
                `Placa`,// 14
                `Guardar`,// 15
                `Cancelar`,// 16
                `Salida de activos`,// 17
                `Listado de productos en inventario`,// 18
                `Asignación de activos`,// 19
                `Cantidad`,// 20
                `Precio`,// 21
                `Cargar Valores a Producto`,// 22
                `Proveedor`,// 23
                `Despachar`,// 24
                `Motivo Baja`,// 25
                `Baja de activos`,// 26
                `Devolución de activos`,// 27
                `Movimientos`,// 28
                `Consecutivo`,// 29
                `Nota de Credito`,// 30
                `El Consecutivo seleccionado, no contiene información para devolución`,// 31
                `Listado de productos del movimiento seleccionado`,// 32
                `Traslado de activos`,// 33
                `Información de productos`,// 34
                `Modificar Asignación de Activos 101-`,// 35
                `Custodio`,// 36
                `Modificar Baja`,// 37
                `Motivo Baja`,// 38
                `Modificar entrada`,// 39
                `Cancelar`,// 40
                `Modificar Salida`,// 41
                `Modificar Traslado`,// 42
                `Reporte de Asignaciónes`,// 43
                `Estado`,// 44
                `Planta`,// 45
                `Centro`,// 46
                `Almacen`,// 47
                `Centro de costo`,// 48
                `Sucursal`,// 49
                `Destino`,// 50
                `Ver`,// 51
                `Unidad`,// 52
                `Regresar`,// 53
                `Reporte de Baja`,// 54
                `Reporte de entradas`,// 55
                `Divisa`,// 56
                `Reporte de salidas`,// 57
                `Reporte de traslados`,// 58
                `Almacén Origen`,// 59
                `Almacén Destino`,// 60
                `Crear Baja de tuberia`,// 61
                `No. tubo`,// 62
                `Metro`,// 63
                `Pies`,// 64
                `Pulgada`,// 65
                `Numero Tubo`,// 66
                `Devolución de tuberia`,// 67
                `Modificar Despacho`,// 68
                `Reporte de despachos`,// 69
                `Crear despacho de tuberia`,// 70
                `Traslado de tuberia`,// 71,
                `Cancelar`// 72               
                , `Descripción antigua` //73
                , `Descripción` //74
                , `Condición` //75
                , `Cod. Antiguo` //76
                , `Descuento` // 77
                , `Reporte existencias ` // 78
                , `Seleccionar` // 79
                , `Descuento` // 80
                , `Cod. Planta` // 81
                , `Cod. Centro` // 82
                , `Cod. Almacen` // 83
                , `Unidad de medida` // 84
                , `Condición` // 85
                , `Ubicación` // 86
                , `Cantidad` // 87
                , `Cambiar ubicación` // 88
                , `Acción` //89
                , `Observación` //90
                , `Ubicación` //91
                , `Guardar ubicación` //92
                , `Cancelar` //93
                , "Confirmación" //94
                , "¿Desea actualizar la ubicación del material " // 95
                , "?" // 96
                , "Sí" // 97
                , "No" // 98
                , "Buen trabajo" // 99
                , "Error" // 100
                , "Se realizo el cambio de ubicación con exito" // 101
                , "Se genero un error al realizar el cambio de ubicación, por favor intentelo de nuevo" // 102
                , "Editar ubicación" // 103
                , "Observación de ubicación" // 104
                , "NOTA: La ubicación diligenciada anteriormente aplica para todos los materiales que se listen a continuación, si desea que alguno de estos se ubique en una lugar diferente, por favor diligencie esta información especificamente en el material" // 105
                , "Comprobante" // 106
                , "Reporte devoluciones" // 107
                , "Recargar tabla" // 108
                , "Obtener reporte" // 109
                , "Consultar reporte" // 110
            ],
            kardex: [
                "Kardex" // 0
                , "Fecha" // 1
                , "Consecutivo" // 2
                , "SLI" // 3
                , "Unidad Media base" // 4
                , "Condición" // 5
                , "Descripción" // 6
                , "Proveedor" // 7
                , "Planta O." // 8
                , "Centro O." // 9
                , "Almacen O." // 10
                , "Movimiento" // 11
                , "Almacen D." // 12
                , "Centro D." // 13
                , "Planta D." // 14
                , "Custodio" // 15
                , "Creador del movimiento" // 16
                , "Impuesto" // 17
                , "Descuento" // 18
                , "Precio" // 19
                , "Total" // 20
                , "Kardex de inventario" // 21
                , "Tipo movimiento" // 22
                , "Placa" // 23
                , "Serial" // 24
                , "Fecha inicio" // 25
                , "Fecha fin" // 26
                , "Buscar por: Serial, Placa, Código antiguo, Código SLI" // 27
                , "Buscar:" //28
                , "Consultar" // 28 
            ],
            inventarioMenus: [
                `Panel de Control`,
                `Adm. de productos`,
                `Adm. de activos`,
                `Adm. de tuberias`
            ],
            panelControl: [
                `Importar catalogación`,
                `Internos`,
                `Externos`,
                `Importe Cat. individual`,
                `Habilitar/Inhabilitar importe`,
                `Importe masivo`,
                `Eliminar importe`,
                `Reporte`,
                `Importe Individual`
                , `Habilitar/Inhabilitar importe`
                , `Importe masivo`
                , `Reporte`
                , `Crear catálogo externo`
                , `Cargué masivo de catalogo externo`
            ],
            estructura: [
                `Estructura`,
                `Planta`,
                `Centro`,
                `Almacén`,
                `Localización`
            ],
            estructuraPlantaPlanta: [
                `Crear Planta`,
                `Editar Planta`,
                `Eliminar Planta`,
                `Reporte Planta`
            ],
            estructuraPlantaCentro: [
                `Crear Centro`,
                `Editar Centro`,
                `Eliminar Centro`,
                `Reporte Centro`
            ],
            estructuraPlantaAlmacen: [
                `Crear Almacén`,
                `Editar Almacén`,
                `Eliminar Almacén`,
                `Reporte Almacén`
            ],
            estructuraPlanta: [
                `Planta`,
                `Nombre de planta:`,
                `Nombre de planta`,
                `Código de planta:`,
                `Código de planta`,
                `Persona de contacto:`,
                `Persona de contacto`,
                `Correo de contacto:`,
                `Correo de contacto`,
                `Dirección:`,
                `Dirección`,
                `Teléfono/Movil:`,
                `Teléfono/Movil`,
                `Guardar`,
                `Error, puede que haya una planta con el mismo código o nombre`,
                `Se creó la planta`
            ],
            estructuraPlantaModificar: [
                `Editar Planta`,
                `Regresar`,
                `Seleccionar Planta:`,
                `Buscar por: planta, código, nombre o email`,
                `Código`,
                `Nombre planta`,
                `Nombre contacto`,
                `Email`,
                `Se modificó la planta correctamente!`,
                `No se puede editar la planta porque no existe`,
                `Error al eliminar planta, revisar procedimientos`,
            ],
            estructuraPlantaEliminar: [
                `Eliminar Planta`,
                `Regresar`,
                `Seleccionar Planta:`,
                `Buscar por: planta, código, nombre o email`,
                `Está seguro de eliminar la planta`,
                `Se eliminó la planta correctamente!`,
                `No se puede eliminar la planta porque está asignada a un centro`,
                `No se puede eliminar la planta porque la planta no existe`,
                `Error al eliminar planta, revisar procedimientos`,
            ],
            estructuraPlantaReporte: [
                `Reporte Plantas`,
                `Crear Planta`,
            ],
            estructuraCentro: [
                `Centro`,
                `Nombre del Centro:`,
                `Nombre del Centro`,
                `Código del Centro:`,
                `Código del Centro`,
                `Planta`,
                `Persona de contacto:`,
                `Persona de contacto`,
                `Correo de contacto:`,
                `Correo de contacto`,
                `Dirección:`,
                `Dirección`,
                `Teléfono/Movil:`,
                `Teléfono/Movil`,
                `Guardar`,
                {
                    alertaConfirmacion: [
                        `¡Confirmación!`,
                        `¿Desea guardar el registro?`,
                        `Sí`,
                        `No`
                    ],
                    alertaError: [
                        `¡Error!`,
                        `Se ha generado un error al realizar el registro`
                    ],
                    alertaSuccess: [
                        `Buen trabajo`,
                        `El registro se ha realizado satisfactoriamente`
                    ],
                    alertRepetido: [
                        `¡Error!`,
                        `El registro que intenta ingresar se encuentra repetido para esta planta`
                    ]
                },
                `Acciones`
            ],
            estructuraCentroModificar: [
                `Editar Centro`,
                `Regresar`,
                `Seleccionar Centro:`,
                `Buscar por: centro o código`,
                {
                    filtro: [
                        `Cod. Centro`,
                        `Planta`,
                        `Centro`
                    ],
                    formulario: [
                        `Planta`,
                        `Centro`,
                        `Código`,
                        `Persona de contacto`,
                        `E-mail`,
                        `Dirección`,
                        `Teléfono`
                    ]
                }
            ],
            estructuraCentroEliminar: [
                `Eliminar Centro`,
                `Regresar`,
                `Seleccionar Centro:`,
                `Buscar por: centro o código`,
                {
                    filtro: [
                        `Cod. Centro`,
                        `Planta`,
                        `Centro`
                    ],
                    formulario: [
                        `Planta`,
                        `Centro`,
                        `Código`,
                        `Persona de contacto`,
                        `E-mail`,
                        `Dirección`,
                        `Teléfono`,
                        `Eliminar`,
                        `Cancelar`
                    ],
                    alertaConfirmacion: [
                        `¡Confirmación!`,
                        `¿Desea eliminar el registro?`,
                        `Sí`,
                        `No`
                    ],
                    alertaError: [
                        `¡Error!`,
                        `Se ha generado un error al eliminar el registro`
                    ],
                    alertaSuccess: [
                        `Buen trabajo`,
                        `El registro se ha eliminado satisfactoriamente`
                    ],
                }
            ],
            estructuraCentroReporte: [
                `Reporte Centros`,
                `Crear Centro`,
                {
                    tabla: [
                        `Planta`,
                        `Centro`,
                        `Contacto`,
                        `Email`,
                        `Código`,
                        `Dirección`
                    ]
                }
            ],
            estructuraAlmacen: [
                `Almacén`,
                `Planta`,
                `Centro`,
                `Nombre del Almacén:`,
                `Nombre de Almacén`,
                `Código de Almacén:`,
                `Código de Almacén`,
                `Persona de Contacto:`,
                `Persona de Contacto`,
                `Correo Electronico:`,
                `Correo Electronico`,
                `Dirección:`,
                `Dirección`,
                `Teléfono/Movil:`,
                `Teléfono/Movil`,
                `Tipo de Administracion:`,
                `Tipo de Administracion`,
                `Guardar`,
                `Debe seleccionar planta`,
                `Debe selecciona centro`,
                `Debe ingresar el nombre del almacén`,
                `Debe ingresar el código del almacén`,
                `Debe ingresar la persona de contacto del almacén`,
                `Debe ingresar el correo electrónico del almacén`,
                `Debe ingresar la dirección del almacén`,
                `Debe ingresar el teléfono del almacén`,
                `Debe seleccionar tipo de administración`,
                `Error al registrar almacén, ya hay un almacén con el mismo nombre o código`,
                `Se creó el almacén correctamente!`,
            ],
            estructuraAlmacenModificar: [
                `Editar Almacén`,
                `Regresar`,
                `Seleccionar Almacén:`,
                `Buscar por: Almacén o código`,
                // `Filtre por: Almacen o Codigo`,
                `No se puede modificar el almacén porque no existe`,
                `Se modificó el almacén`,
                `Error al obtener título`,
                `¿Está seguro de eliminar el almacén?`,
                `No se puede eliminar el almacén porque está asignado a un usuario`,
                `Se eliminó el almacén correctamente`,
                `No se eliminó el almacén por errores desconocidos`,
            ],
            estructuraAlmacenEliminar: [
                `Eliminar Almacén`,
                `Regresar`,
                `Seleccionar Almacén:`,
                `Buscar por: Almacén o código`,
            ],
            estructuraAlmacenReporte: [
                `Reporte Almacénes`,
                `Crear Almacén`,
            ],
            estructuraLocalizacion: [
                `Crear Localización`,
                `Nombre Localización`,
                `Codigo de Localización`,
                `Se creó la localización correctamente!`,
                `Error al registrar la localización, ya hay una localización con el mismo nombre o código`,
                `Editar Localización`, // 5
                `Buscar por: Localización o código`,
                `Seleccionar Localización`,
                `Se actualizó correctamente la localización`, //8
                `Se elimino correctamente la localización`, //9
                `No se puede modificar la localización porque no existe`, // 10
                `Reporte Localización` // 11
            ],
            contabilidad: [
                `Contabilidad`,
                `Centro de Costos`,
                `Sucursal`,
                `Destino`,
                `Proveedores`,
            ],
            // Contabilidad - Centro Costo -(Crear-Modificar-Eliminar-Reporte)
            contabilidadCentroCostoCrear: [
                `Crear Centro Costos`,
                `Código`,
                `Código`,
                `Centro de costos`,
                `Crear`,
                `No se puede crear el centro de costo porque ya hay uno con el mismo nombre o código`,
                `Se creó el centro costo!`,
                `No se puede actualizar el centro de costo porque ya hay uno con el mismo nombre o código`,
                `Se modificó el centro costo!`,
                `El centro de costo no existe`,
            ],
            contabilidadCentroCostoModificar: [
                `Editar Centro Costos`,
                `Regresar`,
                `Seleccionar centro de costos:`,
                `Buscar por: código o descripción`,
            ],
            contabilidadCentroCostoEliminar: [
                `¿Está seguro de eliminar este Centro de Costos?`,
                `Regresar`,
                `Seleccionar centro de costos:`,
                `Buscar por: código o descripción`,
                `Se eliminó correctamente el centro de costo!`,
                `No es posible eliminar el centro de costo, por que ya se encuentra usado en una reserva!`,
                `Eliminar Centro de Costos`
            ],
            contabilidadCentroCostoReporte: [
                `Reporte Centro Costos`,
                `Crear Centro Costos`,
                `Importar centro costos`,
            ],
            contabilidadCentroCostoImporte: [
                `[Centro de costos]`,
                `Ingrese la información requerida en el archivo y guarde los cambios para su posterior cargue`,
                `Haga clic en "Continuar" para pasar a la fase de selección de archivo, seleccione el archivo y de en 
                "Continuar" para empezar a guardar. Cuando el proceso termine se mostrará un log del 
                resultado de la operación la cual puede imprimir dando clic en "Imprimir"`,
            ],
            //


            // Contabilidad - Destino -(Crear-Modificar-Eliminar-Reporte)
            contabilidadDestinoCrear: [
                `Crear Destino`,
                `Código Destino`,
                `Nombre destino`,
                `Crear`,
                `Se creó el registro`,
                `No se puede crear el destino porque el código o nombre ya está registrado`,
            ],
            contabilidadDestinoModificar: [
                `Editar Destino`,
                `Regresar`,
                `Seleccionar Destino:`,
                `Buscar por: código o destino`,
                // `Buscar por: Codigo o nombre del destino`,
                `Se modificó el registro`,
                `No se pudo modificar el destino porque el código o nombre ya está registrado`,
                `No se pudo modificar el destino porque el destino no existe`,
                `¿Está segudo de editar este registro?`
            ],
            contabilidadDestinoEliminar: [
                `Eliminar destino`,
                `Regresar`,
                `Seleccionar Destino:`,
                `Buscar por: código o destino`,
            ],
            contabilidadDestinoCEliminar: [
                `¿Está seguro de eliminar este Destino?`,
                `Regresar`,
                `Seleccionar centro de costos:`,
                `Buscar por: código o descripción`,
                `Se eliminó correctamente el Destino!`,
                `No es posible eliminar el destino, por que ya se encuentra asignado en una reserva!`,
                `Eliminar Destino`
            ],
            contabilidadDestinoReporte: [
                `Reporte destino`,
                `Crear Destino`,
            ],
            contabilidadDestinoImporte: [
                `Carga masiva de destino`,
                `Crear Destino`,
                `Destino`,
            ],


            // Contabilidad - Sucursal -(Crear-Modificar-Eliminar-Reporte)
            contabilidadSucursalCrear: [
                `Línea de negocio`,
                `Sector`,
                `Sucursales`,
                `Crear línea de negocio`,
                `Modificacion línea de negocio`,
                `Eliminar línea de negocio`,
                `Reporte de líneas de negocio`,
                `Importar línea de negocio`,
                `Crear sector`,
                `Modificar sector`,
                `Eliminar sector`,
                `Reporte sector`,
                `Importar sector`,
                `Crear sucursal`,
                `Modificar sucursal`,
                `Eliminar sucursal`,
                `Reporte sucursal`,
                `Importe sucursal`,
                `Obtener id del sector`,
            ],

            createLineaNegocio: [
                `Código línea de negocio`,
                `Nombre línea de negocio`,
                `Crear línea de negocio`,
                `No se puede crear la línea de negocio porque el código o nombre ya se está utilizando`,
                `Se registró la línea de negocio!`,
            ],
            lineaNegocioEliminar: [
                `¿Está seguro de eliminar esta línea de negocio?`,
                `Regresar`,
                `Seleccionar línea de negocio:`,
                `Se eliminó correctamente la línea de negocio!`,
                `No es posible eliminar la línea de negocio, por que ya se encuentra utilizada en un sector!`,
                `Eliminar línea de negocio`,
                `Tenemos un problema al realizar esta acción.`,
            ],
            modificarLineaNegocio: [
                `Editar línea de negocio`,
                `Seleccione línea de negocio`,
                `Buscar por: código o nombre de la línea de negocio`,
                `Editar línea de negocio`,
                `No se puede editar la línea de negocio porque el código o nombre ya se está utilizando`,
                `Se modificó la línea de negocio!`,
                `La línea de negocio no existe`,
            ],

            crearSector: [
                `Seleccione línea de negocio`,
                `Código sector`,
                `Nombre sector`,
                `Se creó el sector`,
                `No se puede crear el sector, el código o nombre ya está registrado para la línea de negocio`,
            ],
            sectorEliminar: [
                `¿Está seguro de eliminar este sector?`,
                `Regresar`,
                `Seleccionar sector:`,
                `Se eliminó correctamente el sector!`,
                `No es posible eliminar el sector, por que ya se encuentra utilizado en una sucursal!`,
                `Eliminar sector`,
                `Tenemos un problema al realizar esta acción.`,
            ],
            modificarSector: [
                `Seleccione sector`,
                `Buscar por: código línea de negocio, nombre línea negocio, código sector o nombre sector`,
                `Se actualizó el sector`,
                `No se puede modificar el sector porque el código o nombre ya está registrado`,
                `No se puede modificar el sector porque no existe`,
            ],

            contabilidadDestinoCargueMasivo: [
                `Cargue Masivo Sucursal`,
                `Regresar`,
                `Seleccionar Destino:`,
                `Buscar por: código o destino`,
            ],

            contabilidadSucursalReporte: [
                `Reporte Sucursal`,
                `Crear Sucursal`,
            ],

            contabilidadProveedorCrear: [
                `Crear proveedor`,
                `Número documento`,
                `Razón social o nombres y apellidos completos`,
                `Selecciona en orden los datos de ubicacion para poder obtener el código postal`,
                `Número celular`,
                `Número Teléfono`,
                `Extensión`,
                `Email 1`,
                `Email 2`,
                `Sitio web`,
                `Número de teléfono o celular`,
                `No se puede adicionar el proveedor porque la razón social o el número de documento ya está registrado`,
                `Se registró el proveedor`,

            ],
            contabilidadProveedorModificar: [
                `Modificar proveedor`,
                `Seleccione el proveedor`,
                `Busque por: Nit o Razón social`,
                `Razón social`,
                `No se puede modificar el proveedor porque la razón social o el número de documento ya está registrado`,
                `Se modificó el proveedor`,
                {
                    form_previsualizar_contabilidad_proveedor: [
                        `Información básica`
                        , `Proveedor`
                        , `Actividad económica`
                        , `Persona de contacto`
                        , `Documento`
                        , `E-Mail`
                        , `Dirección`
                        , `Teléfono`
                        , `Editar`
                        , `Eliminar`
                        , `Cancelar`
                        , `Habilitar`
                        , `Inhabilitar`
                        , `Habilitado`
                        , `Inhabilitado`
                        , `habilitar`
                        , `inhabilitar`
                        , `Guardar`
                        , `Cancelar`
                        , `Estado`
                    ],
                    confimacionEditar: [
                        `Confirmación`
                        , `¿Está seguro de editar este registro?`
                        , `Sí`
                        , `No`
                        , `Buen trabajo`
                        , `El registro se edito satisfactoriamente`
                        , `!Error¡`
                        , `No se logro editar el registro debido a problemas con el servidor`
                    ],
                    confimacionIn_Habilitar: [
                        `Confirmación`
                        , `¿Está seguro que desea `
                        , `Sí`
                        , `No`
                        , `Buen trabajo`
                        , `El registro se edito satisfactoriamente`
                        , `!Error¡`
                        , `La accion seleccionada no se logró realizar debido a problemas con el servidor`
                        , ` este registro?`
                    ],
                    confimacionEliminar: [
                        `Confirmación`
                        , `¿Está seguro de editar este registro?`
                        , `Sí`
                        , `No`
                        , `Buen trabajo`
                        , `El registro se elimino satisfactoriamente`
                        , `!Error¡`
                        , `No se logro eliminar el registro debido a problemas con el servidor`
                    ]

                }
            ],
            contabilidadProveedorEliminar: [
                `Eliminar proveedor`,
            ],
            contabilidadProveedorLista: [
                `Reporte proveedor`,
                `Actividad comercial`,
            ],
            contabilidadProveedorImporte: [
                `Carga masiva de proveedores`,
                `Clic en el siguiente enlace para generar y descargar el archivo`,
                `[Generar y descargar]`,
                `Seleccione: pais, departamento y municipio para poder obtener el código postal`,
                `Id a registrar`,
            ],

            //Sucursal
            createSucursal: [
                `Código Sucursal`,
                `Nombre Sucursal`,
                `Se creó la sucursal`,
                `No se pudo crear la sucursal porque el nombre o código ya están registrados`,
                `No se pudo crear la sucursal porque el sector no existe`,
            ],
            modificarSucursal: [
                `Seleccione la sucursal`,
                `Busque por: código y nombre de línea negocio, código y nombre de sector, código y nombre de sucursal`,
                `Se guardaron los cambios`,
                `No se pudo actualizar porque el código o nombre ya está registrado`,
                `No se pudo actualizar porque la sucursal no existe`,
                `¿Está seguro(a) de eliminar la sucursal?`,
                `Se eliminó la información correctamente`,
                `La sucursal no se puede eliminar porque se encuentra en uso`,
            ],
            importarSucursal: [
                `Seleccione la línea de negocio y después el sector para poder obtener el id del sector`,
            ],
            //

            importarMateriales: [
                `Catalogacion`,
                `Externos`,
                `clases Almacén aquí`,
                `Abrir el archivo "ListaMateriales_empresa.xlsx" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones: `
                , {
                    alertSuccess: [
                        `¡Buen trabajo!`,
                        `El registro se realizo satisfactoriamente`,
                        `Registros realizados satisfactoriamente`
                    ],
                    alertError: [
                        `¡Error!`,
                        'No se logro realizar el registro, a continuación se muestran los errores',
                        `Listado de errores`
                    ],
                    alertErrorNoAlmacen: [
                        `¡Error!`,
                        `La empresa activa no cuenta con ningun almacen relacionado`
                    ],
                    alertErrorGeneral: [
                        `Error al realizar la acción seleccionada`
                        , `Error`
                        , `La información ingresada es incorrecta, por favor verifiquela e intente nuevamente`
                    ]
                },
                `Asignación de material externo a almacen, cargue masivo`
            ],

            materialAlmacenIndividual: [
                `Asignar material a almacen`,
                `Planta`,
                `Centro`,
                `Almacen`,
                `Catálogo`,
                `-- Seleccionar --`,
                `Adicionar`,
                `Guardar`,
                `No se encontro información`,
                `Adicionar material`,
                {
                    tabla: [
                        `Id Almacen`
                        , `Almacen`
                        , `Cod. SLI`
                        , `Material`
                        , `Descripción`
                        , `Acción`
                        , 'Clases seleccionadas'
                        , `Id Material`
                    ],
                    tablaCatalogo: [
                        `Código SLI`
                        , `Clase`
                        , `Fabricante`
                        , `Referencias`
                        , `Descripción Larga`
                        , `Descripción Corta`
                        , `Acción`
                        , `Consulta de catálogo`
                    ],
                    alertItemRepetido: [
                        `¡Error!`
                        , `El material: `
                        , ` ya se encuentra asociado con el almacen: `
                        , `Ok`
                        , `Error al obtener el material`

                    ],
                    alertError: [
                        `¡Error!`,
                        `No ha seleccionado ningun valor para adicionar`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `¡Error!`,
                        `No ha seleccionado un almacen`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `¡Error!`,
                        `No se encontró clase con esa información`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmación`,
                        `¿Desea guardar el registro?`,
                        `Sí`,
                        `No`
                    ],
                    alertSucces: [
                        `Buen trabajo`,
                        `Se asigno el material a el almacen satisfactoriamente`
                    ],
                    alertErrorDb: [
                        `¡Error!`,
                        `La siguiente información ya se encuentra registrada: `
                        , `Almacén `
                        , `Material `
                        , `Por favor corrijalo e intente nuevamente`
                        , `Se genero un error del sistema, por favor tome un pantallazo y comuniquese con el administrador`
                        , `Registros en conflicto`
                    ]
                    , form: [
                        , `Rango incial de registros en los que desea consultar`
                        , `Rango final de registros en los que desea consultar`
                        , `El rango máximo en el que puede consultar es de 3000`
                        , `Debe llenar los campos de consulta para generar el archivo`
                        , `Búsqueda especifica`
                        , `Esta consulta permite obtener hasta 1000 materiales, contando a partir del múmero ingresado`
                        , `Especifico`
                    ]
                },
                `Cancelar`,
                `Buscar por: Código SLI, Clase, Abreviación y Fabricante`



            ],

            materialAlmacenIndividualEditar: [
                `Asignar clase a almacen`,
                {
                    formulario: [
                        `Cod. SLI`,
                        `Clase`,
                        `Almacen`,
                        `Centro`,
                        `Planta`,
                        `Seleccionar Clase`,
                        `Buscar por: Fabricante, Cod. SLI, Clase, Abreviación, Almacen, Cod. Almacen`,
                        `Guardar`,
                        `Cancelar`,
                        `Reporte clases importadas`
                        , `Registro desde donde inicia la consulta`
                        , `Cantidad de registros a consultar`
                        , `La cantidad máxima de materiales que puede obtener es de 1000`
                        , `Debe llenar los campos de consulta para generar el archivo`
                    ],
                    tabla: [
                        `Id Almacen`
                        , `Almacen`
                        , `Cod. SLI`
                        , `Material`
                        , `Descripción`
                        , `Acción`
                        , 'Clases seleccionadas'
                        , `Id Material`
                    ],
                    tablaCatalogo: [
                        `Cod. SLI`
                        , `Clase`
                        , `Descripción Larga`
                        , `Almacen`
                        , `Centro`
                        , `Planta`,
                        , `Descripción Corta`
                        , `Acción`
                        , `Consulta de catálogo`
                    ],
                    alertItemRepetido: [
                        `¡Error!`
                        , `El material: `
                        , ` ya se encuentra asociado con el almacen: `
                        , `Ok`
                        , `Error al obtener el material`
                    ],
                    alertError: [
                        `¡Error!`,
                        `Se ha generado un error al realizar esta acción`,
                        `Ok`
                        , `Se generó un error al obtener los materiales, intente nuevamente`
                        , `Los campos obligatorios para la búsqueda son almacen y rango inicial, por favor llenarlos`
                    ],
                    alertErrorNoAlmacen: [
                        `¡Error!`,
                        `No ha seleccionado un almacen`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `¡Error!`,
                        `No se encontró clase con esa información`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmación`,
                        `¿Desea habilitar/inhabilitar estos registros?`,
                        `Sí`,
                        `No`
                    ],
                    alertSucces: [
                        `Buen trabajo`,
                        `Se habilitaron/inhabilitaron los almacenes seleccionados satisfactoriamente`
                    ],
                    alertErrorDb: [
                        `¡Error!`,
                        `La siguiente relación ya se encuentra en la base de datos: `,
                        `; así que se cancelaron las acciones, por favor corrija e intente nuevamente`
                        , `Id almacén : `
                        , `Id material: `
                        , `Se genero un error del sistema, favor saque pantallazo y comuniquese con el administrador`
                    ]

                }
                , `Habilitar/Inhabilitar clase a un almacen`
                , `Relacionar materiales a almacen de forma masiva`
            ],

            materialExternoCrear: [
                `Cargue material externo`,
                {
                    formulario: [
                        `Código antiguo`
                        , `Descripción`
                        , `Referencias`
                        , `Obsertvación`
                        , `Guardar`
                        , `Cancelar`
                    ],
                    modalDuplicado: [
                        `Materiales duplicados`,
                        `Cerrar`
                    ],
                    alertConfirmacion: [
                        `Confirmación`,
                        `¿Desea importar este material a SLI?`,
                        `Sí`,
                        `No`
                    ],
                    alertSuccess: [
                        `¡Buen trabajo!`,
                        `¡Se ha realizado el registro satisfactoriamente!`,
                    ],
                    alertError: [
                        `¡Error!`,
                        `Se ha generado un error al realizar el registro`,
                    ],
                    alertErrorDuplicado: [
                        `¡Error!`,
                        `El código antiguo ingresado ya se encuentra registrado en el sistema`,
                        `Ver código duplicado`
                    ],
                    referenciaRepetida: [
                        `¡Error!`,
                        `Ésta referencia ya se encuentra agregada`,
                    ],
                }
            ],

            cargueMasivoMaterialExterno: [
                `Cargue masivo catálogo externo`,
                `1. Instructivo`,
                `2. Upload and validation`,
                `3. Log de cargue`,
                {
                    step1: [
                        `Descargue el archivo`
                        , `formatoCargueMasivoCatalogoExterno.xlsx`
                        , `Abrir el archivo "formatoCargueMasivoCatalogoExterno.xlsx" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y 
                        diligenciar los siguientes campos teniendo en cuenta las observaciones: `
                        , `a. Nombre del campo, Longitud: X, Tipo de variable: Y`
                        , `b. Nombre del campo, Longitud: X, Tipo de variable: Y`
                        , `c. Nombre del campo, Longitud: X, Tipo de variable: Y`
                        , `Guardar el archivo en su equipo`
                    ]
                    , step2: [
                        `Seleccionar archivo`
                        , `Click en el boton para adjuntar el archivo`
                        , `Código antiguo`
                        , `Descripción`
                        , `Referencias`
                        , `Observación`
                        , `Acción`
                    ]
                    , step3: [

                    ]
                    , opciones: [
                        `Atras`
                        , `Continuar`
                    ]
                    , logErrores: [
                        `Listado de errores`
                        , `Posición`
                        , `Código antiguo`
                        , `Descripción`
                        , `Referencias`
                        , `Observación`
                        , `Error`
                        , `Error, no se logro conectar con el servidor`
                        , `Error, registro duplicado`
                    ]
                    , alerts: [
                        `Error`
                        , `Se ha generado un error al obtener el archivo de cargue`
                    ]

                }
            ],

            asignar_externo_a_almacen: [
                `Asignar material a almacen`,
                `Planta`,
                `Centro`,
                `Almacen`,
                `Catálogo`,
                `-- Seleccionar --`,
                `Adicionar`,
                `Guardar`,
                `No se encontro información`,
                `Adicionar material`,
                {
                    tabla: [
                        `Id Almacen`
                        , `Almacen`
                        , `Cód. Antiguo`
                        , `Datos adicionales`
                        , `Referencias`
                        , `Acción`
                        , 'Clases asociadas'
                        , `Id Material`
                    ],
                    tablaCatalogo: [
                        `Cód. Antiguo`
                        , `Datos adicionales`
                        , `Referencias`
                        , `Observación`
                        , `Acción`
                        , `Consulta de catálogo`
                    ],
                    alertItemRepetido: [
                        `¡Error!`
                        , `Éste material ya se encuentra asociado con el almacen: `
                        , `Ok`

                    ],
                    alertError: [
                        `¡Error!`,
                        `No ha seleccionado ningun valor para adicionar`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `¡Error!`,
                        `No ha seleccionado un almacen`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `¡Error!`,
                        `No se encontró clase con esa información`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmación`,
                        `¿Desea guardar el registro?`,
                        `Sí`,
                        `No`
                    ],
                    alertSucces: [
                        `Buen trabajo`,
                        `Se asigno el material a el almacen satisfactoriamente`
                        , `Material adicionado a `
                    ],
                    alertErrorDb: [
                        `¡Error!`,
                        `Se han presentado problemas al realizar los siguientes registros: `,
                        `; así que se cancelaron las acciones, por favor corrija he intente nuevamente`
                        , `Id almacen : `
                        , `Id material: `
                    ]

                },
                `Cancelar`,
                `Buscar por: Código SLI, Clase, Abreviación y Fabricante`



            ],

            editar_cargue_externo: [
                `Habilitar/Inhabilitar clase externa a almacen`,
                {
                    formulario: [
                        `Cod. ERP`,
                        `Adicional`,
                        `Referencias`,
                        `Almacen`,
                        `Centro`,
                        `Seleccionar Clase`,
                        `Buscar por: Cod. Antiguo, Descripción, Referencias,  Cod. Almacen, Almacen, Cod. Centro, Centro, Cod. Planta, Planta`,
                        `Guardar`,
                        `Cancelar`,
                        `Reporte clases importadas`
                        , `Planta`
                    ],
                    tabla: [
                        `Id Almacen`
                        , `Almacen`
                        , `Cod. SLI`
                        , `Material`
                        , `Descripción`
                        , `Acción`
                        , 'Clases seleccionadas'
                        , `Id Material`

                    ],
                    tablaCatalogo: [
                        `Cod. ERP`
                        , `Descripción`
                        , `Referencias`
                        , `Observación`
                        , `Cod. Almacen`
                        , `Almacen`
                        , `Centro`
                        , `Planta`
                        , `Acción`
                        , `Almacenes asociados a clases`
                        , `Cod. SLI`
                    ],
                    alertItemRepetido: [
                        `¡Error!`
                        , `El material: `
                        , ` ya se encuentra asociado con el almacen: `
                        , `Ok`

                    ],
                    alertError: [
                        `¡Error!`,
                        `Se ha generado un error al realizar esta acción`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `¡Error!`,
                        `No ha seleccionado un almacen`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `¡Error!`,
                        `No se encontró clase con esa información`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmación`,
                        `¿Desea habilitar/inhabilitar estos registros?`,
                        `Sí`,
                        `No`
                    ],
                    alertSucces: [
                        `Buen trabajo`,
                        `Se habilitaron/inhabilitaron los almacenes seleccionados satisfactoriamente`
                    ],
                    alertErrorDb: [
                        `¡Error!`,
                        `Se han presentado problemas al realizar los siguientes registros: `,
                        `; así que se cancelaron las acciones, por favor corrija he intente nuevamente`
                        , `Id almacen : `
                        , `Id material: `
                    ]

                },
                `Reporte de clases externas asignadas a almacenes`
            ],

            administracionMateriales: [
                `Administracion de clases`,
            ],
            reserva: [
                `Reserva de producto`,
                `Crear Reserva`,
                `Aprobacion Reserva`,
                `Atención Reserva`,
                `Reporte Reserva`,
                `Información general`,
                `Agregar producto`,
                `Informe`,
                `Editar Reserva`,
                `Pestaña`,
                `Se asignara un número cuando se guarde el registro`,
                `Almacén de origen`,
                `Almacén de destino`,
            ],

            crearReserva: [
                `Crear Reserva`,
                `Reportes`,
                `No. Reserva:`,
                `Planta`,
                `Centro`,
                `Almacén`,
                `Traslado a almacén`,
                `Planta Destino:`,
                `Centro Destino:`,
                `Almacén Destino:`,
                `Consultar clases`,
                `Buscar`,
                `Observacion:`,
                `Crear`,
                `Enviar`,
                `Eliminar`,
                `Log de Reserva:`,
                `Planta Destino`,
                `Centro Destino`,
                `Almacén Destino`,
                `Sucursal`,
                `Destino`,
                `Cant. reserva`,
                `Cant. nueva`,
                `Cant. usado`,
                `Cant. reparado`,
                `Unidad de medida base`,
                `Agregar productos`,
                `Lista de productos reservados`,
                `(Vacío)`,
                `¿Está seguro de crear la reserva? Podrá modificarla después`,
                `¿Está de enviar la reserva? No se podrá modificar después`,
                `¿Está seguro de limpiar el formulario?`,
                `Crear`,
                `Enviar`,
                `Limpiar formulario`,
                `No hay productos para agregar`,
                `Debe seleccionar el almacén de destino`,
                `¡Se registró la información correctamente, el código de la reserva es: `,
                `Error al guardar la información`,
                `Actualizar`
                , `Debe llenar los campos marcados con asteriscos y seleccionar almenos un material para poder crear la reserva`
                , `Info. inventario`
                , `Desc. antigua`
            ],
            aprovacionReserva: [
                `Aprobacion de Reservas`,
                `Reservas:`,
                `Buscar`,
                `Observacion:`,
                `Aprobar`,
                `Rechazar`,
                `Cancelar`,
                `Usuario creador`,
                `Fecha creación desde`,
                `Fecha creación hasta`,
                `Almacén de origen`,
                `Almacén de destino`,
                `Centro costo`,
                `Sucursal`,
                `Destino`,
                `Usuario creador`,
                `Fecha de creación`,
                `Acción`,
                `Productos de la reserva`,
                `Código SLI`,
                `Descripción corta`,
                `Unidad de medida`,
                `Cantidad reservada`,
                `Haz clic para ver información detallada de la reserva`,
                `¿Está seguro de recharzar la reserva?`,
                `¿Está seguro de aprobar la reserva?`,
                `Ocurrio un error al actualizar la información`,
                `¡Se guardaron los cambios correctamente!`,
                `Movimientos incompletos de la reserva`,
                `Seleccione el movimiento con el que desea continuar`,
                `Seleccione`,
                `Nuevo`,
                `Continuar`,
                `Planta origen`,
                `Centro origen`,
                `Planta destino`,
                `Centro destino`,
                `Reserva`,
                `Dato adicional`,
            ],
            modificacionReserva: [
                `Editar Reserva`,
                `Estado:`,
                `Estado`,
                `Reserva:`,
                `Reserva`,
                `Buscar`,
                `¿Está seguro de guardar los cambios de la reserva? Podrá modificarla después`,
                `Busque por el código de la reserva`,
                `No hay registros con los parámetros indicados`,
                `Código reserva`,
                `Número de prodcutos`,
                `Cant. total de productos`,
                `Atención`,
                `Esta solicitud ha sido rechazada`,
                `Usted ya no tiene asignado el almacén de origen, debe asignar otro a la reserva`,
                `Usted ya no tienen asignado el almacén de destino, debe asignar otro a la reserva`,
                `Usted ya no tienen asignado el centro de costo, sucursal o el destino, debe re asignar los que estén vacíos`,
            ],
            reporteReserva: [
                `Reporte de Reservas`,
                `Volver`,
                `REPORTE DE RESERVAS`,
                `Cant. entregados`,
                `Cant. faltantes`,
            ],
            movimientos: [
                `Movimientos`,
                `Entrada`,
                `Traslado`,
                `Salida producto`,
                `Baja`,
                `Devolucion`,
                `Crear entrada`
                , `Modificar entrada`
                , `Reporte de entrada`
                , `Crear Salida`
                , `Modificar Salida`
                , `Reporte de Salida` // 11

                , `Crear Asignación` // 12
                , `Modificar Asignación` // 13
                , `Reporte de Asignación` // 14

                , `Crear Baja` // 15
                , `Modificar Baja` // 16 
                , `Reporte de Baja` //17

                , `Crear Traslado` // 18
                , `Modificar Traslado` // 19
                , `Reporte de Traslado` // 20
                , `Salida` // 21
                , `Despacho` // 22
                , `Asignación` // 23

                , "Reportes generales" // 24
            ]
            , movimientoTrasladoTuberia: {
                alertas: [
                    `Se ha actualizado el traslado satisfactoriamente con el consecutivo 104-`
                    , `No existen movimientos para modificar`
                ]
            }
            , movimientosBajaHerramienta: [
                'Entrada'
                , `Información general sobre la entrada`
                , `Consulta de productos`
                , `Planta`
                , `Centro`
                , `Almacen`
                , `Proveedor`
                , `Remisión`
                , `Orden de compra`
                , `Factura`
                , `Centro de costo`
                , `Sucursal`
                , `Destino`
                , `Seleccionar`
                , {
                    tablaDeConsulta: [
                        `SLI`
                        , `Cod. ERP`
                        , `Producto`
                        , `Descripción`
                        , `UMB`
                        , `Referencias`
                        , `Cantidad y estado físico`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                        , `Ver más...`
                        , `Ver menos`
                        , `Adicionar`
                        , `Eliminar`
                        , `Cod. Antiguo`
                        , `Descripción antigua`
                        , `Acción`
                        , `Resultado de la consulta`
                        , `Condición`
                        , `Materiales Seleccionados`
                    ]
                    , alertaBusqueda: [
                        `¡Atención!`
                        , `No se encontraron registros con los parámetros indicados`
                        , `Sección de consultas`
                        , `Buscar por: Planta, centro, almacen, proveedor, remisión, orden de compra, factura, centro costo, sucursal, destino o material`
                        , `No se encontraron elementos`
                        , `Buscar por: Planta, centro, almacen, centro costo, sucursal, destino`
                        , `Lo sentimos, no cuentas con ninguna almacen asociado en este tipo de administración`
                        , `por favor comunicate con el administrador del sistema`
                    ]
                    , alertaAdicionado: [
                        `¡Error!`
                        , `Este porducto ya se encuentra adicionado`
                        , `0 Existencias`
                    ]
                    , logErrores: [
                        `Registro de errores`
                        , `SLI`
                        , `Material`
                        , `Error`
                    ]
                    , alertas: [
                        `El material que desea mover no se encuentra asignado al almacen de destino o se encuentra deshabilitado para ese almacen`
                        , `Se ha creado la entrada satisfactoriamente con el consecutivo 101-`
                        , `¡Buen trabajo!`
                        , `Se ha modificado la entrada satisfactoriamente con el consecutivo 101-`
                        , `No se logro obtener la entrada seleccionada`
                        , `Advertencia la información no se logro guardar, ya que el serial o placa ya existen.`
                        , `Tabla de errores`
                        , `SLI`
                        , `Código antiguo`
                        , `Serial`
                        , `Placa`
                        , `Error`
                        , `Placa duplicada`
                        , `Serial duplicado`
                        , `Material no asociado al almacen`
                    ]
                    , reporte: [
                        `Reporte entradas`
                        , `Crear entrada`
                        , `Consecutivo`
                        , `SLI`
                        , `Estado`
                        , `Almacen`
                        , `Material`
                        , `Descripción`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                        , `E. Físico`
                        , `Información`
                    ]
                    , alertErrorReporte: [
                        `Error`
                        , `El resultado de la consulta no es el adecuado`
                        , `No existen materiales`
                    ]
                    , estadosMovimiento: [
                        `Creado`
                        , `Finalizado`
                        , `Cancelado`
                        , `Error`
                    ]
                }
                , `Guardar`
                , `Enviar`
                , `Cancelar`
                , `Materiales seleccionados`
                , `Cancelar movimiento`

            ]
            , movimientosEntradaProducto: [
                'Entrada'
                , `Información general sobre la entrada`
                , `Consulta de productos`
                , `Planta`
                , `Centro`
                , `Almacen`
                , `Proveedor`
                , `Remisión`
                , `Orden de compra`
                , `Factura`
                , `Centro de costo`
                , `Sucursal`
                , `Destino`
                , `Seleccionar`
                , {
                    tablaDeConsulta: [
                        `SLI`
                        , `Cod. ERP`
                        , `Producto`
                        , `Descripción`
                        , `UMB`
                        , `Referencias`
                        , `Cantidad y estado físico`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                        , `Ver más...`
                        , `Ver menos`
                        , `Adicionar`
                        , `Eliminar`
                        , `Cod. Antiguo`
                        , `Descripción antigua`
                    ]
                    , alertaBusqueda: [
                        `¡Atención!`
                        , `No se encontraron registros con los parámetros indicados`
                        , `Sección de consultas`
                        , `Buscar por: Planta, centro, almacen, proveedor, remisión, orden de compra, factura, centro costo, sucursal, destino o material`
                        , `No se encontraron elementos`
                        , `Buscar por: Planta, centro, almacen, centro costo, sucursal, destino`
                        , `Lo sentimos, no cuentas con ninguna almacen asociado en este tipo de administración`
                        , `por favor comunicate con el administrador del sistema`
                        , `Buscar registro`
                        , `No se encontraron movimientos pendientes` //9
                    ]
                    , alertaAdicionado: [
                        `¡Error!`
                        , `Este porducto ya se encuentra adicionado`
                    ]
                    , logErrores: [
                        `Registro de errores`
                        , `SLI`
                        , `Material`
                        , `Error`
                    ]
                    , alertas: [
                        `El material que desea mover no se encuentra asignado al almacen de destino o se encuentra deshabilitado para ese almacen`
                        , `Se ha creado la entrada satisfactoriamente con el consecutivo 101-`
                        , `¡Buen trabajo!`
                        , `Se ha modificado la entrada satisfactoriamente con el consecutivo 101-`
                        , `No se logro obtener la entrada seleccionada`
                        , `Advertencia la información no se logro guardar, ya que el serial o placa ya existen.`
                        , `Tabla de errores`
                        , `SLI`
                        , `Código antiguo`
                        , `Serial`
                        , `Placa`
                        , `Error`
                        , `Placa duplicada`
                        , `Serial duplicado`
                        , `Material no asociado al almacen`
                        , "Error de validación, la información enviada es incorrecta." // 15
                        , "Error general, por favor comunicarse con el administrador del sistema." // 16 
                        , "No hay disponibilidad, para alguno de los materiales que desea mover." // 17 
                        , "Alguno de los materiales que desea ingresar, no esta asociado al almacen seleccionado." // 18
                        , "El serial o placa de alguno de los materiales que desea ingresar, ya se encuentra en sistema." // 19
                        , "Se genero un error al guardar la ubicación, por favor intentelo de nuevo, si el error persiste, comuniquese con el administrador del sistema" //20
                        , "Restaurar material" //21
                        , "Error en el estado del material que desea actualizar, por favor comuniquese con el administrador y remitale el número de consecutivo" //22
                    ]
                    , reporte: [
                        `Reporte entradas`
                        , `Crear entrada`
                        , `Consecutivo`
                        , `SLI`
                        , `Estado`
                        , `Almacen`
                        , `Material`
                        , `Descripción`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                        , `E. Físico`
                        , `Información`
                    ]
                    , alertErrorReporte: [
                        `Error`
                        , `El resultado de la consulta no es el adecuado`
                        , `No existen materiales`
                    ]
                    , estadosMovimiento: [
                        `Creado`
                        , `Finalizado`
                        , `Cancelado`
                        , `Error`
                    ]
                }
                , `Guardar`
                , `Enviar`
                , `Cancelar`
                , `Materiales seleccionados`
                , `Cancelar movimiento`

            ],
            movimientosTrasladoProducto: [
                'Traslado'
                , `Información general sobre el traslado`
                , `Consulta de productos`
                , `Planta`
                , `Centro`
                , `Almacen`
                , `Proveedor`
                , `Remisión`
                , `Orden de compra`
                , `Factura`
                , `Centro de costo`
                , `Sucursal`
                , `Destino`
                , `Seleccionar`
                , {
                    tablaDeConsulta: [
                        `SLI`
                        , `Producto`
                        , `Descripción`
                        , `Descripción`
                        , `UMB`
                        , `Referencias`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                        , `Ver más...`
                        , `Ver menos`
                        , `Adicionar`
                        , `Eliminar`
                        , `Cant. Solicitada`
                        , `No. Traslado`
                        , `Ubicación`
                        , `Estado`
                    ]
                    , alertaBusqueda: [
                        `¡Atención!`
                        , `No se encontraron registros con los parámetros indicados`
                        , `Sección de consultas`
                        , `Buscar por: Planta, centro, almacen, proveedor, remisión, orden de compra, factura, centro costo, sucursal, destino o material`
                        , `No se encontraron elementos`
                        , `Destino del traslado`
                        , `Origen del traslado`
                        , `Información general`
                        , `¡Debe seleccionar almacenes diferentes!`
                        , `Este campo se habilitara tan pronto seleccione el almacen de origen`
                    ]
                    , alertaAdicionado: [
                        `¡Error!`
                        , `Éste producto ya encuentra agregado con el mismo proveedor, si desea ingresarlo de nuevo cambie el proveedor`
                        , `Este material no cuenta con inventario`
                    ]
                    , confirmacion: [
                        `Confirmación`
                        , `¿Esta seguro que desea cancelar el traslado 104-`
                        , `?`
                        , `Sí`
                        , `No`
                    ]
                    , logErrores: [
                        `Registro de errores`
                    ]
                    , modalMaterial: [
                        `Adiciona material al traslado`
                        , `Proveedor`
                        , `Estado visual`
                        , `Disponibilidad`
                        , `Precio`
                        , `Cantidad`
                        , `Material`
                        , `Codigo SLI`
                        , `Descripción`
                        , ` Sin materiales`
                    ]
                    , estadoVisual: [
                        `Nuevo`
                        , `Usado`
                        , `Reparado`
                        , `Por reparar`
                        , `Dañado`
                    ]
                    , alertas: [
                        `El movimiendo no se logro realizar debido a fallas con los registros`
                        , `Se ha realizado el traslado satisfactoriamente con el consecutivo 104-`
                        , `¡Buen trabajo!`
                        , `Se ha modificado el traslado satisfactoriamente con el consecutivo 104-`
                        , `No se logro obtener el traslado seleccionada`
                    ]
                    , reporte: [
                        `Reporte Traslado`
                        , `Crear traslado`
                        , `Consecutivo`
                        , `SLI`
                        , `Estado`
                        , `Almacen`
                        , `Material`
                        , `Descripción`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , `Total`
                    ]
                    , estadosMovimiento: [
                        `Creado`
                        , `Finalizado`
                        , `Cancelado`
                        , `Error`
                    ]
                    , alertaDivisas: [
                        `Tasa de conversión`
                        , `Recuerde que para habilitar la búsqueda de materiales debe seleccionar un almacen de origen`
                        , `TRM`
                        , `Divisa`
                        , `Valor`
                        , `Divisa de retorno`
                    ]
                    , divisas: [
                        `Peso colombiano`
                        , `Dollar`
                        , `Euro`
                        , `Dollar Canadience`
                    ],

                }
                , `Guardar`
                , `Enviar`
                , `Cancelar`
                , `Materiales seleccionados`
                , `Cancelar movimiento`

            ],

            movimientosEntradaTuberia: [
                {
                    form: [
                        `Ubicación`
                        , 'Observación'
                        , `No. tubo`
                        , `MT`
                        , `FT`
                        , `IN`
                        , `Reporte Maestro Materiales`
                    ]
                    , modal: [
                        `Desc. Larga`
                        , `Código SLI`
                        , `Desc. Corta`
                        , `Serial`
                        , `Placa`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , `Descuento`
                        , `Estado material`
                        , `Total`
                    ]
                    , alertas: [
                        `No es posible guardar, por que no se ha diligenciado información`
                        , `Atención`
                        , `No se ha diligenciado el campo Almacén destino` // 2
                        , `No se ha diligenciado el campo Centro de costo` // 3
                        , `No se ha diligenciado el campo Sucursal` // 4
                        , `No se ha diligenciado el campo Destino` // 5
                        , `No se ha registrado ningún valor de despacho` // 6
                        , `No se ha diligenciado el campo observación` // 7
                        , `Se ha generado el despacho satisfactoriamente con el consecutivo 101-` // 8
                        , `¿Esta seguro de realizar esta acción?` // 9
                        , `Se elimináron los registros diligenciados` // 10
                        , `Se guardo parcialmente la información` // 11
                        , `Se ha generado el traslado satisfactoriamente con el consecutivo 101-` // 12
                        , `No se ha diligenciado el campo Almacén Origen` // 13
                        , `No se ha registrado ningún valor` // 14
                        , `No se encontraron registros con los parámetros indicados` // 15                        
                        , `Se ha generado la baja satisfactoriamente con el consecutivo 101-` // 16
                        , `Se ha generado la devolución satisfactoriamente con el consecutivo 101-` // 17
                        , `Se ha actualizado la baja satisfactoriamente con el consecutivo 101-` // 18
                        , `Se ha actualizado el despacho satisfactoriamente con el consecutivo 101-` // 19
                        , `Se ha generado la asignación satisfactoriamente con el consecutivo 101-` // 20
                        , `No se ha diligenciado el campo custodio` // 21
                        , `En uno o mas registros, no se ha diligenciado el campo serial.` // 22
                        , `En uno o mas registros, no se ha diligenciado el campo placa.` // 23
                        , `No se ha diligenciado el campo cantidad` // 24
                        , `No se ha diligenciado el campo precio` // 25
                        , `No se ha diligenciado el campo impuesto` // 26
                        , `No se ha diligenciado el campo Proveedor` // 27
                        , `Se ha agregado el producto` // 28
                        , `No ha diligenciado ningún producto para poder agregarlo.` // 29
                        , `Se ha actualizado la asignación satisfactoriamente con el consecutivo 101-` // 30
                        , `Se ha actualizado la entrada satisfactoriamente con el consecutivo 101-` // 31
                        , `Operación correcta!` // 32
                        , `Se ha actualizado la salida satisfactoriamente con el consecutivo 101-` // 33
                        , `Se ha actualizado el traslado satisfactoriamente con el consecutivo 101-` // 34
                        , `No se ha diligenciado el campo Factura` // 35
                        , `No se ha diligenciado el campo Nota de Credito` // 36
                        , `` // 37
                        , `` // 38
                        , `EL número de tubo que intenta ingresar ya se encuentra en sistema` // 39
                        , `Movimiento cancelado satisfactoriamente` // 40
                        , `Error al intentar cancelar el movimiento` //41
                    ]
                }
            ]
            , movimientosEntrada: [
                `Remisión:`,
                `Remisión`,
                `Orden de Compra:`,
                `Orden de Compra`,
                `Factura:`,
                `Factura`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Consultar clases:`,
                `Buscar`,
                `Observación:`,
                `Proveedor:`,
                `Proveedor`,
                `N°. Movimiento:`,
                `N°. Movimiento`,
            ],
            movimientosTraslado: [
                `Origen:`,
                `Planta:`,
                `Planta`,
                `Centro:`,
                `Centro`,
                `Almacén:`,
                `Almacén`,
                `Reserva:`,
                `Reserva`,
                `Destino:`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Consultar clases:`,
                `Buscar`,
                `Observación:`,
                `Trasladar`,
                `Remision:`,
                `Remision`,
            ],
            movimientosSalida: [
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Destino:`,
                `Reserva:`,
                `Reserva`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Remision:`,
                `Remision`,
                `N° Factura:`,
                `N° Factura`,
                `Consultar clases:`,
                `Buscar`,
                `Observación:`,
                `Observación`,
                `Crear`,
                `Salida`,
                `Eliminar`,
                `Consulta los productos del almacén seleccionado`,
                `Solicitud`,
                `¿Está seguro de eliminar el producto de la lista?`,
                `No se pudo obtener el texto.`,
                `Debe seleccionar un almacén`,
                `Debe seleccionar un centro de costo`,
                `Debe seleccionar una sucursal`,
                `Debe seleccionar un destino`,
                `Debe agregar almenos un producto`,
                `Modificar salida`,
                `Seleccione el consecutivo del movimiento`,
                `Consecutivo`,
                `¿Está seguro de cancelar el movimiento?`,
                `¡Se canceló el movimiento correctamente!`,
                `Error al cancelar el movimiento`,
                `Cancelar reserva`,
                `Limpiar formulario`,
                `Debe seleccionar un consecutivo`,
                `Crear salida`,
            ],

            movimientosDevolucion: [
                `Origen:`,
                `Planta:`,
                `Planta`,
                `Centro:`,
                `Centro`,
                `Almacén:`,
                `Almacén`,
                `Tipo de Devolución:`,
                `Tipo de Devolución`,
                `Movimiento:`,
                `Movimiento`,
                `N°. Movimiento:`,
                `N°. Movimiento`,
                `Consultar clases:`,
                `Buscar`,
                `Observación:`,
                `Devolver`,
            ],
            movimientosBaja: [
                `Origen:`,
                `Planta:`,
                `Planta`,
                `Centro:`,
                `Centro`,
                `Almacén:`,
                `Almacén`,
                `Aprobación de Baja`,
                `Reserva:`,
                `Reserva`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Consultar clases:`,
                `Buscar`,
                `Observacion:`,
                `Observacion`,
                `Baja`,
                `N°. Entrada:`,
                `N°. Entrada`,
            ],
            planificacion: [
                `Planificacion`,
            ],
            tomaInventarios: [
                `Toma de inventarios`
                , `Internos`
                , `Externos`
                , `Toma de inventario`
                , `Conteo`
                , `Con archivo`
                , `Sin archivo`
                , `Cargar productos`
                , `Conteo`
                , `Crear producto`
                , `Conteo`
                , `Reporte conteo`
                , `Atención conciliación`
                , `Reporte conciliación externa`
                , `Atención conciliación`
                , `Reajuste conciliación`
                , `Reporte toma de inventario`

            ],

            configMaterial: [
                `Config. Clase`,
            ],

            reportes: [
                `Reportes`,
            ],

            administracionActHerr: [
                `Administracion de Activos y Herramientas`,
            ],
            reservaActHerr: [
                `Reserva de herramientas/activos`,
                `Crear Reserva`,
                `Aprobacion Reserva`,
                `Editar Reserva`,
                `Atención Reserva`,
                `Reporte Reserva`,
            ],

            reservaCrearReservaActHerr: [
                `Crear Reserva`,
                `Reportes`,
                `No. Reserva:`,
                `Estado:`,
                `Planta*`,
                `Centro*`,
                `Almacén*`,
                `Asignación`,
                `Traslado`,
                `Custodio*`,
                `Custodio`,
                `Planta Destino:`,
                `Centro Destino:`,
                `Almacén Destino:`,
                `Centro de Costos*`,
                `Sucursal*`,
                `Destino*`,
                `Consultar clases:`,
                `Buscar`,
                `Observacion:`,
                `Observacion`,
                `Crear`,
                `Enviar`,
                `Eliminar`,
                `Log de la Reserva:`,
            ],

            reservaModificarReservaActHerr: [
                `Editar Reserva`,
                `Estado:`,
                `Estado`,
                `Reserva:`,
                `Reserva`,
                `Buscar`,
            ],
            reservaAprobacionReservaActHerr: [
                `Aprobacion de Reservas`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Observacion:`,
                `Observacion`,
                `Aprobar`,
                `Rechazar`,
                `Cancelar`,
                `Buscar`,
                `Reportes`,
            ],

            reservaReporteReservaActHerr: [
                `Reporte de Reservas`,
                `Planta:`,
                `Planta`,
                `Centro:`,
                `Centro`,
                `Almacén:`,
                `Almacén`,
                `Estado:`,
                `Estado`,
                `Desde:`,
                `Desde`,
                `Hasta:`,
                `Hasta`,
            ],

            movimientosActHerr: [
                `Movimientos herramientas/activos`,
                `Entrada`,
                `Asignacion`,
                `Devolucion`,
                `Traslado`,
                `Baja`,
                `Salida`,
            ],
            movimientosActHerrEntrada: [
                `Remisión:`,
                `Remisión`,
                `Orden de Compra:`,
                `Orden de Compra`,
                `Factura:`,
                `Factura`,
                `Proveedor:`,
                `Proveedor`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Consultar clases`,
                `Buscar`,
                `Observación:`,
                `Observación`,
                `Crear`,
                `Enviar`,
                `Eliminar`,
            ],

            movimientosActHerrAsignacion: [
                `Asignacion`,
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Reserva:`,
                `Reserva`,
                `Custodio:`,
                `Custodio`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Consultar clases:`,
                `Buscar`,
                `Observacion:`,
                `Observacion`,
                `Crear`,
                `Asignar`,
                `Eliminar`,
            ],

            movimientosActHerrDevolucion: [
                `Devolucion`,
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Tipo de Devolucion:`,
                `Tipo de Devolucion`,
                `Movimiento:`,
                `Movimiento`,
                `N°.Movimiento:`,
                `N°.Movimiento`,
                `Custodio:`,
                `Custodio`,
                `Consultar clases:`,
                `Buscar`,
                `Observacion:`,
                `Observacion`,
                `Crear`,
                `Devolver`,
                `Cancelar:`,

            ],

            movimientosActHerrTraslado: [
                `Traslado`,
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Reserva:`,
                `Reserva`,
                `Destino:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Consultar clases:`,
                `Buscar`,
                `Observacion:`,
                `Observacion`,
                `Crear`,
                `Trasladar`,
                `Eliminar`,
            ],

            movimientosActHerrBaja: [
                `Baja`,
                `Origen:`,
                `Planta:`,
                `Centro:`,
                `Almacén:`,
                `Aprobación de Baja:`,
                `Reserva:`,
                `Reserva`,
                `Centro de Costos:`,
                `Centro de Costos`,
                `Sucursal:`,
                `Sucursal`,
                `Destino:`,
                `Destino`,
                `Consultar clases:`,
                `Buscar`,
                `Observación:`,
                `Observación`,
                `Crear`,
                `Baja`,
                `Eliminar`,
            ],


            planificacionActHerr: [
                `Planificacion`,
            ],

            tomaInventariosActHerr: [
                `Toma de inventarios`
                , `Internos`
                , `Externos`
                , `Con archivo`
                , `Sin archivo`
                , {
                    "subMenuTomaInventario": [
                        `Toma de inventarios` //0
                        , `Conteo` //1
                        , `Toma de Inventario` /** TITULO de INTERNO 2 */
                        , `Fotografias` //3
                        , `Responsable` //4
                        , `Conteo del inventario` //5
                        , `Filtro de busqueda` //6
                        , `Almacén` //7
                        , `No se ha diligenciado el campo ` //8
                        , `La toma de inventario seleccionada supera el limite de conteos.` //9
                        , `Se creo un nuevo conteo satisfactoriamente` //10
                        , `Se creo la toma de inventario satisfactoriamente y se genero el primer conteo para el responsable asignado` //11
                        , `Ya existe un conteo en proceso` //12
                        , `En Proceso` //13
                        , `Finalizado` //14
                        , `Cancelado` //15
                        , `Estado` //16
                        , `Acción` //17
                        , `No existen tomas de inventario para este almacén` //18
                        , `Se cancelo satisfactoriamente el conteo` //19
                        , `Se cancelo satisfactoriamente la toma de inventario` //20
                        , `No se ha detectado toma de inventario` //21
                        , `Resultado de la busqueda` //22
                        , `Proveedor*` //23
                        , `Estado Material*` //24
                        , `Cantidad*` //25
                        , `Listado de productos contados` //26
                        , `Seleccione el conteo a retomar` //27
                        , `Nuevo` //28
                        , `Usado` //29
                        , `Reparado` //30
                        , `Puede buscar por nombre de material, descripción corta, proveedor, serial y placa` //31
                        , `Este almacén no tiene inventario` //32
                        , `¡No se puede agregar un activo y/o herramienta ya contado!` //33
                        , `Codigo SLI` //34
                        , `Desc corta` //35    
                        , `Unidad Medida` //36
                        , `Proveedor` //37    
                        , `Estado Fisico` //38
                        , `Precio` //39    
                        , `Impuesto` //40
                        , `Serial` //41    
                        , `Placa` //42
                        , `Adicionar` //43    
                        , `Click para buscar materiales catalogados sin inventario` //44
                        , `Activar camara para leer codigos` //45    
                        , `Click para cargar` //46
                        , `Agregar Material no inventariado` //47    
                        , `Consulte por Codigo SLI, descripción corta y/o nombre de material` //48
                        , `Al finalizar la toma de inventarios, para guardar o finalizar el proceso, es necesaria una conexión a internet 
                        y debe tener en cuenta que no puede estar sin conexión mas de 2 horas o el sistema no le permitira guardar.` //49                        
                        , `Se perdio la conección a internet, por favor no realice ninguna acción hasta restaurar la conexión` // 50
                        , `Codigo SLI` //51
                        , `Cancelar` //52
                        , `Agregar` //53
                        , `Usted no cuenta con conteos asignados` //54
                        , `No se puede realizar el envio del conteo, ya que tiene
                           materiales sin guardar.` //55
                        , `Se guardo satisfactoriamente el conteo` //56
                        , `El conteo fue enviado satisfactoriamente` //57
                        , `Buen trabajo` //58
                        , `Para diligenciar el archivo excell debe tener encuenta
                           los siguientes campos obligatorios.` //59
                        , `Codigo ERP` //60
                        , `Unidad de Medida` //61
                        , `Estado del material` //62
                        , `Descripción corta o larga` //63
                        , `Serial` //64
                        , `Placa` //65
                        , `Carga masiva inventario externo` //66
                        , `Almacén` //67
                        , `Campos_Inventario_Externo.xlsx` //68
                        , `Responsable` //69
                        , `¡En el archivo cargado existen registros
                        con campos obligatorios no diligenciados!` //70
                        , `¡Debe seleccionar un responsable!` //71
                        , `Toma Inventario` //72
                        , `Informe Toma Inventario` //73
                        , `Listado de Materiales` //74
                        , `Acción` //75
                        , `Codigo ERP` //76
                        , `Serial` //77
                        , `Placa` //78
                        , `Almacén` //79
                        , `Estado del Material` //80
                        , `UMB` //81
                        , `Precio` //82
                        , `Descripción Corta` //83
                        , `Descripción Larga` //84
                        , `Fabricante` //85
                        , `Proveedor` //86
                        , `Referencia` //87
                        , `Ubicación` //88
                        , `Campo 1` //89
                        , `Campo 2` //90
                        , `Campo 3` //91
                        , `Leer Código` //92
                        , `Agregar` //93
                        , `Puede buscar por Codigo ERP, descripción corta, proveedor, serial y placa. Tambien puede filtrar por * despues de la palabra.` //94
                        , `Observación` //95
                        , `Crear conciliación` //96
                        , `Informe General` //97
                        , `Se creo satisfactoriamente la conciliación` //98
                        , `Conciliar` //99
                        , `Conciliar Toma de Inventario` //100 
                        , `Seleccione la conciliación` //101
                        , `--Seleccione uno--` //102
                        , `Conciliación` //103
                        , `Existen conteo en progeso` //104
                        , `Ya cuenta con una conciliación en proceso` //105
                        , `Archivos agregados al material` //106
                        , `Nombre del archivo` //107
                        , `Tipo de archivo` //108
                        , `Agregar Nuevo Material` //109
                        , `El archivo que trata de subir, ya esta agregado.` //110
                        , `No hay registros` //111
                        , `Se envio correctamente la conciliación` //112
                        , `Guardar` //113
                        , `Enviar` //114
                        , `Esta conciliación ya ha sido enviada` //115
                        , `Se guardo correctamente la conciliación` //116
                        , `Reporte conciliación` //117
                        , `Conciliación en proceso` //118                        
                        , `Debe conciliar el producto para cargar archivos` //119      
                        , `Verifique que guardo los cambios antes de enviar la conciliación.` //120  
                        , `Cancelar TIHA-` //121
                        , `Conciliacion inventario interno activo` //122
                        , `Reporte de existencias activos` //123
                        , `Kardex` //124
                    ]
                }
            ],


            configActHerr: [
                `Config. Act/Herr`,
            ],

            custodiosActHerr: [
                `Custodios de herramientas/activos`,
                `Crear Custodio`,
                `Editar Custodio`,
                `Eliminar Custodio`,
                `Reporte Custodio`,
                `Catálogo en custodio`,
            ],

            custodiosActHerrCrear: [
                `¿Está seguro de crear custodio `,
                `Regresar`,
                `Datos Principales`,
                `Nombre*:`,
                `Nombre`,
                `Apellido*:`,
                `Apellido`,
                `Tipo Documento*:`,
                `N°. Documento*:`,
                `N°. Documento`,
                `Activo`,
                `Herramienta`,
                `Observacion:`,
                `Observacion`,
                `Cargo*:`,
                `Código CIIU`,
                `Datos de Contacto`,
                `Código Postal:`,
                `Dirección*:`,
                `Dirección`,
                `Celular*:`,
                `Celular`,
                `Teléfono:`,
                `Teléfono`,
                `Persona Contacto`,
                `Correo Electronico*:`,
                `Correo Electronico`,
                `Guardar`,
                `Cancelar`,
                `Se creo correctamente el custodio`,
                `Crear custodio `,
            ],

            custodioEliminarActHerr: [
                `Eliminar Custodio`, //0
                `Seleccione custodio a eliminar`, //1
                `Información básica`, //2
                `Nombres`, //3
                `Apellidos`, //4
                `# documento`, //5
                `Corre`, //6
                `Eliminar`, //7
                `Cancelar`, //8
            ],

            reportesActHerr: [
                `Reportes`,
            ],


            administracionTuberias: [
                `Administracion de Tuberias`,
            ],
            reservaTuberias: [
                `Reserva de tuberia`,
                `Crear Reserva`,
                `Aprobacion Reserva`,
                `Atención Reserva`,
                `Reporte Reserva`,
                `Editar Reserva`,
            ],
            movimientosTuberias: [
                `Movimientos de tuberia`,
                `Entrada de tuberia`,
                `Despacho de tuberia`,
                `Devolucion de tuberia`,
                `Baja de tuberia`,
                `Traslado de tuberia`,
            ],
            planificacionTuberias: [
                `Planificacion`,
            ],
            tomaInventariosTuberias: [
                `Toma de inventarios de tuberia`
                , `Internos`
                , `Externos`
                , `Con archivo`
                , `Sin archivo`
            ],
            reportesTuberias: [
                `Reportes de tuberia`,
            ]
            , tomaInventarios_material: [
                `Toma de inventarios`
                , {
                    takingInventory: [
                        `Toma de inventario`
                        , `Responsable`
                        , {
                            confirmnacion: [
                                `Confirmación`
                                , `¿Desea crear un nuevo conteo para la toma de inventarios seleccionada ?`
                                , `¿Desea crear una nueva toma de inventarios?`
                            ]
                            ,
                            tableResultFilter: [
                                `SLI`
                                , `Material`
                                , `Tipo`
                                , `Grupo`
                                , `Fabricante`
                                , `UMB`
                                , `Descripción`
                                , `Acción`
                                , `Posibles materiales a inventariar`
                            ]
                            , SelectedMaterialsTable: [
                                `SLI`
                                , `Descripción`
                                , `UMB`
                                , `Proveedor`
                                , `E. Físico`
                                , `Cantidad`
                                , `Precio`
                                , `Impuesto`
                                , `Acción`
                                , `Materiales seleccionados`
                                , `Reporte toma de inventario`
                            ]
                            , materialInProcess: [
                                `Catalogo seleccionado`
                                , `Catalogo`
                                , `Formulario`
                                , `SLI`
                                , `Material`
                                , `Descripción corta`
                                , `Unidad de medida`
                                , `Fabricante`
                                , `Descripción larga`
                            ]
                            , form: [
                                `Proveedor`
                                , `Estado físico`
                                , `Cantidad`
                                , `Cód. SLI`
                                , `Descripción`
                                , `Precio`
                                , `Impuesto`
                            ]
                            , modal: [
                                `Tomas de inventarios pendientes para esta empresa`
                                , `Toma`
                                , `Conteos`
                                , `Acción`
                            ]
                            , alerts: [
                                `Error`
                                , `El usuario actual no cuenta con conteos asociados`
                                , `Advertencia`
                                , `Se perdio la conección a internet, por favor no realice ninguna acción hasta restaurar la conexión`
                                , `Sin conexión`
                                , `Al finalizar la toma de inventarios, para guardar o finalizar el proceso, es necesaria una conexión a internet`
                                , `Se genero un error al adicionar el material seleccionado`
                                , `Alguno de los campos obligatorios se encuentra vacio o con un valor no permitido, por fovor rectifiquelo`
                                , `Confirmación`
                                , `Este material ya se encuentra adicionado, ¿Desea actualizar su información?`
                                , `Sí`
                                , `No`
                                , `No se ha logrado obtener el material que desea editar`
                                , `¿Esta seguro de eliminar el material con la descripción `
                                , `¿Esta seguro de guardaro el conteo numero `
                                , `¿Esta seguro de finalizar el conteo numero `
                                , `Alguno de los materiales que tiene adicionados no cuenta con una cantidad valida, si algun material seleccionado cuenta con una cantidad cero, por favor retirelo de la lista o adicione una cantidad`
                                , `Se ha generado un error al guardar el conteo, por favor intentelo en unos minutos`
                                , `Conteo `
                                , `guardado satisfactoriamente`
                                , `La toma de inventario `
                                , ` no cuenta con conteos en proceso`
                                , `¿Desea adicionar el producto con el código SLI `
                                , `Buen trabajo`
                                , `La toma de inventarios "`
                                , `" no cuenta con conteos en proceso`

                            ]
                        }
                        , `Ejecutar conteo`
                    ]
                    , takinExternalInventory_whitFile: [
                        `Toma inventario desde cero`
                        , `Código`
                        , `Producto`
                        , `Proveedor`
                        , `Descripción`
                        , `Referencia`
                        , `Estado Físico`
                        , `Cantidad`
                        , `Precio`
                        , `Impuesto`
                        , {
                            tableResultFilter: [
                                `SLI`
                                , `Producto`
                                , `Descripción`
                                , `E. Físico`
                                , `Proveedor`
                                , `Referencia`
                                , `Cantidad`
                                , `Impuesto`
                                , `Precio`
                                , `Acción`
                                , `Materiales seleccionados`
                            ]
                        }
                    ]
                    , takingExternalInventory_withOutFile: [
                        `Cargue masivo de materiales externos`,
                        `Regresar`,
                        {
                            titles: [`Instructivo`, `Cargue y validación`, `Resultado`]
                            , paso1: [
                                `Descargar el archivo `
                                , `Formato_materiales_externos.xlsx`
                            ],
                            paso2: [
                                `Abrir el archivo "Formato_materiales_externos.xlsx" con una aplicacion de hojas de calculo (Excel,LibreOffice Cal...) y diligenciar los siguientes campos teniendo en cuenta las observaciones: `
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Unidad de medida base (UMB)`
                                , `Cantidad`
                                , `Planta, Centro o Almacen`
                            ]
                            , paso3: [

                                `El cargue se realizo satisfactoriamente.`,
                                `Registros en conflicto`,
                            ],
                            alerts: [
                                `Buen trabajo`
                                , `¡Error!`
                                , `El cargue se realizo satisfactoriamente.`
                                , `Se encontraron errores que no permitieron que se realizara el registro de la información, a continuación se mostraran los registros en conflicto`
                                , `¡El archivo cargado no es valido!`
                                , `El archivo cargado se encuentra vacio o no cuenta con información valida`
                            ]
                            , tablaLog: [
                                `Resultado de errores` //0
                                , `Código ERP` //1
                                , `Descripción L.` //2
                                , `Descripción C.` //3
                                , `UMB` //4
                                , `Ubicacion` //5
                                , `Proveedor` //6
                                , `Continuar` //7
                                , `Atras` //8
                                , `Nombre` //9
                                , `Numero de Documento` //10
                                , `El archivo supera la cantidad de errores soportada (20).` //11
                                , `error` //12
                            ]
                        }
                    ]
                    , createTakingInventory: [
                        `Conteo`
                        , `Estado`
                        , `Responsable`
                        , `Acción`
                        , {
                            form: [
                                `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Responsable`
                                , `Informe general`
                                , `Crear conteo`
                                , `Crear conciliación`
                                , `Cancelar toma de inventario`
                                , `Limpiar`
                                , `Crear toma de inventario`
                            ]
                            , alerts: [
                                `Buen trabajo`
                                , `Error`
                                , `Toma de inventario creada satisfactoriamente`
                                , `Se genero un error al realizar la toma de inventario`
                                , `¿Desea cancelar esta toma de inventario?`
                                , `¿Desea crear esta una nueva toma de inventario?`
                                , `¿Desea crear un nuevo conteo para la toma de inventario `
                                , `No puede crear un nuevo conteo haste que el anterio este finalizado`
                                , `El máximo de conteos por toma de inventarios es de 3`
                                , `Confirmación`
                                , `Sí`
                                , `No`
                                , `Se elimino la toma de inventario satisfactoriamente`
                                , `No se logro eliminar la toma de inventarios actual`
                                , `¿Desea cancelar el conteo `
                                , `Se cancelo satisfactoriamente el conteo `
                                , `Se genero un error al cancelar el conteo `
                                , `¿Desea limpiar el formulario?`
                                , `El almacen seleccionado no cuenta con inventario`
                                , `No ha diligenciado todos los campos requeridos`
                                , `No se logro obtener la información del conteo "`
                                , `", si el error persiste contacte con el administrador del sistema`
                                , `Algunos de los campos requeridos para crear la conciliación se encuentra vacios`
                                , `¿Esta seguro de crear la conciliación para la toma de inventario `
                                , `Se creo la conciliaciín satisfactoriamente`
                                , `Se genero un error al crear la conciliación, si el error persiste comuniquese con el administrador de sistema`
                                , `La toma de inventario `
                                , `no puede tener más conteos porque se encuentra en proceso de conciliación con el número de conciliación `
                                , `No es posible crear la conciliación de la toma de inventarios teniendo un conteo en proceso`
                            ]
                            , SelectedMaterialsTable: [
                                `SLI`
                                , `Descripción`
                                , `UMB`
                                , `Proveedor`
                                , `E. Físico`
                                , `C-1`
                                , `C-2`
                                , `C-3`
                                // , `Cantidad`
                                , `Precio`
                                , `Impuesto`
                                // , `Acción`
                                , `Materiales seleccionados`
                                , `Reporte toma de inventario`
                            ]
                        }
                        , `Limpiar`
                        , `Eliminar`
                    ]
                    , reportTalkingInventory: [
                        `Reporte toma de inventario interna`
                        , {
                            form: [
                                `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Tomas de inventario`
                                , `[Seleccionar]`
                            ]
                            , information: [
                                `Información general`
                                , `Toma de inventario`
                                , `# de Conteos`
                                , `Conteos`
                                , `Aprobador`
                                , `Fecha creación`
                                , `Almacen`
                                , `Estado toma de inventario`
                                , `Conciliación`
                                , `Reajuste`

                            ]
                            , alerts: [
                                `Información`
                                , `Esta empresa no cuenta con plantas`
                                , `Este almacen no cuenta con tomas de inventario`
                                , `Esta toma de inventario se encuentra cancelada`
                            ]
                            , table: [
                                `Reporte toma de inventario `
                                , `Cod. SLI`
                                , `Cod. ERP`
                                , `Desc. Corta`
                                , `Referencias`
                                , `Cantidad en sistema`
                                , `Conteo 1`
                                , `Conteo 2`
                                , `Conteo 3`
                                , `Resultado`
                                , `Diferencia`
                                , `Resultado conciliación`
                                , `UMB`
                                , `Condición`
                                , `Fabricante`
                                , `Proveedor`
                                , `Precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Conciliación`
                                , `Observación`
                                , `Soportes`
                            ]

                        }
                    ]
                    , stateCount: {
                        "0": `En proceso`
                        , "1": `Finalizado`
                        , "2": `Cancelado`
                        , "3": `Fotografia`
                    }
                    , stateTalkingInventory: {
                        "0": `En proceso`
                        , "1": `Finalizado`
                        , "2": `Cancelado`
                    }
                    , stateConciliation: {
                        "0": `En proceso`
                        , "1": `Finalizado`
                        , "2": `Cancelado`
                    }
                    , stateReadjustment: {
                        "0": `En proceso`
                        , "1": `Finalizado`
                    }
                    , countTalkingInventory_external: [
                        `Toma de inventario`
                        , `Responsable`
                        , {
                            confirmnacion: [
                                `Confirmación`
                                , `¿Desea crear un nuevo conteo para la toma de inventarios seleccionada ?`
                                , `¿Desea crear una nueva toma de inventarios?`
                            ]
                            , tableResultFilter: [
                                `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `Estado del Material`
                                , `UMB`
                                , `Fabricante`
                                , `Proveedor`
                                , `Referencia`
                                , `Cantidad`
                                , `precio`
                                , `Almacén`
                                , `Ubicación`
                                , `Observacion`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Materiales a inventariar`
                                , `Acción`
                            ]
                            , form: [
                                `Agregar material no inventariado`
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `Estado del Material`
                                , `UMB`
                                , `Fabricante`
                                , `Proveedor`
                                , `Referencias`
                                , `Cantidad`
                                , `Divisa`
                                , `precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacén`
                                , `Ubicación`
                                , `Observacion`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Agregar`
                                , `Cancelar`
                                , `Actualizar`
                            ]
                            , modal: [
                                `Tomas de inventario en proceso para la empresa `
                                , `Toma de inventario`
                                , `Conteos`
                                , `Acción`
                            ]
                            , alerts: [
                                `Error`
                                , `Se genero un error al obtener la información del usuario, es posible que el usuario no cuente con información asígnada, si no es así, intentelo de nuevo más tarde`
                                , `Advertencia`
                                , `Se perdio la conección a internet, por favor no realice ninguna acción hasta restaurar la conexión`
                                , `Se genero un error al obtener la información del conteo`
                                , `Debe llenar alguno de estos campos obligatoriamente`
                                , `Hay algunos campos obligatorios en blanco, por favor llenelos e intentente guardar nuevamente`
                                , `No se ha realizado ninguna acción, por favor hagalo y guarde nuevamente`
                                , `Se genero un error al guardar la información dilegenciada, por favor verifique el estado de la red e intentelo de nuevo`
                                , `Buen trabajo`
                                , `Conteo`
                                , ` se guardado satisfactoriamente`
                                , ` se finalizado satisfactoriamente`
                                , `Advertencia`
                                , `Esta toma de inventarios no cuenta con conteos en proceso, por favor solicite la creación del conteo e intentelo de nuevo`
                                , `La toma de inventarios seleccionada no cuenta con conteos pendientes`
                                , `¿Esta seguro de guardar el conteo `
                                , `¿Esta seguro de finalizar el conteo `
                                , `Confirmación`
                                , `Esta toma de inventario no cuenta con materiales en conflicto, por favor elimine el conteo y proceda a realizar la conciliación`
                                , `Esta empresa no cuenta con tomas de inventario en proceso`
                                , `Información`
                            ]
                        }
                        , `Ejecutar conteo`
                    ]
                    , external_product_counter_report: [
                        `Reporte conteo toma de inventario`
                        , {
                            alerts: [
                                `Confirmación`
                                , `¿Esta seguro de eliminar el conteo `
                                , `Conteo `
                                , ` eliminado satisfactoriamente`
                                , ` creado satisfactoriamente`
                                , `Se genero un error al eliminar el conteo `
                                , `Información`
                                , `La toma de inventarios seleccionada no cuenta con conteos actualmente, por favor verifique la información e intente nuevamente`
                                , `Buen trabajo`
                                , `Error`
                                , `¿Está seguro de eliminar la toma de inventario `
                                , `Toma de inventarios `
                                , ` eliminada satisfactoriamente`
                                , `Se genero un error al eliminar la toma de inventario `
                                , `La toma de inventarios `
                                , ` se encuntra cancelada, por tal motivo no se puede realizar ningúna acción con ella`
                                , `¿Esta seguro de crear un nuevo conteo para la toma `
                                , `El conteo se creo satisfactoriamente`
                                , `Se genero un error al crear el conteo, por favor espere un momento e intentelo de nuevo, si el error persiste comuniquese con el administrador`
                                , `Actualmente no hay tomas de inventario en el sistema, por favor verifiquelo e ingrese nuevamente`
                                , `Usted no puede crear más conteos para la toma de inventario `
                                , `, ya que completo el máximo de conteos finalizados`
                                , `, ya que aun tiene un conteo en proceso`
                                , `¿Esta seguro de crear la conciliación para la toma de inventario `
                                , `La toma de inventario `
                                , ` cuenta con conteos en proceso`
                                , ` no cuenta con conteos para conciliar`
                                , `La conciliación para la toma de inventario `
                                , ` se creo satisfactoriamente`
                                , `Se genero un error al realizar la conciliación para la toma de inventario `
                                , `Sí`
                                , `No`
                                , ` no cuenta con conteos, por tal motivo no es posible crear una conciliación`
                                , ` no cuenta con conteos, ¿desea continuar y adicionar un conteo? `

                            ]
                            , form: [
                                `Seleccionar toma de inventario`
                                , `Seleccionar responsable`
                                , `-- Seleccionar --`
                                , `Tomar código`
                                , `Agregar conteo`
                                , `Guardar`
                                , `Enviar`
                                , `Cancelar conteo`
                                , `Crear conciliación`
                                , `Informe general`
                                , `Cancelar toma de inventario`
                            ]
                            , tableFilter: [
                                `Conteo`
                                , `Estado`
                                , `Responsable`
                                , `Acción`
                                , `Conteos actuales`
                            ]
                            , tableComparison: [
                                `Tabla de comparacación`
                                , `Cod. ERP`
                                , `Estado Físicol`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `Precio`
                                , `Impuesto`
                                , `Proveedor`
                                , `Referencias`
                                , `Fabricante`
                                , `UMB`
                                , `Divisa`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Observación`
                                , `Aprobador`
                                , `Conteo 1`
                                , `Conteo 2`
                                , `Conteo 3`
                                , `Toma de inventarios `
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Conteo`
                            ]
                        }
                    ]
                    , external_conciliation_product: [
                        `Conciliación Productos externos`
                        , {
                            form: [
                                `Selecciona la conciliación a atender`
                                , `-- Seleccionar --`
                                , `Conciliación `
                                , `Guardar conciliación`
                                , `Crear nuevo producto`
                                , `Cancelar`
                                , `Enviar conciliación`
                            ]
                            , tableProducts: [
                                `Tabla de conciliación`
                                , `C. ERP`
                                // , `Descripción larga`
                                , `Descripción corta`
                                , `Referencia(s)`
                                , `Cantidad en sistema`
                                , `Conteo 1`
                                , `Conteo 2`
                                , `Conteo 3`
                                , `Resultado`
                                , `Diferencia`
                                , `Conciliar`
                                , `Resultado conciliación`
                                , `UMB`
                                , `Estado Físico`
                                , `Fabricante`
                                , `Proveedor`
                                , `Precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Soportes`
                                , `Reporte conciliación`
                                , `Conciliar`
                            ]
                            , alerts: [
                                `Error`
                                , `Se genero un error al obtener la información de la conciliación `
                                , `Algunos de los archivos cargados no cuentan con una extención valida, por favor verifique que el archivo sea de alguno de estos tipos: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg, .pdf`
                                , `Los siguientes archivos ya se encuentran agregados `
                                , `Los compos requeridos para esta acción se encuentran vacios`
                                , `Los siguientes archivos se encuentran defectuosos `
                                , `Algunos campos requeridos que se encuentran vacios`
                                , `Confirmación`
                                , `¿Desea agregar el producto con código ERP `
                                , `Buen trabajo`
                                , `Material añadido satisfactoriamente`
                                , `El producto`
                                , ` ya se encuentra registrado`
                                , `¿Esta seguro de guardar la conciliación `
                                , `¿Esta seguro de finalizar la conciliación `
                                , `Conciliación `
                                , `Finalizada satisfactoriamente`
                                , `Se genero un error al guardar "Conciliación`
                                , `,por favor verifique la información ingresada e intentelo de nuevo`
                                , `¿Esta seguro de eliminar la conciliación del material con código ERP `
                                , `¿Esta seguro de guardar la conciliación del material con código ERP `
                                , `No se puede limpiar la conciliación de un material que no ha sido modificado`
                                , `¿Esta seguro de borrar el material con el codigo ERP `
                                , ` de la conciliación?`
                            ]
                            , modalConciliation: [
                                `Conciliar`
                                , `Entrada`
                                , `Salida`
                                , `Digite el valor a conciliar`
                                , `Añadir soporte`
                                , `Guardar`
                                , `Cancelar`
                                , `Subir`
                                , `Seleccionar archivo`
                                , `Deshacer conciliación`
                                , `Borrar producto`
                            ]
                            , modalNewProduct: [
                                `Agregar material a la conciliación`
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `Estado del Material`
                                , `UMB`
                                , `Fabricante`
                                , `Proveedor`
                                , `Referencias`
                                , `Cantidad`
                                , `Divisa`
                                , `precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacén`
                                , `Ubicación`
                                , `Observacion`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Agregar`
                                , `Cancelar`
                            ]
                        }
                    ]
                    , report_external_conciliation_product: [
                        `Reporte conciliación externa`
                        , {
                            form: [
                                `Selecciona la conciliación a atender`
                                , `-- Seleccionar --`
                                , `Conciliación `
                                , `Guardar conciliación`
                                , `Crear nuevo producto`
                                , `Cancelar`
                                , `Enviar conciliación`
                            ]
                            , tableProducts: [
                                `Tabla de conciliación`
                                , `C. ERP`
                                // , `Descripción larga`
                                , `Descripción corta`
                                , `Referencia(s)`
                                , `Cantidad en sistema`
                                , `Conteo 1`
                                , `Conteo 2`
                                , `Conteo 3`
                                , `Resultado`
                                , `Diferencia`
                                // , `Conciliar`
                                , `Resultado conciliación`
                                , `UMB`
                                , `Estado Físico`
                                , `Fabricante`
                                , `Proveedor`
                                , `Precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Soportes`
                                , `Reporte conciliación`
                                , `Conciliar`
                            ]
                            , alerts: [
                                `Error`
                                , `Se genero un error al obtener la información de la conciliación `
                                , `Algunos de los archivos cargados no cuentan con una extención valida, por favor verifique que el archivo sea de alguno de estos tipos: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg, .pdf`
                                , `Los siguientes archivos ya se encuentran agregados `
                                , `Los compos requeridos para esta acción se encuentran vacios`
                                , `Los siguientes archivos se encuentran defectuosos `
                                , `Algunos campos requeridos que se encuentran vacios`
                                , `Confirmación`
                                , `¿Desea agregar el producto con código ERP `
                                , `Buen trabajo`
                                , `Material añadido satisfactoriamente`
                                , `El producto`
                                , ` ya se encuentra registrado`
                                , `¿Esta seguro de guardar la conciliación `
                                , `¿Esta seguro de finalizar la conciliación `
                                , `Conciliación `
                                , `Finalizada satisfactoriamente`
                                , `Se genero un error al guardar "Conciliación`
                                , `,por favor verifique la información ingresada e intentelo de nuevo`
                                , `¿Esta seguro de eliminar la conciliación del material con código ERP `
                                , `¿Esta seguro de guardar la conciliación del material con código ERP `
                                , `No se puede limpiar la conciliación de un material que no ha sido modificado`
                                , `¿Esta seguro de borrar el material con el codigo ERP `
                                , ` de la conciliación?`
                            ]
                            , modalConciliation: [
                                `Conciliar`
                                , `Entrada`
                                , `Salida`
                                , `Digite el valor a conciliar`
                                , `Añadir soporte`
                                , `Guardar`
                                , `Cancelar`
                                , `Subir`
                                , `Seleccionar archivo`
                                , `Deshacer conciliación`
                                , `Borrar producto`
                            ]
                            , modalNewProduct: [
                                `Agregar material a la conciliación`
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `Estado del Material`
                                , `UMB`
                                , `Fabricante`
                                , `Proveedor`
                                , `Referencias`
                                , `Cantidad`
                                , `Divisa`
                                , `precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacén`
                                , `Ubicación`
                                , `Observacion`
                                , `Campo 1`
                                , `Campo 2`
                                , `Campo 3`
                                , `Agregar`
                                , `Cancelar`
                            ]
                        }
                    ]

                    , internal_conciliation_product: [
                        `Conciliación Productos internos`
                        , {
                            form: [
                                `Selecciona la conciliación a atender`
                                , `-- Seleccionar --`
                                , `Conciliación `
                                , `Guardar conciliación`
                                , `Crear nuevo producto`
                                , `Cancelar`
                                , `Enviar conciliación`
                                , `Rechazado`
                            ]
                            , tableProducts: [
                                `Tabla de conciliación`
                                , `C. SLI`
                                , `C. ERP`
                                // , `Descripción larga`
                                , `Descripción corta`
                                , `Referencia(s)`
                                , `Cantidad en sistema`
                                , `Conteo 1`
                                , `Conteo 2`
                                , `Conteo 3`
                                , `Resultado`
                                , `Diferencia`
                                , `Conciliar`
                                , `Resultado conciliación`
                                , `UMB`
                                , `Estado Físico`
                                , `Fabricante`
                                , `Proveedor`
                                , `Precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Estado`
                                , `Observación`
                                , `Soportes`
                                , `Reporte conciliación`
                                , `Conciliar`
                            ]
                            , alerts: [
                                `Error`
                                , `Se genero un error al obtener la información de la conciliación `
                                , `Algunos de los archivos cargados no cuentan con una extención valida, por favor verifique que el archivo sea de alguno de estos tipos: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg, .pdf`
                                , `Los siguientes archivos ya se encuentran agregados `
                                , `Los compos requeridos para esta acción se encuentran vacios`
                                , `Los siguientes archivos se encuentran defectuosos `
                                , `Algunos campos requeridos que se encuentran vacios`
                                , `Confirmación`
                                , `¿Desea agregar el producto con código SLI `
                                , `Buen trabajo`
                                , `Material añadido satisfactoriamente`
                                , `El producto`
                                , ` ya se encuentra registrado`
                                , `¿Esta seguro de guardar la conciliación `
                                , `¿Esta seguro de finalizar la conciliación `
                                , `Conciliación `
                                , `Finalizada satisfactoriamente`
                                , `Se genero un error al guardar "Conciliación`
                                , `,por favor verifique la información ingresada e intentelo de nuevo`
                                , `¿Esta seguro de eliminar la conciliación del material con código ERP `
                                , `¿Esta seguro de guardar la conciliación del material con código ERP `
                                , `No se puede limpiar la conciliación de un material que no ha sido modificado`
                                , `¿Esta seguro de borrar el material con el codigo ERP `
                                , ` de la conciliación?`
                                , `El producto no se puede eliminar debido a que es un material arrojado por las desigualdades de los conteos`
                                , `Se genero un error al obtener el archivo `
                            ]
                            , modalConciliation: [
                                `Conciliar`
                                , `Entrada`
                                , `Salida`
                                , `Digite el valor a conciliar`
                                , `Añadir soporte`
                                , `Guardar`
                                , `Cancelar`
                                , `Subir`
                                , `Seleccionar archivo`
                                , `Deshacer conciliación`
                                , `Borrar producto`
                            ]
                            , modalNewProduct: [
                                `Agregar material a la conciliación`
                                , `Código SLI`
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `UMB`
                                , `Fabricante`
                                , `Referencias`
                                , `Cantidad`
                                , `Precio`
                                , `Impuesto`
                                , `Proveedor`
                                , `Condición`
                                , `Agregar`
                                , `Cancelar`
                            ]
                        }
                    ]
                    , readjustmentInternal: [
                        `Conciliación Productos externos`
                        , {
                            form: [
                                `Selecciona la conciliación a atender`
                                , `-- Seleccionar --`
                                , `Conciliación `
                                , `Sucursal`
                                , `Centro de costo`
                                , `Destino`
                                , `Enviar`
                                , `Cancelar`
                            ]
                            , tableProducts: [
                                `Tabla de conciliación`
                                , `C. SLI`
                                , `C. ERP`
                                , `Descripción corta`
                                , `Referencia(s)`
                                , `Cantidad en sistema`
                                , `Resultado`
                                , `Diferencia`
                                , `Conciliación`
                                , `Resultado conciliación`
                                , `Rechazar`
                                , `Observación`
                                , `UMB`
                                , `Estado Físico`
                                // , `Fabricante`
                                , `Proveedor`
                                , `Precio`
                                , `Impuesto`
                                , `Planta`
                                , `Centro`
                                , `Almacen`
                                , `Ubicación`
                                , `Soportes`
                                , `Reporte conciliación`
                                , `Conciliar`
                            ]
                            , alerts: [
                                `Error`
                                , `Buen trabajo`
                                , `Confirmación`
                                , `Se genero un error al obtener la conciliación seleccionada, si el error persiste comuniquese con el administrador`
                                , `¿Esta seguro de realizar el reajuste a la toma de inventario `
                                , `Sí`
                                , `No`
                                , `El reajuste se realizo satisfactoriamente`
                                , `Se realizo envión la conciliación a revisión`
                                , `Se genero un error al realizar el reajuste de inventario`
                                , `Se genero un error al obtener el archivo `
                                , `Los campos de sucursal, centro de costo y destino son obligatorios, por favor llenelos para continuar`
                            ]
                            , modalConciliation: [
                                `Conciliar`
                                , `Entrada`
                                , `Salida`
                                , `Digite el valor a conciliar`
                                , `Añadir soporte`
                                , `Guardar`
                                , `Cancelar`
                                , `Subir`
                                , `Seleccionar archivo`
                                , `Deshacer conciliación`
                                , `Borrar producto`
                            ]
                            , modalNewProduct: [
                                `Agregar material a la conciliación`
                                , `Código SLI`
                                , `Codigo ERP`
                                , `Descripción Larga`
                                , `Descripción Corta`
                                , `UMB`
                                , `Fabricante`
                                , `Referencias`
                                , `Cantidad`
                                , `Precio`
                                , `Impuesto`
                                , `Proveedor`
                                , `Condición`
                                , `Agregar`
                                , `Cancelar`
                            ]

                            , modalErrors: [
                                `Errores al realizar el reajuste`
                                , `Cod. SLI`
                                , `Proveedor`
                                , `Estado físico`
                                , `Cantidad a conciliar`
                                , `Cantidad faltante en inventario para conciliar`
                            ]
                        }
                    ]

                }
            ]
        }


        // this.arrInspeccionMMateriales = [`Duplicado`, `Pendiente`, `Falto de información`, `Enviado a calidad`, `Seleccione inspección`,`Aprobado`, `Rechazado`];
        // this.arrEstadoMMaterial = [`Completado`, `Incompleto`];
        this.arrInspeccionMMateriales = ["Duplicado", "Pendiente", "Falto de información", "Enviado a calidad", "Aprobado", "Rechazado"];
        this.arrIndicatorsInspection = [
            {
                '1': "Duplicado"
                , '2': "Pendiente"
                , '3': "Falto.Inf"
                , '4': "Env.calidad"
                , '5': "Aprobado"
                , '6': "Rechazado"
                , '7': "No tratado"
            }
        ];

        this.typeMovements = {
            "1": "Entrada"
            , "2": "Salida"
            , "3": "Devolución"
            , "4": "Traslado"
            , "5": "Baja"
            , "6": "Asignación"
        };
        this.arrEstadoMMaterial = ["Completado", "Incompleto"];
        this.arrInspeccionDinamicoSel = ["Aprobado", "Rechazado"];

        this.permission = {
            "1": "Crear empresa"
            , "2": "Editar empresa"
            , "3": "Eliminar empresa"
            , "4": "Habilitar/Inhabilitar empresa"
            , "5": "Carga masiva empresa"
            , "6": "Asignar idioma"
            , "7": "Reporte empresa"
            , "8": "Crear usuario"
            , "12": "Editar usuario"
            , "13": "Eliminar usuario"
            , "14": "Habilitar/Inhabilitar usuario"
            , "15": "Carga masiva usuario"
            , "16": "Reporte usuario"
            , "17": "Crear rol"
            , "18": "Editar rol"
            , "19": "Eliminar rol"
            , "20": "Habilitar/Inhabilitar rol"
            , "21": "Reporte rol"
            , "22": "Reporte Log"
            , "23": "Crear tipo"
            , "24": "Editar tipo"
            , "26": "Eliminar tipo"
            , "27": "Reporte tipo"
            , "28": "Crear grupo"
            , "29": "Editar grupo"
            , "30": "Eliminar grupo"
            , "31": "Carga masiva grupo"
            , "32": "Reporte grupo"
            , "33": "Crear clase"
            , "34": "Editar clase"
            , "35": "Eliminar clase"
            , "36": "Carga masiva clase"
            , "37": "Reporte clase"
            , "38": "Crear caracteristica"
            , "39": "Editar caracteristica"
            , "40": "Eliminar caracteristica"
            , "41": "Reporte caracteristica"
            , "42": "Crear valor"
            , "43": "Editar valor"
            , "44": "Eliminar valor"
            , "45": "Reporte valor"
            , "46": "Crear unidad de medida"
            , "47": "Editar unidad de medida"
            , "48": "Eliminar unidad de medida"
            , "49": "Reporte unidad de medida"
            , "50": "Crear fabricante"
            , "51": "Editar fabricante"
            , "52": "Eliminar fabricante"
            , "53": "Carga fabricante"
            , "54": "Reporte fabricante"
            , "55": "Configurar textos"
            , "56": "Crear campos solicitud"
            , "57": "Editar campos solicitud"
            , "58": "Reporte campos solicitud"
            , "59": "Cargar descripción antigua individual"
            , "60": "Cargar descripción antigua masiva"
            , "61": "Reporte campos solicitud"
            , "62": "Asignar solicitud"
            , "63": "Asignar descripciones"
            , "64": "Indicadores"
            , "65": "Reportes"
            , "66": "Catalogar clase"
            , "67": "Calidad"
            , "68": "Crear solicitud catalogación"
            , "69": "Editar solicitud catalogación"
            , "70": "Reporte solicitud catalogación"
            , "71": "Atención"
            , "72": "Consultar clases"
            , "73": "Informe catalogación de clases"
            , "74": "Crear planta"
            , "75": "Editar planta"
            , "76": "Eliminar planta"
            , "77": "Reporte planta"
            , "78": "Crear centro"
            , "79": "Editar centro"
            , "80": "Eliminar centro"
            , "81": "Reporte centro"
            , "82": "Crear almacén"
            , "83": "Editar almacén"
            , "84": "Eliminar almacén"
            , "85": "Reporte almacén"
            , "86": "Importe catálogación individual"
            , "87": "Habilitar/Inhabilitar importe catalogación"
            , "88": "Importe masivo catalogación a almacen"
            , "89": "Reporte importe a almacen"
            , "90": "Crear centro de costos"
            , "91": "Editar centro de costos"
            , "92": "Eliminar centro de costos"
            , "93": "Importar centro de costos"
            , "94": "Reporte centro de costos"
            , "95": "Crear linea de negocio"
            , "96": "Editar linea de negocio"
            , "97": "Eliminar linea de negocio"
            , "98": "Importar linea de negocio"
            , "99": "Reporte linea de negocio"
            , "100": "Crear sector"
            , "101": "Editar sector"
            , "102": "Eliminar sector"
            , "103": "Importar sector"
            , "104": "Reporte sector"
            , "105": "Crear sucursal"
            , "106": "Editar sucursal"
            , "107": "Eliminar sucursal"
            , "108": "Importar sucursal"
            , "109": "Reporte sucursal"
            , "110": "Crear destino"
            , "111": "Editar destino"
            , "112": "Eliminar destino"
            , "113": "Importar destino"
            , "114": "Reporte destino"
            , "115": "Crear proveedor"
            , "116": "Editar proveedor"
            , "117": "Eliminar proveedor"
            , "118": "Importar proveedor"
            , "119": "Reporte proveedor"
            , "120": "Crear reserva productos"
            , "121": "Aprobación de reserva productos"
            , "122": "Editar reserva producto"
            , "124": "Reporte reserva produtos"
            , "125": "Importe catalogación externo"
            , "126": "Habilitar/Inhabilitar importe externo"
            , "127": "Carga masiva externa"
            , "128": "Reporte carga catalogación externa"
            , "129": "Crear catálogo externo"
            , "130": "Carga masiva catalogo externo"
            , "131": "Adicionar usuario a empresa"
            , "132": "Administración de productos, entradas"
            , "133": "Administración de productos, traslado"
            , "134": "Administración de productos, salida"
            , "135": "Administración de productos, devolución"
            , "136": "Administración de productos, baja"
            , "137": "Crear reserva herramienta/activo"
            , "138": "Crear custodio"
            , "139": "Modificar custodio"
            , "140": "Eliminar custodio"
            , "142": "Reporte custodio"
            , "143": "Editar reserva herramienta/activo"
            , "144": "Aprobación reserva herramienta/activo"
            , "145": "Reporte reserva heramientas/activos"
            , "148": "Carga masiva característica"
            , "149": "Carga masiva Valor"
            , "150": "Carga masiva solicitud catalogación"
            , "153": "Crear reserva tuberia"
            , "154": "Editar reserva tuberia"
            , "155": "Aprobación reserva tuberia"
            , "157": "Reporte reserva tuberia"
            , "158": "Atención de reserva productos"
            , "159": "Salida reserva producto"
            , "160": "Modificar entrada"
            , "161": "Reporte entrada"
            , "162": "Administración de activos, entradas"
            , "164": "Modificar entrada activos"
            , "165": "Modificar salida productos"
            , "166": "Reporte entrada activos"
            , "167": "Crear aprobador"
            , "168": "Asignar almacen"
            , "169": "Salida reserva activo"
            , "171": "Entrada tuberia"
            , "172": "Administrar salida de activos"
            , "173": "Modificar entrada tuberia"
            , "176": "Modificar salida activo"
            , "177": "Reporte entrada tuberia"
            , "178": "Reporte salida activo"
            , "179": "Reporte salida producto"
            , "180": "Asignar activos"
            , "181": "Modificar asignación de activos"
            , "184": "Modificar baja producto"
            , "185": "Modificar traslado producto"
            , "186": "Reporte traslado producto"
            , "188": "Reporte asignación de activos"
            , "189": "Crear baja activos"
            , "190": "Modificar baja activos"
            , "191": "Reporte de baja producto"
            , "192": "Reporte de baja activos"
            , "193": "Crear traslado activos"
            , "194": "Modificar traslado activos"
            , "195": "Reporte de traslado activos"
            , "196": "Devolución de activos"
            , "197": "Despacho de tuberia"
            , "198": "Reporte de devolución"
            , "199": "Modificar tuberia despacho"
            , "200": "Reporte tuberia despacho"
            , "201": "Baja tuberia"
            , "202": "Modificar baja tuberia"
            , "203": "Reporte baja tuberia"
            , "204": "Crear traslado tuberia"
            , "205": "Modificar traslado tuberia"
            , "207": "Reporte traslado tuberia"
            , "208": "Devolución tuberia"
            , "211": "Reporte de devolución tubería"
            , "212": "Gestión de calidad"
            , "213": "Atención reserva Herramientas/activos"
            , "214": "Atención reserva tuberia"
            , "216": "Salida reserva tuberia"
            , "217": "Adicionar divisa a empresa"
            , "218": "Reporte Devolución Activo"
            , "220": "Informe General de Solicitudes"
            , "261": "Indicadores de catalogación"
            , "262": "Crear cliente"
            , "264": "Crear equipo"
            , "265": "Modificar equipo"
            , "266": "Reporte equipo"
            , "267": "Modificar cliente"
            , "268": "Reporte cliente"
            , "269": "Configuracion General Mantenimiento"
            , "270": "Configuracion Usuarios Mantenimiento"
            , "271": "Crear check list"
            , "272": "Modificar check list"
            , "274": "Reporte check list"
            , "275": "Crear lista de precios"
            , "277": "Carga masiva lista de precios"
            , "278": "Reporte lista de precios"
            , "280": "Crear kits"
            , "281": "Modificar kits"
            , "282": "Report kits"
            , "283": "Crear promociones"
            , "285": "Modificar promoción"
            , "286": "Reporte promoción"
            , "287": "Tempario Mantenimiento"
            , "288": "Crear agenda"
            , "289": "Crear entrada de quipo"
            , "290": "Modificar entrada de equipo"
            , "291": "Reporte entrada de equipo"
            , "292": "Calendario de agenda"
            , "293": "Crear presupuesto"
            , "294": "Modificar presupuesto"
            , "295": "Reporte presupuesto"
            , "296": "Distribución"
            , "297": "Crear convenio"
            , "298": "Modificar convenio"
            , "299": "Reporte convenio"
            , "300": "Atención OT"
            , "301": "Distribuición"
            , "302": "Crear adicionales"
            , "303": "Aprobar orden de trabajo"
            , "304": "Atención orden de trabajo"
            , "305": "Cronograma"
            , "306": "Calendario cronograma"
            , "307": "Proforma Mantenimiento"
            , "308": "Orden de trabajo"
            , "309": "Crear entrega de equipo"
            , "312": "Modificar entrega de equipo"
            , "313": "Reporte entrega de equipo"
            , "366": "Conciliación toma de inventario Externa Tuberia"
            , "367": "Toma de inventario Interna"
            , "368": "Reporte toma de inventario interna"
            , "369": "Conteo toma de inventarios"
            , "370": "Atención conciliación"
            , "371": "Reajuste conciliación"
            , "372": "Toma inventario Externa"
            , "373": "Toma inventario Externa con Material"
            , "374": "Conteo toma inventario externa con archivo"
            , "375": "Repoorte conteo toma de inventario Externa"
            , "376": "Atención Conciliación"
            , "377": "Reporte Conciliación"
            , "378": "Toma inventario Interna Herramientas/Activos"
            , "379": "Toa inventario Externa Herramientas/Activos"
            , "380": "Toma inventario Externa Herramientas/Activos con archiv"
            , "381": "Toma inventario conteo Herramientas/Activos"
            , "382": "Conciliación Herramientas/Activos"
            , "383": "Reajuste Herramientas/Activos"
            , "384": "Reporte toma de inventarios Herramientas/Activos"
            , "385": "Conteo Herramientas/Activos Inventario Externo con arch"
            , "386": "Reporte toma de inventario Herramientas/Activos Externo"
            , "387": "Reporte conciliación toma de inventario Herramientas/Ac"
            , "388": "Toma de inventario Interna Tuberia"
            , "389": "Conteo toma de inventario Tuberia"
            , "390": "Conciliación toma de inventario Tuberia"
            , "391": "Reajuste Tuberia"
            , "392": "Reporte conciliación Tuberia"
            , "393": "Importe Toma de inventario Externo Tuberia"
            , "394": "Conteo Externo Tuberia"
            , "395": "Reporte toma de inventario Externa Tuberia"
            , "396": "Reporte existencias Activos/Herramientas"
            , "397": "Devolución por custodio AH"
            , "398": "Kardex"
            , "399": "Catálogo en custodia (Reporte)"
            , "400": "Catálogo GEB"
            , "401": "Conteo Activos(Externo con archivo)"
            , "402": "Informe Toma de Inventario activo(Externo con archivo)"
            , "403": "Conciliacion TI Activo (Externo con archivo)"
        };


        this.modules = {
            "1": "Administrativo"
            , "2": "Catalogación"
            , "3": "Planeación"
            , "4": "Inventario"
            , "5": "Compras"
            , "6": "Contratación"
            , "7": "Proveedores"
            , "8": "Logística"
            , "9": "QA/QC"
            , "10": "Mantenimiento"
        };

        this.stateUser = {
            "1": "Registrado"
            , "2": "Inhabilitado"
            , "3": "Habilitado"
            , "4": "Sin Contraseña"
        }

        this.currencies = {
            "1": "$-Peso colombiano"
            , "2": "US$-Dolar estadounidense"
            , "3": "€-Euro"
            , "4": "C$-Dolar canadience"
        }

        this.physical_state = {
            "0": "Seleccionar..."
            , "Error": `No se encontro información`
            , "1": "Nuevo"
            , "2": "Usado"
            , "3": "Reparado"
            , "4": "Por reparar"
            , "5": "Dañado"
        }

        this.stateRequest = [
            'Creada'
            , 'Enviada'
            , 'Tratada'
            , 'Cancelada'
            , 'Rechazada'
        ]

        this.indicators = {
            titles: [
                `Indicadores`
            ]
            , graphics: [
                `1. Estado del proyecto de catalogación.`
                , `2. Top de grupo maestro de producción.`
                , `3. Top de productos.`
                , `4. Estado del proyecto de catalogación (Gráfico de torta).`
                , `5. Informe tipos de solicitud de catalogación - día a día.`
                , `6. Informe de catalogación del proyecto.`
                , `7. Informe de catalogación día a día.`
                , `8. Informe estado de catalogación día a día.`
                , `9. Informe top usuarios solicitantes.`
                , `10. Informe de solicitudes por inspección.`
            ]
            , formIndicators: [
                `Indicadores a generar`
                , `[Seleccionar]`
                , `Inspección`
                , `Limite`
                , `Usuario`
                , `Fecha inicio`
                , `Fecha Fin`
                , `Generar indicador`
                , `Meta`
                , `Tipo solicitud`
            ]
            , graphicCataloginByProject: [
                `Promedio`
                , `Catalogado`
                , `Meta`
            ]
            , graphicRequestVsTry: [
                `Solicitudes tratadas`
                , `Solicitudes creadas`
            ]
            , componentGraphics: [
                `Gráfico no encontrado`
                , `Error al generar los graficos`
            ]
        }

        this.index = {
            title: [
                `Bienvenido a SLI-ERP`
                , `Software empresarial encargado de la catalogación y gestión de inventarios.`
            ]
        }

        this.modMaintenance = {
            menu: {
                general: [
                    `Panel de Control`
                    , `Administración`
                    , `Recepción`
                    , `Taller`
                    , `Devolución`
                    , `Indicadores/Reportes`
                ]
                , panelControl: [
                    `Configuración General Mantenimiento`
                    , `Configuración de usuarios`
                ]
                , managment: {
                    equipment: [
                        `Equipo`
                        , `Crear equipo`
                        , `Modificar equipo`
                        , `Reporte equipo`
                    ],
                    client: [
                        `Cliente`,
                        `Crear cliente`,
                        `Modificar cliente`,
                        `Reporte clientre`
                    ],
                    checklist: [
                        `Lista chequeo`,
                        `Crear lista chequeo`,
                        `Modificar lista chequeo`,
                        `Reporte lista chequeo`
                    ],
                    agreement: [
                        `Convenio`,
                        `Crear convenio`,
                        `Modificar convenio`,
                        `Reporte convenio`
                    ]
                    , price: {
                        name: 'Precio'
                        , listPrice: [
                            `Lista de precios`
                            , `Crear lista de precios`
                            , `Carga masiva de lista de precios`
                            , `Reporte lista de precios`
                        ]
                        , tempario: [
                            `Tempario`
                            , `Crear tempario`
                        ]
                    }
                    , kit: [
                        'Kits'
                        , `Crear Kits`
                        , `Modificar Kits`
                        , `Reporte Kits`
                    ]
                    , promocions: [
                        `Promociones`
                        , `Crear promociones`
                        , `Modificar promociones`
                        , `Reporte promociones`
                    ]
                }
                , reception: {
                    diary: [
                        `Agenda`,
                        `Crear agenda`,
                        `Calendario agenda`
                    ],
                    budget: [
                        `Presupuesto`,
                        `Crear presupuesto`,
                        `Modificar presupuesto`,
                        `Reporte presupuesto`
                    ]
                    , equipmentEntry: {
                        name: 'Entrada de equipo'
                        , createEquipmentEntry: [
                            `Crear entrada de equipo`

                        ]
                        , modifyEquipmentEntry: [
                            `Modificar entrada de equipo`
                        ]
                        , resultEquipmentEntry: [
                            `Reporte entrada de equipo`
                        ]
                    }
                }, workshop: {
                    workOrder: [`Orden de trabajo`],
                    distribution: [`Distribución`],
                    additionals: [`Adicionales`],
                    maintenance: [`Administración`, `Atención o.w.`]
                    , approval: ['Aprobación OT']
                    , attentionOT: [
                        `Mantenimiento`
                        , `Atención OT`
                    ]
                },
                return: {
                    schedule: [
                        `Cronograma`
                        , `Crear cronograma`
                        , `Modificar cronograma`
                    ]
                    , equipmentDelivery: [
                        `Devolución de equipos`
                        , `Devolución de equipo`
                        , `Modificar entrega de equipo`
                        , `Reporte devolución de equipo`
                    ]
                }
            }

        };
    }

    idiomaIngles() {
        this.arrEstadoFisicoMaterial = [
            "-Empty-"
            , "New"
            , "Used"
            , "Repaired"
            , "For repair"
            , "Damaged"
        ];
        this.arrTextEmail = [
            {
                asunto: "Creation of SLI User.", titulo: "Creation of SLI User.", body: `
                <p> <h3> Hi! <b> [name], </h3></b></p>
                <br>
                <p> Your user has been created successfully, the data to enter are as follows: </p>
                <p><b> URL:</b> [url] </p>
                <p><b> User:</b> [user] </p>
                <p><b> Password:</b> [password] </p>
                <p> If you have any questions or problems with entering the platform, please notify us by replying to this email <a href="sli.support@stockgi.com.">sli.support@stockgi.com</a>. </p>
                <br>
                <p> Regards,</p>
                <p> SLI support area.</p>
            `}
        ]

        this.arrTextEstadoReajuste = ["Yes", "No"];

        this.arrReporteTomaInventario = [
            "Report takes inventory"
            , "Select take inventory"
            , "Files added to the tool"
            , "The material was not reconciled"
        ];


        this.arrReajusteActivo = [
            "Reset active inventory" //1
        ];
        this.arrReajusteTuberia = [
            "Reset inventory" // 0
            , "Select reconciliation" //1
            , "General information of the inventory taking" //2
            , "Take inventory" //3
            , "of counts" //4
            , "of counts" //5
            , "Creation date" //6
            , "Responsible user" //7
            , "Product List" //8
            , "Difference" //9
            , "Conciliation" //10
            , "Files" //11
            , "To refuse" //12
            , "Reset Reset" //13
            , "Are you sure to make the reset?" //14
            , "The information was registered correctly" //15
            , "Error while trying to insert the rejected material to the reconciliation" //16
            , "Error updating the status of the reconciling material" //17
            , "Error trying to update reconciliation status" //18
            , "There are no reconciled materials to insert" //19
            , "Error creating movement" //20
            , "Error agreagr tube movement" //21
            , "Error updating status" //22
            , "Unknown error" //23
            , "Approver User" //24
        ];
        this.arrTextConteoIntTuberia = [
            "Counting takes inventory" // 0
            , "Warehouse" // 1
            , "Count No." // 2
            , 'To continue with the count click on the "Show counts" button and select the count' // 3
            , 'Show counts' // 4
            , 'There are no pending counts for you' // 5
            , 'Code. SLI' // 6
            , 'No. tube' // 7
            , 'Add to missing' // 8
            , 'Read code' // 9
            , 'List of counted products' // 10
            , "SLI code" // 11
            , "Desc. corta" // 12
            , "mod" //13
            , "Meters" //14
            , "Feet" //15
            , "Inch" //16
            , "Provider" //17
            , "Remove" //18
            , "Counts in process" //19
            , "Count" //20
            , "Warehouse" //21
            , "Creation date" //22
            , "Approver" //23
            , "Continue counting" //24
            , "Are you sure to remove the tube from the count?" //25
            , "The tube number must be unique" //26
            , "All fields are required" //27
            , "Are you sure to keep the count?" //28
            , "Are you sure to send the count?" //29
            , "The information was registered correctly" //30
            , "Error registering count" //31
            , "You must add at least one tube" //32
            , "The count to be modified has not been selected" //33
            , "Select currency" //34
            , "Price" //35
            , "Physical state" //36
            , "Uniform" //37
            , "Cannot create count. It may be because the shot already has the limit number of counts or one is in process" //38
            , "Plate - Serial" //39
        ];
        this.arrReporteConciInvExtTuberia = [
            "Reconciliation Report inv. ext. pipeline"
            , "to download"
            , "State"
        ];
        this.arrConciliacionExtTuberia = [
            "Reconciliation takes inventory" // 0
            , "Select reconciliation" // 1
            , "Add material" // 2
            , "Existence" // 3
            , "Files added to the tube" // 4
            , "file name" // 5
            , "Type of file" // 6
            , "Remove" // 7
            , "Error changing tube status" // 8
            , "You must enable reconciliation" // 9
            , "Unsupported file type, only the following types are supported: xlsx, png, pdf, jpg, docx, csv, txt, jpga, jpeg." // 10
            , "Error converting file, file may be defective" // 11
            , "Reconcile" // 12
            , "Are you sure to send the reconciliation" // 13
            , "You are sure to keep the reconciliation" // 14
            , "The reconciliation was saved successfully!" // 15
            , "Error trying to save the reconciliation" // 16
            , "Close" // 17
            , "Tube number" // 18
            , "You are already registered for the material" // 19
            , "The tube number must be unique" // 20
            , "All fields are required" // 21
        ];
        this.arrRepoInvtExtFile = [
            "Pipe count report" //0
            , "Take inventory" //1
            , "Responsible" //2
            , "Create count" //3
            , "General listing" //4
            , "You must select the physical inventory" //5
            , "Are you sure to cancel the count?" //6
            , "Are you sure to create the count?" //7
            , "You must fill in the fields marked with:*" //8
            , "Counting successfully canceled" //9
            , "Error canceling count" //10
            , "The count was created correctly" //11
            , "The physical inventory taking already has the maximum counts" //12
            , "Error creating count" //13
            , "Report of physical inventory count" //14
            , "Are you sure to cancel the physical inventory?" //15
            , "You must select the physical inventory" //16
            , "The physical inventory was successfully canceled" //17
            , "Error canceling physical inventory" //18
            , "Conciliation" //19
            , "Clean" //20
            , "The physical inventory is counting in process" //21
            , "Amount inv inv. ext. pipeline" //22
            , "Clean form" //23
            , "Conciliation No." //24
            , "The count cannot be created because the inventory is being reconciled." //25
            , "Are you sure to create the reconciliation?" //26
            , "The information was registered correctly!" //27
            , "The reconciliation cannot be created because it is already registered for taking inventory" //28
            , "The reconciliation cannot be created because it has counts in process" //29
            , "Error creating reconciliation" //30
        ];
        this.arrEstadoConteoInvTuberia = ["In process", "Finalized", "Canceled"];

        this.arrTextTomaInvTub = [
            "Physical taking of internal inventory" // 0
            , "Responsible" // 1
            , "The warehouse has the following count in progress" // 2
            , "Count" // 3
            , "Responsible" // 4
            , "Date" // 5
            , "Cancel" // 6
            , "save" // 7
            , "You must select warehouse" // 8
            , "You must select Responsible for the count" // 9
            , "There is a count in progress, to create a new count you must finish or cancel the current one" // 10
            , "Are you sure you create the physical inventory?" // 11
            , "Are you sure to cancel the physical inventory?" // 12
            , "The procedure for successful physical inventory was created" // 13
            , "Error trying to save count" // 14
            , "The count was canceled successfully" // 15
            , "Error while trying to cancel the count" // 16
            , "The selected warehouse does not have inventory" // 17
            , "Physical inventory taking No." // 18
            , "Remove" // 19
            , "Are you sure to cancel the count?" // 20
            , "Filter in warehouse to check the physical inventory you want to cancel" // 21
            , "Inventory physical withdrawal was canceled successfully" // 22
            , "Take external pipe inventory" // 23
            , "Open the file with a spreadsheet application and fill it with the required information" // 24
            , "Select the user responsible for the first count" // 25
            , "On the line" // 26
            , "ERP code value is needed" // 27
            , "the value of either description is needed" // 28
            , "the base unit of measure value is needed" // 29
            , "any value in plant, center or warehouse is needed" // 30
            , "the tube number value is needed" // 31
            , "the measured value in meter, foot or inch is needed" // 32
            , "the tube number has already been entered" // 33
            , "there was an unknown error" // 34
            , "Counting takes inv. internal" // 35
            , "Conciliation takes inv. int. pipeline" // 36
            , "Create count" // 37
            , "General report" // 38
            , "You cannot create a count or a new take because the warehouse has shots in process or has the maximum number of counts assigned" // 39
            , "Error while trying to cancel physical inventory" // 40
            , "Cancel takes inventory" // 41
            , "Clean form" // 42
        ];

        this.arrTextTomaInvTubExt = [
            "External physical inventory pipe" // 0
            , "Counting takes inventory" // 1
            , "Count No." // 2
            , 'To continue with the count click on the "Show counts" button and select the count' // 3
            , "Show counts" // 4
            , "There are no pending counts for you" // 5
            , "Filter the material" // 6
            , "Filter the material by all values ​​separated by*" // 7
            , "Clean" // 8
            , "Read code "// 9 
            , "Add" // 10
            , "Tell" // 11
            , "ERP code" // 12
            , "No. tube" // 13
            , "Metro" // 14
            , "At" // 15
            , "Inch" // 16
            , "Desc. wide" // 17
            , "Desc. corta" // 18
            , "Maker" // 19
            , "Reference" // 20
            , "UMB" // 21
            , "Uniform" // 22
            , "Unit cost" // 23
            , "Tax" // 24
            , "Provider" // 25
            , "Physical fitness" // 26
            , "Plant" // 27
            , "Center" // 28
            , "Warehouse" // 29
            , "Location" // 30
            , "Observation" // 31
            , "Field 1" // 32
            , "Field 2" // 33
            , "Field 3" // 34
            , "Approver" // 35
            , "Counts in process" // 36
            , "Creation date" // 37
            , "Creation date" // 38
            , "Continue counting" // 39
            , "Add material" // 40
            , "Attention" // 41
            , "You must enter the ERP code" // 42
            , "You must enter the tube number" // 43
            , "The tube number is already registered for this inventory take" // 44
            , "You must enter at least one description" // 45
            , "You must enter the base unit of measure" // 46
            , "You must add plant, center or warehouse" // 47
            , "You must enter the meter, foot, or inch" // 48
            , "Are you sure to keep the count?" // 49
            , "Are you sure to send the count?" // 50
            , "The information was registered correctly" // 51
            , "Error registering count" // 52
            , "You must add at least one tube" // 53
            , "The count to be modified has not been selected" // 54
            , "No. takes physical inventory" // 55
        ]









        this.textReferencia = "Reference(s)";
        this.textFabricante = "Maker";

        this.correcionSli = [
            'Filter',
            'Save',
            'Cancel',
            'Close',
            'Select cataloger',
            'Search old code',
            'Search SLI code',
            'Search ERP',
            'Remember?',
            'Create',
            'Modify',
            'Inactive',
            'Created',
            'Rejected',
            'Treated',
            'Sent',
            'Canceled',
            'Select',
            'Text',
            'Drop down list',
            'Group, only option',
            'Group, multiple selection',
            'Date',
            'Broad text'
            , `Request`
            , `Search request`
            , `Update`
        ];

        this.arrReporteActTub = [
            `Num. pipe`
            , `Meters`
            , `Feets`
            , `Inch`
            , `PIPE RETURN REPORT`
            , `TOOL RETURN REPORT`
            , `Board`
            , `Serial`
            , `Tax`
            , `Discount`
            , `Creation date`
            , `Remission`
            , `Pipeline return report`
            , `Tool return report`
            , `Tool/Asset Return`
            , `The plate or serial is already registered for the material` //15
        ];

        this.arrTextoDevolucion = [
            `Movement type`
            , `Movement products`
            , `Source warehouse`
            , `Target warehouse`
            , `Cost center`
            , `Branch office`
            , `Target`
            , `Invoice`
            , `Credit note`
            , `Quant. mov.`
            , `Quant. ret.`
            , `Quant. stock`
            , `No results were found with the indicated parameters`
            , `Must select type of movement and warehouse`
            , `Error entering movement. This can be caused because there is not enough inventory, the amount returned is greater than the amount of the movement or the sum of the amounts returned is greater than the amount of the movement in the following codes`
            , `Return`
            , `Are you sure to make the return? `
            , `Create return`
            , `Return Report`
            , `Of` /* En inglés sería OF */
            , `Supplier`
            , `Type Mov.`
            , `Source plant`
            , `Source center`
            , `Target plant`
            , `Target center`
            , `Reserve`
            , `Quant. mov.`
            , `Quant. ret.`
            , `Cant. New`
            , `Cant. used` //29
            , `Cant. repaired` //30
            , `Movement Amount` //31
            , `Refund Amount` //32
        ];

        this.arrTextoBaja = [
            `Low reason`
            , `Create low`
            , `Modify low`
            , `Unsubscribe report`
        ];
        this.arrTextMotivoBaja = [``, `Sale`, `Obsolescence`, `Lost`];
        this.arrTextoTipoMov = [``, `Entry`, `Output`, `Return`, `Transfer`, `Low`, `Assignment`];

        this.arrSalidaReporteProducto = [
            `Departure Report`
            , `Create output`
            , `Cód. movement`
            , `Warehouse`
            , `Desc. short`
            , `Quantity`
            , `State`
            , `Cost center`
            , `Branch office`
            , `Target`
            , `Creation date`
            , `Request`
            , `Observation`
        ];

        this.cargaMasivaSocket = [
            "Warning",
            "Are you sure to cancel process?"
        ];

        this.tipoMovimientoBaja =
            [
                "Obsolete product (Obsolensence)",
                "Lost Product (Lost)",
                "Product sold (Sale)",
            ];

        this.alertaInactividad = [
            `Close due to inactivity!`
            , `Application will close in`
            , `seconds.`
        ]

        this.arrSalidaReservaProducto = [
            "Reserve departure"
            , "Source warehouse"
            , "Cost center"
            , "Branch office"
            , "Target"
            , "Applicant"
            , "Approver"
            , "Approver comments"
            , "ERP code"
            , "Quant. requested"
            , "Quant. delivered"
            , "Quant. missing"
            , "There is no reservation with the information entered, the reservation may no longer be available "
            , "Info. Inventory"
            , "Break free"
            , "Provider"
            , "Price"
            , "Cant. avail."
            , "Pack off"
            , "No reservation was found with the indicated parameters"
            , "BOOKING INFORMATION REPORT"
            , "Amount entered exceeds that requested by the reservation"
            , "No changes have been made"
            , "Are you sure to make output?"
            , "Are you sure to creat output?"
            , "No action was taken due to the following mistake(s)"
            , "The amount you entered is greater than that requested in the SLI code:"
            , "There is not enough inventory for the amount entered in the SLI code:"
            , "The movement was registered correctly with the code:"
            , "Create"
            , "Target warehouse"
            , "Must fill in the necessary information to carry out the movement"
            , "Required fields to make the reservation attention"
            , {
                salidaReservaHerramientas: [
                    `Serial`
                    , `Plaque`
                    , `Provider`
                    , `Price`
                    , `Dispatch`
                ]
                , salidaReservaTuberia: [
                    `No. Pipe`
                    , `Measures`
                    , `Provider`
                    , `Price`
                    , `Dispatch`
                    , `Meter(s)`
                    , `Feet(s)`
                    , `Inch(s)`

                ]
            }
        ];

        this.arrImporteCaracteristica = [
            "Upload massive feature"
            , "Feature list"
            , "Generate with all products"
            , "Class"
            , "Code"
            , "Abbreviation"
            , "Group"
            , "Material type"
            , "Generate file"
            , "Select file"
            , "Upload"
            , "Cancel"
            , "Import results"
            , "Print"
            , "Return to import"
            , "Error saving the import"
            , "All changes were discarded due to the following errors: <br>"
            , "• There are incomplete observations of the feature of the line"
            , "• There is feature with incomplete translations on the line "
            , "• Unknown error when inserting feature, can be caused by special characters in the text or file modifications on the line"
            , "• Error while trying to check the feature index on the line "
            , "• Error inserting feature into line"
            , "Warnings"
            , "• Line feature "
            , " Already registered. <br>"
            , "Excellent!"
            , "They have registered ("
            , "). Following lines were not inserted: <br>"
            , "• Line feature "
            , " Already registered. <br>"
            , "All features have been registered successfully"
            , `Restart Search`
        ];

        this.arrTituloImpMas = [
            "idRequestType"
            , "toxicCode"
            , "ERPcode"
            , "SLIcode"
            , "productDescription"
            , "specificApplication"
            , "numberPart"
            , "typeApplyEquipment "
            , "Maker"
            , "modelApply"
            , "NFPAclassification"
            , "umb"
            , "anotherInfo"
            , "location"
        ];

        this.arrTituloImpCarac = [
            "idClass"
            , "Class"
            , "Code"
            , "Abbreviation"
            , "Group"
            // , "tipoMaterial"
            , "Multivalue"
        ];

        this.arrImporteSolicitud = [
            "Upload massive request"
            , "Request report "
            , "Generate and download the file by clicking"
            , "Here"
            , "Enter the necessary information taking into account what is the id type information provided in the same file with its respective description and information "
            , "IMPORTANT:"
            , " • Do not modify the basic structure of the file."
            , " Generate the file every time you import, because dynamic fields can be deactivated or deleted."
            , "Error"
            , "Modifications due to errors in the following lines were discarded: <br/>"
            , " • Error inserting the line product:"
            , " • Id in the dynamic values does not exist on the line: "
            , " • In the required dynamic fields no values were added on the line:"
            , "Excellent!"
            , "Upload was successful"
            , "Registers"
            , " • The file that is generated has two tabs to obtain information: Catalog and location"
        ]

        this.arrActBtn = [
            "Edit"
            , "Enable/Disable"
            , "Delete"
            , "Cancel"
            , "Basic information"
        ];
        this.arrAsignacionAlmacen = [
            "Assign warehouse"
            , "User selection"
            , "Assign main warehouses"
            , "Assign target warehouse"
            , "Add warehouse"
            , "Save"
            , "Are you sure to delete the assignment to the warehouse?, these changes will be taken when you click save"
            , "Warehouse is already registered"
            , "ASSIGNED WAREHOUSES REPORT"
            , "Select an user"
            , "Must assign at least one warehouse to the user"
            , "Are you sure to save register?"
            , "Error deleting assignment"
            , "Information was registered correctly"
            , "Are you sure to cancel register"
        ];

        this.arrAtencionReserva = [
            "Attention Reservation"
            , "Reservation list"
            , "Plant"
            , "Center"
            , "Warehouse"
        ];
        this.arrAtencionReservaHerramientas = [
            "Attention Reservation"
            , "Reservation list"
            , "Plant"
            , "Center"
            , "Warehouse"
            , {
                alertas: [
                    `There is not inventory in the next source place:`
                    , `There is not material inventory`
                    , `Have already completed the amount by the reservation`
                ]
                , modal: [
                    `Realized`
                ]
            }
        ];
        this.arrCreadorAprobador = [
            "Assign creators and approvers "
            , "User selection"
            , "Add creators"
            , "Add approvers"
            , "Add warehouse"
            , "Accounting"
            , "Creator"
            , "Approver"
            , "Add user"
            , "Users Added "
            , "Select warehouses to which you want to give permission"
            , "Plant"
            , "Center"
            , "Type adm."
            , "Warehouse"
            , "Action"
            , "Add cost center"
            , "Add branch office"
            , "Add Target"
            , "Must select a cost center"
            , "Cost center is already added"
            , "Must select a branch office"
            , "Branch office is already added"
            , "Must select a Target"
            , "Target is already added"
            , "User is already added"
            , "Are you sure to save or update register?"
            , "Must add at least one approver"
            , "Must add at least one warehouse"
            , "Must add at least one cost center"
            , "Must add at least one branch office"
            , "Must add at least one target"
            , "Must add at least one creator"
            , "Must select user"
            , "Must select creator or approver"
            , "Error updating information"
            , "Information was updated correctly"
            , "Are you sure to cancel process, changes have not been saved?"
        ];
        this.rolValidacion = [
            `! Error¡`,
            `The user`,
            `do not have access to this functionality`
            , "Error validating user access"
            , "Token not valid or expired"
        ]

        this.tituloSelectInspeccion = "Select inspection";

        this.arrTipoAdministracion = {
            AM: `Products management`
            , AA: `Asset management`
            , AT: `Pipeline management`
            , AH: `Tool/Assets management`
            , AS: `Service management `
        };

        this.login = [
            `Select`,
            {
                modalSelect: [
                    `Select a company`,
                    `Select`,
                    `Accept`,
                    `Cancel`,
                    `Company`,
                    `Language`
                ],
                alertBuscarIdioma: [
                    `Unrelated languages!`,
                    `Selected company does not have associated languages`,
                    `Accept`
                ],
                alertaIngresIdioa: [
                    `Language not selected`,
                    `To enter is neccessary select a language`,
                    `Accept`
                ],
                alertaSuccess: [
                    `Password restored, instructions will arrive in your email.`
                ],
                alertError: [
                    `Error performing password reset, contact system administrator.`
                ]
                , modalResetPasswod: [
                    'Password change'
                    , `New password`
                    , `Repeat password`
                    , `Save`
                    , `Cancel`
                    , `Password reset failed, please contact administrator.`
                    , `Passwords entered do not match`
                ]


            }
        ]

        this.tagsCustomSearch = [
            `Catalog query`
            , `Enter several paramters separated by *`
            , `Ask for any product master field`
            , `Create product master`
            , `SLI code`
            , `Information`
            , `ERP code`
            , `Product`
            , `Produc code`
            , `Type code`
            , `Type`
            , `Products group code  `
            , `Group`
            , `Abbreviation Gr. M.`
            , `Measurement unit code`
            , `Measurement unit `
            , `Value`
            , `Feature`
            , `Maker`
            , `Open. Maker`
            , `Reference`
            , `Searching . . .`
            , `Clic on show in table to list all results`
            , `Only results `
            , `Toxic code`
            , `Desc. long`
            , `Desc. short`
            , `Inspection`
            , `Comments`
            , `Toxic master`
            , `Request (toxic)`
            , `List result`
            , `Product code`
            , `Group code`
            , `Unit`
            , `Creator catalog`
            , `Creation date`
            , `Approver catalog`
            , `Approval date`
            , `Desc. old`
            , `Inspection comment`
            , `Quality state`
            , `Quality management`
            , `See in form`
            , `Product type`
            , `Management type`
            , `Creator/Modifier`
            , {
                modal: [
                    `Update ERP code`
                    , `Save`
                    , `Cancel`
                    , `Enter the ERP code...`
                    , `Are you sure you change the inspection status to material `
                    , `Yes`
                    , `No`
                    , `Confirmation`
                ]
                , tableModal: [
                    `Cod. SLI`
                    , `Short description`
                    , `Inspection`
                    , `Cod. Old`
                    , `Creator /Modifier`
                    , `ERP code`
                    , `Limit result`
                    , "Start date"
                    , 'End date'
                ]
                , alerts: [
                    `Good job`
                    , `Error`
                    , `Do you want to update the ERP code?`
                    , `Yes`
                    , `No`
                    , `ERP code updated successfully`
                    , `An error was generated when updating the SLI code`
                    , `Confirmation`
                    , `The selected request has an associated SLI code, do you want to obtain it?`
                    , `You have changed the product / request associated with this SLI code, do you want to update the material?`
                ]
            }
            , `UMB`
            , `Consult for SLI code, ERP code, name or code. material, name or code group, manufacturer or reference`
            , `Aditional descriptions`
            , `Cod. Anitiguo`
            , `Desc. Anitiguo`
            , `Search for material`
            , `Empty features` // 54
        ];

        this.arrTituloSolicitudToxico = [`numberPart`
            , `productDescription`
            , `specificApplication`
            , `typeEquipmentApp`
            , `maker`
            , `modelApply`
            , `Toxic code`
            , `Erpcode`
            , `Slicode`
            , `NfpaClassification`
            , `anotherInformation`
            , `UMBcoding`];

        this.arrMestroToxico = [
            `additionalData`
            , `reference`
            , `observation`
        ];

        // this.arrInspeccionMMateriales = [`Duplicado`, `Pendiente`, `Falto de information`, `Enviado a calidad`, `Select inspección`];
        // this.arrEstadoMMaterial = [`Aprobado`, `Rechazado`];
        this.arrInspeccionMMateriales = ["Duplicated", "Pending", "Information is missing", "Sent to quality", "Approved", "Rejected"];
        this.stateInspection = {
            "0": "Select"
            , "1": "Duplicate"
            , "2": "Pending"
            , "3": "Lack of information"
            , "4": "Sent to quality"
            , "5": "Approved"
            , "6": "Rejected"
        }
        this.arrEstadoMMaterial = ["Completed", "Incomplete"];
        this.arrInspeccionDinamicoSel = ["Approved", "Rejected"];

        this.arrAsignar = [
            {
                solicitud: [
                    `Assign Request`, `Click `, `here`, ` to generate and download file`
                ]
            },
            {
                tóxicos: [
                    `Assign toxic`
                ]
            }
        ];

        this.arrRolFrms = [
            {
                eliminarRol: [`Delete role`, `Select role to delete`, `Search: role name, module or permissions`
                    , `Role name`, ` Modules `, ` Permissions `, `No register found with the parameter:`, `The indicated role does not exist`, `Indicated role cannot be deleted because it is assigned to an user`
                    , `Are you sure to delete role?`]
            },
            {
                habilitarInhabilitarRol: [`Enable or disable role`, `Select role `
                    , `Role is not registered`, `Changes were made correctly`
                    , `Must define role state`, `Error while editing role`, `Are you sure of`, ` role`
                    , ` role, This role is assigned`, ` users`, ` <b class="cursor-pointer" id="printLog">See users</b>`]
            },
            {
                editarRol: [`Edit role`, `Select role`, `Select another role`
                    , `Basic information`, `Role name`, `Role state`, `Role permissions`
                    , `Select permission`, `Add permission`, `Permission name`, `Action`
                    , `Delete`, `No delete`, `Save`, `delete role`, `Cancel`, `Selected role`, `Are you sure to save changes?`
                    , `there was a mistake, changess were discarded `, `Registration updated successfully!`
                    , `Disabling this role will afect`, ` users.`, `See users`
                    , `Permission`, `This role does not have any permission`, `Add all`]
            },
            {
                imprimirListaUsuario: [`User report assigned to role`
                    , `Following users will be affected by the action since they are assigned to the role`
                    , `Full name`, `Email`]
            },
            {
                crearRol: [`Create role`, `Must select a permission`, `Role cannot be empty`, `Select role state`
                    , `Must add at least one permission`, `Error creating role, this error can be caused because there is a role with the same name`, `Error assigning role permission`
                    , `Role was registered successfully`]
            },
            {
                form_previsualizar_Rol: [
                    `Role`,
                    `State`,
                    `Permissions amount`,
                    `See more...`,
                    `Edit`,
                    `Enable`,
                    `Delete`,
                    `Cancel`,
                    `Registered`,
                    `Enable`,
                    `Disabled`,
                    `Disable`,
                    `Are you sure to enable role?`,
                    `Are you sure to disable role?`,
                    `Basic Information`,
                    `Module`,
                    `Permissions`,
                    `This role has users assigned`,
                    `Role was successfully deleted.`
                ]

            }
        ];
        this.arrUsuarioFrms = [
            {
                eliminarUsuario: [
                    `Delete user`,
                    `Select user to delete`,
                    `Search: name, surname, document or email`
                    , `Name (s)`
                    , `Surnames`
                    , `# document`
                    , `Email`
                    , `Are you sure to delete user?`
                    , `Cannot delete user because it does not exist in database`
                    , `User cannot be deleted`
                    , ` because it is assigned to a company`
                    , `User`
                    , `was successfully deleted`
                    , `Are you sure to delete the custodian ?`
                    , `Error deleting custodian`]
            },
            {
                habilitarInhabilitarUsuario: [
                    `Enable or Disable user`
                    , `Select field to Enable/Disable`
                    , ` User`
                    , `Select user`
                    , `Must define user state`
                    , `User cannot be delete because it is assigned to a company`
                    , `User`, `cannot save changes because user does not exist`
                    , `Are you sure of ¿Está seguro de`
                    , `` //falta
                ],
                alertError: [
                    `User`
                    , `is assigned to a company`
                    , `User does not exist`
                    , `Entered state is null or invalid`
                ],
                alertSuccess: [
                    `User`
                    , ` was `
                    , ` successful`
                ]
            },
            {
                editarUsuario: [`Select user`, `Select another user`]
            }
        ];

        this.arrUMBFrms = [
            {
                general: [
                    `Base measurement unit`,
                    `Base measurement unit report`,
                    `Base measurement unit report`,
                    `Create base measurement unit`,
                    `Edit base measurement unit`,
                    `Delete base measurement unit`]
            },
            {
                crearUMB: [
                    `UMB coding`,
                    `UMB`,
                    `Observation`,
                    `Translations`,
                    `Language`,
                    `Translation UMB`,
                    `Translation observation`,
                    `Language`,
                    `Translation in`,
                    `Must fill in the marked fields  with an asterisk`,
                    `Must complete translations to continue`,
                    `Base measurement unit was registered`,
                    `measurement unit cannot be created because a registration with the same code already exists And/or description.`,
                    `Attention`,
                    `Are you sure to create measurement unit?`,
                ]
            },
            {
                modificarUMB: [
                    `Edit Unit`,
                    `Select unit to edit`,
                    `Search: code or name`,
                    `Code`,
                    `Name`,
                    `Measurement unit updated successfully`,
                    `Error updating measurement unit, this error can be caused by duplication of codes`,
                    `Select another UMB`,
                    `Select unit to delete`,
                    `Are you sure to modify measurement unit?`
                ]
            },
            {
                eliminarUMB: [
                    `UMB code`,
                    `UMB and translations`,
                    `Observation and translations`,
                    `Measurement unit was deleted correctly`,
                    `There was an unknown error when trying to delete the base measurement unit`,
                    `Measurement unit is being used in cataloging request`,
                    `Measurement unit is being used in material master`,
                    `Measurement unit does not exist`,
                    `Are you sure to delete measurement unit?`,
                ]
            },
            {
                tablaUMB: [
                    `Id`,
                    `UMB coding`,
                    `Base measurement unit`,
                    `Observation`,
                    `Language`,
                    `Base measurement unit report`
                ]
            }
        ];

        this.arrInputFrmSolicitud = [
            {
                crearCampo: [
                    `Create request field`,
                    `Add field`,
                    `Field Report`,
                    `Select field type`,
                    `Field feature`,
                    `Field title`,
                    `Field items`,
                    `Field title E.g.: Name (s)`,
                    `Item Name E.g.: Citizenship card`,
                    `Enter field title`,
                    `Must add at least one item to the field, if field is not multiple selection choose another`,
                    `Enter item name`,
                    `There is already an item for the field with the same name`,
                    `Type fields`,
                    `Field title`,
                    `Is it required?`,
                    `Preview`,
                    `Action`,
                    `There are no fields added`,
                    `Ok`,
                    `Fields Request Report`,
                    `Are you sure to create field?`,
                    `There is no field added to save!`,
                    `Current changes have not been saved, do you want to discard them?`,
                    `Cannot save field, may have already registered a field with the same name`,
                    `Field was registered correctly`,
                    `Some empty fields were found, modify them and try again`,
                    `Desc. informative`,
                ]
            },
            {
                tablaCampos: [
                    `Request Field Report`,
                    `Id`,
                    `Creation date`,
                    `Creation time`
                ]
            },
            {
                modificarCampos: [
                    `Edit Request Field`,
                    `Select field`,
                    `Search: title or field type`,
                    `Save`,
                    `Disable`,
                    `Enable`,
                    `Delete`,
                    `Cancel`,
                    `Request fields list`,
                    `Are you sure you modify field?`,
                    `Are you sure to disable field?`,
                    `Are you sure to activate field?`,
                    `Are you sure to delete field? `,
                    `Cannot add item, update page and try again`,
                    `Error`,
                    `An item with the same name already exists`,
                    `Attention`,
                    `Must add an item to the field, those that are going to be deleted or inactivated`,
                    `Must add all field translations`,
                    `Must add all translations of the field items`,
                    `Must add at least one item to the field`,
                    `Error modifying the field, this field or its items may be in use`,
                    `Field changed successfully`,
                    `Error updating field state`,
                    `Field state updated successfully`,
                    `Error deleting field, may be in use`,
                    `Field deleted successfully`,
                ]
            }
        ];

        this.arrSerResImpoMas = [
            `This error can be caused by the following reasons.<br>&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;Misallocation of values ​​in the file.<br><br>&nbsp;&nbsp;&nbsp;&nbsp;If you are sure that these errors do not apply please contact StockGI.`
            , `File reading error`
            , `This error can be caused by the following reasons.<br>○&nbsp;&nbsp;File structure modification.<br>○&nbsp;&nbsp;Misallocation of values ​​in the file.<br><br>If you are sure that these errors do not apply please contact StockGI.`
            , ``
        ];

        this.arrMenu = [
            `Planning`,
            `Inventories`,
            `Purchases`,
            `Hiring`,
            `Suppliers`,
            `Logistics`,
            `QA/QC`,
            `Maintenance`
        ];
        this.valueSeleccion = ` -- Select -- `;
        this.indicadoresCatalogacion = [
            `Indicators Cataloging`,
            `Search:`,
            {
                steps: [
                    `Client`,
                    `User`,
                    `Date from`,
                    `Date to`,
                    `Search`,
                    `Results`
                ],
            },
            {
                steps1: [
                    `1. Instructive`,
                    `2. Upload and validation`,
                    `3. Load log`
                ],
            },
        ];

        this.opcionesgen = [``

        ];

        this.asignarEmpresaUsuario = [
            {
                title1: `Attention`,
                title2: `Information`,
                title3: `There was a mistake`,
                title4: `Excellent`,
                text1: `User has already been assigned a company`,
                text2: `Must select company`,
                text3: `Must select user`,
                text4: `Current changes have not been saved, Do you want to discard them?`,
                text5: `You have not made any changes!`,
                text6: `Are you sure you want to save the changes made?`,
                text7: `Changes saved!`,
                type1: `Info`,
                type2: `Question`,
                type3: `Error`,
                type4: `Success`,
                confirmButtonText: `Ok`,
                errorInfo1: `. If you cannot solve error as an user, contact us`,
                errorInfo2: `No changes were made, because `,
                errorInfo3: `Happened `,
                errorInfo4: `Occurred `,
                errorInfo5: ` Errors in #`,
                errorInfo6: ` Error in the #`,


            }
        ];

        //Campos menu lateral
        this.nombremodulos = [`Administrative`, {
            submod: [
                `Role`,
                `Company`,
                `User`,
                `Report`,
                // `Consulta clases`,
                `Catalog query`,
                `Assign User`,
                `Assign company to user`
                , `Indicators`
                , `Reports GEB`
            ],
            reporsub: [`Log`]
        },
            {
                subEmpresa: [
                    `Company Report`,
                    `Create company`,
                    `Edit Company`,
                    `Delete company`,
                    `Enable /Disable company`,
                    `Upload massive company`,
                    `Assign language`
                    , `Assign Currency`
                ]
            },
            {
                subUsuario: [
                    `User report`,
                    `Create user`,
                    `Edit user`,
                    `Delete user`,
                    `Enable/Disable user`,
                    `Upload massive user`,
                    `Assign user`
                ]
            },
            {
                subRol: [
                    `Role report`,
                    `Create role`,
                    `Edit role`,
                    `Delete role`,
                    `Enable/Disable role`,
                    `Upload massive role`
                ]
            }
        ];

        //Campos formulario empresa
        this.formularioempresa = [
            // `Formulario de Empresa`,
            `Create company`,
            `Return`,
            {
                sec1: [`Company data`, `Location data`, `Contact information`]

            },
            `NIT`,
            `Company name`,
            `Comercial activity`,
            `CIIU code`,
            {
                modciiu: [`Economic activity`, `Section`, `Division`, `Group`, `Class`, `Search CIIU code`, `Close`, `Save`],
                sec2: [`Country`, `Department`, `Municipality`, `Postal code`, `Address`, `Complement`],
                sec3: [`Personal contact`, `Email`, `Phone`, `Extension`, `Cell phone`, `currency`, `Language`, `Select`],
                acciones: [`Add company`, `Cancel`],
                modalemp: [`Add users`, `Load users by choice`, `Upload files by flat file`, `Select users to assign`, `Download guide file`, `here`]
            },
            {
                modalUsuario: [`Save`, `Close`],
                error_dosModos: [`Error!`, `Must only use one of two ways to load users`],
                error_usuarioIndividual: [`Error!`, `An error was generated when registering users`]
            },
            {
                eliminarEmpresa: [
                    `Delete company`,
                    `Select company to delete`,
                    `Search: nit, name or mail`,
                    `No registration found with the parameter: `,
                    `Nit`,
                    `Name`,
                    `Mail`,
                    `Nit: `,
                    `Name: `,
                    `Mail: `,
                    `Delete`,
                    `Cancel`,
                    `Are you sure to delete company?`,
                    `Good job`,
                    `Company successfully deleted `,
                    `Error!`,
                    `This company does not exist in the database`,
                    `Cannot delete this company because it is assigned to an user`,
                    `Select another company`,
                    `Select company`,
                    `Edit`,
                    `Enable`,
                    `Disable`,
                    // `Se realizaron los cambios correctamente a la empresa `
                ]
            },
            {
                habilitarInhabilitarEmpresa: [
                    `Enable or disable company`,
                    `Select company`,
                    `Are you sure of `,
                    ` Company `,
                    ` Enable `,
                    ` Disable `,
                    `An unknown error occurred.`,
                    `Company `,
                    ` has associated users`,
                    `The new state of the company `,
                    ` is`
                    , `Error changing company state `
                ],
            },
            {
                form_previsualizar_empresa: [
                    `Company`,
                    `Nit`,
                    `E-Mail`,
                    `Personal contact`,
                    `Address`,
                    `Phone`,
                    `Edit`,
                    `Enable`,
                    `Delete`,
                    `Cancel`,
                    `State`,
                    `Registered`,
                    `Enabled`,
                    `Disabled`,
                    `Disable`,
                    `Enable`,
                    `Disable`,
                    `Basic information`
                ]
            }
        ];

        //Tabla empresa
        this.tablaempresa = [
            `Company report`,
            `Load company`,
            `Create company`, {
                col: [
                    `Id company`,
                    `Nit`,
                    `Company name`,
                    `Address`,
                    `City`,
                    `Contact`,
                    `Email`,
                    `Phone`,
                    `Extension`,
                    `Cell phone`,
                    `Action`,
                    `State`,
                    `Languages`,
                    `Currency`
                    , `Creator/Modifier`
                ]
            }];

        //Tabla usuario
        this.tablausuario = [`User report`, `Load user`, `Create user`, {
            col: [`Id`, `Full name`, `Document type`, `Document number`, `Mail`, `Address`, `Municipality`, `Phone`, `Extension`, `mobile phone`, `Company name`, `Appointment`, `Action`, `State`, `Role`]
        }];

        //Tabla custodio
        this.tablacustodio = [`Custodian report`, `Load custodian`, `Create custodian`, {
            col: [`Id`, `Full name`, `Position`, `Document type`, `Document number`, `Mail`, `Address`, `Phone`, `Mobile phone`]
        }];

        //Treeview Rol
        this.treeviewrol = [`Role Creation`, `Add Role`, `Close`, `Save`];

        this.consultaMaeriales = [`Class query`,];
        //Tabla Roll
        this.tablarol = [`Roles Report`, `Create Role`,
            {
                col: [`Id Role`, `Role`, `Module`, `Permission`, `Action`, `State`]
            },
        ];

        this.formEmpresaArray = { textoInpSearchCIIU: `Search CIIU code` };

        //Formulario usuario
        this.formulario = [`User form`, {
            sec1: [`Personal information`, `Contact information`, `Permissions`],
        },
            `Name`,
            `Surname`,
            `Document type`,
            `Document number`,
            `Password`, {
                sec2: [`Mail`, `Address`, `Country`, `Department`, `Municipality`, `Postal Code`, `Cell phone`, `Phone`, `Extension`, `Password`],
                sec3: [`Company`, `Appointment`, `Role`],
            }];

        //Wizard Cargue empresa
        this.wizardcargaempresa = [`Upload massive companies`,
            {
                steps: [`1. Instructive`, `2. Upload and validation`, `3. Load log`],
            },
            `Download file`, `companies.xls`,
            {
                step1: [`Open file "companies.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account those that are required:`,
                    `a. Field "companyName" is required.`,
                    `b. Field "companyNit" is required. `,
                    `c. Field "companyAddress" is required. `,
                    `d. Field "addressComplement " is required. `,
                    `e. Field "idPostalCode" is required. `,
                    `f. Field "companyContact" is required. `,
                    `g. Field "companyEmail" is required. `,
                    `h. Field "companyPhone" is required. `,
                    `i. Field "idCurrency" is required. `,
                ]
            },
            `Save file to your computer`,
            {
                step2: [`Select file`, `Basic file fnformation`, `File`, `# rows`, `Modification`, `Size`, `Action`, `Click on the + to attach the file`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: [`Next, necessary data for loading the information will be displayed..`, `Information of`, `Control`, `Id to register`, `Ids Postal Code`, `Ids currency`, `Select Postal Code`, `Select currency`]
            }];

        this.wizardtoxicos = [`Loading Old Catalog`,
            {
                steps: [
                    `1. Instructive`,
                    `2. Upload and validation`,
                    `3. Load log`,
                    `Description List`
                ],
            },
            `Download file`, `oldMaterials.xls`,
            {
                step1: [`Open file "oldMaterials.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`,
                    `a. Field idCataloger is required`,
                    `b. Field codToxic is required`,
                    `c. Field reference is required`],
                step2: [
                    `a. Field companyCode is required`,
                    `b. Field creationDate is required`,
                    `c. Field userCreation is required`,
                    `c. Field idCatalogingUser is required`
                ],
                step3: [
                    `a. Field idCataloger is required`,
                    `b. Field ToxicCode is required`,
                    `c. Field reference is required`
                ]
            },
            `Save file to your computer`,
            {
                step2: [`Select file`, `Basic file information`, `file`, `# rows`, `Modification`, `Size`, `Action`, `Click on the + to attach file`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: [`Old code individual loadig`]
            }];

        this.wizardtoxicoIndividual = [`Upload individual toxic`,
            {
                steps: [`1. Instructive`, `2. Upload and validation`, `3. Load log`],
            },
            `Download file`, `oldMaterials.xls`,
            {
                step1: [`Open file "oldMaterials.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`, `a. Field Name, Length: X, Variable type: Y`, `b. Field Name, Length: X, Variable type: Y`, `c. Field Name, Length: X, Variable type: Y`]
            },
            `Save file to your computer`,
            {
                step2: [`Select file`, `Basic file information`, `File`, `# rows`, `Modification`, `Size`, `Action`, `Click on the + to attach file`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: [`Old code individual loading`]
            }];
        this.wizardMaterial = [`Upload massive classes`,
            {
                steps: [`1. Instructive`, `2. Upload and validation`, `3. Load log`],
            },
            `Download file`, `classes.xls`,
            {
                step1: [`Open file "classes.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`, `a. Field Name, Length: X, Variable type: Y`, `b. Field Name, Length: X, Variable type: Y`, `c. Field Name, Length: X, Variable type: Y`]
            },
            `Save file to your computer`,
            {
                step2: [`Select file`, `Basic file information`, `File`, `# rows`, `Modification`, `Size`, `Action`, `Click on the + to attach file`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: []
            }];


        this.wizardcargausuario = [`Upload massive user`,
            {
                steps: [`1. Instructive`, `2. Upload and validation`, `3. Load log`],
            },
            `Download file`, `user.xls`,
            {
                step1: [
                    `Open file "user.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`,
                    `a. Field "idRole" is required.`,
                    `b. Field "idTypeDocument" is required.`,
                    `c. Field "idPostalCode" is required.`,
                    `d. Field "Name(s)" is required.`,
                    `e. Field "Surnames" is required.`,
                    `f. Field "documentNumber" is required.`,
                    `g. Field "email" is required.`,
                    `h. Field "password" is required.`,
                    `i. Field "address" is required.`,
                    `j. Field "phone or cellphone" is required.`,
                    `k. Field "password" is required.`,
                ]

            },
            `Save file to your computer`,
            {
                step2: [`File Upload`, `Multiple Files`, `Registration date:`, `Registration number found:`, `Name`, `Size`, `Progress`, `Attach file`, `Actions`, `Progress:`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: [`Next, necessary data for loading the information will be displayed.`, `Ids session state`, `Ids user states`, `Ids role`, `Ids type document`, `Ids postal code`, `Information of`, `Control`, `Id to register`, ` Select session state `, ` Select user state`, ` Select role `, ` Select T.D. `, ` Select postal code `]
            }];

        this.wizardasignacion = [`Upload massive toxic`,
            {
                steps: [`1. Instructive`, `2. Upload and validation`, `3. Load log`],
            },
            `Download file`, `oldMaterials.xls`,
            {
                step1: [`Open file "materialesAngituos.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`, `a. Field Name, Length: X, Variable type: Y`, `b. Field Name, Length: X, Variable type: Y`, `c. Field Name, Length: X, Variable type: Y`]
            },
            `Save file to your computer`,
            {
                step2: [`Select file`, `Basic file information`, `File`, `# rows`, `Modification`, `Size`, `Action`, `Click on the + to attach file`, `Upload`, `Cancel`, `Delete`, `Upload all`, `Cancel all`, `Delete all`]
            },
            {
                step3: []
            }];

        //form Empresa
        this.formEmpresa = [
            {
                modal:
                    [
                        `Add uers`,
                        `Load users by choice`,
                        `Upload files by flat file`,
                        `Select users to assign`
                    ],
                alerts: [
                    `Confirm`,
                    `Do you want to create company? `,
                    `Yes`,
                    `No`,
                    `Action successfully completed`,
                    `Error`,
                    `Successful operation`,
                    `Ok`

                ]
            }];



        //tabla log
        this.titleTableLog = `log table`

        //login
        this.titulo = `Login`;
        this.remember = `Remind me`;
        this.forgetPswd = `Forgot your password?`;
        this.signIn = `To access`;
        this.signUp = `Check in`;
        this.stepSignUp = `Follow the next steps for registration`;
        this.agree = `I accept the`;
        this.termsAndCond = `terms and Conditions`;
        this.regainPaswd = `Recover password`;
        this.enterEmailRecovery = `Enter an email to recover your password`;
        this.inpEmail = `Email`;
        this.inpPasswd = `Password`;
        this.requestButton = `Request`
        this.cancelButton = `Cancel`
        this.dontAccount = `Do not you have an account yet?`;
        this.primaryText = `Join Our Community`;
        this.secondaryText = `Lorem ipsum dolor sit amet, coectetuer adipiscing<br>
        elit sed diam nonummy et nibh euismod`;
        this.mensajeInicioSesion1 = `We are a team...`;
        this.mensajeInicioSesion2 = `and together we can change the world.`;

        this.errorLogin = `User or password you entered is incorrect, please correct them!`;

        //Input from register
        this.inpNumberDocumentUser = `Document number`;
        this.inpNameUser = `Names`;
        this.inpLastnameUser = `Surnames`;
        this.inpEmailUsuario = `Email`;
        this.inpPasswordUser = `Password`;
        this.inpPasswordValidation = `Confirm your password`;
        this.inpAddressUser = `Address`;
        this.inpPhoneUser = `Phone`;
        this.inpExtUser = `Ext`;
        this.inpMovilUser = `Cell phone number`;
        this.inpCompanyUser = `Company name`;
        this.inpAppointmentUser = `Appointment`;
        this.inpAuthorizePerson = `Authorizing Person`;
        this.inpEmailAuthorizePerson = `Email authorizing person`;

        //Opciones genericas
        this.selectDocumentType = `Document type`;
        this.fieldSelect = `[Select...]`;
        this.selectCountry = `Country`;
        this.selectDepartament = `Department`;
        this.selectMunicipaly = `Municipality`;
        this.selectPostalCode = `Postal code`;
        this.fieldRequire = `Required or invalid field`
        this.btnBack = `Return`;

        this.asociarIdiomaEmpresa = [
            `Associate language with companies`,
            {
                formulario: [
                    `Companies`,
                    `Languages`,
                    `Save`,
                    `Cancel`,
                    `Select`,
                    `Actions`,
                    `Return`
                ],
                alertDuplicado: [
                    `Duplicity Error`,
                    `Association you want to make is already in the list`,
                    `Accept`
                ],
                alertaConfirmacion: [
                    `Confirm`,
                    `Are you sure you save the data?`,
                    `Accept`,
                    `Cancel`
                ],
                alertaConfirmarEliminar: [
                    `Confirm`,
                    `Are you sure do you want to delete this register?`,
                    `Accept`,
                    `Cancel`
                ],
                alertSuccess: [
                    `Good job`,
                    `Registrations were made successfully`,
                    `Accept`
                ],
                tablaAsignaciones: [
                    `Relationships by company`,
                    `Associations to be made`,
                    `Registers not found`
                ],
                alertVerificarIdioma: [
                    `Error`,
                    `This company already has this registered language`,
                    `Accept`
                ],
                alertaEliminarIdiomaActual: [
                    `Error`,
                    `The language you want to delete is in use, please change it`,
                    `Accept`
                ],
                alertEliminarSuccess: [
                    `Good job`,
                    `Registration successfully deleted`,
                    `Accept`
                ]
            }
        ]


        this.associate_divisa_to_company = [
            `Associate company currency`,
            {
                formulario: [
                    `Companies`,
                    `Currency`,
                    `Save`,
                    `Cancel`,
                    `Select`,
                    `Actions`,
                    `Return`
                ],
                tablaAsignaciones: [
                    `Relationships by company`,
                    `Associations to be made`,
                ]
                , alerts: [
                    `General error`
                    , `Failed to get server information`
                    , `Failed to add the selected currency`
                    , `No modification has been made`
                    , `Good job`
                    , `Actions performed successfully`
                    , `Cannot delete all the currencies associated with a company`
                    , `It was not possible to perform any of the requested actions`
                ]
                , table_log: [
                    `Conflicting Registers`
                ]
            }
        ]

        // Modulo Catalogación
        this.modCatalogacion = [
            `Cataloging`,
            {
                subMenu: [
                    `Control Panel`,
                    {
                        menu: [
                            `User`,
                            `Create Type`,
                            `Create Group`,
                            `Create Product`,
                            `Create Measurement Unit`,
                            `Create Maker`,
                            `Texts`,
                            `Day to day`,
                            {
                                subMenu: [`Request fields`]
                            },
                            `Request Fields`,
                            `Edit Type`,
                            `Edit Group`,
                            `Edit Product`,
                            `Edit Measurement Unit`,
                            `Edit Maker`,
                            `Type`,
                            `Group`,
                            `Measurement Unit`,
                            `Maker`,
                            `Product`,
                            `Product Master`,
                            `Edit Product`,
                            `Test`,
                            `Delete Group`,
                            `Group Report`,
                            `Upload group`,
                            `Upload group`,
                            `Delete Type`,
                            `Type Report`,
                            `Features`,
                            `Product Report`,
                            `Upload Massive Product`,
                            `Delete Product`,
                            `Value`,
                            `Create Features`,
                            `Delete Features`,
                            `Create Value`,
                            `Value Report`,
                            `Delete Maker`,
                            `Maker Report`,
                            `Features report`,
                            `Edit Features`,
                            `Edit Value`,
                            `Delete Value`,
                            `Upload massive maker`,
                            `Upload massive values`,
                            `Upload massive state`,
                            , `Create input`
                            , `Modify input `
                            , `Input report `
                            , `Create dispatch`
                            , `Modify dispatch`
                            , `Dispatch Report`
                            , `Create low`
                            , `Modify low`
                            , `Low report`
                            , `Create Transfer`
                            , `Modify Transfer`
                            , `Transfer Report`
                        ]
                    },
                    `Catalog`,
                    {
                        menu: [
                            `Upload catalog`,
                            `Create catalog`,
                            `Quality`,
                            `Assign`,
                            `Indicators`,
                            `Upload catalog`,
                            `Upload Old Description`,
                            `Old descriptions report`,
                            `Assign request`,
                            `Assign descriptions`,
                            `Quality management`,
                            `Quality management report`
                        ]
                    },
                    `Day to day`,
                    {
                        menu: [
                            `Cataloging request`,
                            `Cataloging request report`,
                            `Create cataloging request`,
                            `Edit Cataloging Request`,
                            `Attention`,
                            `Report`,
                            `General Report Requests`,
                        ]
                    },
                    `Reports`,
                ],
                formTipoMaterial: [
                    `Create Type`,
                    {
                        formulario: [
                            `Company`,
                            `Type code`,
                            `Select ...`,
                            `Type name`,
                            `Range`,
                            `Description`,
                            `Observation`,
                            `Save`,
                            `Cancel`,
                            `From`,
                            `To`,
                            `Translations`,
                            `This field must be larger`,
                            `This field must have at least 4 digits`,
                            `Type Report`,
                            `Administration`,
                            `Assets and tools`,
                            `Pipelines`,
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Type`,
                            `Observation`
                        ],
                        alertIdiomasError: [
                            `Languages not found`,
                            `Selected company does not have associated languages`,
                            `Accept`
                        ],
                        alertErrorGuardar: [
                            `Error loading translations`,
                            `Must enter translations required by the company`,
                            `Accept`
                        ],
                        alertErrorRepetido: [
                            `Error creating register`,
                            `The information you want to enter generates duplicity with the following register:`,
                            `Id Type: `,
                            `Code: `,
                            `Minimum range: `,
                            `Maximum range: `,
                            `Type name: `
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Type`,
                            `Accept`,
                            `Registered successfully`
                        ],
                        alertConfirmación: [
                            `Confirm`,
                            `Are you sure to create type? `,
                            `Yes`,
                            `No`
                        ],
                        alertNegativo: [
                            `Error`,
                            `Invalid ranges entered`
                        ]
                    }
                ],
                elimiarTipoMaterial: [
                    `Delete type`,
                    `Return`,
                    `Search: Type code,type or observation`,
                    `Basic information`,
                    `Type code`,
                    `Type name`,
                    `Observation`,
                    `Minimum range`,
                    `Maximum range`,
                    `Delete`,
                    `Cancel`,
                    `Select type to Delete`,
                    {
                        alertaConfirmar: [
                            `Confirm`,
                            `Are you sure to delete type?`,
                            `Yes`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Type`
                            , ` was successfully deleted`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`,
                            `Type`
                            , `cannot be deleted since it is assigned to a class`,
                            `Ok`
                        ],
                    }
                ],
                editarTipoMaterial: [
                    `Edit type`,
                    `Return`,
                    `Search: Type code, range or observation`,
                    `Type code`,
                    `Type name`,
                    `Observation`,
                    `Minimum range`,
                    `Maximum range`,
                    `Edit`,
                    `Cancel`,
                    `Select type to edit`,
                    `Select another type`,
                    {
                        alertaConfirmar: [
                            `Confirm`,
                            `Are you sure to edit this Type?`,
                            `Yes`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Type edited successfully`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`,
                            `Registration you want to insert is duplicated with the following information`,
                            `Id Type: `,
                            `Code: `,
                            `Type: `,
                            `Observation: `
                        ],
                        alertDatosNoActualizados: [
                            `Error!`,
                            `Form you want to save does not register any changes`,
                            `Ok`
                        ]

                    }
                ],
                reporteTipoMaterial: [
                    `Id`,
                    `Type code`,
                    `Type`,
                    `Minimum range`,
                    `Maximum range`,
                    `Observation`,
                    `Type Report`,
                    `Create type`,
                    `Type Report`,
                ],
                formGroupArticulo: [
                    `Create group`,
                    {
                        formulario: [
                            `Group code`,
                            `Group name`,
                            `Abbreviation`,
                            `Observation`,
                            `Save`,
                            `Cancel`,
                            `Group Report`,
                            `Delete`,
                            `Return`,
                            `Search: code, Group name or Abbreviation`,
                            `Delete Group`,
                            `Edit Group`,
                            `Select group to edit`,
                            `Select group`,
                            `Code`,
                            `Group name`,
                            `No search results found`,
                            `Select another group`,
                        ],
                        cargaMasiva: [
                            `Upload`,
                            `Select language`,
                            `Languages`,
                            `Languages`,
                            `Id Language`,
                            `Upload massive group`,
                            `Download file`,
                            `groupclass.xls`,
                            `Open file "groupclass.xls" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations:`,
                            `a. Field codMaterialGroup is required`,
                            `b. Field materialGroup is required`,
                            `c. Field abbreviationMaterialGroup is required`,
                            `Save file to your computer`,
                            `Select file`,
                            `Basic file information`,
                            `File`,
                            `# rows`,
                            `Modification`,
                            `Size`,
                            `Action`,
                            `Click on the + to attach file`,
                            `Upload`,
                            `Cancel`,
                            `Delete`,
                            `Upload all`,
                            `Cancel all`,
                            `Delete all`,
                            `1. Instructive`,
                            `2. Upload and validation`,
                            `3. Load log`,
                            `d. Field idLanguage is required`
                        ],
                        eliminar: [
                            `No register found with value: `,
                            `Are you sure to delete group?`,
                        ],
                        alertErrorGuardar: [
                            `Error loading translations`,
                            `Must enter translations required by the company`,
                            `Accept`
                        ],
                        alertErrorRepetido: [
                            `Error creating register`,
                            `The information you want to enter is already registered in the system!`,
                            `Accept`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Registration done successfully`,
                            `Accept`
                        ],
                        editar: [
                            `Searching`,
                        ],
                        tabla: [
                            `Id`,
                            `Code`,
                            `Class group`,
                            `Abbreviation`,
                            `Observation`,
                            `Group Report`,
                            `Create group`,
                            `Group report`,
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Class group`,
                            `Abbreviation`,
                            `Observation`
                        ],
                        mensajeCargaGA: [
                            `Error parsing file, the file may be corrupted or not correct`,
                            `They have registered`,
                            `Registers.`,
                            `Success!`,
                            `Error!`,
                            `Data not saved.`,
                            `○ To class group code`,
                            `Absence "Id Language" o there are errors in the fields`,
                            `• `,
                            `There are another errors in the registers: `,
                            `○ There is an error with the group code or the information is incorrect, check if the file is correct.`,
                            `○ A translation for this Code already exists in database `,
                            `○ There is an error with the Class Group Code`,
                            `○ There is an error validating duplicity. This error can be caused by the wrong data placement, verify the data. `,
                            `Warning`,
                            `Have not selected a file to be loaded`,
                            `Ok`,
                            "The uploaded file exceeds the maximum allowed size of 7.5MB" // 17
                            // {{texto.modCatalogacion[1].formGroupArticulo[1].cargaMasiva[12]}}
                        ],
                        alertSuccessModificar: [
                            `Good job`,
                            `Successfully updated group`,
                            `Accept`
                        ],
                        alertErrorModificar: [
                            `Error`,
                            `Group cannot be edited because it is duplicated with an existing one`,
                            `Accept`
                        ],
                        alertConfirmar: [
                            `Confirm`,
                            `Are you sure to edit class group?`,
                            `Accept`,
                            `Cancel`,
                            `Are you sure to save material group?`,
                        ],


                    }
                ],
                eliminarGrupoMaterial: [
                    `Delete group`,
                    {
                        formulario: [
                            `Group code`
                            , `Group`
                            , `Abbreviation`
                            , `Select group to delete`
                            , `Search: code, Group name o Abbreviation`
                            , `Basic information`
                            , `Group code`
                            , `Group name`
                            , `Abbreviation`
                            , `Observation`
                            , `Delete`
                            , `Cancel`
                        ],
                        alertError: [
                            `Error!`
                            , `Group cannot be deleted because it is assigned to a class`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Register was deleted successfully`
                        ]
                    }
                ],
                formProductoUNSPSC: [
                    `Create product`,
                    {
                        formulario: [
                            `Select ...`,
                            `Type`,
                            `Group`,
                            `Product name`,
                            `Product code`,
                            `Abbreviation`,
                            `Observation`,
                            `Save`,
                            `Cancel`,
                            `No groups found`,
                            `Does not have any type`,
                            `Product Report`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Product`,
                            `Abbreviation`,
                            `Observation`
                        ],
                        alertaConfirmacion: [
                            `Confirm`,
                            `Are you sure to create product`,
                            `Yes`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `Attention`,
                            `Some translation fields are empty`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `Registration failed!`,
                            `An error was generated that did not allow the register to be saved`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Product `
                            , ` was created successfully`,
                            `Ok`
                        ],
                        alertErrorDB: [
                            `Error!`,
                            `Product`
                            , ` is duplicated with the following information: [`,
                            `Ok`
                        ],

                        ErrorGeneral: [
                            `Error!`,
                            `Database connection problem, please contact administrator`,
                            `ok`
                        ]
                    }
                ],
                reporteMaterial: [
                    `ID`,
                    `Cod. Product`,
                    `Product`,
                    `Type`,
                    `Group`,
                    `Abbreviation`,
                    `Observation`,
                    `Group Code`
                ],
                editarMaterial: [
                    `Edit Product`,
                    {
                        formulario: [
                            `Select`,
                            `Search: Code, Product, Abbreviation or observation`,
                            `Return`,
                            `Product code`,
                            `Product Name`,
                            `Abbreviation`,
                            `Observation`,
                            `Type`,
                            `Product Group`,
                            `No product groups found`,
                            `Does not have any type`,
                            `Save`,
                            `Cancel`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Product`,
                            `Abbreviation`,
                            `Observation`
                        ],
                        alertaConfirmacion: [
                            `Confirm`,
                            `Are you sure to edit product?`,
                            `Yes`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `Registration failed!`,
                            `No value has been updated in the form`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `Registration failed!`,
                            `An error was generated that did not allow the register to be saved`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Product `,
                            ` was successfully updated`
                        ],
                        alertErrorDB: [
                            `Error!`,
                            `Product `,
                            ` generate duplicity with the following information: [`

                        ],
                        ErrorGeneral: [
                            `Error!`,
                            `Database connection problem, please contact administrator`,
                            `Ok`
                        ],
                        ErrorTipoMaterial: [
                            `Selected Product was not saved!`,
                            `This error is due to the fact that the selected Product already has this code assigned`,
                            `Ok`
                        ]
                    }
                ],
                elimiarMaterial: [
                    `Delete Product`,
                    `Return`,
                    `Search: Code, Product , Abbreviation or observation`,
                    `Basic information`,
                    `Product code`,
                    `Product name`,
                    `Group code`,
                    `Group name`,
                    `Type code`,
                    `Type`,
                    `Abbreviation`,
                    `Observation`,
                    `Delete`,
                    `Cancel`,
                    `Select product to delete`,
                    {
                        alertaConfirmar: [
                            `Confirm`,
                            `Are you sure to delete product `,
                            `Yes`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Product`,
                            ` was successfully deleted`
                        ],
                        alertaError: [
                            `Error!`,
                            `Product`,
                            ` which you are trying to delete has already been assigned to a Product`,
                            `Product cannot be deleted `,
                            ` because it has assigned features`,
                            `Product cannot be deleted `,
                            ` due unknown errors`
                        ],
                    }
                ],
                cargueMasivoMaterial: [
                    `Upload massive product `,
                    `Return`,
                    {
                        paso1: [
                            `class.xlsx`,
                            `Open file "class.xlsx" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations: `,
                            `Product Group`,
                            `Type`,
                            `Features`,
                            `Options`,
                            `Value`,
                            `Language`,
                            `Product Group`,
                            `Type`,
                            `[Select]`,
                            `Search Product Group`,
                            "Select language",
                            "Select product group"
                        ],
                        paso3: [
                            `Upload was successful.`,
                            `Conflicting Registers`,

                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Upload was successful.`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`, // 0
                            `Errors were found that did not allow the information to be recorded, then the conflicting records will be shown`, // 1
                            `Ok` // 2
                            , "An error was generated when saving the information" // 3
                            , "If entered translation information is incorrect" // 4
                            , "Some conflicting values ​​were found, please rectify them and try again" // 5
                            , "Error, duplicate record" // 6
                            , "Error, invalidol record" // 7
                            , "Error, invalid translation identifiers" // 8
                            , "General error when entering information" // 9
                        ],
                        errorColumnas: [
                            `Error!`,
                            `Uploaded file  is not valid!`,
                            `Ok`
                        ],
                        alertErrorCamposIncompletos: [
                            `Error!`,
                            `Some required fields in the file are empty`
                        ],
                        paso2: [
                            `a. Field codMaterial is required`,
                            `b. Field numMaterial is required`,
                            `c. Field abbreviationMat is required`,
                            `d. Field IdLanguage is required`,
                            `e. Field idMaterialGrupo is required`,
                            `f. Field idMaterialType is required`
                        ]
                    }
                ],
                unidadMedida: [
                    `Create measurement unit`,
                    {
                        formulario: [
                            `Select ...`,
                            `Company`,
                            `Measurement unit`,
                            `Symbol`,
                            `Description`,
                            `Save`,
                            `Cancel`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ]
                    }
                ],
                fabricante: [
                    `Create maker`,
                    {
                        formulario: [
                            `Maker`,
                            `Abbreviation`,
                            `Add maker`,
                            `No.`,
                            `Maker`,
                            `Abbreviation`,
                            `Actions`,
                            `Save`,
                            `Cancel`,
                            `Makers report`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        fabricanteExistente: [
                            `Error!`,
                            `This maker is already pre-uploaded.`,
                            `Ok`
                        ],
                        camposVacios: [
                            `Error!`,
                            `Modification fields are empty`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Maker was created successfully`,
                            `Ok`

                        ],
                        alertaError: [
                            `Error!`,
                            `A failure to register has been generated due to duplication of values`,
                            `Ok`
                        ],
                        alertaConfirmacion: [
                            `Confirm`,
                            `Do you want to update register?`,
                            `Yes`,
                            `No`
                        ],
                        alertConfirmación: [
                            `Confirm`,
                            // `¿Está seguro de crear el fabricante `,
                            `Are you sure to create pre-uploaded makers?`,
                            `Yes`,
                            `No`
                        ],
                    }
                ],
                modificarFabricante: [
                    `Edit Maker`,
                    {
                        formulario: [
                            `Maker`,
                            `Select maker`,
                            `Search: Id, maker name or Abbreviation`,
                            `Id`,
                            `Maker`,
                            `Abbreviated`,
                            `Select another maker`,
                            `Observation`,
                            `Makers Report`,
                            `Makers:`,
                            `Search: Maker name or Abbreviation`,
                            `Edit Maker`,
                            `Makers Report`,
                            `Create Maker`,
                            `Save`,
                            `Cancel`

                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Maker `,
                            ` was successfully updated`,

                        ],
                        alertaFormulario: [
                            `Error!`,
                            `Have not modified any form fields`,

                        ],
                        alertaDuplicado: [
                            `Error!`,
                            `Update you want to perform generates duplicity with the following information: [ `,

                        ],
                        alertaError: [
                            `Error!`,
                            `A failed registering has been generated`,

                        ],
                        alertaConfirmacion: [
                            `Confirm`,
                            `Do you want to update register?`,
                            `Yes`,
                            `No`
                        ]
                    }
                ],
                eliminarFacricante: [
                    `Delete Maker`,
                    {
                        formulario: [
                            `Maker`,
                            `Select maker`,
                            `Search: Id,maker or Abbreviation`,
                            `Id`,
                            `Maker`,
                            `Abbreviated`,
                            `Select another maker`,
                            `Observation`,
                            `Makers Report`,
                            `Makers:`,
                            `Search: Maker's name or Abbreviation`,
                            `Delete`,
                            `Cancel`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Maker was successfully deleted`,
                            `Ok`

                        ],
                        alertaConfirmacion: [
                            `Confirm`,
                            `Are you sure to remove this maker?`,
                            `Yes`,
                            `No`
                        ],
                        alertaError: [
                            `Error!`,
                            `An error was generated while deleting the register`,
                            `Ok`
                        ]
                    }
                ],
                Reportefabricante: [
                    `Id`,
                    `Maker`,
                    `Abbreviation`,
                    `Create maker`,
                    `Maker report`,
                    `Makers Report`
                ],
                cargueMasivoFabricante: [
                    `Upload massive maker`,
                    `Return`,
                    {
                        paso1: [
                            `Makers.xlsx`,
                            `Open file "Makers.xlsx" with an application of spreadsheets (Excel,LibreOffice Cal...) and fill in the following fields taking into account the observations: `,
                            `Companies`,
                            `[Select]`,
                            `Field`,
                            `Value`,
                            `Value for excel`,
                            `Value for excel`,
                            `Value for excel`,
                            'Search Company',
                            'Select Company'
                        ],
                        paso3: [
                            `Upload was successful.`,
                            `Conflicting Registers`,

                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Upload was successful.`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`,
                            `Errors were found that did not allow the information to be registerd, the following will show cogistersting registers`,
                            `Ok`
                        ],
                        errorColumnas: [
                            `Error!`,
                            `Uploaded file is not valid!`,
                            `Ok`
                        ],
                        tablaLog: [
                            `Errors Result` //0
                            , `Position` //1
                            , `Maker` //2
                            , `Abbreviation` //3
                            , `Company name` //4
                            , `Nit` //5
                            , `Email` //6
                            , `Continue` //7
                            , `Back` //8
                            , `Name` //9
                            , `Document number ` //10
                            , `File exceeds supported errors number (20).` //11
                            , `Error` //12
                        ],
                        paso2: [
                            `a. Field Maker is required`,
                            `b. Field Abbreviation is required`,
                            `c. Field idCompany is required`
                        ]
                    }
                ],
                ajustesCatalogacion: [
                    `General text setting`,
                    {
                        formulario: [
                            `Inspection:`,
                            `User:`,
                            `Inspection`,
                            `User`,
                            `Search`,
                            `Short description length:`,
                            `Include maker in short description`,
                            `Include reference in short description`,
                            `Save`,
                            `Cancel`,
                            `Character separation feature:`,
                            `Short description length`,
                            `Character separation feature`,
                            `Are you sure to save data?`,
                            `-- Select state --`,
                            `Select cataloger`,
                            `Cataloging date range`,
                            `From`,
                            `To`,
                            `Save`,
                            'Cataloger',
                            'Search Cataloger',
                            'Results of the operation.',
                            'Observation',
                            'Id',
                            'Code to treat',
                            'Description',
                            'Reference',
                            'State',
                            'Assigned Cataloger',
                            'Atention',
                            'You must add the file'
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        alertErrorRepetido: [
                            `Error saving text settings`,
                            `General settings you want to enter are already registered in the system!`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `General text settings updated successfully`,
                            `Ok`
                        ],
                        alertInfo: [
                            `Attention`,
                            `Enter required fields market with (*)`,
                            `Ok`
                        ]
                    }
                ],
                catalogoToxico: [
                    `Old catalog`,
                    {
                        formulario: [
                            `Select ...`,
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ]
                    }
                ],
                crearMaterial: [
                    `Create catalog`,
                    {
                        filtroToxico: [
                            `Query code`,
                            `Old code`,
                            `SLI code`,
                            `ERP`,
                            `Old description`,
                            `Duplicated codes`,
                            `Old description`
                            , `ERP Code`
                            , `Materials with which duplicity is generated by reference`
                        ],
                        formulario: [
                            `Select ...`, // 0
                            `Company`, // 1
                            `Stockat code`, // 2
                            `Group`, // 3
                            `Old code`, // 4
                            `Type`, // 5
                            `Product`, // 6
                            `Unit of measure`, // 7
                            `Manufacturer`, // 8
                            `ERP code`, // 9
                            `Complement technical information`, // 10
                            `Reference (s)`, // 11
                            `Inspection Comment`, // 12
                            `Update classification`, // 13
                            `Cancel`, // 14
                            `Update classification`, // 15
                            `Long text`, // 16
                            `Abbreviated Text`, // 17
                            `Inspection`, // 18
                            `Inspection comment`, // 19
                            `Update description`, // 20
                            `Alternative text`, // 21
                            `English`, // 22
                            `Type`, // 23
                            `Group`, // 24
                            `Class`, // 25
                            `Save inspection`, // 26
                            `Picture`, // 27
                            `Load`, // 28
                            `No information found`, // 29
                            `Create classification`, // 30
                            `Create features`, // 31
                            `Long text`, // 32
                            `Abbreviated Text`, // 33
                            `References (s)`, // 34
                            `Clear form` // 35
                            , // 36
                            , `Type of administration` // 37
                            , `Material` // 38
                            , `Active` // 39
                            , `Tool / Assets` // 40
                            , `Pipe` // 41
                            , `Service` // 42
                            , `Product type` // 43
                            , `Return` // 44
                            , "No values ​​found" // 45
                            , "Additional Information" // 46
                            , "Get description" // 47
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        modalCaracteristicas: [
                            `Features and additional information`,
                            `Order`,
                            `Feature`,
                            `Value`
                        ],
                        error: [
                            `Error`,
                            `This reference has already been entered!`
                            , `Error validating the information entered`
                        ],
                        success: [
                            `Good job`,
                            `Classification performed satisfactorily`,
                            `Inspection sent successfully`
                        ],
                        envio: [
                            `Sent to QA`,
                            ``
                        ],
                        alertConfirmacion: [
                            `Confirm`,
                            `Do you want to create classification?`,
                            `Yes`,
                            `No`
                        ],
                        alertConfirmacionActualizacion: [
                            `Confirm`,
                            `Do you want to update classification?`,
                            `Yes`,
                            `No`
                        ],
                        alertConfirmacionCaracteristicas: [
                            `Confirm`,
                            `Do you want to save selected features?`,
                            `Yes`,
                            `No`
                        ],
                        alertError: [
                            `Error!`,
                            `An error was generated when validating the register`,
                        ],
                        alertNoCaracteristicas: [
                            `Error!`,
                            `This class has no features created!`,
                        ],
                        alertrRegistroRepetido: [
                            `Error!`,
                            `This class is registered with code: `,
                            `class name: `,
                            `Group: `,
                            `Type: `,
                            "An error was generated while saving the information" // 5
                        ],
                        alertCamposNoValidos: [
                            `Error!`,
                            `Delected class has not been validated correctly`
                        ],
                        alertCaracteristicaVacia: [
                            `Error`,
                            `Some features are not assigned any value yet`
                        ],
                        errorBuscar: [
                            `Error!`,
                            `Register not found`
                        ],
                        errorConexion: [
                            `Error!`,
                            `Connection with server was not achieved`
                        ],
                        descripcionToxica: [
                            `Untreated code`,
                            `Additional Information `,
                            `Reference`,
                            `Observation`,
                            `Request state untreated code`,
                            `Treaty`
                            , `Untreated`
                        ],
                        errorCrearCaracteristicas: [
                            `Error!`,
                            `An error was generated when creating features for this class`,
                            `Ok`
                            , `This class is duplicated by reference with the following SLI codes`
                            , `This class is duplicated by features with the following SLI codes`
                            , `This class is duplicated by features and manufacturer with the following SLI codes`
                            , `Error saving image`
                            , `General error, please contact someone from technical support`
                            , `Do you want to continue?`
                        ],
                        errorNoCaracteristicas: [
                            `Error!`,
                            `Have not selected any value for this class`,
                            `Ok`
                        ],
                        confirmacionComentario: [
                            `Confirm`,
                            `Do you want to save this comment?`,
                            `Yes`,
                            `No`
                        ],
                        errorInspeccionNoAgregada: [
                            `Error!`,
                            `Must select an inspection action`,
                            `Ok`
                            , `it was generated an error to save informatión, can you try again? `
                        ],
                        errorActualzarIncompleto: [
                            `Warning!`,
                            `Some data was not updated as they generate conflict with another registers`,
                        ],
                        errorActualizarAll: [
                            `Error!`,
                            `An error was generated when updating information`
                        ],
                        alertaWarning: [
                            `Warning`,
                            `This class has no features created!`
                        ],
                        advertenciaDuplicado: [
                            `Duplicated class, do you want to continue?`,
                            `Class you are trying to catalog is already registered with the SLI code: `,
                            `, maker registered for this class is: `,
                            ` and conflicting references are: `,
                            `Yes`,
                            `No`
                        ],
                        advertenciaDuplicadoCaracteristicas: [
                            `Duplicated class, do you want to continue?`,
                            `Class you are trying to catalog is already registered with the SLI code: `,
                            ` and duplicated features are: `,
                            `Yes`,
                            `No`
                        ],
                        caracteristicasDuplicadas: [
                            `Duplicated class, do you want to continue?`,
                            `Features you have selected for the current class are already registered with the SLI code: `,
                            ` and class name is: `,
                            `Yes`,
                            `No`
                        ],
                        alertErrorCodInspeccion: [
                            'Error!',
                            'Failed to get inspection code'
                        ],
                        alertInfoNoCaracteristicas: [
                            'Information!',
                            'Class you want to catalog does not have features'
                        ],
                        alertInfoNoValores: [
                            'Information!',
                            'Class you want to catalog does not have assigned values'
                        ],
                        alertInfoNoReferencias: [
                            'Information!',
                            'Class you want to catalog does not have references'
                        ],
                        confirmacionDuplicar: [
                            'Confirm',
                            'Do you want to import this material?',
                            'Yes',
                            'No'
                        ],
                        caracteristicasNoValor: [
                            `Warning`,
                            `Selected class does not have values ​​assigned in some of its features`
                        ],
                        errorObtenerCaracteristicas: [
                            `Error!`,
                            `An error was generated when getting features and values ​​from this class`
                        ],
                        errorCaracterSeparacion: [
                            `!Error!`,
                            `Company in which you are cataloging, does not have separation characters or characters limit, assign some value to be able to visualize the descriptions`
                        ]
                        , referenciaDuplicada: [
                            `Duplicated Reference`
                            , `Duplicated codes are generated: `
                        ]
                        , errorLoagin: [
                            `An error was generated when loading the information, please contact system administrator`
                        ]
                        , getCloned: [
                            `Confirmation`
                            , `The material that you try to get it was cataloged, do you want bring?`
                        ]
                        , notMaterial: [
                            `Error`
                            , `No existe el material a caracterizar`
                        ]

                    }
                ],
                calidadQA: [
                    `Quality Q.A.`,
                    {
                        formulario: [
                            `Select ...`,
                            `Company`,
                            `Inspection state`,
                            `User`,
                            `With code`,
                            `Save`,
                            `Cancel`
                        ],
                    }
                ],
                formModificarTipoMaterial: [
                    `Edit Type`,
                    {
                        formulario: [
                            `Company`,
                            `Code`,
                            `Select ...`,
                            `Name Type`,
                            `Range`,
                            `Description`,
                            `Observation`,
                            `Save`,
                            `Cancel`,
                            `From`
                        ],
                        cargaMasiva: [
                            `Upload`,
                        ],
                        alertas: [
                            {
                                title: `Error`,
                                texto: `Must select a company!`,
                            },
                            {
                                title: `Error`,
                                texto: `Must select a Type!`
                            }


                        ],
                        botones: [
                            `Ok`,
                            ``
                        ],
                        alertIdiomasError: [
                            `Error!`,
                            `No languages ​​associated with this register were found`,
                            `Ok`
                        ]
                    }
                ],
                formModificarGroupArticulo: [
                    `Edit Article Group`,
                    {
                        formulario: [
                            `Company`,
                            `Code`,
                            `Select ...`,
                            `Article group`,
                            `Description`,
                            `Observation`,
                            `Abbreviated`,
                            `Save`,
                            `Cancel`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ]
                    }
                ],
                modificarUnidadMedida: [
                    `Edit Measurement unit`,
                    {
                        formulario: [
                            `Select ...`,
                            `Company`,
                            `Measurement unit`,
                            `Symbol`,
                            `Description`,
                            `Save`,
                            `Cancel`,
                            `Observation`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ]
                    }
                ],
                modificarProductoUNSPSC: [
                    `Edit Product`,
                    {
                        formulario: [
                            `Select ...`,
                            `Company`,
                            `Product to Edit`,
                            `Type`,
                            `Article group`,
                            `Code`,
                            `Description`,
                            `Abbreviated`,
                            `Observation`,
                            `Save`,
                            `Cancel`,
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ]
                    }
                ],

                caracteristicas: [
                    `Create feature`,
                    {
                        formulario: [
                            `Products`,
                            `Feature`,
                            `Observation`,
                            `Save features`,
                            `Cancel`,
                            `Edit`,
                            `Update Order`,
                            `Position`,
                            `Action`,
                            `Edit feature`,
                            'Save edition',
                            `Translations`,
                            `Add feature`,
                            `Cancel feature`,
                            `Information`,
                            `Has features in preview that have not been saved.`,
                            `Multivalue`,
                            `Features report`,
                            `Create feature`,
                        ],
                        claseEnUso: [
                            `Product in use`
                        ],
                        alertErrorDB: [
                            `Error!`,
                            `Error communicating with server`,
                            `Ok`
                        ],
                        caracteristicaExistente: [
                            `Error!`,
                            `This feature is already pre-loaded.`,
                            `Ok`
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Feature`,
                            `Observation`
                        ],
                        menu: [
                            `Create feature`,
                            `Preview`
                        ],
                        alertTraducciones: [
                            `Attention`,
                            `Must complete translations to continue`,
                            `Ok`
                        ],
                        alertCaracteristicaAdd: [
                            `Added feature!`,
                            `To permanently save the features please go to preview`,
                            `Ok`
                        ],
                        alertIdiomaMaterial: [
                            `Error!`,
                            `Selected class is not valid`,
                            `Ok`
                        ],
                        alertNoCaracteristicas: [
                            `Error!`,
                            `Does not have features`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Features have been successfully registered`,
                            `Ok`
                        ],
                        alertaRepetido: [
                            `Error!`,
                            `We have found repeated registers for this company`,
                            `Ok`,
                            `Id Feature`,
                            `Feature`,
                            `Observation`
                        ],
                        alertaConfirmacion: [
                            `Confirm!`,
                            `Do you want to save registers?`,
                            `Yes`,
                            `No`
                        ],
                        alertaMaterialDireferente: [
                            `Error!`,
                            `Error in selected classes`
                        ]
                    }

                ],
                editarCaracteristica: [
                    `Edit feature`,
                    {
                        formulario: [
                            `Select feature to edit`,
                            `Search: Id, class or observation`,
                            `Return`,
                            `Feature`,
                            `Observation`,
                            `Position`,
                            `Actions`,
                            `Multivalue`,
                            `Language`,
                            `Actions`,
                            `Warning!`,
                            `Has items that will be deleted as soon as you save your actions, if you want to undo these click actions`,
                            `here`
                            , `This product has no feature`
                        ],
                        cargaMasiva: [
                            `Upload`,
                            ``
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Feature`,
                            `Observation`,
                        ],
                        alertaConfirmacion: [
                            `Do you want to update register?`,
                            `To continue select yes`,
                            `Yes`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `Registration failed!`,
                            `No value has been updated in the form`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `Registration failed!`,
                            `An error was generated that did not allow the register to be saved`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Registers edited successfully`,
                            `Ok`
                        ],
                        alertErrorDB: [
                            `Error!`,
                            `An error was generated when registering, verify that the type you are trying to enter does not have the same code associated with different features`,
                            `Ok`
                        ],
                        ErrorGeneral: [
                            `Error!`,
                            `Database connection problem, please contact administrator`,
                            `Ok`
                        ],
                        ErrorTipoMaterial: [
                            `Selected Type was not saved!`,
                            `This error is due to the fact that selected Type already has this assigned code `,
                            `Ok`
                        ],
                        edicionCaracteristica: [
                            `Good job`,
                            `Register was edited successfully`,
                            `Ok`
                        ],
                        valoresNoEditados: [
                            `Error!`,
                            `Have not made any changes`
                        ],
                        ErrorEliminar: [
                            `Error!`,
                            `Features you want to delete already have related classes or values`
                        ],
                        ErrorEliminar2: [
                            `Error!`,
                            `Cannot delete features that you have edited`
                        ]
                    }
                ],
                reporteCaracteristica: [
                    `Cod. Product`,
                    `Product`,
                    `Feature`,
                    `Return`,
                    `Features report`
                ],
                elimiarCaracteristica: [
                    `Delete feature`,
                    `Return`,
                    `Search: Id, feature or observation`,
                    `Basic information`,
                    `Product`,
                    `Product code`,
                    `Feature`,
                    `Observation`,
                    `Delete`,
                    `Cancel`,
                    `Select feature to delete`,
                    {
                        alertaConfirmar: [
                            `Confirm`,
                            `Are you sure to delete this Type?`,
                            `Yes`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Type successfully deleted`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`,
                            `Error while trying to delete Type because it is already assigned to cataloged material.`,
                            `Ok`
                        ],
                    }
                ],
                valor: [
                    `Values`,
                    {
                        formulario: [
                            `Products`,
                            `Feature`,
                            `Value name`,
                            `Abbreviation`,
                            `Cancel`,
                            `Edit`,
                            `Save`,
                            `Position`,
                            `Action`,
                            `Modify feature`,
                            'Save edition',
                            `Translations`,
                            `Create Value`,
                            `Cancel Value`,
                            `Information`,
                            `Has features in preview that have not been saved.`,
                            `No Classes associated with this company were found`,
                            `No features associated with this class were found`,
                            `Edit Value`,
                            `[Select]`,
                            `Create value`,
                            `Class and feature in use`

                        ],
                        alertErrorDB: [
                            `Error!`,
                            `Error communicating with server`,
                            `Ok`
                        ],
                        valorExistente: [
                            `Error!`,
                            `This feature is already pre-loaded.`,
                            `Ok`
                        ],
                        tablaTraduccion: [
                            `Language`,
                            `Value`,
                            `Abbreviation`
                        ],
                        menu: [
                            `Create value`,
                            `Preview`
                        ],
                        alertTraducciones: [
                            `Attention`,
                            `Some translation fields are empty`,
                            `Ok`
                        ],
                        alertValorAdd: [
                            `Added values`,
                            `To permanently save the values ​​please go to the preview tab`,
                            `Ok`
                        ],
                        alertIdiomaMaterial: [
                            `Error!`,
                            `Selected class is not valid`,
                            `Ok`
                        ],
                        alertNoCaracteristicas: [
                            `Error!`,
                            `Does not have features`,
                            `Ok`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Values ​​successfully registered`,
                            `Ok`
                        ],
                        alertaRepetido: [
                            `Error!`,
                            `An error was generated when inserting the following values: [ `,
                            `Following values ​​are duplicated: [`
                        ],
                        alertaDB: [
                            `Error!`,
                            `An error was generated while registering`,
                        ],
                        alertaConfirmacion: [
                            `Confirm!`,
                            `Do you want to save registers?`,
                            `Yes`,
                            `No`
                        ]
                        , tableLog: [
                            `Table result`
                            , `Conflict Value`
                            , `Abbreviation`
                            , `Language`
                        ]
                    }

                ],
                editarValor: [
                    `Edit Values`,
                    {
                        formulario: [
                            `products`,
                            `Feature`,
                            `Value`,
                            `Abbreviation`,
                            `Cancel`,
                            `Edit`,
                            `Save`,
                            `Position`,
                            `Action`,
                            `Modify feature`,
                            'Save edition',
                            `Translations`,
                            `Add value`,
                            `Cancel value`,
                            `Information`,
                            `Has features in preview that have not been saved.`,
                            `No products associated with this company were found`,
                            `No features associated with this Product were found`,
                            `Select value to edit`,
                            `Search: Id value,Product,feature,value or observation`,
                            `[Select]`,
                            `Language`
                        ],
                        filtro: [
                            `Product`,
                            `Feature`,
                            `Value`,
                            `Abbreviation`
                        ],
                        alertErrorDB: [
                            `Error!`,
                            `Error communicating with server`,
                            `Ok`
                        ],
                        alertaConfirmacion: [
                            `Do you want to update register?`,
                            `To continue select yes`,
                            `Yes`,
                            `No`

                        ],
                        errorFormInvalido: [
                            `Registration failed!`,
                            `No value has been updated in the form`,
                            `Ok`
                        ],
                        errorGuardar: [
                            `Registration failed!`,
                            `An error was generated that did not allow the register to be saved`,
                            `Ok`
                        ],
                        alertSuccess: [
                            `Good job`,
                            `Registration was successful`,
                            `Ok`
                        ],
                        ErrorGeneral: [
                            `Error!`,
                            `Database connection problem, please contact administrator`,
                            `Ok`
                        ],
                    }
                ],
                reporteValor: [
                    `Value Report`,
                    {
                        camposTablas: [
                            `Product`,
                            `Id feature`,
                            `feature`,
                            `Values`,
                            `Abbreviation`,
                            `Product code`
                        ],
                        botones: [
                            'Create value'
                        ]

                    }
                ],
                elimiarValor: [
                    `Delete Value`,
                    `Return`,
                    `Search: Id value,Product,feature,value or observation`,
                    `Basic information`,
                    `Product`,
                    `Product code`,
                    `Feature`,
                    `Observation`,
                    `Delete`,
                    `Cancel`,
                    `Select value to delete`,
                    `Value`,
                    {
                        alertaConfirmar: [
                            `Confirm`,
                            `Are you sure to delete this value?`,
                            `Yes`,
                            `No`
                        ],
                        alertaSuccess: [
                            `Good job`,
                            `Value successfully deleted`,
                            `Ok`
                        ],
                        alertaError: [
                            `Error!`,
                            `Value you want to delete is associated with a SLI code`,
                            `Ok`
                        ],
                    }
                ],
                cargaMasivaValor: [
                    'Upload massive values'
                    , `Values report`
                    , `See material`
                    , `Select another material`
                    , `Material`
                    , `Code`
                    , `Abbreviation`
                    , `Observation`
                    , `Material group`
                    , `Material type`
                    , `Generate file`
                    , {
                        alertaConfirmacionGeneral: [
                            `Confirm`
                            , `Do you want to generate a file with all the products associated with this company?`
                            , `Do you want to generate the load format with the selected Product?`
                            , `Yes`
                            , `No`
                            , `Do you want to upload selected file?`
                        ]
                        , alertaError: [
                            `Error`
                            , `An error was generated when creating the form for loading values, please contact system administrator`
                        ]
                        , tablaInfo: [
                            'File'
                            , `# Registers`
                            , `Modification date`
                            , `Size`
                            , `Action`
                            , `File information`
                        ]
                        , alertSuccess: [
                            `Good job!`
                            , `Registration was successful `
                        ]
                        , alertError: [
                            `Error!`
                            , `Some errors were generated during loading, please review log`
                        ]
                        , tablaLog: [
                            `Position`
                            , `Code`
                            , `Class`
                            , `Fature`
                            , `Value`
                            , `Abbreviation`
                            , `Actions registers`
                            , `Error`
                            , `BD Error`
                            , `Duplicated Registration`
                            , `Runtime error`
                        ]

                    }
                    , `Value`
                    , `Abbreviation`
                    , `Select file`
                    , `Upload`
                    , `Search`
                    , `Upload`
                    , `Cancel`
                    , `Language`
                    , `Restart search`
                ],
                cargatoxicoindividual: [
                    `Upload Old Description`,
                    `Old code`,
                    `Description`,
                    `Reference`,
                    `Observation`,
                    `Assign User`,
                    `Create Old Description`,
                    `Return`,
                    `Description report`,
                ],
                reportetoxico: [
                    `Description report`,
                    `Upload Toxic`,
                    `Code was created successfully`,
                    `Good job`,
                    `Ok`,
                    `Error`,
                    `Error creating code to try`,
                    `Ok`,
                    `Description report`,
                    `Do you want to create Old Description?`,
                ],
            },
            `Language`,

        ];

        //Modulo dia a dia
        this.solicituddiadia = [
            `Cataloging Request Form`,
            `Attention cataloging request`,
            {
                solicitudDia: [
                    `Request type `,
                    `Product description `,
                    `Specific application`,
                    `Part number`,
                    `Equipment Type to which it applies`,
                    `Maker`,
                    `Model to which it applies`,
                    `NFPA classification`,
                    `UMB`,
                    `Another information`,
                    `Add`,
                    `Action`,
                    `Edit`,
                    `Delete`,
                    `Send request`,
                    `Cataloging Request:`,
                    `Cataloging Request Nº:`,
                    `Request State:`,
                    `Code to treat`,
                    `ERP Code`,
                    `SLI Code`,
                    `Warning`,
                    `This request will be created but will not be sent directly, to send it after creation you must modify it in your respective form! do you want to continue?`,
                    `This request will be saved and sent directly so it cannot be modified later! do you want to continue?`,
                    `Must add at least one product`,
                    `There was an error registering request. Changes were canceled`,
                    `Request was registered correctly`,
                    `Must add one code`,
                    `Must select at least one option in field `,
                    `Field `,
                    ` is empty`,
                    `Are you sure to delete the product, changes will not be made if you do not register the request?`,
                    `Request number is: `,
                    `Add product`,
                    `Request type`,
                    `Base measurement unit`,
                    `Save`,
                    `Are you sure to cancel request?`
                    , `Save`
                    , `Upload files`
                    , `Select files`
                    , `File extensions allowed`
                    , `The maximum size allowed in files is`
                    , `Request number`
                    , `Id requested product`
                ],
                alertaError: [
                    `Error`
                    , `Existing files`
                    , `File extension is not allowed or the file is too large to be saved.`
                    , `Error reading file`
                ]
            },
            {
                atencionDeSolicitudes: [`Client`, `State`, `Consecutive follow-up`, `results per page`, `Search`]
            },
            {
                informeCatalogacion: [`Class Cataloging Report`, `Consecutive`, `Search`]
            },
            {
                modificarSolicitudCatalogacion:
                    [
                        `Edit Cataloging Request`,
                        `Select request status`,
                        `Select request number`,
                        `Error saving register`,
                        `Register was saved correctly`,
                        `Cancel request`,
                    ]
            },
            {
                tablaSolicitudCatalogacion: [
                    `Cataloging Request Report`,
                    `Create request`,
                    `Select request number`,
                    `Id`,
                    `Request Number`,
                    `Request state`,
                    `Number items`,
                    `Creation date`,
                    `Responsible Cataloger`,
                    `Return to request list`,
                    `Date treated`,
                    `Action`,
                    `Quality date`,
                    `Creator user`,
                    `Request Type`, //14
                    `Inspection`,
                    `Inspection Comment`,
                    `Material type`,
                    `Group code`,
                    `Group name`,
                    `Class code`,
                    `Class name`,
                    `Files`,
                    `General Report Requests`
                ]
            },
            {
                tablaSolicitudCatalogacionItems: [
                    `Id product`,
                    `Product description`,
                    `Specific application`,
                    `Part number`,
                    `Type equipment applic.`,
                    `Maker`,
                    `Model`,
                    `Code to  review`,
                    `ERP code`,
                    `SLI code`,
                    `NFPA classification `,
                    `NFPA `,
                    `Additional information`,
                    `Request Type`,
                    `Base measurement unit`,
                    `PRODUCT REPORT REQUEST CATALOGING`,
                    `REPORT REQUEST CATALOG`,
                    `Action`
                    , `Files`
                    , `Creator user`
                ]
            }];

        this.editarUsuario = [
            `Edit User`,
            `Personal information`,
            `Contact information`,
            `Permissions`,
            {
                formulario: [
                    `Name`,
                    `Surname`,
                    `Document type`,
                    `Document number`,
                    `Email`,
                    `Password`,
                    `Address`,
                    `Country`,
                    `Departament`,
                    `Municipality`,
                    `Postal Code`,
                    `Phone`,
                    `Cell phone`,
                    `Extension`,
                    `Company where work`,
                    `Position in the company`,
                    `Role in the platform`,
                    `Select`,
                    `Save`,
                    `Cancel`,
                    `Change`,
                    `Return`,
                    `Delete`
                ],
                modal: [
                    `Search user by document or id`,
                    `Document number, full name or id`,
                    `Search`,
                    `Cancel`
                ],
                error: [
                    `User not found`,
                    `User not found`,
                    `Understood!`
                ],
                alertaContrasena: [
                    `User not found`,
                    `User not found`,
                    `Understood!`
                ],
                cambioContrasena: [
                    `Do you want to change password?`,
                    ``,
                    `Continue`,
                    `Cancel`
                ],
                alertConfirmacion: [
                    `Do you want to edit user:`,
                    `?`,
                    `Yes`,
                    `No`
                    , `Confirm`
                ],
                alertaSuccess: [
                    `Edited register`,
                    `Ok`,
                ]
            }
        ]

        this.crearUsuario = [
            `Create User`,
            `Personal information `,
            `Contact information`,
            `Permissions`,
            {
                formulario: [
                    `Name`,
                    `Surname`,
                    `Document type`,
                    `Document number`,
                    `Email`,
                    `Password`,
                    `Address`,
                    `Country`,
                    `Departament`,
                    `Municipality`,
                    `Postal Code`,
                    `Phone`,
                    `Cell phone`,
                    `Extension`,
                    `Company where work`,
                    `Position in the company`,
                    `Role in the platform`,
                    `Select`,
                    `Save`,
                    `Cancel`,
                    `Change`,
                    `Return`
                ],
                modal: [
                    `Search user by document or id`,
                    `Document number`,
                    `Search`,
                    `Cancel`
                ],
                error: [
                    `Registration Error`,
                    `Email or Document number you entered is already registered`,
                    `Understood!`
                ],
                alertConfirmacion: [
                    `Do you want to create user: `,
                    `?`,
                    `Yes`,
                    `No`
                ],
                alertaSuccess: [
                    `Created register `,
                    `To enter check your email`,
                    `Ok`,
                ],
                textosRequeridos: [
                    `Password must be at least 8 characters`
                ],
                alertNoContacto: [
                    `Error!`,
                    `Must enter a contact number`
                ]
            }
        ]

        this.editarEmpresa = [
            `Edit company`,
            `Basic information`,
            `Location information`,
            `Contact information `,
            {
                formulario: [
                    `Nit`,
                    `Company name`,
                    `Comercial activity`,
                    `Country`,
                    `Departament`,
                    `Municipality`,
                    `Postal Code`,
                    `Address`,
                    `Complement`,
                    `Contact person`,
                    `Email`,
                    `Phone`,
                    `Extension`,
                    `Cell phone`,
                    `Currency`,
                    `Select`,
                    `Save`,
                    `Cancel`,
                    `Return`,
                    `CIIU`,
                    `Delete`

                ],
                modalBuscar: [
                    `Search company by nit or id`,
                    `Nit or id`,
                    `Search`,
                    `Cancel`
                ],
                modalCIIU: [
                    `Search CIIU activity`,
                    `Nit, name or id`,
                    `Search`,
                    `Cancel`,
                    `CIIU section`,
                    `CIIU Division`,
                    `CIIU group`,
                    `CIIU Class`,
                    `Selected Activity: `,
                    `Arm code`,
                    `Search code`
                    , `Advanced search by code`
                ],
                error: [
                    `Company not found`,
                    `Company not found`,
                    `Understood!`
                ],
                alertaContrasena: [
                    `Company not found`,
                    `Company not found`,
                    `Understood!`
                ],
                cambioContrasena: [
                    `Do you want to change password?`,
                    ``,
                    `Continue`,
                    `Cancel`
                ],
                alertaConfirmacion: [
                    `Confirm`,
                    `Do you want to associate users with the company `,
                    `Yes`,
                    `No`
                ]
                ,
                alertConfirmacion: [
                    `Do you want to update register `,
                    `?`,
                    `Yes`,
                    `No`
                ],
                alertaSuccess: [
                    `Edited Register`,
                    `Accept`,
                ],
                errorActividad: [
                    `Error saving register`,
                    `You have not selected one activity`,
                    `Accept`
                ],
                alertCIIU: [
                    `Error`,
                    `CIIU code you entered is not valid, please verify it!`,
                    `Accept`
                ],
                alertUsuarios: [
                    `Error`,
                    `General error, contact administrator: `,
                    `Accept`
                ],
                alertGuardar: [
                    `Error`,
                    `Nit, mail or company name you want to create already exists in our BD`,
                    `Accept`
                ],
                alertSuccess: [
                    `Successful operation`,
                    `Accept`
                ],
                alertIdiomaError1: [
                    `Error`,
                    `Selected language has already been entered!`,
                    `Accept`
                ],
                alertIdiomaError2: [
                    `Advertencia`,
                    `Selected language has already been entered!`,
                    `Accept`
                ],
                errorNoContacto: [
                    `Error!`,
                    `Have not entered a contact number`
                ],
                alertErrorCargueMasivo: [
                    `Error`,
                    `Following users were not able to associate with the company`
                ],
                logErrores: [
                    `Error log: `,
                    `Name: `,
                    `Position: `
                ],
                alertSuccessCargueMasivo: [
                    `Successful operation`,
                    `Users successfully associated with the company `
                ]

            }
        ]

        this.dataTableText = [
            `Visible columns`
            , `Print`
            , `Copy`
            , `Excel`
            , `PDF`
            , `CSV`
        ]

        this.inventarioModulo = {
            movInventarios: [
                `Create entry`,
                `Remission`,
                `Purchase order`,
                `Invoice`,
                `Observation`,
                `Consult Products`,
                `Fill out the form`,
                `Remove`,
                `Clean`,
                `SLI code`,
                `Desc. Corta`,
                `Tax`,
                `Material status`,
                `Serial`,
                `Board`,
                `save`,
                `Cancel`,
                `Asset Outflow`,
                `List of products in inventory`,
                `Asset allocation`,
                `Quantity`,
                `Price`,
                `Load Values ​​to Product`,
                `Provider`,
                `Pack off`,
                `Low Reason`,
                `Asset Withdrawal`,
                `Asset return`,
                `Movements`,
                `Consecutive`,
                `Credit note`,
                `The consecutive selected does not contain information for return`,
                `List of products of the selected movement`,
                `Asset transfer`,
                `Product information`,
                `Modify Asset Allocation 101-`,
                `Custodian`,
                `Modify Low`,
                `Low Reason`,
                `Edit Entry`,
                `Cancel`,
                `Modify Output`,
                `Modify Transfer`,
                `Assignment Report`,
                `State`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Cost center`,
                `Branch office`,
                `Destination`,
                `Watch`,
                `Unity`,
                `To return`,
                `Withdrawal Report`,
                `Ticket Report`,
                `Uniform`,
                `Departure Report`,
                `Transfer Report`,
                `Origin Warehouse`,
                `Destination Warehouse`,
                `Create Pipeline Drop`,
                `No. tube`,
                `Metro`,
                `Feet`,
                `Inch`,
                `Tube number`,
                `Pipeline return`,
                `Modify Dispatch`,
                `Office Report`,
                `Create pipeline clearance`,
                `Pipe Transfer`
                , `Old description`
                , `Description` // 74
                , `Condition` //75
                , `Cod. Old` //76
                , `Discount` // 77
                , `Stock report ` // 78
                , `To select` // 79
                , `Discount` // 80
                , `Code. Plant` // 81
                , `Cod. Center` // 82
                , `Cod. Warehouse` // 83
                , `Unit of measurement` // 84
                , `Condition` // 85
                , `Location` // 86
                , `Quantity` // 87
                , `Change location` // 88
                , `Action` // 89
                , `Location code` // 90
                , `Location` // 91
                , `Save location` // 92
                , `Cancel` // 93
                , "Confirmation" // 94
                , "Do you want to update the location of the material" // 95
                , "?" // 96
                , "Yes" // 97
                , "No" // 98
                , "Good job" // 99
                , "Error" // 100
                , "The location change was successful" // 101
                , "An error was generated while making the change of location, please try again" // 102
                , "Edit location" // 103
                , "Location observation" // 104
                , "NOTE: The location filled out above applies to all the materials listed below, if you want any of these to be located in a different place, please fill in this information specifically in the material" // 105
                , "Proof" // 106
                , "Returns report" // 107
                , "Reload table" // 108
                , "Get report" // 109
                , "Consult report" // 110
            ],
            kardex: [
                "Kardex"
                , "Date"
                , "Consecutive"
                , "SLI"
                , "Base Base Unit"
                , "Condition"
                , "Description"
                , "Supplier"
                , "Plant O."
                , "Centro O."
                , "Store O."
                , "Movement"
                , "Plant D."
                , "Center D."
                , "Destination D."
                , "Custodian"
                , "Creator of the movement"
                , "Tax"
                , "Discount"
                , "Price"
                , "Total"
                , "Movement type" // 22
                , "Plate" // 23
                , "Serial" // 24
                , "Cod. Antiguo" // 24

            ],
            inventarioMenus: [
                `Control Panel`,
                `Product Adm.`,
                `Asset Management`,
                `Adm. Of pipes`
            ],
            panelControl: [
                `Import cataloging`,
                `Interns`,
                `External`,
                `Individual Cat. Amount`,
                `Enable / Disable Amount`,
                `Massive amount`,
                `Delete amount`,
                `Report`,
                `Individual Amount`,
                `Enable / Disable Amount`,
                `Massive amount`,
                `Report`,
                `Create external catalog`,
                `I loaded massive external catalog`
            ],
            estructura: [
                `Structure`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Location`
            ],
            estructuraPlantaPlanta: [
                `Create Plant`,
                `Edit Plant`,
                `Remove Plant`,
                `Plant Report`
            ],
            estructuraPlantaCentro: [
                `Create Center`,
                `Edit Center`,
                `Delete Center`,
                `Center Report`
            ],
            estructuraPlantaAlmacen: [
                `Create Warehouse`,
                `Edit Warehouse`,
                `Delete Warehouse`,
                `Warehouse Report`
            ],
            estructuraPlanta: [
                `Plant`,
                `Plant name:`,
                `Plant name`,
                `Plant code:`,
                `Plant code`,
                `Contact person:`,
                `Contact person`,
                `Contact email:`,
                `Contact email`,
                `Direction:`,
                `Direction`,
                `Mobile phone:`,
                `Mobile phone`,
                `save`,
                `Error, there may be a plant with the same code or name`,
                `The plant was created`
            ],
            estructuraPlantaModificar: [
                `Edit Plant`,
                `To return`,
                `Select Plant:`,
                `Search by: plant, code, name or email`,
                `Code`,
                `Plant name`,
                `Contact name`,
                `Email`,
                `The plant was modified correctly!`,
                `The plant cannot be edited because it does not exist`,
                `Error removing plant, review procedures`,
            ],
            estructuraPlantaEliminar: [
                `Remove Plant`,
                `To return`,
                `Select Plant:`,
                `Search by: plant, code, name or email`,
                `You are sure to remove the plant`,
                `The plant was removed correctly!`,
                `The plant cannot be removed because it is assigned to a center`,
                `The plant cannot be removed because the plant does not exist`,
                `Error removing plant, review procedures`,
            ],
            estructuraPlantaReporte: [
                `Plants Report`,
                `Create Plant`,
            ],
            estructuraCentro: [
                `Center`,
                `Name of the centre:`,
                `Name of the centre`,
                `Center Code:`,
                `Center Code`,
                `Plant`,
                `Contact person:`,
                `Contact person`,
                `Contact email:`,
                `Contact email`,
                `Direction:`,
                `Direction`,
                `Mobile phone:`,
                `Mobile phone`,
                `save`,
                {
                    alertaConfirmacion: [
                        `Confirmation!`,
                        `Do you want to save the record?`,
                        `Yes`,
                        `No`
                    ],
                    alertaError: [
                        `Error!`,
                        `An error was generated when registering`
                    ],
                    alertaSuccess: [
                        `Nice job`,
                        `Registration was successful`
                    ],
                    alertRepetido: [
                        `Error!`,
                        `The record you are trying to enter is repeated for this plant`
                    ]
                },
                `Actions`
            ],
            estructuraCentroModificar: [
                `Edit Center`,
                `To return`,
                `Select Center:`,
                `Search by: center the code`,
                {
                    filtro: [
                        `Code. Centro`,
                        `Plant`,
                        `Center`
                    ],
                    formulario: [
                        `Plant`,
                        `Center`,
                        `Code`,
                        `Contact person`,
                        `Email`,
                        `Direction`,
                        `Phone`
                    ]
                }
            ],
            estructuraCentroEliminar: [
                `Delete Center`,
                `To return`,
                `Select Center:`,
                `Search by: center the code`,
                {
                    filtro: [
                        `Code. Centro`,
                        `Plant`,
                        `Center`
                    ],
                    formulario: [
                        `Plant`,
                        `Center`,
                        `Code`,
                        `Contact person`,
                        `Email`,
                        `Direction`,
                        `Phone`,
                        `Remove`,
                        `Cancel`
                    ],
                    alertaConfirmacion: [
                        `Confirmation!`,
                        `Do you want to delete the record?`,
                        `Yes`,
                        `No`
                    ],
                    alertaError: [
                        `Error!`,
                        `An error was generated while deleting the record`
                    ],
                    alertaSuccess: [
                        `Nice job`,
                        `The record has been deleted successfully`
                    ],
                }
            ],
            estructuraCentroReporte: [
                `Report Centers`,
                `Create Center`,
                {
                    tabla: [
                        `Plant`,
                        `Center`,
                        `Contact`,
                        `Email`,
                        `Code`,
                        `Direction`
                    ]
                }
            ],
            estructuraAlmacen: [
                `Warehouse`,
                `Plant`,
                `Center`,
                `Warehouse Name:`,
                `Warehouse Name`,
                `Warehouse Code:`,
                `Warehouse Code`,
                `Contact person:`,
                `Contact person`,
                `Email:`,
                `Email`,
                `Direction:`,
                `Direction`,
                `Mobile phone:`,
                `Mobile phone`,
                `Type of Administration:`,
                `Type of Administration`,
                `save`,
                `You must select plant`,
                `You must select center`,
                `You must enter the store name`,
                `You must enter the store code`,
                `You must enter the store contact person`,
                `You must enter the store email`,
                `You must enter the store address`,
                `You must enter the store phone`,
                `You must select type of administration`,
                `Error registering store, there is already a store with the same name or code`,
                `Store created successfully!`,
            ],
            estructuraAlmacenModificar: [
                `Edit Warehouse`,
                `To return`,
                `Select Warehouse:`,
                `Search by: Warehouse or code`,
                `Cannot modify store because it does not exist`,
                `Warehouse modified`,
                `Error getting title`,
                `Are you sure to delete the warehouse?`,
                `The store cannot be deleted because it is assigned to a user`,
                `Store deleted successfully`,
                `The store was not deleted due to unknown errors`,
            ],
            estructuraAlmacenEliminar: [
                `Delete Warehouse`,
                `To return`,
                `Select Warehouse:`,
                `Search by: Warehouse or code`,
            ],
            estructuraAlmacenReporte: [
                `Warehouse Report`,
                `Create Warehouse`,
            ],
            estructuraLocalizacion: [
                `Create Location`,
                `Name Location`,
                `Location Code`,
                `Location was created successfully!`,
                `Error registering location, there is already a location with the same name or code`,
                `Edit Location`,
                `Search by: Location code`,
                `Select Location`,
                `Location successfully updated`,
                `Location was successfully deleted`,
                `The location cannot be modified because it does not exist`,
                `Location Report`
            ],
            contabilidad: [
                `Accounting`,
                `Cost center`,
                `Branch office`,
                `Destination`,
                `Suppliers`,
            ],
            contabilidadCentroCostoCrear: [
                `Create Cost Center`,
                `Code`,
                `Code`,
                `Cost center`,
                `Create`,
                `The cost center cannot be created because there is already one with the same name or code`,
                `The cost center was created!`,
                `The cost center cannot be updated because there is already one with the same name or code`,
                `The cost center was modified!`,
                `The cost center does not exist`,
            ],
            contabilidadCentroCostoModificar: [
                `Edit Cost Center`,
                `To return`,
                `Select cost center:`,
                `Search by: code or description`,
            ],
            contabilidadCentroCostoEliminar: [
                `Are you sure to remove this Cost Center?`,
                `To return`,
                `Select cost center:`,
                `Search by: code or description`,
                `The cost center was successfully removed!`,
                `It is not possible to eliminate the cost center, because it is already used in a reservation!`,
                `Remove Cost Center`
            ],
            contabilidadCentroCostoReporte: [
                `Cost Center Report`,
                `Create Cost Center`,
                `Import center costs`,
            ],
            contabilidadCentroCostoImporte: [
                `[Cost center
                 ]`,
                `Enter the required information in the file and save the changes for later upload`,
                `Click on "Continue" to proceed to the file selection phase, select the file and click on
                          "Continue" to start saving. When the process is finished, a log of the
                          result of the operation which you can print by clicking on "Print"`,
            ],
            contabilidadDestinoCrear: [
                `Create Destination`,
                `Destination Code`,
                `Destination name`,
                `Create`,
                `The record was created`,
                `The destination cannot be created because the code or name is already registered`,
            ],
            contabilidadDestinoModificar: [
                `Edit Destination`,
                `To return`,
                `Select Destination:`,
                `Search by: code or destination`,
                //      `Buscar por: Codigo o nombre del destino`,
                `Registry changed`,
                `The destination could not be modified because the code or name is already registered`,
                `The destination could not be modified because the destination does not exist`,
                `Are you still editing this record?`
            ],
            contabilidadDestinoEliminar: [
                `Delete Destination`,
                `To return`,
                `Select Destination:`,
                `Search by: code or destination`,
            ],
            contabilidadDestinoCEliminar: [
                `Are you sure to delete this Destination?`,
                `To return`,
                `Select cost center:`,
                `Search by: code or description`,
                `Destination was successfully deleted!`,
                `It is not possible to delete the destination, because it is already assigned in a reservation!`,
                `Delete Destination`
            ],
            contabilidadDestinoReporte: [
                `Destination Report`,
                `Create Destination`,
            ],
            contabilidadDestinoImporte: [
                `Bulk destination load`,
                `Create Destination`,
                `Destination`,
            ],
            // Contabilidad - Sucursal -(Crear-Modificar-Eliminar-Reporte)
            contabilidadSucursalCrear: [
                `Business line`,
                `Sector`,
                `Branch offices`,
                `Create business line`,
                `Business line modification`,
                `Delete business line`,
                `Business lines report`,
                `Import business line`,
                `Create sector`,
                `Modify Sector`,
                `Delete sector`,
                `Sector Report`,
                `Import sector`,
                `Create branch`,
                `Modify Branch`,
                `Delete branch`,
                `Branch Report`,
                `Branch Amount`,
                `Get sector id`,
            ],
            createLineaNegocio: [
                `Business Line Code`,
                `Business Line Name`,
                `Create business line`,
                `The business line cannot be created because the code or name is already being used`,
                `The business line was registered!`,
            ],
            lineaNegocioEliminar: [
                `Are you sure to eliminate this line of business?`,
                `To return`,
                `Select business line:`,
                `Business line was successfully deleted!`,
                `It is not possible to eliminate the business line, because it is already used in a sector!`,
                `Delete business line`,
                `We have a problem performing this action.`,
            ],
            modificarLineaNegocio: [
                `Edit business line`,
                `Select business line`,
                `Search by: code or name of the business line`,
                `Edit business line`,
                `The business line cannot be edited because the code or name is already being used`,
                `The business line was modified!`,
                `The business line does not exist`,
            ],
            crearSector: [
                `Select business line`,
                `Sector Code`,
                `Sector Name`,
                `The sector was created`,
                `The sector cannot be created, the code or name is already registered for the business line`,
            ],
            sectorEliminar: [
                `Are you sure to eliminate this sector?`,
                `To return`,
                `Select sector:`,
                `Sector deleted successfully!`,
                `It is not possible to eliminate the sector, because it is already used in a branch!`,
                `Delete sector`,
                `We have a problem performing this action.`,
            ],
            modificarSector: [
                `Select sector`,
                `Search by: business line code, business line name, sector code or sector name`,
                `The sector was updated`,
                `The sector cannot be modified because the code or name is already registered`,
                `The sector cannot be modified because it does not exist`,
            ],
            contabilidadDestinoCargueMasivo: [
                `Load Massive Branch`,
                `To return`,
                `Select Destination:`,
                `Search by: code or destination`,
            ],
            contabilidadSucursalReporte: [
                `Branch Report`,
                `Create Branch`,
            ],
            contabilidadProveedorCrear: [
                `Create provider`,
                `Document number`,
                `Corporate name or full names`,
                `Select the location data in order to get the zip code`,
                `Mobile Number`,
                `Telephone number`,
                `Extension`,
                `Email 1`,
                `Email 2`,
                `Website`,
                `Telephone or cell phone number`,
                `The provider cannot be added because the company name or document number is already registered`,
                `The supplier registered`,
            ],
            contabilidadProveedorModificar: [
                `Edit Provider`,
                `Select the provider`,
                `Search by: Nit the Company Name`,
                `Business name`,
                `The supplier cannot be modified because the company name or document number is already registered`,
                `The provider was modified`,
                {
                    form_previsualizar_contabilidad_proveedor: [
                        `Basic information`,
                        `Provider`,
                        `Economic activity`,
                        `Contact person`,
                        `Document`,
                        `Email`,
                        `Direction`,
                        `Phone`,
                        `Edit`,
                        `Remove`,
                        `Cancel`,
                        `Enable`,
                        `Disable`,
                        `Enabled`,
                        `Disabled`,
                        `enable`,
                        `disable`,
                        `save`,
                        `Cancel`,
                        `State`
                    ],
                    confimacionEditar: [
                        `Confirmation`,
                        `Are you sure you edit this record?`,
                        `Yes`,
                        `No`,
                        `Nice job`,
                        `The record was edited successfully`,
                        `!Error¡`,
                        `Failed to edit the registry due to server issues`
                    ],
                    confimacionIn_Habilitar: [
                        `Confirmation`,
                        `Are you sure you want `,
                        `Yes`,
                        `No`,
                        `Nice job`,
                        `The record was edited successfully`,
                        `Error!`,
                        `The selected action could not be performed due to problems with the server`,
                        ` this record?`
                    ],
                    confimacionEliminar: [
                        `Confirmation`,
                        `Are you sure to edit this record?`,
                        `Yes`,
                        `No`,
                        `Nice job`,
                        `The record was deleted successfully`,
                        `Error!`,
                        `Failed to delete registry due to server issues`
                    ]
                }
            ],
            contabilidadProveedorEliminar: [
                `Delete provider`,
            ],
            contabilidadProveedorLista: [
                `Supplier Report`,
                `Comercial activity`,
            ],
            contabilidadProveedorImporte: [
                `Mass provider load`,
                `Click on the following link to generate and download the file`,
                `[Generate and download
                 ]`,
                `Select: country, department and municipality to obtain the zip code`,
                `Id a registrar`,
            ],
            //Sucursal
            createSucursal: [
                `Branch Code`,
                `Branch Name`,
                `The branch was created`,
                `The branch could not be created because the name or code is already registered`,
                `The branch could not be created because the sector does not exist`,
            ],
            modificarSucursal: [
                `Select the branch`,
                `Search by: code and business line name, code and sector name, code and branch name`,
                `Changes saved`,
                `Could not update because the code or name is already registered`,
                `Could not update because the branch does not exist`,
                `Are you sure to delete the branch?`,
                `Information was deleted successfully`,
                `The branch cannot be deleted because it is in use`,
            ],
            importarSucursal: [
                `Select the line of business and then the sector to obtain the id of the sector`,
            ],
            //

            importarMateriales: [
                `Cataloging`,
                `External`,
                `Warehouse classes here`,
                `Open the file "ListaMateriales_empresa.xlsx" with an application of spreadsheets (Excel, LibreOffice Cal ...) and fill in the following fields taking into account the observations:`,
                {
                    alertSuccess: [
                        `Nice job!`,
                        `Registration was successful`,
                        `Successful records`
                    ],
                    alertError: [
                        `Error!`,
                        'Registration failed, errors are shown below',
                        `Error List`
                    ],
                    alertErrorNoAlmacen: [
                        `Error!`,
                        `The active company does not have any related storage`
                    ],
                    alertErrorGeneral: [
                        `Error performing the selected action`
                    ]
                },
                `Assignment of external material to warehouse, load bulk`
            ],
            materialAlmacenIndividual: [
                `Assign material to storage`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Catalogue`,
                `-- Select --`,
                `Add`,
                `save`,
                `No information found`,
                `Add material`,
                {
                    tabla: [
                        `Warehouse Id`,
                        `Warehouse`,
                        `Code. SLI`,
                        `Material`,
                        `Description`,
                        `Action`,
                        'Selected classes',
                        `Id Material`
                    ],
                    tablaCatalogo: [
                        `SLI code`,
                        `Class`,
                        `Maker`,
                        `References`,
                        `Long description`,
                        `Short description`,
                        `Action`,
                        `Catalog inquiry`
                    ],
                    alertItemRepetido: [
                        `Error!`,
                        `The material:`,
                        `It is already associated with the store:`,
                        `Ok`,
                        `Error getting material`
                    ],
                    alertError: [
                        `Error!`,
                        `You have not selected any value to add`,
                        `Ok`
                        , `An error was generated when obtaining the materials, please try again`
                    ],
                    alertErrorNoAlmacen: [
                        `Error!`,
                        `You have not selected a warehouse`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `Error!`,
                        `No class was found with that information`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmation`,
                        `Do you want to save the record?`,
                        `Yes`,
                        `No`
                    ],
                    alertSucces: [
                        `Nice job`,
                        `The material was assigned to the warehouse successfully`
                    ],
                    alertErrorDb: [
                        `¡Error!`,
                        `The following information is already registered:`,
                        `Warehouse`,
                        `Material`,
                        `Please correct it and try again`,
                        `A system error was generated, please take a screenshot and contact the administrator`,
                        `Records in conflict`
                    ]
                    , form: [
                        , `Initial range of records in which you want to consult`
                        , `Final range of records in which you want to consult`
                        , `The maximum range in which you can consult is 3000`
                        , `You must fill in the query fields to generate the file`
                        , `Specific search`
                        , `This query allows you to obtain up to 1000 materials, counting from the number entered`
                        , `Specific`
                    ]
                },
                `Cancel`,
                `Search by: SLI Code, Class, Abbreviation and Manufacturer`
            ],
            materialAlmacenIndividualEditar: [
                `Assign class to warehouse`,
                {
                    formulario: [
                        `Code. SLI`,
                        `Class`,
                        `Warehouse`,
                        `Center`,
                        `Plant`,
                        `Select Class`,
                        `Search by: Manufacturer, Cod. SLI, Class, Abbreviation, Store, Cod. They store`,
                        `save`,
                        `Cancel`,
                        `Report imported classes`
                        , `Registration from where the query begins`
                        , `Number of records to consult`
                        , `The maximum amount of materials you can get is 1000`
                        , `You must fill in the query fields to generate the file`
                    ],
                    tabla: [
                        `Warehouse Id`,
                        `Warehouse`,
                        `Code. SLI`,
                        `Material`,
                        `Description`,
                        `Action`,
                        'Selected classes',
                        `Id Material`
                    ],
                    tablaCatalogo: [
                        `Code. SLI`,
                        `Class`,
                        `Long description`,
                        `Warehouse`,
                        `Center`,
                        `Plant`,
                        ,
                        `Short description`,
                        `Action`,
                        `Catalog inquiry`
                    ],
                    alertItemRepetido: [
                        `Error!`,
                        `The material:`,
                        `It is already associated with the store:`,
                        `Ok`,
                        `Error getting material`
                    ],
                    alertError: [
                        `Error!`,
                        `An error was generated while performing this action`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `Error!`,
                        `You have not selected a warehouse`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `Error!`,
                        `No class was found with that information`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmation`,
                        `Do you want to enable / disable these records?`,
                        `Yes`,
                        `No`
                    ],
                    alertSucces: [
                        `Nice job`,
                        `Successfully selected stores enabled / disabled`
                    ],
                    alertErrorDb: [
                        `Error!`,
                        `The following relationship is already in the database:`,
                        `; So the actions were canceled, please correct and try again`,
                        `Warehouse Id:`,
                        `Id material:`,
                        `A system error was generated, please take a screenshot and contact the administrator`
                    ]
                }
            ],
            materialExternoCrear: [
                `Load external material`,
                {
                    formulario: [
                        `Old code`,
                        `Description`,
                        `References`,
                        `Obsertvation`,
                        `save`,
                        `Cancel`
                    ],
                    modalDuplicado: [
                        `Duplicate Materials`,
                        `Close`
                    ],
                    alertConfirmacion: [
                        `Confirmation`,
                        `Do you want to import this material into SLI?`,
                        `Yes`,
                        `No`
                    ],
                    alertSuccess: [
                        `Nice job!`,
                        `Registration has been completed successfully!`,
                    ],
                    alertError: [
                        `Error!`,
                        `An error was generated when registering`,
                    ],
                    alertErrorDuplicado: [
                        `Error!`,
                        `The old code entered is already registered in the system`,
                        `See duplicate code`
                    ],
                    referenciaRepetida: [
                        `Error!`,
                        `This reference is already added`,
                    ],
                }
            ],
            cargueMasivoMaterialExterno: [
                `Load massive external catalog`,
                `1. Instructional`,
                `2. Upload and validation`,
                `3. Load log`,
                {
                    step1: [
                        `Download the file`,
                        `formatoCargueMasivoCatalogoExterno.xlsx`,
                        `Open the "LoadMasiveCatalogoExterno.xlsx" file with a spreadsheet application (Excel, LibreOffice Cal ...) and
                                  fill in the following fields taking into account the observations:`,
                        `to. Field Name, Length: X, Variable Type: Y`,
                        `b. Field Name, Length: X, Variable Type: Y`,
                        `C. Field Name, Length: X, Variable Type: Y`,
                        `Save the file to your computer`
                    ],
                    step2: [
                        `Select File`,
                        `Click on the button to attach the file`,
                        `Old code`,
                        `Description`,
                        `References`,
                        `Observation`,
                        `Action`
                    ],
                    step3: [],
                    opciones: [
                        `attracted`,
                        `Continue`
                    ],
                    logErrores: [
                        `Error List`,
                        `Position`,
                        `Old code`,
                        `Description`,
                        `References`,
                        `Observation`,
                        `Error`,
                        `Error, failed to connect to server`,
                        `Error, duplicate registration`
                    ]
                }
            ],
            asignar_externo_a_almacen: [
                `Assign material to storage`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Catalogue`,
                `-- Select --`,
                `Add`,
                `save`,
                `No information found`,
                `Add material`,
                {
                    tabla: [
                        `Warehouse Id`,
                        `Warehouse`,
                        `Code Old`,
                        `Additional data`,
                        `References`,
                        `Action`,
                        'Associated classes',
                        `Id Material`
                    ],
                    tablaCatalogo: [
                        `Code Old`,
                        `Additional data`,
                        `References`,
                        `Observation`,
                        `Action`,
                        `Catalog inquiry`
                    ],
                    alertItemRepetido: [
                        `Error!`,
                        `This material is already associated with the warehouse:`,
                        `Ok`
                    ],
                    alertError: [
                        `Error!`,
                        `You have not selected any value to add`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `Error!`,
                        `You have not selected a warehouse`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `Error!`,
                        `No class was found with that information`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmation`,
                        `Do you want to save the record?`,
                        `Yes`,
                        `No`
                    ],
                    alertSucces: [
                        `Nice job`,
                        `The material was assigned to the warehouse successfully`
                        , `Material added to `
                    ],
                    alertErrorDb: [
                        `Error!`,
                        `There have been problems in making the following records:`,
                        `; so the actions were canceled, please correct and try again`,
                        `Store ID:`,
                        `Id material: `
                    ]
                },
                `Cancel`,
                `Search by: SLI Code, Class, Abbreviation and Manufacturer`
            ],
            editar_cargue_externo: [
                `Enable / Disable external class to store`,
                {
                    formulario: [
                        `Code. ERP`,
                        `Additional`,
                        `References`,
                        `Warehouse`,
                        `Center`,
                        `Select Class`,
                        `Search by: Cod. Antiguo, Description, References, Cod. Warehouse, Warehouse, Cod. Centro, Centro, Cod. Planta, Planta`,
                        `save`,
                        `Cancel`,
                        `Report imported classes`,
                        `Plant`
                    ],
                    tabla: [
                        `Warehouse Id`,
                        `Warehouse`,
                        `Code. SLI`,
                        `Material`,
                        `Description`,
                        `Action`,
                        'Selected classes',
                        `Id Material`
                    ],
                    tablaCatalogo: [
                        `Code. ERP`,
                        `Description`,
                        `References`,
                        `Observation`,
                        `Cod. Warehouse`,
                        `Warehouse`,
                        `Center`,
                        `Plant`,
                        `Action`,
                        `Warehouses associated with classes`
                        , `Code. SLI`
                    ],
                    alertItemRepetido: [
                        `Error!`,
                        `The material:`,
                        `It is already associated with the store:`,
                        `Ok`
                    ],
                    alertError: [
                        `Error!`,
                        `An error was generated while performing this action`,
                        `Ok`
                    ],
                    alertErrorNoAlmacen: [
                        `Error!`,
                        `You have not selected a warehouse`,
                        `Ok`
                    ],
                    alertErrorMaterialNoFound: [
                        `Error!`,
                        `No class was found with that information`,
                        `Ok`
                    ],
                    alertConfirmacion: [
                        `Confirmation`,
                        `Do you want to enable / disable these records?`,
                        `Yes`,
                        `No`
                    ],
                    alertSucces: [
                        `Nice job`,
                        `Successfully selected stores enabled / disabled`
                    ],
                    alertErrorDb: [
                        `Error!`,
                        `There were problems when making the following records:`,
                        `; so the actions were canceled, please correct and try again`,
                        `Store id:`,
                        `Id material:`
                    ]
                },
                `Report of external classes assigned to warehouses`
            ],
            administracionMateriales: [
                `Class Administration`,
            ],
            reserva: [
                `Product reservation`,
                `Create Reservation`,
                `Approval Reserve`,
                `Reservation attention`,
                `Reserve Report`,
                `General information`,
                `Add product`,
                `Report`,
                `Edit Reservation`,
                `Tab`,
                `A number will be assigned when the record is saved`,
                `Warehouse of origin`,
                `Destination warehouse`,
            ],
            crearReserva: [
                `Create Reservation`,
                `Reports`,
                `No. Reserve:`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Transfer to warehouse`,
                `Destination Plant:`,
                `Destination Center:`,
                `Destination Warehouse:`,
                `Consult classes`,
                `Look for`,
                `Observation:`,
                `Create`,
                `Submit`,
                `Remove`,
                `Log of Reserve:`,
                `Destination Plant`,
                `Destination Center`,
                `Destination Warehouse`,
                `Branch office`,
                `Destination`,
                `Singing. reservation`,
                `New Qty`,
                `Cant. used`,
                `Cant. repaired`,
                `Base Unit of Measure`,
                `Add products`,
                `List of reserved products`,
                `(Empty)`,
                `Are you sure to create the reservation? You can modify it later`,
                `Are you sending the reservation? It cannot be modified later`,
                `Are you sure to clean the form?`,
                `Create`,
                `Submit`,
                `Clean form`,
                `There are no products to add`,
                `You must select the destination store`,
                `The information was registered correctly, the reservation code is:`,
                `Error saving information`,
                `To update`,
                `You must fill in the fields marked with asterisks and select at least one material to create the reservation`,
                `Inventory info`
                , `Desc. ancient`
            ],
            aprovacionReserva: [
                `Reservation Approval`,
                `Reservations:`,
                `Look for`,
                `Observation:`,
                `Approve`,
                `To refuse`,
                `Cancel`,
                `Creator user`,
                `Creation date from`,
                `Creation date until`,
                `Warehouse of origin`,
                `Destination warehouse`,
                `Cost center`,
                `Branch office`,
                `Destination`,
                `Creator user`,
                `Creation date`,
                `Action`,
                `Reserve Products`,
                `SLI code`,
                `Short description`,
                `Unit of measurement`,
                `Reserved Amount`,
                `Click to see detailed reservation information`,
                `Are you sure to reject the reservation?`,
                `Are you sure you approve the reservation?`,
                `There was an error updating the information`,
                `The changes were saved correctly!`,
                `Incomplete reservation movements`,
                `Select the movement you want to continue with`,
                `Select`,
                `New`,
                `Continue`,
                `Origin plant`,
                `Origin center`,
                `Destination plant`,
                `Destination center`,
                `Reservation`,
                `Additional data`,
            ],
            modificacionReserva: [
                `Edit Reservation`,
                `State:`,
                `State`,
                `Reservation:`,
                `Reservation`,
                `Look for`,
                `Are you sure you save your reservation changes? You can modify it later`,
                `Search by reservation code`,
                `There are no records with the indicated parameters`,
                `Reservation code`,
                `Number of products`,
                `Total number of products`,
                `Attention`,
                `This request has been rejected`,
                `You no longer have the origin store assigned, you must assign another to the reservation`,
                `You no longer have the destination warehouse assigned, you must assign another to the reservation`,
                `You no longer have the cost center, branch or destination assigned, you must reassign those that are empty`,
            ],
            reporteReserva: [
                `Booking Report`,
                `return`,
                `RESERVATIONS REPORT`,
                `Qty delivered`,
                `Singing. missing`,
            ],
            movimientos: [
                `Movements`,
                `Entry`,
                `Transfer`,
                `Product output`,
                `Low`,
                `Return`,
                `Create entry`,
                `Edit Entry`,
                `Input report`,
                `Create Output`,
                `Modify Output`,
                `Departure Report`,
                `Create Assignment`,
                `Modify Assignment`,
                `Assignment Report`,
                `Create Low`,
                `Modify Low`,
                `Withdrawal Report`,
                `Create Transfer`,
                `Modify Transfer`,
                `Transfer Report`,
                `Departure`,
                `Office`,
                `Assignment`
            ]
            , movimientoTrasladoTuberia: {
                alertas: [
                    `The transfer has been successfully updated with the consecutive 104-`
                    , `There are no movements to modify`
                ]
            }
            , movimientosBajaHerramienta: [
                'Entry'
                , `General entry information`
                , `Product inquiry`
                , `Plant`
                , `Center`
                , `Warehouse`
                , `Supplier`
                , `Referral`
                , `Purchase order`
                , `Invoice`
                , `Cost Center`
                , `Branch Office`
                , `Destination`
                , `Select`
                , {
                    tablaDeConsulta: [
                        `SLI`
                        , `Cod. ERP`
                        , `Product`
                        , `Description`
                        , `UMB`
                        , `References`
                        , `Quantity and physical state`
                        , `Price`
                        , `Tax`
                        , `Total`
                        , `See more ...`
                        , `See less`
                        , `Add`
                        , `Delete`
                        , `Cod. Old`
                        , `Old description`
                        , `Action`
                        , `Query result`
                    ]
                    , alertaBusqueda: [
                        `Attention!`
                        , `No records were found with the indicated parameters`
                        , `Query section`
                        , `Search by: Plant, center, warehouse, supplier, referral, purchase order, invoice, cost center, branch, destination or material`
                        , `No items found`
                        , `Search by: Plant, center, warehouse, cost center, branch, destination`
                        , `Sorry, you don't have any associated storage in this type of administration`
                        , `please contact the system administrator`
                    ]
                    , alertaAdicionado: [
                        ` Error! `
                        , `This product is already added`
                        , `0 Stock`
                    ]
                    , logErrores: [
                        `Error log`
                        , `SLI`
                        , `Material`
                        , `Error`
                    ]
                    , alertas: [
                        `The material you want to move is not assigned to the destination store or is disabled for that store`
                        , `The entry was successfully created with the consecutive 101-`
                        , `Good job!`
                        , `The entry has been modified successfully with the consecutive 101-`
                        , `The selected entry could not be obtained`
                        , `Warning the information was not saved, since the serial or board already exist.`
                        , `Error table`
                        , `SLI`
                        , `Old Code`
                        , `Serial`
                        , `Plate`
                        , `Error`
                        , `Duplicate Plate`
                        , `Serial duplicate`
                        , `Material not associated with storage`
                    ]
                    , reporte: [
                        `Report tickets`
                        , `Create entry`
                        , `Consecutive`
                        , `SLI`
                        , `State`
                        , `Warehouse`
                        , `Material`
                        , `Description`
                        , `Quantity`
                        , `Price`
                        , `Tax`
                        , `Total`
                        , `E. Physical`
                        , `Information`
                    ]
                    , alertErrorReporte: [
                        `Error`
                        , `The result of the query is not adequate`
                        , `There are no materials`
                    ]
                    , estadosMovimiento: [
                        `Created`
                        , `Finished`
                        , `Canceled`
                        , `Error`
                    ]
                }
                , `Save`
                , `Send`
                , `Cancel`
                , `Selected materials`
                , `Cancel movement`

            ]
            , movimientosEntradaProducto: [
                'Entry',
                `General information about the entrance`,
                `Product inquiry`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Provider`,
                `Remission`,
                `Purchase order`,
                `Invoice`,
                `Cost center`,
                `Branch office`,
                `Destination`,
                `Select`,
                {
                    tablaDeConsulta: [
                        `FIG`,
                        `Code. ERP`,
                        `Product`,
                        `Description`,
                        `UMB`,
                        `References`,
                        `Quantity and fitness`,
                        `Price`,
                        `Tax`,
                        `Total`,
                        `see more...`,
                        `See Less`,
                        `Add`,
                        `Remove`
                        , `Cod. Old`
                        , `Old description`
                    ],
                    alertaBusqueda: [
                        `Attention!`,
                        `No records were found with the indicated parameters`,
                        `Query Section`,
                        `Search by: Plant, center, warehouse, supplier, referral, purchase order, invoice, cost center, branch, destination or material`,
                        `No items found`,
                        `Search by: Plant, center, warehouse, cost center, branch, destination`,
                        `Sorry, you do not have any associated storage in this type of administration`,
                        `please contact the system administrator`
                        , `Search record`
                        , `No pending movements were found` //9
                    ],
                    alertaAdicionado: [
                        `Error!`,
                        `This product is already added`
                    ],
                    logErrores: [
                        `Error log`
                    ],
                    alertas: [
                        `The movement was not successful due to failures with the records or the material is not assigned to the warehouse.`,
                        `Entry successfully created with consecutive 101-`,
                        `Nice job!`,
                        `The entry has been modified successfully with the consecutive 101-`,
                        `Failed to get selected entry`,
                        `Warning the information was not saved, since the serial or board already exist.`
                        , `Error table`
                        , `SLI`
                        , `Old Code`
                        , `Serial`
                        , `Plate`
                        , `Error`
                        , `Duplicate Plate`
                        , `Serial duplicate`
                        , `Material not associated with storage`
                    ],
                    reporte: [
                        `Report tickets`,
                        `Create entry`,
                        `Consecutive`,
                        `FIG`,
                        `State`,
                        `Warehouse`,
                        `Material`,
                        `Description`,
                        `Quantity`,
                        `Price`,
                        `Tax`,
                        `Total`,
                        `E. Physical`,
                        `information`
                    ],
                    alertErrorReporte: [
                        `Error`,
                        `The result of the query is not adequate`,
                        `There are no materials`
                    ],
                    estadosMovimiento: [
                        `Created`,
                        `Finalized`,
                        `Canceled`,
                        `Error`
                    ]
                },
                `save`,
                `Submit`,
                `Cancel`,
                `Selected Materials`,
                `Cancel movement`
            ],
            movimientosTrasladoProducto: [
                'Transfer',
                `General information about the transfer`,
                `Product inquiry`,
                `Plant`,
                `Center`,
                `Warehouse`,
                `Provider`,
                `Remission`,
                `Purchase order`,
                `Invoice`,
                `Cost center`,
                `Branch office`,
                `Destination`,
                `Select`,
                {
                    tablaDeConsulta: [
                        `SLI`,
                        `Product`,
                        `Description`,
                        `Description`,
                        `UMB`,
                        `References`,
                        `Quantity`,
                        `Price`,
                        `Tax`,
                        `Total`,
                        `see more...`,
                        `See Less`,
                        `Add`,
                        `Remove`,
                        `Singing. Requested`,
                        `No. Transfer`,
                        `Location`,
                        `State`
                    ],
                    alertaBusqueda: [
                        `Attention!`,
                        `No records were found with the indicated parameters`,
                        `Query Section`,
                        `Search by: Plant, center, warehouse, supplier, referral, purchase order, invoice, cost center, branch, destination or material`,
                        `No items found`,
                        `Destination of the transfer`,
                        `Origin of the transfer`,
                        `General information`,
                        `You must select different stores!`,
                        `This field will be enabled as soon as you select the source store`
                    ],
                    alertaAdicionado: [
                        `Error!`,
                        `This product is already added with the same supplier, if you want to enter it again change the supplier`
                    ],
                    confirmacion: [
                        `Confirmation`,
                        `Are you sure you want to cancel the transfer 104-`,
                        `?`,
                        `Yes`,
                        `No`
                    ],
                    logErrores: [
                        `Error log`
                    ],
                    modalMaterial: [
                        `Add material to the transfer`,
                        `Provider`,
                        `Visual state`,
                        `Availability`,
                        `Price`,
                        `Quantity`,
                        `Material`,
                        `SLI code`,
                        `Description`,
                        ` Without materials`
                    ],
                    estadoVisual: [
                        `New`
                        , `Used`
                        , `Repaired`
                        , `For repair`
                        , `Damaged`
                    ],
                    alertas: [
                        `The move failed to perform due to record failures`,
                        `The transfer was successful with the consecutive 104-`,
                        `Nice job!`,
                        `The transfer has been modified successfully with the consecutive 104-`,
                        `The selected transfer could not be obtained`
                    ],
                    reporte: [
                        `Transfer Report`,
                        `Create transfer`,
                        `Consecutive`,
                        `FIG`,
                        `State`,
                        `Warehouse`,
                        `Material`,
                        `Description`,
                        `Quantity`,
                        `Price`,
                        `Tax`,
                        `Total`
                    ],
                    estadosMovimiento: [
                        `Created`,
                        `Finalized`,
                        `Canceled`,
                        `Error`
                    ],
                    alertaDivisas: [
                        `Conversion rate`,
                        `Remember that to enable material search you must select a source store`,
                        `TRM`,
                        `Uniform`,
                        `Value`,
                        `Return Currency`
                    ],
                    divisas: [
                        `Colombian peso`,
                        `Dollar`,
                        `Euro`,
                        `Dollar Canadience`
                    ],
                },
                `save`,
                `Submit`,
                `Cancel`,
                `Selected Materials`,
                `Cancel movement`
            ],
            movimientosEntradaTuberia: [
                {
                    form: [
                        `Location`,
                        'Observation',
                        `No. tube`,
                        `MT`,
                        `FT`,
                        `IN`,
                        `Materials Master Report`
                    ],
                    modal: [
                        `Long desc.`,
                        `SLI code`,
                        `Short desc.`,
                        `Serial`,
                        `Board`,
                        `Quantity`,
                        `Price`,
                        `Tax`,
                        `Discount`,
                        `Material state`,
                        `Total`
                    ],
                    alertas: [
                        `It is not possible to save, because no information has been filled out`,
                        `Attention`,
                        `The destination Warehouse field has not been filled out`,
                        `The cost center field has not been filled out`,
                        `The Branch field has not been filled out`,
                        `The Destination field has not been filled out`,
                        `No dispatch value has been registered`,
                        `The observation field has not been filled out`,
                        `The dispatch has been successfully generated with the consecutive 101-`,
                        `Are you sure to perform this action?`,
                        `Completed records were deleted`,
                        `The information was partially saved`,
                        `The transfer was successfully generated with the consecutive 101-`,
                        `The Warehouse Origin field has not been filled out`,
                        `No value has been registered`,
                        `No records were found with the indicated parameters`,
                        `The withdrawal has been successfully generated with the consecutive 101-`,
                        `The return has been successfully generated with the consecutive 101-`,
                        `The withdrawal has been successfully updated with the consecutive 101-`,
                        `The dispatch has been successfully updated with the consecutive 101-`,
                        `The assignment has been successfully generated with the consecutive 101-`,
                        `The custodial field has not been filled out`,
                        `In one or more records, the serial field has not been filled out.`,
                        `In one or more records, the plaque field has not been filled out.`,
                        `The quantity field has not been filled out`,
                        `The price field has not been filled out`,
                        `The imposed field has not been filled out`,
                        `The Supplier field has not been filled out`,
                        `The product has been added`,
                        `You have not filled out any product to add it.`,
                        `The assignment has been successfully updated with the consecutive 101-`,
                        `The entry has been successfully updated with the consecutive 101-`,
                        `Correct operation!`,
                        `The output has been successfully updated with the consecutive 101-`,
                        `The transfer has been successfully updated with the consecutive 101-`,
                        `The Invoice field has not been filled out`,
                        `The Credit Note field has not been filled out`,
                        ``,
                        ``
                        , `The tube number you are trying to enter is already in the system` // 39
                        , `Movement successfully canceled` // 40
                        , `Error trying to cancel the movement` // 41
                    ]
                }
            ],
            movimientosEntrada: [
                `Remission:`,
                `Remission`,
                `Purchase order:`,
                `Purchase order`,
                `Invoice:`,
                `Invoice`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Provider:`,
                `Provider`,
                `No. Movement:`,
                `No. Movement`,
            ],
            movimientosTraslado: [
                `Origin:`,
                `Plant:`,
                `Plant`,
                `Center:`,
                `Center`,
                `Warehouse:`,
                `Warehouse`,
                `Reservation:`,
                `Reservation`,
                `Destination:`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Move`,
                `Remission:`,
                `Remission`,
            ],
            movimientosSalida: [
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Destination:`,
                `Reservation:`,
                `Reservation`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Remission:`,
                `Remission`,
                `Invoice No.:`,
                `Invoice No.`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Departure`,
                `Remove`,
                `Consult the products of the selected warehouse`,
                `Request`,
                `Are you sure to remove the product from the list?`,
                `Could not get text.`,
                `You must select a store`,
                `You must select a cost center`,
                `You must select a branch`,
                `You must select a destination`,
                `You must add at least one product`,
                `Edit output`,
                `Select the consecutive of the movement`,
                `Consecutive`,
                `Are you sure to cancel the movement?`,
                `The movement was canceled correctly!`,
                `Error canceling movement`,
                `Cancel booking`,
                `Clean form`,
                `You must select a consecutive`,
                `Create output`,
            ],
            movimientosDevolucion: [
                `Origin:`,
                `Plant:`,
                `Plant`,
                `Center:`,
                `Center`,
                `Warehouse:`,
                `Warehouse`,
                `Return Type:`,
                `Return Type`,
                `Movement:`,
                `Movement`,
                `No. Movement:`,
                `No. Movement`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Give back`,
            ],
            movimientosBaja: [
                `Origin:`,
                `Plant:`,
                `Plant`,
                `Center:`,
                `Center`,
                `Warehouse:`,
                `Warehouse`,
                `Discharge Approval`,
                `Reservation:`,
                `Reservation`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Low`,
                `No. Entry:`,
                `No. Entry`,
            ],
            planificacion: [
                `Planning`,
            ],
            tomaInventarios: [
                `Inventory taking`,
                `Interns`,
                `External`,
                `Taking inventory`,
                `Count`,
                `With file`,
                `No file`,
                `Load products`,
                `Count`,
                `Create product`,
                `Count`,
                `Count report`,
                `Conciliation care`,
                `External reconciliation report`,
                `Conciliation care`,
                `Reset Reconciliation`,
                `Inventory taking report`
            ],
            configMaterial: [
                `Config. Class`,
            ],
            reportes: [
                `Reports`,
            ],
            administracionActHerr: [
                `Asset Management and Tools`,
            ],
            reservaActHerr: [
                `Reserve tools / assets`,
                `Create Reservationation`,
                `Approval Reserve`,
                `Edit Reservationon`,
                `Reservation Attentionntion`,
                `Reserve Report`,
            ],
            reservaCrearReservaActHerr: [
                `Create Reservation`,
                `Reports`,
                `No. Reserve:`,
                `State:`,
                `Plant*`,
                `Center*`,
                `Warehouse*`,
                `Assignment`,
                `Transfer`,
                `Custodian*`,
                `Custodian`,
                `Destination Plant:`,
                `Destination Center:`,
                `Destination Warehouse:`,
                `Cost center*`,
                `Branch office*`,
                `Destination*`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Submit`,
                `Remove`,
                `Reserve Log:`,
            ],
            reservaModificarReservaActHerr: [
                `Edit Reservation`,
                `State:`,
                `State`,
                `Reservation:`,
                `Reservation`,
                `Look for`,
            ],
            reservaAprobacionReservaActHerr: [
                `Reservation Approval`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Observation:`,
                `Observation`,
                `Approve`,
                `To refuse`,
                `Cancel`,
                `Look for`,
                `Reports`,
            ],
            reservaReporteReservaActHerr: [
                `Booking Report`,
                `Plant:`,
                `Plant`,
                `Center:`,
                `Center`,
                `Warehouse:`,
                `Warehouse`,
                `State:`,
                `State`,
                `Since:`,
                `Since`,
                `Until:`,
                `Until`,
            ],
            movimientosActHerr: [
                `Tool / asset movements`,
                `Entry`,
                `Assignment`,
                `Return`,
                `Transfer`,
                `Low`,
                `Departure`,
            ],
            movimientosActHerrEntrada: [
                `Remission:`,
                `Remission`,
                `Purchase order:`,
                `Purchase order`,
                `Invoice:`,
                `Invoice`,
                `Provider:`,
                `Provider`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Consult classes`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Submit`,
                `Remove`,
            ],
            movimientosActHerrAsignacion: [
                `Assignment`,
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Reservation:`,
                `Reservation`,
                `Custodian:`,
                `Custodian`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Assign`,
                `Remove`,
            ],
            movimientosActHerrDevolucion: [
                `Return`,
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Return Type:`,
                `Return Type`,
                `Movement:`,
                `Movement`,
                `No.Movement:`,
                `No.Movement`,
                `Custodian:`,
                `Custodian`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Give back`,
                `Cancel:`,
            ],
            movimientosActHerrTraslado: [
                `Transfer`,
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Reservation:`,
                `Reservation`,
                `Destination:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Move`,
                `Remove`,
            ],
            movimientosActHerrBaja: [
                `Low`,
                `Origin:`,
                `Plant:`,
                `Center:`,
                `Warehouse:`,
                `Discharge Approval:`,
                `Reservation:`,
                `Reservation`,
                `Cost center:`,
                `Cost center`,
                `Branch office:`,
                `Branch office`,
                `Destination:`,
                `Destination`,
                `Consult classes:`,
                `Look for`,
                `Observation:`,
                `Observation`,
                `Create`,
                `Low`,
                `Remove`,
            ],
            planificacionActHerr: [
                `Planning`,
            ],
            tomaInventariosActHerr: [
                `Inventory taking`,
                `Interns`,
                `External`,
                `With file`,
                `No file`,
                {
                    "subMenuTomaInventario": [
                        `Inventory taking`,
                        `Count`,
                        `Inventory Taking`,
                        `Photographs`,
                        `Responsible`,
                        `Inventory count`,
                        `Search filter`,
                        `Warehouse`,
                        `The field`,
                        `The selected inventory take exceeds the count limit.`,
                        `A new count was successfully created`,
                        `The inventory was created satisfactorily and the first count for the assigned person was generated`,
                        `A count is already in progress`,
                        `In Process`,
                        `Finished`,
                        `Canceled`,
                        `Status`,
                        `Action`,
                        `There are no inventory shots for this warehouse`,
                        `The count was successfully canceled`,
                        `Inventory taking is canceled`,
                        `Inventory taking not detected`,
                        `Search result`,
                        `Provider * `,
                        `Material Status * `,
                        `Quantity * `,
                        `List of counted products`,
                        `Select the count to resume`,
                        `New`,
                        `Used`,
                        `Repaired`,
                        `You can search by material name, short description, supplier, serial and plate`,
                        `This warehouse has no inventory`,
                        `You cannot add an asset and / or tool already counted! `,
                        `SLI code`,
                        `Short cut`,
                        `Unit Measured`,
                        `Supplier`,
                        `Physical State`,
                        `Price`,
                        `Tax`,
                        `Serial`,
                        `Plate`,
                        `Add`,
                        `Click to search for cataloged materials without inventory`,
                        `Activate camera to read codes`,
                        `Click to load`,
                        `Adding Non-Inventory Material`,
                        `Consult for SLI Code, short description and / or material name`,
                        `At the end of the inventory taking, to save or finish the process, an internet connection is necessary and you must keep in mind that you cannot be offline for more than 2 hours or the system will not allow you to save.`,
                        `Internet connection was lost, please do not take any action until the connection is restored`,
                        `SLI code`,
                        `Cancel`,
                        `Add`,
                        `You do not have assigned counts`,
                        `Unable to send the count, as it has unsaved materials.`,
                        `The count was saved successfully`,
                        `The count was sent successfully`,
                        `Good job`,
                        `To fill out the excell file you must keep in mind The following mandatory fields.`,
                        `ERP code`,
                        `Unit of measurement`,
                        `Material status`,
                        `Short or long description`,
                        `Serial`,
                        `Board`,
                        `Mass load external inventory`,
                        `Warehouse`,
                        `Fields_External_Inventory.xlsx`,
                        `Responsible`,
                        `In the loaded file there are records with mandatory fields not filled out!`,
                        `You must select a person in charge!`,
                        `Take Inventory`,
                        `Inventory Take Report`,
                        `Materials List`,
                        `Action`,
                        `ERP code`,
                        `Serial`,
                        `Plate`,
                        `Warehouse`,
                        `Material Status`,
                        `UMB`,
                        `Price`,
                        `Short Description`,
                        `Long Description`,
                        `Manufacturer`,
                        `Supplier`,
                        `Reference`,
                        `Location`,
                        `Field 1`,
                        `Field 2`,
                        `Field 3`,
                        `Read Code`,
                        `Add`,
                        `You can search by ERP code, short description, provider, serial and license plate. You can also filter by * after the word.`,
                        `Observation`,
                        `Create reconciliation`,
                        `General Report`,
                        `The reconciliation was successfully created`,
                        `Reconcile`,
                        `Reconcile Inventory Taking`,
                        `Select the reconciliation`,
                        `--Select one-- `,
                        `Conciliation`,
                        `There is a count in progress`,
                        `You already have a reconciliation in progress`,
                        `Files added to the material`,
                        `File Name`,
                        `File Type`,
                        `Add New Material`,
                        `The file you are trying to upload is already added.`,
                        `No records`,
                        `The reconciliation was successfully sent`,
                        `Save`,
                        `Submit`,
                        `This reconciliation has already been sent`,
                        `The reconciliation was saved correctly`,
                        `Reconciliation Report`,
                        `Conciliation in process`,
                        `You must reconcile the product to load files`,
                        `Verify that I save the changes before submitting the reconciliation.`,
                        `Chancellor TIHA-`,
                        `Active internal inventory reconciliation`
                    ]
                }
            ],
            configActHerr: [
                `Conf. Act/tool`,
            ],
            custodiosActHerr: [
                `Custodians of tools / assets`,
                `Create Custodian`,
                `Edit Custodian`,
                `Remove Custodian`,
                `Custodian Report`,
                `Catalog in custodian`,
            ],
            custodiosActHerrCrear: [
                `Are you sure to create custodian `,
                `To return`,
                `Main data`,
                `Name*:`,
                `Name`,
                `Last name*:`,
                `Last name`,
                `Type Document*:`,
                `No. Document*:`,
                `No. Document`,
                `Active`,
                `Tool`,
                `Observation:`,
                `Observation`,
                `Position*:`,
                `ISIC code`,
                `Contact information`,
                `Postal Code:`,
                `Direction*:`,
                `Direction`,
                `Cell phone*:`,
                `Cell phone`,
                `Phone:`,
                `Phone`,
                `Contact person`,
                `Email*:`,
                `Email`,
                `save`,
                `Cancel`,
                `The custodian was created correctly`,
                `Create Custodian`,
            ],
            custodioEliminarActHerr: [
                `Remove Custodian`,
                `Select custodian to delete`,
                `Basic Information`,
                `Names`,
                `Surname`,
                `# document`,
                `Run`,
                `Delete`,
                `Cancel`,
            ],
            reportesActHerr: [
                `Reports`,
            ],
            administracionTuberias: [
                `Pipeline Administration`,
            ],
            reservaTuberias: [
                `Pipe Reserve`,
                `Create Reservation`,
                `Approval Reserve`,
                `Reservation attention`,
                `Reserve Report`,
                `Edit Reservation`,
            ],
            movimientosTuberias: [
                `Pipeline Movements`,
                `Pipe inlet`,
                `Pipeline Office`,
                `Pipeline return`,
                `Low pipe`,
                `Pipe Transfer`,
            ],
            planificacionTuberias: [
                `Planning`,
            ],
            tomaInventariosTuberias: [
                `Inventory pipeline`,
                `Interns`,
                `External`,
                `With file`,
                `No file`
            ],
            reportesTuberias: [
                `Pipeline Reports`,
            ],
            tomaInventarios_material: [
                `Inventory taking`,
                {
                    takingInventory: [
                        `Taking inventory`,
                        `Responsible`,
                        {
                            confirmnacion: [
                                `Confirmation`,
                                `Do you want to create a new count for the selected inventory taking?`,
                                `Do you want to create a new inventory take?`
                            ],
                            tableResultFilter: [
                                `SLI`,
                                `Material`,
                                `Kind`,
                                `Group`,
                                `Maker`,
                                `UMB`,
                                `Description`,
                                `Action`,
                                `Possible materials to inventory`
                            ],
                            SelectedMaterialsTable: [
                                `SLI`,
                                `Description`,
                                `UMB`,
                                `Provider`,
                                `E. Physical`,
                                `Quantity`,
                                `Price`,
                                `Tax`,
                                `Action`,
                                `Selected Materials`,
                                `Inventory taking report`
                            ],
                            materialInProcess: [
                                `Selected Catalog`,
                                `Catalog`,
                                `Form`,
                                `SLI`,
                                `Material`,
                                `Short description`,
                                `Unit of measurement`,
                                `Maker`,
                                `Long description`
                            ],
                            form: [
                                `Provider`,
                                `Physical state`,
                                `Quantity`,
                                `Code. SLI`,
                                `Description`,
                                `Price`,
                                `Tax`
                            ],
                            modal: [
                                `Inventory taking pending for this company`,
                                `Taking`,
                                `Counts`,
                                `Action`
                            ],
                            alerts: [
                                `Error`,
                                `The current user does not have associated counts`,
                                `Warning`,
                                `Internet connection was lost, please do not take any action until the connection is restored`,
                                `Without connection`,
                                `At the end of the inventory taking, to save or finish the process, an internet connection is necessary`,
                                `An error was generated when adding the selected material`,
                                `Some of the mandatory fields are empty or with a value not allowed, please rectify it`,
                                `Confirmation`,
                                `This material is already added, do you want to update your information?`,
                                `Yes`,
                                `No`,
                                `Failed to get the material you want to edit`,
                                `Are you sure you want to remove the material with the description?`,
                                `Are you sure to keep count number`,
                                `Are you sure you finish counting number`,
                                `Some of the materials you have added do not have a valid quantity, if any selected material has a zero quantity, please remove it from the list or add an amount`,
                                `An error was generated while saving the count, please try in a few minutes`,
                                `Count`,
                                `saved successfully`,
                                `Taking inventory`,
                                `does not have counts in process`,
                                `Do you want to add the product with the SLI code`,
                                `Nice job`,
                                `Inventory taking"`,
                                `"does not have counts in process`
                            ]
                        },
                        `Ejecutar conteo`
                    ],
                    takinExternalInventory_whitFile: [
                        `Take inventory from scratch`,
                        `Code`,
                        `Product`,
                        `Provider`,
                        `Description`,
                        `Reference`,
                        `Physical state`,
                        `Quantity`,
                        `Price`,
                        `Tax`,
                        {
                            tableResultFilter: [
                                `SLI`,
                                `Product`,
                                `Description`,
                                `P. state`,
                                `Provider`,
                                `Reference`,
                                `Quantity`,
                                `Tax`,
                                `Price`,
                                `Action`,
                                `Selected Materials`
                            ]
                        }
                    ],
                    takingExternalInventory_withOutFile: [
                        `Bulk load of external materials`,
                        `Return`,
                        {
                            titles: [
                                `Instructive`,
                                `Upload and validation`,
                                `Outcome`
                            ],
                            paso1: [
                                `Download the file `,
                                `External_material_format.xlsx`
                            ],
                            paso2: [
                                `Open the file "Format_materiales_externos.xlsx" with an application of spreadsheets (Excel, LibreOffice Cal ...) and fill in the following fields taking into account the observations: `,
                                `ERP code`,
                                `Long description`,
                                `Base unit of measure (UMB)`,
                                `Quantity`,
                                `Plant, Center or Warehouse`
                            ],
                            paso3: [
                                `The upload was successful.`,
                                `Records in conflict`,
                            ],
                            alerts: [
                                `Nice job`,
                                `¡Error!`,
                                `The upload was successful.`,
                                `Errors were found that did not allow the information to be recorded, then the conflicting records were shown`,
                                `The loaded file is not valid!`,
                                `The loaded file is empty or does not have valid information`
                            ],
                            tablaLog: [
                                `Error Result`,
                                `ERP code`,
                                `Long desc.`,
                                `Short desc.`,
                                `UMB`,
                                `Location`,
                                `Provider`,
                                `Continue`,
                                `attracted`,
                                `Name`,
                                `Document number`,
                                `The file exceeds the number of errors supported (20).`,
                                `error`
                            ]
                        }
                    ],
                    createTakingInventory: [
                        `Count`,
                        `State`,
                        `Responsible`,
                        `Action`,
                        {
                            form: [
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Responsible`,
                                `General report`,
                                `Create count`,
                                `Create reconciliation`,
                                `Cancel inventory taking`,
                                `Clean`,
                                `Create inventory take`
                            ],
                            alerts: [
                                `Nice job`,
                                `Error`,
                                `Taking inventory created successfully`,
                                `An error was generated when taking inventory`,
                                `Do you want to cancel this inventory taking?`,
                                `Do you want to create this a new inventory take?`,
                                `Do you want to create a new inventory count`,
                                `You cannot create a new count until the previous one is finished`,
                                `The maximum counts per inventory take is 3`,
                                `Confirmation`,
                                `Yes`,
                                `No`,
                                `Inventory taking successfully removed`,
                                `It was not possible to eliminate the current inventory taking`,
                                `Do you want to cancel the count`,
                                `The count was successfully canceled`,
                                `An error was generated when the count was canceled`,
                                `Do you want to clean the form?`,
                                `The selected warehouse does not have inventory`,
                                `You have not filled out all required fields`,
                                `Failed to get count information"`,
                                `", if the error persists contact the system administrator`,
                                `Some of the fields required to create the reconciliation are empty`,
                                `Are you sure you create the reconciliation for inventory taking`,
                                `The reconciliation was successfully created`,
                                `An error was generated when creating the reconciliation, if the error persists contact the system administrator`,
                                `Taking inventory`,
                                `You cannot have more counts because you are in the reconciliation process with the reconciliation number`,
                                `It is not possible to create the reconciliation of the inventory taking having a count in process`
                            ],
                            SelectedMaterialsTable: [
                                `SLI`,
                                `Description`,
                                `UMB`,
                                `Provider`,
                                `P. state`,
                                `C-1`,
                                `C-2`,
                                `C-3`,
                                `Cantidad`,
                                `Price`,
                                `Tax`,
                                `Action`,
                                `Selected Materials`,
                                `Inventory taking report`
                            ]
                        },
                        `Clean`,
                        `Remove`
                    ],
                    reportTalkingInventory: [
                        `Report of internal inventory`,
                        {
                            form: [
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Inventory shots`,
                                `[Select
                                 ]`
                            ],
                            information: [
                                `General information`,
                                `Taking inventory`,
                                `# of Counts`,
                                `Counts`,
                                `Approver`,
                                `Creation date`,
                                `Warehouse`,
                                `Inventory taking status`,
                                `Conciliation`,
                                `Readjustment`
                            ],
                            alerts: [
                                `information`,
                                `This company does not have plants`,
                                `This warehouse does not have inventory shots`,
                                `This inventory is canceled.`
                            ],
                            table: [
                                `Inventory taking report`,
                                `Code. SLI`,
                                `Code. ERP`,
                                `Desc. Corta`,
                                `References`,
                                `System Quantity`,
                                `Count 1`,
                                `Count 2`,
                                `Count 3`,
                                `Outcome`,
                                `Difference`,
                                `Reconciliation result`,
                                `UMB`,
                                `Condition`,
                                `Maker`,
                                `Provider`,
                                `Price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Conciliation`,
                                `Observation`,
                                `Brackets`
                            ]
                        }
                    ],
                    stateCount: {
                        "0": `In process`,
                        "1": `Finalized`,
                        "2": `Canceled`,
                        "3": `Photography`
                    },
                    stateTalkingInventory: {
                        "0": `In process`,
                        "1": `Finalized`,
                        "2": `Canceled`
                    },
                    stateConciliation: {
                        "0": `In process`,
                        "1": `Finalized`,
                        "2": `Canceled`
                    },
                    stateReadjustment: {
                        "0": `In process`,
                        "1": `Finalized`
                    },
                    countTalkingInventory_external: [
                        `Taking inventory`,
                        `Responsible`,
                        {
                            confirmnacion: [
                                `Confirmation`,
                                `Do you want to create a new count for the selected inventory taking?`,
                                `Do you want to create a new inventory take?`
                            ],
                            tableResultFilter: [
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `Material Status`,
                                `UMB`,
                                `Maker`,
                                `Provider`,
                                `Reference`,
                                `Quantity`,
                                `price`,
                                `Warehouse`,
                                `Location`,
                                `Observation`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Materials to inventory`,
                                `Action`
                            ],
                            form: [
                                `Add non-inventory material`,
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `Material Status`,
                                `UMB`,
                                `Maker`,
                                `Provider`,
                                `References`,
                                `Quantity`,
                                `Uniform`,
                                `price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Observation`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Add`,
                                `Cancel`,
                                `To update`
                            ],
                            modal: [
                                `Inventory taking in process for the company`,
                                `Taking inventory`,
                                `Counts`,
                                `Action`
                            ],
                            alerts: [
                                `Error`,
                                `An error was generated when obtaining user information, it is possible that the user does not have information assigned, if not, try again later`,
                                `Warning`,
                                `Internet connection was lost, please do not take any action until the connection is restored`,
                                `An error was generated while obtaining the count information`,
                                `You must fill out any of these fields.`,
                                `There are some required blank fields, please fill them out and try to save again`,
                                `No action has been taken, please do it and save again`,
                                `An error was generated while saving the information that is not available, please check the network status and try again`,
                                `Nice job`,
                                `Count`,
                                `was saved successfully`,
                                `completed successfully`,
                                `Warning`,
                                `This inventory taking does not have counts in process, please request the creation of the count and try again`,
                                `The selected inventory does not have pending counts`,
                                `Are you sure you keep the count`,
                                `Are you sure you have finished counting`,
                                `Confirmation`,
                                `This inventory does not have conflicting materials, please delete the count and proceed to make the reconciliation`,
                                `This company does not have inventory shots in process`,
                                `information`
                            ]
                        },
                        `Ejecutar conteo`
                    ],
                    external_product_counter_report: [
                        `Inventory counting report`,
                        {
                            alerts: [
                                `Confirmation`,
                                `Are you sure to delete the count`,
                                `Count`,
                                `successfully removed`,
                                `successfully created`,
                                `An error was generated while deleting the count`,
                                `information`,
                                `The selected inventory is currently not counted, please verify the information and try again`,
                                `Nice job`,
                                `Error`,
                                `Are you sure to eliminate inventory taking`,
                                `Inventory taking`,
                                `successfully removed`,
                                `An error was generated while deleting the inventory`,
                                `Inventory taking`,
                                `it is canceled, for this reason no action can be taken with it`,
                                `Are you sure you create a new count for the shot`,
                                `The count was successfully created`,
                                `An error was generated when creating the count, please wait a moment and try again, if the error persists contact the administrator`,
                                `There are currently no inventory shots in the system, please verify and enter again`,
                                `You cannot create more counts for taking inventory`,
                                `, as I complete the maximum counts finished`,
                                `, since it still has a count in process`,
                                `Are you sure you create the reconciliation for inventory taking`,
                                `Taking inventory`,
                                `has counts in process`,
                                `does not have counts to reconcile`,
                                `The reconciliation for taking inventory`,
                                `it was successfully created`,
                                `An error was generated when reconciling to take inventory`,
                                `Yes`,
                                `No`,
                                `it does not have counts, for this reason it is not possible to create a reconciliation`,
                                `You don't have counts, do you want to continue and add a count?`
                            ],
                            form: [
                                `Select inventory take`,
                                `Select responsible`,
                                `- Select--`,
                                `Take code`,
                                `Add count`,
                                `save`,
                                `Submit`,
                                `Cancel count`,
                                `Create reconciliation`,
                                `General report`,
                                `Cancel inventory taking`
                            ],
                            tableFilter: [
                                `Count`,
                                `State`,
                                `Responsible`,
                                `Action`,
                                `Current counts`
                            ],
                            tableComparison: [
                                `Comparison table`,
                                `Code. ERP`,
                                `Physical State`,
                                `Long description`,
                                `Short description`,
                                `Price`,
                                `Tax`,
                                `Provider`,
                                `References`,
                                `Maker`,
                                `UMB`,
                                `Uniform`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Observation`,
                                `Approver`,
                                `Count 1`,
                                `Count 2`,
                                `Count 3`,
                                `Inventory taking`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Count`
                            ]
                        }
                    ],
                    external_conciliation_product: [
                        `Conciliation External Products`,
                        {
                            form: [
                                `Select the reconciliation to attend`,
                                `-- Select --`,
                                `Conciliation`,
                                `Save reconciliation`,
                                `Create new product`,
                                `Cancel`,
                                `Send reconciliation`
                            ],
                            tableProducts: [
                                `Reconciliation table`,
                                `Code ERP`,
                                `Descripción larga`,
                                `Short description`,
                                `References)`,
                                `System Quantity`,
                                `Count 1`,
                                `Count 2`,
                                `Count 3`,
                                `Outcome`,
                                `Difference`,
                                `Reconcile`,
                                `Reconciliation result`,
                                `UMB`,
                                `Physical state`,
                                `Maker`,
                                `Provider`,
                                `Price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Brackets`,
                                `Reconciliation Report`,
                                `Reconcile`
                            ],
                            alerts: [
                                `Error`,
                                `An error was generated when obtaining the reconciliation information`,
                                `Some of the uploaded files do not have a valid extension, please verify that the file is one of these types: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg,. pdf`,
                                `The following files are already added`,
                                `The compos required for this action are empty`,
                                `The following files are defective`,
                                `Some required fields that are empty`,
                                `Confirmation`,
                                `Do you want to add the product with ERP code`,
                                `Nice job`,
                                `Successfully added material`,
                                `The product`,
                                `is already registered`,
                                `Are you sure to keep the reconciliation`,
                                `Are you sure to finalize the reconciliation`,
                                `Conciliation`,
                                `Successfully completed`,
                                `An error was generated while saving "Reconciliation"`,
                                `, please verify the information entered and try again`,
                                `Are you sure to eliminate the reconciliation of the material with ERP code`,
                                `Are you sure to keep the reconciliation of the material with ERP code`,
                                `The reconciliation of a material that has not been modified cannot be cleaned`,
                                `Are you sure to delete the material with the ERP code`,
                                `of conciliation?`
                            ],
                            modalConciliation: [
                                `Reconcile`,
                                `Entry`,
                                `Departure`,
                                `Enter the value to reconcile`,
                                `Add support`,
                                `save`,
                                `Cancel`,
                                `Upload`,
                                `Select File`,
                                `Undo reconciliation`,
                                `Delete product`
                            ],
                            modalNewProduct: [
                                `Add material to the reconciliation`,
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `Material Status`,
                                `UMB`,
                                `Maker`,
                                `Provider`,
                                `References`,
                                `Quantity`,
                                `Uniform`,
                                `price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Observation`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Add`,
                                `Cancel`
                            ]
                        }
                    ],
                    report_external_conciliation_product: [
                        `External reconciliation report`,
                        {
                            form: [
                                `Select the reconciliation to attend`,
                                `--Select--`,
                                `Conciliation`,
                                `Save reconciliation`,
                                `Create new product`,
                                `Cancel`,
                                `Send reconciliation`
                            ],
                            tableProducts: [
                                `Reconciliation table`,
                                `Code. ERP`,
                                `Descripción larga`,
                                `Short description`,
                                `Reference(s)`,
                                `System Quantity`,
                                `Count 1`,
                                `Count 2`,
                                `Count 3`,
                                `Outcome`,
                                `Difference`,
                                `Conciliar`,
                                `Reconciliation result`,
                                `UMB`,
                                `Physical state`,
                                `Maker`,
                                `Provider`,
                                `Price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Brackets`,
                                `Reconciliation Report`,
                                `Reconcile`
                            ],
                            alerts: [
                                `Error`,
                                `An error was generated when obtaining the reconciliation information`,
                                `Some of the uploaded files do not have a valid extension, please verify that the file is one of these types: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg,. pdf`,
                                `The following files are already added`,
                                `The compos required for this action are empty`,
                                `The following files are defective`,
                                `Some required fields that are empty`,
                                `Confirmation`,
                                `Do you want to add the product with ERP code`,
                                `Nice job`,
                                `Successfully added material`,
                                `The product`,
                                `is already registered`,
                                `Are you sure to keep the reconciliation`,
                                `Are you sure to finalize the reconciliation`,
                                `Conciliation`,
                                `Successfully completed`,
                                `An error was generated while saving "Reconciliation"`,
                                `, please verify the information entered and try again`,
                                `Are you sure to eliminate the reconciliation of the material with ERP code`,
                                `Are you sure to keep the reconciliation of the material with ERP code`,
                                `The reconciliation of a material that has not been modified cannot be cleaned`,
                                `Are you sure to delete the material with the ERP code`,
                                `of conciliation?`
                            ],
                            modalConciliation: [
                                `Reconcile`,
                                `Entry`,
                                `Departure`,
                                `Enter the value to reconcile`,
                                `Add support`,
                                `save`,
                                `Cancel`,
                                `Go up`,
                                `Select File`,
                                `Undo reconciliation`,
                                `Delete product`
                            ],
                            modalNewProduct: [
                                `Add material to the reconciliation`,
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `Material Status`,
                                `UMB`,
                                `Maker`,
                                `Provider`,
                                `References`,
                                `Quantity`,
                                `Uniform`,
                                `price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Observation`,
                                `Field 1`,
                                `Field 2`,
                                `Field 3`,
                                `Add`,
                                `Cancel`
                            ]
                        }
                    ],
                    internal_conciliation_product: [
                        `Conciliation Internal Products`,
                        {
                            form: [
                                `Select the reconciliation to attend`,
                                `--Select--`,
                                `Conciliation`,
                                `Save reconciliation`,
                                `Create new product`,
                                `Cancel`,
                                `Send reconciliation`,
                                `Rejected`
                            ],
                            tableProducts: [
                                `Reconciliation table`,
                                `C. SLI`,
                                `C. ERP`,
                                `Descripción larga`,
                                `Short description`,
                                `References)`,
                                `System Quantity`,
                                `Count 1`,
                                `Count 2`,
                                `Count 3`,
                                `Outcome`,
                                `Difference`,
                                `Reconcile`,
                                `Reconciliation result`,
                                `UMB`,
                                `Physical state`,
                                `Maker`,
                                `Provider`,
                                `Price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `State`,
                                `Observation`,
                                `Brackets`,
                                `Reconciliation Report`,
                                `Reconcile`
                            ],
                            alerts: [
                                `Error`,
                                `An error was generated when obtaining the reconciliation information`,
                                `Some of the uploaded files do not have a valid extension, please verify that the file is one of these types: .xlsx, .png, .jpg, .docx, .csv, .txt, .jpga, .jpeg,. pdf`,
                                `The following files are already added`,
                                `The compos required for this action are empty`,
                                `The following files are defective`,
                                `Some required fields that are empty`,
                                `Confirmation`,
                                `Do you want to add the product with SLI code`,
                                `Nice job`,
                                `Successfully added material`,
                                `The product`,
                                `is already registered`,
                                `Are you sure to keep the reconciliation`,
                                `Are you sure to finalize the reconciliation`,
                                `Conciliation`,
                                `Successfully completed`,
                                `An error was generated while saving "Reconciliation"`,
                                `, please verify the information entered and try again`,
                                `Are you sure to eliminate the reconciliation of the material with ERP code`,
                                `Are you sure to keep the reconciliation of the material with ERP code`,
                                `The reconciliation of a material that has not been modified cannot be cleaned`,
                                `Are you sure to delete the material with the ERP code`,
                                `of conciliation?`,
                                `The product cannot be eliminated because it is a material thrown by the inequalities of the counts`,
                                `An error was generated while getting the file`
                            ],
                            modalConciliation: [
                                `Reconcile`,
                                `Entry`,
                                `Departure`,
                                `Enter the value to reconcile`,
                                `Add support`,
                                `save`,
                                `Cancel`,
                                `Go up`,
                                `Select File`,
                                `Undo reconciliation`,
                                `Delete product`
                            ],
                            modalNewProduct: [
                                `Add material to the reconciliation`,
                                `SLI code`,
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `UMB`,
                                `Maker`,
                                `References`,
                                `Quantity`,
                                `Price`,
                                `Tax`,
                                `Provider`,
                                `Condition`,
                                `Add`,
                                `Cancel`
                            ]
                        }
                    ],
                    readjustmentInternal: [
                        `Conciliation External Products`,
                        {
                            form: [
                                `Select the reconciliation to attend`,
                                `--Select--`,
                                `Conciliation`,
                                `Branch office`,
                                `Cost center`,
                                `Destination`,
                                `Submit`,
                                `Cancel`
                            ],
                            tableProducts: [
                                `Reconciliation table`,
                                `C. SLI`,
                                `C. ERP`,
                                `Short description`,
                                `References)`,
                                `System Quantity`,
                                `Outcome`,
                                `Difference`,
                                `Conciliation`,
                                `Reconciliation result`,
                                `To refuse`,
                                `Observation`,
                                `UMB`,
                                `Physical state`,
                                `Fabricante`,
                                `Provider`,
                                `Price`,
                                `Tax`,
                                `Plant`,
                                `Center`,
                                `Warehouse`,
                                `Location`,
                                `Brackets`,
                                `Reconciliation Report`,
                                `Reconcile`
                            ],
                            alerts: [
                                `Error`,
                                `Nice job`,
                                `Confirmation`,
                                `An error was generated when obtaining the selected reconciliation, if the error persists contact the administrator`,
                                `Are you sure to perform the readjustment to the inventory taking`,
                                `Yes`,
                                `No`,
                                `The reset was successful`,
                                `Reconciliation was sent for review`,
                                `An error was generated when performing inventory reset`,
                                `An error was generated while getting the file`,
                                `The fields of branch, cost center and destination are mandatory, please fill them out to continue`
                            ],
                            modalConciliation: [
                                `Reconcile`,
                                `Entry`,
                                `Departure`,
                                `Enter the value to reconcile`,
                                `Add support`,
                                `save`,
                                `Cancel`,
                                `Upload`,
                                `Select File`,
                                `Undo reconciliation`,
                                `Delete product`
                            ],
                            modalNewProduct: [
                                `Add material to the reconciliation`,
                                `SLI code`,
                                `ERP code`,
                                `Long description`,
                                `Short description`,
                                `UMB`,
                                `Maker`,
                                `References`,
                                `Quantity`,
                                `Price`,
                                `Tax`,
                                `Provider`,
                                `Condition`,
                                `Add`,
                                `Cancel`
                            ],
                            modalErrors: [
                                `Errors when resetting`,
                                `C. SLI`,
                                `Provider`,
                                `Physical state`,
                                `Amount to be reconciled`,
                                `Missing amount in inventory to reconcile`
                            ]
                        }
                    ]
                }
            ]
        }

        // this.arrInspeccionMMateriales = [`Duplicado`, `Pendiente`, `Falto de information`, `Enviado a calidad`, `Select inspección`,`Aprobado`, `Rechazado`];
        // this.arrEstadoMMaterial = [`Completado`, `Incompleto`];
        this.arrInspeccionMMateriales = ["Duplicated", "Pending", "Information is missing", "Sent to quality", "Approved", "Rejected"];
        this.arrIndicatorsInspection = [
            {
                '1': "Duplicate"
                , '2': "Pending"
                , '3': "Lack.Info"
                , '4': "Sent.QA"
                , '5': "Approved"
                , '6': "Rejected"
                , '7': "Not treated"
            }
        ];
        this.arrEstadoMMaterial = ["Completed", "Incomplete"];
        this.arrInspeccionDinamicoSel = ["Approved", "Rejected"];

        this.permission = {
            "1": "Create company"
            , "2": "Edit company"
            , "3": "Delete company"
            , "4": "Enable / Disable company"
            , "5": "Mass load company"
            , "6": "Assign language"
            , "7": "Company report"
            , "8": "Create user"
            , "12": "Edit User"
            , "13": "Delete user"
            , "14": "Enable / Disable user"
            , "15": "Mass user load"
            , "16": "User report"
            , "17": "Create role"
            , "18": "Edit role"
            , "19": "Remove role"
            , "20": "Enable / Disable role"
            , "21": "Role Report"
            , "22": "Log Report"
            , "23": "Create type"
            , "24": "Edit type"
            , "26": "Delete type"
            , "27": "Report type"
            , "28": "Create group"
            , "29": "Edit group"
            , "30": "Delete group"
            , "31": "Mass group load"
            , "32": "Group Report"
            , "33": "Create class"
            , "34": "Edit class"
            , "35": "Remove class"
            , "36": "Mass load class"
            , "37": "Report class"
            , "38": "Create feature"
            , "39": "Edit feature"
            , "40": "Remove feature"
            , "41": "Characteristic report"
            , "42": "Create value"
            , "43": "Edit Value"
            , "44": "Delete value"
            , "45": "Value report"
            , "46": "Create unit of measure"
            , "47": "Edit unit of measure"
            , "48": "Delete unit of measure"
            , "49": "Unit of measure report"
            , "50": "Create manufacturer"
            , "51": "Edit manufacturer"
            , "52": "Delete manufacturer"
            , "53": "Load manufacturer"
            , "54": "Manufacturer Report"
            , "55": "Configure texts"
            , "56": "Create request fields"
            , "57": "Edit request fields"
            , "58": "Report fields request"
            , "59": "Load individual old description"
            , "60": "Load massive old description"
            , "61": "Report fields request"
            , "62": "Assign Request"
            , "63": "Assign descriptions"
            , "64": "Indicators"
            , "65": "Reports"
            , "66": "Catalog class"
            , "67": "Quality"
            , "68": "Create cataloging request"
            , "69": "Edit cataloging request"
            , "70": "Catalog request report"
            , "71": "Attention"
            , "72": "Consult classes"
            , "73": "Class cataloging report"
            , "74": "Create plant"
            , "75": "Edit plant"
            , "76": "Remove plant"
            , "77": "Plant report"
            , "78": "Create center"
            , "79": "Edit center"
            , "80": "Delete center"
            , "81": "Center Report"
            , "82": "Create warehouse"
            , "83": "Edit Store"
            , "84": "Delete warehouse"
            , "85": "Warehouse Report"
            , "86": "Individual cataloging amount"
            , "87": "Enable / Disable cataloging amount"
            , "88": "Mass storage cataloging amount"
            , "89": "Report amount to warehouse"
            , "90": "Create cost center"
            , "91": "Edit cost center"
            , "92": "Remove cost center"
            , "93": "Import cost center"
            , "94": "Cost Center Report"
            , "95": "Create business line"
            , "96": "Edit business line"
            , "97": "Delete business line"
            , "98": "Import business line"
            , "99": "Business line report"
            , "100": "Create sector"
            , "101": "Edit sector"
            , "102": "Delete sector"
            , "103": "Import sector"
            , "104": "Sector Report"
            , "105": "Create branch"
            , "106": "Edit branch"
            , "107": "Delete branch"
            , "108": "Import branch"
            , "109": "Branch Report"
            , "110": "Create destination"
            , "111": "Edit Destination"
            , "112": "Delete destination"
            , "113": "Import destination"
            , "114": "Destination report"
            , "115": "Create supplier"
            , "116": "Edit provider"
            , "117": "Delete provider"
            , "118": "Import supplier"
            , "119": "Supplier Report"
            , "120": "Create product reservation"
            , "121": "Approval of reservation products"
            , "122": "Edit product reservation"
            , "124": "Product reserve report"
            , "125": "External cataloging amount"
            , "126": "Enable / Disable external amount"
            , "127": "External mass load"
            , "128": "External cataloging load report"
            , "129": "Create external catalog"
            , "130": "Mass load external catalog"
            , "131": "Add user to company"
            , "132": "Product administration, tickets"
            , "133": "Product management, transfer"
            , "134": "Product management, exit"
            , "135": "Product administration, return"
            , "136": "Product administration, download"
            , "137": "Create tool / asset reserve"
            , "138": "Create custodian"
            , "139": "Modify custodian"
            , "140": "Remove custodian"
            , "142": "Custodian Report"
            , "143": "Edit tool / asset reservation"
            , "144": "Tool / asset reserve approval"
            , "145": "Tool / asset reserve report"
            , "148": "Characteristic bulk load"
            , "149": "Mass Load Value"
            , "150": "Mass load cataloging request"
            , "153": "Create pipe reservation"
            , "154": "Edit pipe reservation"
            , "155": "Pipeline reserve approval"
            , "157": "Pipeline reserve report"
            , "158": "Product reservation service"
            , "159": "Output product reservation"
            , "160": "Modify entry"
            , "161": "Input report"
            , "162": "Asset management, entries"
            , "164": "Modify active entry"
            , "165": "Modify output products"
            , "166": "Asset Entry Report"
            , "167": "Create approver"
            , "168": "Assign Store"
            , "169": "Active reserve output"
            , "171": "Pipe inlet"
            , "172": "Manage asset output"
            , "173": "Modify pipe entry"
            , "176": "Modify active output"
            , "177": "Pipe inlet report"
            , "178": "Active output report"
            , "179": "Product output report"
            , "180": "Assign assets"
            , "181": "Modify asset allocation"
            , "184": "Modify low product"
            , "185": "Modify product transfer"
            , "186": "Product transfer report"
            , "188": "Asset allocation report"
            , "189": "Create low assets"
            , "190": "Modify low assets"
            , "191": "Low Product Report"
            , "192": "Low asset report"
            , "193": "Create asset transfer"
            , "194": "Modify asset transfer"
            , "195": "Transfer report assets"
            , "196": "Return of assets"
            , "197": "Pipeline Office"
            , "198": "Return Report"
            , "199": "Modify office pipe"
            , "200": "Office pipeline report"
            , "201": "Low pipe"
            , "202": "Modify low pipe"
            , "203": "Low pipeline report"
            , "204": "Create pipe transfer"
            , "205": "Modify pipe transfer"
            , "207": "Pipeline transfer report"
            , "208": "Pipeline return"
            , "211": "Pipeline return report"
            , "212": "Quality Management"
            , "213": "Attention reserve Tools / assets"
            , "214": "Attention pipeline reservation"
            , "216": "Pipeline reserve outlet"
            , "217": "Add currency to company"
            , "218": "Active Return Report"
            , "220": "General Request Report"
            , "261": "Cataloging indicators"
            , "262": "Create client"
            , "264": "Create team"
            , "265": "Modify device"
            , "266": "Team Report"
            , "267": "Modify client"
            , "268": "Customer Report"
            , "269": "General Maintenance Configuration"
            , "270": "Maintenance Users Configuration"
            , "271": "Create check list"
            , "272": "Modify check list"
            , "274": "Report check list"
            , "275": "Create price list"
            , "277": "Bulk loading price list"
            , "278": "Price list report"
            , "280": "Create kits"
            , "281": "Modify kits"
            , "282": "Report kits"
            , "283": "Create promotions"
            , "285": "Modify promotion"
            , "286": "Promotion report"
            , "287": "Maintenance Tempario"
            , "288": "Create agenda"
            , "289": "Create team entry"
            , "290": "Modify device entry"
            , "291": "Equipment input report"
            , "292": "Calendar calendar"
            , "293": "Create budget"
            , "294": "Modify budget"
            , "295": "Budget report"
            , "296": "Distribution"
            , "297": "Create agreement"
            , "298": "Modify agreement"
            , "299": "Agreement report"
            , "300": "Attention OT"
            , "301": "Distribution"
            , "302": "Create additional"
            , "303": "Approve work order"
            , "304": "Work order attention"
            , "305": "Schedule"
            , "306": "Calendar schedule"
            , "307": "Maintenance Proforma"
            , "308": "Work order"
            , "309": "Create equipment delivery"
            , "312": "Modify equipment delivery"
            , "313": "Equipment delivery report"
            , "366": "Reconciliation of External Pipeline Inventory"
            , "367": "Taking Internal Inventory"
            , "368": "Report on internal inventory"
            , "369": "Inventory taking count"
            , "370": "Attention conciliation"
            , "371": "Reconciliation Reconciliation"
            , "372": "Take External Inventory"
            , "373": "Take External Inventory with Material"
            , "374": "Counting takes external inventory with file"
            , "375": "External inventory taking count"
            , "376": "Attention Conciliation"
            , "377": "Conciliation Report"
            , "378": "Take Internal Inventory Tools / Assets"
            , "379": "Toa External Inventory Tools / Assets"
            , "380": "Take External Inventory Tools / Assets with file"
            , "381": "Take inventory counting Tools / Assets"
            , "382": "Tools / Assets Reconciliation"
            , "383": "Reset Tools / Assets"
            , "384": "Report on inventory of Tools / Assets"
            , "385": "Counting Tools / Assets External Inventory with arch"
            , "386": "Report on inventory of Tools / External Assets"
            , "387": "Reconciliation Report Inventory Inventory Tools / Ac"
            , "388": "Internal Pipeline Inventory Taking"
            , "389": "Pipeline inventory count"
            , "390": "Reconciliation of inventory pipeline"
            , "391": "Pipeline Reset"
            , "392": "Pipeline reconciliation report"
            , "393": "Amount Taking of External Pipeline Inventory"
            , "394": "External Pipe Count"
            , "395": "Report taking inventory External Pipe"
            , "396": "Report Active Stock / Tools"
            , "398": "Kardex"
            , "397": "Return by custodian AT"
            , "399": "Catalog in custody (Report)"
            , "400": "GEB Catalog"
            , "401": "Asset Count (External with file)"
            , "402": "Active Inventory Taking Report (External with file)"
            , "403": "Active IT Reconciliation (External with file)"
        };

        this.typeMovements = {
            "1": "Entry"
            , "2": "Exit"
            , "3": "Return"
            , "4": "Transfer"
            , "5": "Low"
            , "6": "Assignment"
        };


        this.modules = {
            "1": "Administrative"
            , "2": "Cataloging"
            , "3": "Planning"
            , "4": "Inventory"
            , "5": "Purchases"
            , "6": "Hiring"
            , "7": "Suppliers"
            , "8": "Logistics"
            , "9": "QA/QC"
            , "10": "Maintenance"
        };

        this.stateUser = {
            "1": "Registered"
            , "2": "Disabled"
            , "3": "Enabled"
            , "4": "Without password"
        }

        this.currencies = {
            "1": "Colombian peso"
            , "2": "American dollar"
            , "3": "Euro"
            , "4": "Canadian dollar"
        }

        this.physical_state = {
            "0": "Select ..."
            , "Error": `No information found`
            , "1": "New"
            , "2": "Used"
            , "3": "Repaired"
            , "4": "For repair"
            , "5": "Damaged"
        }

        this.stateRequest = {
            "1": 'Created'
            , "2": 'Sent'
            , "3": 'Treated'
            , "4": 'Canceled'
            , "5": 'Rejected'
        }

        this.indicators = {
            titles: [
                `Indicators`
            ]
            , graphics: [
                `1. Status of the cataloging project.`
                , `2. Top of production master group.`
                , `3. Top of products.`
                , `4. Status of the cataloging project (Pie graphic).`
                , `5. Report types of cataloging request - day by day.`
                , `6. Project cataloging report.`
                , `7. Cataloging report day by day.`
                , `8. Report status of cataloging day by day.`
                , `9. Report top requesting users.`
                , `10. Inspection request report.`
            ]
            , formIndicators: [
                `Indicators to generate`
                , `[Select]`
                , `Inspection`
                , `Limit`
                , `User`
                , `Start date`
                , `End Date`
                , `Generate indicator`
                , `Goal`
                , `Type request`
            ]
            , graphicCataloginByProject: [
                `Average`
                , `Cataloging`
                , `Goal`
            ]
            , componentGraphics: [
                `Graphic not found`
                , `Error generating graphics`
            ]

        }

        this.index = {
            title: [
                ` Welcome to SLI!`
                , `Business software in charge of cataloging and inventory management.`
            ]
        }

        this.modMaintenance = {
            menu: {
                general: [
                    `Control Panel`
                    , `Administration`
                    , `Reception`
                    , `Workshop`
                    , `Return`
                    , `Indicators / Reports`
                ]
                , panelControl: []
                , managment: {
                    equipment: [
                        `Team`
                        , `Create team`
                        , `Modify device`
                        , `Team report`
                    ],
                    client: [
                        `Client`,
                        `Create customer`,
                        `Modify client`,
                        `Clientre report`
                    ],
                    checklist: [
                        `Check list`,
                        `Create checklist`,
                        `Modify checklist`,
                        `Check list report`
                    ],
                    agreement: [
                        `Agreement`,
                        `Create agreement`,
                        `Modify agreement`,
                        `Agreement report`
                    ]
                }
                , reception: {
                    diary: [
                        `Agenda`,
                        `Create agenda`,
                        `Calendar calendar`
                    ],
                    budget: [
                        `Budget`,
                        `Create budget`,
                        `Modify budget`,
                        `Budget report`
                    ]
                    , equipmentDelivery: [
                        `Equipment delivery`
                        , `Create team delivery`
                        , `Modify equipment delivery`
                        , `Equipment delivery report`
                    ]
                    , equipmentEntry: {
                        name: 'Team entry'
                        , createEquipmentEntry: [
                            `Create team entry`

                        ]
                        , modifyEquipmentEntry: [
                            `Modify device input`
                        ]
                        , resultEquipmentEntry: [
                            `Equipment entry report`
                        ]
                    }
                }, workshop: {
                    workOrder: [`Work order`],
                    distribution: [`Distribution`],
                    additionals: [`Additional`],
                    maintenance: [`Attention o.w.`]
                    , attentionOT: [
                        `Maintenance`
                        , `OT attention`
                    ]
                },
                return: {
                    schedule: [`Create schedule`, ` Modify schedule`]
                    , price: {
                        name: 'Precio'
                        , listPrice: [
                            `Create price list`
                            , `Massive loading of price list`
                            , `Price list report`
                        ]
                        , tempario: []
                    }
                    , kit: [
                        'Kits'
                        , `Create Kits`
                        , `Modify Kits`
                        , `Report Kits`
                    ]
                    , promocions: [
                        `Promotions`
                        , `Create promotions`
                        , `Modify promotions`
                        , `Report promotions`
                    ]
                }
            }

        };
    }

    idiomaFrances() {
    }


}








import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { Router } from "@angular/router";

import swal from 'sweetalert2';

@Injectable()
export class ServicesGeneralsService {


  constructor(
    private ajax_S: AjaxService,
    private router: Router
  ) {


  }

  closeSesion() {
    sessionStorage.removeItem('currentUser');
    location.reload();
  }

}

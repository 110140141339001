import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AjaxService } from '../../../_services/ajax.service';
import { textoModuloAdm } from '../../../texto';
import { DycpService } from '../../../auth/_services/dycp';
import { CompanyServiceService } from '../../../_services/company-service.service';

declare var $: any;
declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['header-nav.component.css']
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public encript: DycpService = new DycpService();
    public user: any;


    constructor(
        public company: CompanyServiceService
        , public ajax: AjaxService
        , public texto: textoModuloAdm
    ) {

    }
    ngOnInit() {
        this.user = this.encript.desencriptar();
        this.company.validateCompany();
    }
    ngAfterViewInit() {
        mLayout.initHeader();
    }

}


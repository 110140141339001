/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase se encarga de conectarse a la API y obtener el token junto con los datos del usuario
*/

import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/add/operator/map";
import { JwtService } from '../../_services/jwt.service';
import { variablesGlobales } from '../../variablesGlobales'
import *  as crypto from 'crypto-js';
import { Observable } from "rxjs";
import { DycpService } from "./dycp";

@Injectable()
export class AuthenticationService {

    private jwt: JwtService;
    private encript: DycpService = new DycpService();
    constructor(private http: Http, private httpClient: HttpClient) {
        this.jwt = new JwtService();

    }

    login(email: string, password: string, ididioma: string) {


        let data = { 'user': email, 'paswd': this.encriptarPasswd(password) }

        return new Promise((resolve, reject) => {

            if (typeof data.user != 'undefined' && typeof data.paswd != 'undefined' && data.user != null && data.paswd != null) {

                let url: any = variablesGlobales.urlGlobal;

                this.httpClient.post(url + 'auth/login', data)
                    .subscribe((response: any) => {
                        if (response.status == 1) {
                            this.jwt.decodificar(response).then((res) => {
                                let user: any = res;
                                if (user && user.token) {
                                    this.encript.encriptar(JSON.stringify(user));
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            });
                        } else {
                            console.log("La validación error");
                            resolve(false)
                        }
                    })
            } else {
                resolve(false);
            }
        })
    }

    forgotPassword(email: string): any {
        let data: any = { email: email };

        try {
            if (data.email != '' && typeof data.email != 'undefined') {
                let url = `${variablesGlobales.urlGlobal}auth/forgot-password`;

                return this.http.post(url, data, { headers: new Headers({ 'autorization': null, 'Content-Type': 'application/json' }) });
            } else {
                return null;
            }
        } catch (x) {
            console.log(x)
            return null;
        }
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('jstree');
        sessionStorage.removeItem('rolesPermisos');
        sessionStorage.removeItem('idiomaApp');
        sessionStorage.clear();
    }

    encriptarPasswd(cadena) {
        return crypto.SHA512(cadena).toString();
    }
}
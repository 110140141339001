/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase se encarga de realizar los llamados a la API y obtener los datos para la parte del login.

*/

import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { variablesGlobales } from '../../variablesGlobales'
import { User } from "../_models";
import { DycpService } from "./dycp";

@Injectable()
export class UserService {
    private encript: DycpService = new DycpService();
    constructor(private http: Http, private router: Router) {
    }

    verify() {
        let url: any = variablesGlobales.urlGlobal;
        return this.http.get(url + 'v/public/verify', this.jwt()).map((response: Response) => response.json());
    }

    forgotPassword(email: string) {
        return this.http.post('/public/forgot-password', JSON.stringify({ email }), this.jwt()).map((response: Response) => response.json());
    }

    getAll() {
        return this.http.get('/api/users', this.jwt()).map((response: Response) => response.json());
    }

    getById(id: number) {
        return this.http.get('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    create(user: User) {
        console.log(user);
        return this.http.post('/api/users', user, this.jwt()).map((response: Response) => response.json());
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.idUser, user, this.jwt()).map((response: Response) => response.json());
    }

    delete(id: number) {
        return this.http.delete('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        // debugger;
        let currentUser = this.encript.desencriptar();
        if (currentUser && currentUser.token) {

            let headers = new Headers({ 'autorization': currentUser.token });
            return new RequestOptions({ headers: headers });
        } return null;
    }
}
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { textoModuloAdm } from '../../../texto';
import { variablesGlobales } from '../../../variablesGlobales';
import { AjaxService } from '../../../_services/ajax.service';
import { ServicesGeneralsService } from '../../../_services/services-generals.service';
import { Router, NavigationEnd } from "@angular/router";
import { ScriptLoaderService } from '../../../_services/script-loader.service';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./aside-nav.component.css"]
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    asideNavComponent: any;
    arrMenu: any = [];
    constructor(public texto: textoModuloAdm, private router: Router, private ajaxS: AjaxService, private serviceG: ServicesGeneralsService, private _script: ScriptLoaderService) {
        this.asideNavComponent = this;
        this.arrMenu = variablesGlobales.arrMenu;
        router.events.subscribe((val) => {
            // see also 
            if (val instanceof NavigationEnd) {
                let uniqueCode = this.reactiveLinkNew();
                if (uniqueCode) {
                    $("#innerMenu .menu__link--current a.a_menu__link--current").removeClass("a_menu__link--current");
                    $("#innerMenu .menu__link--current").removeClass("menu__link--current");
                    $("#" + uniqueCode).addClass("menu__link--current");
                    $("#" + uniqueCode + " a").addClass("a_menu__link--current");
                    $("#btnClearSearch")[0].click();
                }
            }
        });
    }

    ngOnInit() {

    }

    
    ngAfterViewInit() {
        this.activeMenu(this.arrMenu, null);
        var variable = this.asideNavComponent;
        $("#btnAtrasMenu").unbind();
        $("#btnAtrasMenu").bind({
            "click": function () {
                // var dataMenu = $("#ml-menu .menu__breadcrumbs a");
                $("#ml-menu .menu__breadcrumbs a").each(function (contador, obj) {
                    var contadorUlt = contador + 1;
                    var res = $("#ml-menu .menu__breadcrumbs a").length - contadorUlt;
                    if (res == 1) {
                        $("#ml-menu .menu__breadcrumbs a").eq(contador)[0].click();
                    }
                });
            }
        });
        $("#btnClearSearch").unbind();
        $("#btnClearSearch").bind({
            "click": function () {
                $("#txtSearchMenu").val("");
                variable.activeMenu(variable.arrMenu);
            }
        });
        $("#txtSearchMenu").unbind();
        $("#txtSearchMenu").bind({
            "keyup": function (e) {
                e.stopImmediatePropagation();
                if (e.which <= 90 && e.which >= 48 || e.which >= 96 && e.which <= 105 || e.which == 8) {
                    let valorText: any = $("#txtSearchMenu").val();
                    if (valorText.length > 2) {
                        let value = $(this).val();
                        let arr = variable.arrMenu;
                        let arrFilter: any = variable.filtrarMenu(arr, value);
                        let objInfoMenu = variable.crearArregloMenuFiltrado(arrFilter);
                        let ulMain: any = variable.crearUlMainFilter(arrFilter);
                        variable.activeMenu(objInfoMenu.arreglo, ulMain);
                    } else {
                        if (!$(".menu__link--current").parent("ul").hasClass("menu__level--current")) {
                            variable.activeMenu(variable.arrMenu, null);
                        }
                    }
                }
            }
        });
        mLayout.initAside();
    }

    crearUlMainFilter(arr) {
        let html: any = '<ul data-menu="main" class="menu__level" tabindex="-1" role="menu" aria-label="All">';
        for (let i = 0; i < arr.length; i++) {
            let fila = arr[i];
            let isActive = this.isActiveLink(fila.link);
            let liActive = "";
            let aActive = "";
            if (isActive) {
                liActive = " active-in-filter";
            }
            let arrLi = this.verificarSubMenu(this.arrMenu, fila.uniqueCode);
            html += '<li id="' + fila.uniqueCode + '" class="menu__item' + liActive + '" role="menuitem cursor-pointer" title="' + eval(fila.tag) + '">';
            html += '<div class="cont-sol-item">';
            html += '<label class="' + fila.icono + ' icono-menu"></label>';
            html += '<label class="text-menu-sol"> ' + eval(fila.tag) + ' </label>';
            html += '</div>';
            let accionItemMenu = "";
            if (fila.link) {
                accionItemMenu = ' routerLink="' + fila.link + '" routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"';
            }
            if (arrLi.length > 0) {
                html += '<a class="menu__link" data-submenu="' + fila.uniqueCode + '" aria-owns="' + fila.uniqueCode + '"  ' + accionItemMenu + '>' + eval(fila.tag) + '</a>';
            } else {
                html += '<a class="menu__link"  ' + accionItemMenu + '>' + eval(fila.tag) + '</a>';
            }
            html += '</li>';
        }
        html += '</ul>';
        return html;
    }

    crearArregloMenuFiltrado(arr) {
        let arreglo: any = [];
        let arrIndex: any = [];
        for (let j = 0; j < arr.length; j++) {
            let obj = arr[j];
            arrIndex.push(obj.uniqueCode);
        }
        let bucle: any = false;
        while (!bucle) {
            let contador: any = 0;
            let newIndex: any = [];
            for (let i = 0; i < arrIndex.length; i++) {
                let obj = arrIndex[i];
                let arrMenuChild = this.verificarSubMenu(this.arrMenu, obj);
                if (arrMenuChild.length > 0) {
                    contador++;
                    for (let j = 0; j < arrMenuChild.length; j++) {
                        let objChild = arrMenuChild[j];
                        arreglo.push(objChild);
                        newIndex.push(objChild.uniqueCode);
                    }
                }
            }
            if (contador == 0) {
                bucle = true;
            } else {
                arrIndex = newIndex;
            }
        }
        let objInfoMenu: any = new Object();
        objInfoMenu.listSearch = arr;
        objInfoMenu.arreglo = arreglo;
        return objInfoMenu;
    }

    validarExistenciaUl(arr, uniqueCode) {
        let string: string = uniqueCode.toString();
        return arr.filter(item => {
            let algo: string = item.idPadre.toString();
            if (algo.toLowerCase() == string.toLowerCase()) {
                return true;
            }
            return false;
        });
    }

    reactiveLinkNew() {
        let routeLink = this.router.url;
        let arrRealRouter: any = routeLink.split("?");
        for (let i = 0; i < this.arrMenu.length; i++) {
            let obj = this.arrMenu[i];
            if (obj.link == arrRealRouter[0]) {
                // console.log("Entra");
                return obj.uniqueCode;
            }
        }
        return false;
    }

    activeMenu(arrMenu, ulMain) {
        this.crearMenuHtml(arrMenu, ulMain);
        if ($("#inicMenu").length <= 0) {
            $("body").append('<script id="inicMenu" src="assets/js/activeMenu.js"></script>');
            $(".flex-sol").attr("style", "max-height: " + medidaHV + "px !important");
        } else {
            $("#inicMenu").remove();
            $("body").append('<script id="inicMenu" src="assets/js/activeMenu.js"></script>');
        }
    }

    resetearArrMenu() {
        for (let i = 0; i < this.arrMenu.length; i++) {
            let fila = this.arrMenu[i];
            fila.active = false;
        }
    }

    crearMenuHtml(arrMenu, ulMain) {
        // variablesGlobales.arrMenu        
        let html: any = "";
        let ul: any = "";
        let li: any = "";
        let htmlSubMenu: any = "";
        let grupo: any = null;
        if (ulMain) {
            html += ulMain;
        }
        for (var i = 0; i < arrMenu.length; i++) {
            let fila = arrMenu[i]
            if (grupo != fila.idPadre) {//Si es diferente es porque pasamos a otro sub menú
                if (ul) {
                    ul += li;
                    ul += '</ul>';
                }
                html += ul;
                ul = "";
                li = "";
                grupo = fila.idPadre;
                if (grupo != null && grupo != "null" && grupo != undefined) {
                    ul = '<ul data-menu="' + fila.idPadre + '" id="' + fila.idPadre + '" class="menu__level" tabindex="-1" role="menu" aria-label="All">';
                } else {
                    ul = '<ul data-menu="main" class="menu__level" tabindex="-1" role="menu" aria-label="All">';
                }
            }
            //Se crear el li del item y se busca si tiene sub menu también 
            let arrLi = this.verificarSubMenu(this.arrMenu, fila.uniqueCode);
            let isActive = this.isActiveLink(fila.link);
            let liActive = "";
            let aActive = "";
            if (isActive && !ulMain) {
                liActive = " menu__link--current";
                aActive = " a_menu__link--current";
            } else if (isActive && ulMain) {
                liActive = " active-in-filter";
            }
            li += '<li id="' + fila.uniqueCode + '" class="menu__item' + liActive + '" role="menuitem cursor-pointer" title="' + eval(fila.tag) + '">';
            li += '<div class="cont-sol-item">';
            li += '<label class="' + fila.icono + ' icono-menu"></label>';
            li += '<label class="text-menu-sol"> ' + eval(fila.tag) + ' </label>';
            li += '</div>';
            let accionItemMenu = "";
            if (fila.link) {
                accionItemMenu = ' routerLink="' + fila.link + '" routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"';
            }
            if (arrLi.length > 0) {
                li += '<a class="menu__link' + aActive + '" data-submenu="' + fila.uniqueCode + '" aria-owns="' + fila.uniqueCode + '"  ' + accionItemMenu + '>' + eval(fila.tag) + '</a>';
            } else {
                li += '<a class="menu__link' + aActive + '"  ' + accionItemMenu + '>' + eval(fila.tag) + '</a>';
            }
            li += '</li>';
            if (i == arrMenu.length - 1) {
                let arrLi = this.verificarSubMenu(this.arrMenu, fila.idPadre);
                if (arrLi) {
                    htmlSubMenu += '<ul data-menu="' + fila.idPadre + '" id="' + fila.idPadre + '" class="menu__level" tabindex="-1" role="menu" aria-label="All">';
                    for (var j = 0; j < arrLi.length; j++) {//Se crea el sub menú del submenú
                        let liFila = arrLi[j];

                        let isActive = this.isActiveLink(liFila.link);
                        let liChildActive = "";
                        let aChildActive = "";
                        if (isActive && !ulMain) {
                            liChildActive = " menu__link--current";
                            aChildActive = " a_menu__link--current";
                        } else if (isActive && ulMain) {
                            liChildActive = " active-in-filter";
                        }
                        htmlSubMenu += '<li id="' + liFila.uniqueCode + '" class="menu__item cursor-pointer' + liChildActive + '" role="menuitem" title="' + eval(liFila.tag) + '">';
                        htmlSubMenu += '<div class="cont-sol-item">';
                        htmlSubMenu += '<label class="' + liFila.icono + ' icono-menu"></label>';
                        htmlSubMenu += '<label class="text-menu-sol"> ' + eval(liFila.tag) + ' </label>';
                        htmlSubMenu += '</div>';
                        let arrLiSub = this.verificarSubMenu(this.arrMenu, liFila.uniqueCode);//Se filtra para saber si tiene submenú
                        let accionItemSubMenu: any = "";
                        if (liFila.link) {
                            accionItemSubMenu = ' routerLink="' + liFila.link + '" routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"';
                        }
                        if (arrLiSub.length > 0) {
                            htmlSubMenu += '<a class="menu__link' + aChildActive + '" data-submenu="' + liFila.uniqueCode + '" aria-owns="' + liFila.uniqueCode + '"  ' + accionItemSubMenu + '>' + eval(liFila.tag) + '</a>';
                        } else {
                            htmlSubMenu += '<a class="menu__link' + aChildActive + '"  ' + accionItemSubMenu + '>' + eval(liFila.tag) + '</a>';
                        }
                        htmlSubMenu += '</li>';
                    }
                    htmlSubMenu += '</ul>';
                }
            }
        }
        if (arrMenu.length == 1) {//Si es diferente es porque pasamos a otro sub menú
            if (ul) {
                ul += li;
                ul += '</ul>';
            }
            html += ul;
        }
        html += htmlSubMenu;
        var variable = this.asideNavComponent;
        $("#innerMenu").html(html);
        $("#innerMenu li a").unbind();
        $("#innerMenu li a").bind({
            "click": function () {
                if ($(this).attr("routerLink")) {
                    variable.accionBtnA($(this).attr("routerLink"));
                }
            }
        });
    }

    isActiveLink(link) {
        let routeLink = this.router.url;
        if (link == routeLink) {
            return true;
        }
        return false;
    }

    verificarSubMenu(arr, uniqueCode) {
        let string: string = uniqueCode.toString();
        return arr.filter(item => {
            let algo: string = item.idPadre.toString();
            if (algo.toLowerCase() == string.toLowerCase()) {
                return true;
            }
            return false;
        });
    }

    filtrarMenu(arr, string) {
        let buscar: string = string.toString();
        var variable = this.asideNavComponent;
        return arr.filter(item => {
            let formato = item.tag.replace("this", "variable");
            let key: string = eval(formato) + "";
            if (key.toLowerCase().indexOf(buscar.toLowerCase()) >= 0) {
                return true;
            }
            return false;
        });
    }

    accionBtnA(url: any) {
        this.router.navigate([url]);
    }
}
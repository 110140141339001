
export class variablesGlobales {
    public permisos: any;

    // static urlGlobal: any = 'http://' + window.location.host.replace(":" + window.location.port, "") + ":3000/";
    // static urlGlobal: any = 'https://sli-erp.com:3001/'; 
    static urlGlobal: any = 'https://sli-erp.com:3000/'; 
    static keyYandex: any = `trnsl.1.1.20191211T200624Z.bd58442ad29eb28a.1c9183a7f37c7ce9beb37f819688b254470e67bc`;

    // static urlGlobal:any = 'https://sli-api-rest.herokuapp.com/';
    // static urlGlobal: any = 'http://192.168.1.40:3000/';

    
    static arrMenu: any = [
        { "tag": "this.texto.nombremodulos[0]", "link": null, "icono": "fa fa-university", "uniqueCode": 0, "idPadre": "null", "active": false }//Administrativo
        , { "tag": "this.texto.modCatalogacion[0]", "link": null, "icono": "fa fa-sitemap", "uniqueCode": 1, "idPadre": "null", "active": false }//Catalogación
        , { "tag": "this.texto.arrMenu[0]", "link": null, "icono": "fa fa-clipboard-list", "uniqueCode": 2, "idPadre": "null", "active": false }//Planeación
        


        , { "tag": "this.texto.arrMenu[1]", "link": null, "icono": "fa fa-warehouse", "uniqueCode": 3, "idPadre": "null", "active": false }//Inventarios

        , { "tag": "this.texto.arrMenu[7]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": "MTC", "idPadre": "null", "active": false }// Mantenimiento

        , { "tag": "this.texto.arrMenu[2]", "link": null, "icono": "fa fa-shopping-cart", "uniqueCode": 4, "idPadre": "null", "active": false }//Compras
        , { "tag": "this.texto.arrMenu[3]", "link": null, "icono": "fa fa-handshake", "uniqueCode": 5, "idPadre": "null", "active": false }//Contratacion
        , { "tag": "this.texto.arrMenu[4]", "link": null, "icono": "fa fa-industry", "uniqueCode": 6, "idPadre": "null", "active": false }//Proveedores
        , { "tag": "this.texto.arrMenu[5]", "link": null, "icono": "fa fa-truck", "uniqueCode": 7, "idPadre": "null", "active": false }//Logística
        , { "tag": "this.texto.arrMenu[6]", "link": null, "icono": "fa fa-line-chart", "uniqueCode": 8, "idPadre": "null", "active": false }//QA/QC
        
        , { "tag": "this.texto.nombremodulos[1].submod[1]", "link": null, "icono": "fa fa-building", "uniqueCode": 9, "idPadre": 0, "active": false }//Administrativo - empresa
        , { "tag": "this.texto.nombremodulos[1].submod[2]", "link": null, "icono": "fa fa-user", "uniqueCode": 10, "idPadre": 0, "active": false }//Administrativo - Usuario
        , { "tag": "this.texto.nombremodulos[1].submod[0]", "link": null, "icono": "fa fa-address-card", "uniqueCode": 11, "idPadre": 0, "active": false }//Administrativo - Rol
        , { "tag": "this.texto.nombremodulos[1].submod[3]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": 12, "idPadre": 0, "active": false }//Administrativo - Reporte
        , { "tag": "this.texto.modCatalogacion[1].subMenu[0]", "link": null, "icono": "fa fa-sliders", "uniqueCode": 13, "idPadre": 1, "active": false }//Catalogación - Panel de control
        , { "tag": "this.texto.modCatalogacion[1].subMenu[2]", "link": null, "icono": "fa fa-th-large", "uniqueCode": 14, "idPadre": 1, "active": false }//Catalogación - Catalogar

        // Inventario
        , { "tag": "this.texto.inventarioModulo.inventarioMenus[0]", "link": null, "icono": "fa fa-clipboard-list", "uniqueCode": "PC", "idPadre": 3, "active": false }//Inventarios - Panel de Control
        , { "tag": "this.texto.inventarioModulo.inventarioMenus[1]", "link": null, "icono": "fa fa-warehouse", "uniqueCode": "ADM", "idPadre": 3, "active": false }//Inventarios - Administracion de Materiales
        , { "tag": "this.texto.inventarioModulo.inventarioMenus[2]", "link": null, "icono": "fa fa-warehouse", "uniqueCode": "ADAH", "idPadre": 3, "active": false }//Inventarios - Administracion de Activos
        , { "tag": "this.texto.inventarioModulo.inventarioMenus[3]", "link": null, "icono": "fa fa-warehouse", "uniqueCode": "ADT", "idPadre": 3, "active": false }//Inventarios - Administracion de Tuberias

        // Inventario - Panel de control - Estructura
        , { "tag": "this.texto.inventarioModulo.estructura[0]", "link": null, "icono": "fa fa-list-ol", "uniqueCode": "ESTR", "idPadre": "PC", "active": false }//Inventarios - Panel de Control - Estructura

        // Inventario - Panel de control - Importar Materiales
        , { "tag": "this.texto.inventarioModulo.panelControl[0]", "link": null, "icono": "fa fa-file-upload", "uniqueCode": "IM", "idPadre": "PC", "active": false }//Inventarios - Panel de Control - Importar Materiales

        // Inventario - Panel de control - Contabilidad
        , { "tag": "this.texto.inventarioModulo.contabilidad[0]", "link": null, "icono": "fas fa-calculator", "uniqueCode": "COTBLD", "idPadre": "PC", "active": false }//Inventarios - Panel de Control - Contabilidad
        , { "tag": "this.texto.arrCreadorAprobador[0]", "link": "/createCreadorAprobador", "icono": "fas fa-retweet", "uniqueCode": "CRCA", "idPadre": "PC", "active": false } //Inventarios - Panel de Control - Asignar aprobador creador
        , { "tag": "this.texto.arrAsignacionAlmacen[0]", "link": "/createAsignarAlmacen", "icono": "fas fa-retweet", "uniqueCode": "CAA", "idPadre": "PC", "active": false } //Inventarios - Panel de Control - Asignar aprobador creador

        // Inventario - Administracion de Materiales - Reserva
        , { "tag": "this.texto.inventarioModulo.reserva[0]", "link": null, "icono": "fa fa-cubes", "uniqueCode": "RSV", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Reserva

        // Inventario - Administracion de Materiales - Movimientos
        , { "tag": "this.texto.inventarioModulo.movimientos[0]", "link": null, "icono": "fa fa-dolly-flatbed", "uniqueCode": "MOVS", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Movimientos

        // Inventario - Administracion de Materiales - Planificacion
        // , { "tag": "this.texto.inventarioModulo.planificacionActHerr[0]", "link": null, "icono": "fas fa-inbox", "uniqueCode": "PLNF", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Planificacion

        // Inventario - Administracion de Materiales - Toma de Inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[0]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINV", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[1]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TII", "idPadre": "TINV", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[2]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TIE", "idPadre": "TINV", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Exteros

        , { "tag": "this.texto.inventarioModulo.tomaInventarios[3]", "link": "/tomaInventarioInterna_material", "icono": "fas fa-file-signature", "uniqueCode": "TIITI", "idPadre": "TII", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[4]", "link": "/count_tomaInventario_material", "icono": "fas fa-box-open", "uniqueCode": "TIIC", "idPadre": "TII", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos - Conteo
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[14]", "link": "/AttendInternalConciliation", "icono": "fas fa-user-check", "uniqueCode": "TIIAC", "idPadre": "TII", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos - Atención a conciliación
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[15]", "link": "/readjustment_internalConciliation", "icono": "fas fa-balance-scale", "uniqueCode": "TIIAC", "idPadre": "TII", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos - Atención a conciliación
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[16]", "link": "/report-talking-inventory-material", "icono": "fas fa-file-contract", "uniqueCode": "TIIAC", "idPadre": "TII", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Internos - Atención a conciliación

        , { "tag": "this.texto.inventarioModulo.tomaInventarios[6]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TIESA", "idPadre": "TIE", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Sin archivo
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[5]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TIECA", "idPadre": "TIE", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo

        , { "tag": "this.texto.inventarioModulo.tomaInventarios[7]", "link": "/tomaInventarioExternal_withFile_material", "icono": "fas fa-paste", "uniqueCode": "TIECACP", "idPadre": "TIECA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo - cargue de productos
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[8]", "link": "/countTalkingInventoryExternal_withFile", "icono": "fas fa-list-ol", "uniqueCode": "TIECAC", "idPadre": "TIECA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo  - conteo
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[12]", "link": "/attentionConciliation_product", "icono": "fas fa-paste", "uniqueCode": "TIECAC", "idPadre": "TIECA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo  - Atención conciliación
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[11]", "link": "/externalProductCounterReport", "icono": "fas fa-paste", "uniqueCode": "TIECAC", "idPadre": "TIECA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo  - Reporte
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[13]", "link": "/ReportConciliation_externalProduct", "icono": "fas fa-paste", "uniqueCode": "TIECAC", "idPadre": "TIECA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Con archivo  - Reporte


        , { "tag": "this.texto.inventarioModulo.tomaInventarios[9]", "link": "/tomaInventarioExterna_material", "icono": "fas fa-paste", "uniqueCode": "TIESACPN", "idPadre": "TIESA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Sin archivo - Crear producto
        , { "tag": "this.texto.inventarioModulo.tomaInventarios[10]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TIESAC", "idPadre": "TIESA", "active": false }//Inventarios - Administracion Materiales - Toma de inventario - Externos - Sin archivo  - conteo

        // Inventario - Administracion de Materiales - Config. Material
        , { "tag": "this.texto.inventarioModulo.configMaterial[0]", "link": null, "icono": "fas fa-cogs", "uniqueCode": "CONFGMTRL", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Config. Materiales

        // Inventario - Administracion de Materiales - Reportes
        , { "tag": "this.texto.inventarioModulo.reportes[0]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": "RPTS", "idPadre": "ADM", "active": false }//Inventarios - Administracion Materiales - Reportes

        // Inventario - Administracion de Materiales - Reserva - (Creacion - Aprovacion - Modificacion - Atencion - Reporte)
        , { "tag": "this.texto.inventarioModulo.reserva[1]", "link": "/crearReserva", "icono": "fa fa-clipboard-list", "uniqueCode": "CRR", "idPadre": "RSV", "active": false }//Inventarios - Administracion Materiales - Reserva - Creacion
        , { "tag": "this.texto.inventarioModulo.reserva[2]", "link": "/aprobacionReserva", "icono": "fa fa-clipboard-check", "uniqueCode": "APRR", "idPadre": "RSV", "active": false }//Inventarios - Administracion Materiales - Reserva - Aprovacion
        , { "tag": "this.texto.inventarioModulo.reserva[8]", "link": "/modificacionReserva", "icono": "fas fa-edit", "uniqueCode": "MODFIR", "idPadre": "RSV", "active": false }//Inventarios - Administracion Materiales - Reserva - Modificacion
        , { "tag": "this.texto.inventarioModulo.reserva[3]", "link": "/atencionReserva", "icono": "fas fa-tasks", "uniqueCode": "ATCR", "idPadre": "RSV", "active": false }//Inventarios - Administracion Materiales - Reserva - Atencion
        , { "tag": "this.texto.inventarioModulo.reserva[4]", "link": "/reporteReserva", "icono": "fas fa-file-contract", "uniqueCode": "REPR", "idPadre": "RSV", "active": false }//Inventarios - Administracion Materiales - Reserva - Reporte

        // Inventario - Administracion de Materiales - Movimientos - (Entrada - Traslado - Salida - Devolucion - Baja)
        , { "tag": "this.texto.inventarioModulo.movimientos[1]", "link": null, "icono": "fa fa-plus-circle", "uniqueCode": "ENT", "idPadre": "MOVS", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[2]", "link": null, "icono": "fas fa-truck", "uniqueCode": "TRSLDO", "idPadre": "MOVS", "active": false }//Inventarios - Administracion Materiales - Movimientos - Traslado
        , { "tag": "this.texto.inventarioModulo.movimientos[3]", "link": null, "icono": "fa fa-clipboard-list", "uniqueCode": "SLD", "idPadre": "MOVS", "active": false }//Inventarios - Administracion Materiales - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[5]", "link": null, "icono": "fa fa-sync", "uniqueCode": "DVLCN", "idPadre": "MOVS", "active": false }//Inventarios - Administracion Materiales - Movimientos - Devolucion
        , { "tag": "this.texto.inventarioModulo.movimientos[4]", "link": null, "icono": "fas fa-trash-alt", "uniqueCode": "BJA", "idPadre": "MOVS", "active": false }//Inventarios - Administracion Materiales - Movimientos - Baja


        // Menú de entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[6]", "link": "/administracion_producto_entrada", "icono": "fa fa-plus-circle", "uniqueCode": "ENT_CREAR", "idPadre": "ENT", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[7]", "link": "/administracion_producto_entrada_modificar", "icono": "fas fa-edit", "uniqueCode": "ENT_MODIFICAR", "idPadre": "ENT", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[8]", "link": "/administracion_producto_entrada_reporte", "icono": "fas fa-file-contract", "uniqueCode": "ENT_REPORTE", "idPadre": "ENT", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada


        // Menú traslado (Crear, modificicar, Reporte)
        , { "tag": "this.texto.inventarioModulo.movimientos[18]", "link": "/administracion_producto_traslado", "icono": "fa fa-plus-circle", "uniqueCode": "TRASL_CREAR", "idPadre": "TRSLDO", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[19]", "link": "/administracion_producto_modificar_traslado", "icono": "fas fa-edit", "uniqueCode": "TRASL_MODIFICAR", "idPadre": "TRSLDO", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[20]", "link": "/administracion_producto_reporte_traslado", "icono": "fas fa-file-contract", "uniqueCode": "TRASL_REPORTE", "idPadre": "TRSLDO", "active": false }//Inventarios - Administracion Materiales - Movimientos - Entrada



        //Inventario - Administracion de Activos/Herramientas - Reserva
        , { "tag": "this.   texto.inventarioModulo.reservaActHerr[0]", "link": null, "icono": "fa fa-cubes", "uniqueCode": "RSVH", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva
        //Inventario - Administracion de Activos/Herramientas - Movimientos
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[0]", "link": null, "icono": "fa fa-dolly-flatbed", "uniqueCode": "MOVSH", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos
        //Inventario - Administracion de Activos/Herramientas - Planificacion
        // , { "tag": "this.texto.inventarioModulo.planificacionActHerr[0]", "link": null, "icono": "fas fa-inbox", "uniqueCode": "PLNFH", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Planificacion
        //Inventario - Administracion de Activos/Herramientas - Toma de Inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[0]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINVH", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.custodiosActHerr[0]", "link": null, "icono": "fas fa-users-cog", "uniqueCode": "CUSTDS", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias
        , { "tag": "this.texto.inventarioModulo.movimientos[24]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": "RPTINV", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[1]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINVHHI", "idPadre": "TINVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[2]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINVHHE", "idPadre": "TINVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][123]", "link": "/reporte_existencia_activos", "icono": "fas fa-chart-line", "uniqueCode": "REAH", "idPadre": "RPTINV", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][124]", "link": "/kardex", "icono": "fas fa-columns", "uniqueCode": "REAH", "idPadre": "RPTINV", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.custodiosActHerr[5]", "link": "/reporteMmCustodioAH", "icono": "fas fa-list", "uniqueCode": "REPRHCUSTDS", "idPadre": "RPTINV", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias - Reporte

        //Inventario INTERNO
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][0]", "link": "/tomaInventarioInterna_herramienta", "icono": "fas fa-plus", "uniqueCode": "TINVHHIA", "idPadre": "TINVHHI", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][1]", "link": "/tomaInventarioInterna_caseone_conteo", "icono": "fas fa-user-edit", "uniqueCode": "TINVHHIC", "idPadre": "TINVHHI", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas        
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][122]", "link": "/conciliacionIntActivo", "icono": "fas fa-user-check", "uniqueCode": "TINVHHICC", "idPadre": "TINVHHI", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas        
        , { "tag": "this.texto.arrReajusteActivo[0]", "link": "/reajusteInventarioActivo", "icono": "fas fa-balance-scale", "uniqueCode": "TINVHHIR", "idPadre": "TINVHHI", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas        
        , { "tag": "this.texto.arrReporteTomaInventario[0]", "link": "/reporteTomaInventarioActivo", "icono": "fas fa-list", "uniqueCode": "TIAR", "idPadre": "TINVHHI", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma interna de inventarios herramientas        
        //Inventario EXTERNO
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[4]", "link": "/tomaInventarioExterna_herramienta", "icono": "fas fa-paste", "uniqueCode": "TINVHHES", "idPadre": "TINVHHE", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[3]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINVHHEC", "idPadre": "TINVHHE", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][72]", "link": "/tomaInventarioExterna_withFile_herramienta", "icono": "fa fa-upload", "uniqueCode": "TINVHHECMM", "idPadre": "TINVHHEC", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][1]", "link": "/tomaInventarioExterna_withFile_herramienta_counting", "icono": "fas fa-boxes", "uniqueCode": "TINVHHECCOU", "idPadre": "TINVHHEC", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas - CONTEO
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][73]", "link": "/tomaInventarioExterna_withFile_herramienta_informe_externa", "icono": "fas fa-file-contract", "uniqueCode": "TINVHHECCOU", "idPadre": "TINVHHEC", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas - Reporte
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][99]", "link": "/tomaInventarioExterna_withFile_herramienta_conciliar_externa", "icono": "fas fa-clipboard-check", "uniqueCode": "TINVHHECCON", "idPadre": "TINVHHEC", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas - Conciliación
        , { "tag": "this.texto.inventarioModulo.tomaInventariosActHerr[5]['subMenuTomaInventario'][117]", "link": "/tomaInventarioExterna_withFile_herramienta_reporte_conciliar_externa", "icono": "fas fa-file-contract", "uniqueCode": "TINVHHECCONINF", "idPadre": "TINVHHEC", "active": false }//Inventarios - Administracion de Activos/Herramientas - Toma de inventario - Toma externa de inventarios herramientas - Reporte Conciliación

        //Inventario - Administracion de Activos/Herramientas - Config. Act/Herr
        // , { "tag": "this.texto.inventarioModulo.configActHerr[0]", "link": null, "icono": "fas fa-cogs", "uniqueCode": "CONFGACTHERR", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Config. Act/Herr
        //Inventario - Administracion de Activos/Herramientas - Custodias
        //Inventario - Administracion de Activos/Herramientas - Reportes
        // , { "tag": "this.texto.inventarioModulo.reportesActHerr[0]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": "RPTSH", "idPadre": "ADAH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reportes

        //Inventario - Administracion de Activos/Herramientas - Reserva - (Creacion - Aprovacion - Modificacion - Atencion - Reporte)
        , { "tag": "this.texto.inventarioModulo.reservaActHerr[1]", "link": "/crearReservaHA", "icono": "fa fa-clipboard-list", "uniqueCode": "CRRH", "idPadre": "RSVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Creacion
        , { "tag": "this.texto.inventarioModulo.reservaActHerr[2]", "link": "/aprobacionReservaHA", "icono": "fa fa-clipboard-check", "uniqueCode": "APRRH", "idPadre": "RSVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Aprovacion
        , { "tag": "this.texto.inventarioModulo.reservaActHerr[3]", "link": "/modificacionReservaHA", "icono": "fas fa-edit", "uniqueCode": "MODFIR", "idPadre": "RSVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Modificacion
        , { "tag": "this.texto.inventarioModulo.reservaActHerr[4]", "link": "/atencionReservaHA", "icono": "fas fa-headset", "uniqueCode": "ATCRH", "idPadre": "RSVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Atencion
        , { "tag": "this.texto.inventarioModulo.reservaActHerr[5]", "link": "/reporteReservaHA", "icono": "fas fa-file-contract", "uniqueCode": "REPRH", "idPadre": "RSVH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva  - Reporte

        // Inventario - Administracion de Activos/Herramientas - Movimientos - (Entrada - Asignacion - Devolucion - Traslado - Baja)
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[1]", "link": null, "icono": "fa fa-plus-circle", "uniqueCode": "ENTH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[2]", "link": null, "icono": "fas fa-user-tag", "uniqueCode": "ASIGH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos - Asignacion
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[3]", "link": null, "icono": "fa fa-sync", "uniqueCode": "DVLCNH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos - Devolucion
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[4]", "link": null, "icono": "fas fa-truck", "uniqueCode": "TRSLDOH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos - Traslado
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[5]", "link": null, "icono": "fas fa-trash-alt", "uniqueCode": "BJAH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion de Activos/Herramientas - Movimientos - Baja
        , { "tag": "this.texto.inventarioModulo.movimientosActHerr[6]", "link": null, "icono": "fa fa-clipboard-list", "uniqueCode": "SLDH", "idPadre": "MOVSH", "active": false }//Inventarios - Administracion Materiales - Movimientos - Salida

        // Menú de entrada activos
        , { "tag": "this.texto.inventarioModulo.movimientos[6]", "link": "/activos_producto_entrada", "icono": "fa fa-plus-circle", "uniqueCode": "ENTH_CREAR", "idPadre": "ENTH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[7]", "link": "/activos_producto_entrada_modificar", "icono": "fas fa-edit", "uniqueCode": "ENTH_MODIFICAr", "idPadre": "ENTH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientos[8]", "link": "/activos_producto_entrada_informe", "icono": "fas fa-file-contract", "uniqueCode": "ENTH_REPORTE", "idPadre": "ENTH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Entrada

        // Menú de salida activos
        , { "tag": "this.texto.inventarioModulo.movimientos[9]", "link": "/salidaAH", "icono": "fa fa-plus-circle", "uniqueCode": "SLDH_CREAR", "idPadre": "SLDH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[10]", "link": "/modificarSalidaAH", "icono": "fas fa-edit", "uniqueCode": "SLDH_MODIFICAR", "idPadre": "SLDH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[11]", "link": "/reporteSalidaAH", "icono": "fas fa-file-contract", "uniqueCode": "SLDH_REPORTE", "idPadre": "SLDH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida

        // Menú de asignación activos
        , { "tag": "this.texto.inventarioModulo.movimientos[12]", "link": "/asignacionAH", "icono": "fa fa-plus-circle", "uniqueCode": "ASIGH_CREAR", "idPadre": "ASIGH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Asignación
        , { "tag": "this.texto.inventarioModulo.movimientos[13]", "link": "/modificarAsignacionAH", "icono": "fas fa-edit", "uniqueCode": "ASIGH_MODIFICAR", "idPadre": "ASIGH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Asignación
        , { "tag": "this.texto.inventarioModulo.movimientos[14]", "link": "/reporteAsignacionAH", "icono": "fas fa-file-contract", "uniqueCode": "ASIGH_REPORTE", "idPadre": "ASIGH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Asignación

        // Menú de baja activos
        , { "tag": "this.texto.inventarioModulo.movimientos[15]", "link": "/bajaAH", "icono": "fa fa-plus-circle", "uniqueCode": "BJAH_CREAR", "idPadre": "BJAH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[16]", "link": "/modificarBajaAH", "icono": "fas fa-edit", "uniqueCode": "BJAH_MODIFICAR", "idPadre": "BJAH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[17]", "link": "/reporteBajaAH", "icono": "fas fa-file-contract", "uniqueCode": "BJAH_REPORTE", "idPadre": "BJAH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida

        // Menú de traslado activos
        , { "tag": "this.texto.inventarioModulo.movimientos[18]", "link": "/trasladoAH", "icono": "fa fa-plus-circle", "uniqueCode": "TRSLDOAH_CREAR", "idPadre": "TRSLDOH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[19]", "link": "/modificarTrasladoAH", "icono": "fas fa-edit", "uniqueCode": "TRSLDOAH_MODIFICAR", "idPadre": "TRSLDOH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida
        , { "tag": "this.texto.inventarioModulo.movimientos[20]", "link": "/reporteTrasladoAH", "icono": "fas fa-file-contract", "uniqueCode": "TRSLDOAH_REPORTE", "idPadre": "TRSLDOH", "active": false }//Inventarios - Administracion Herramientas - Movimientos - Salida

        //Inventario - Administracion de Activos/Herramientas - Custodias - (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.custodiosActHerr[1]", "link": "/crearCustodio", "icono": "fa fa-clipboard-list", "uniqueCode": "CRCUSTDS", "idPadre": "CUSTDS", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias - Crear
        , { "tag": "this.texto.inventarioModulo.custodiosActHerr[2]", "link": "/modificarCustodio", "icono": "fas fa-user-cog", "uniqueCode": "MODFCUSTDS", "idPadre": "CUSTDS", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias - Modificar
        // , { "tag": "this.texto.inventarioModulo.custodiosActHerr[3]",    "link": "/eliminarCustodio",            "icono": "fas fa-trash-alt",       "uniqueCode": "ELIMCUSTDS",     "idPadre": "CUSTDS",  "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias - Eliminar
        , { "tag": "this.texto.inventarioModulo.custodiosActHerr[4]", "link": "/reporteCustodio", "icono": "fas fa-file-contract", "uniqueCode": "REPRHCUSTDS", "idPadre": "CUSTDS", "active": false }//Inventarios - Administracion de Activos/Herramientas - Custodias - Reporte


        //Inventario - Administracion de Tuberias - Reserva
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[0]", "link": null, "icono": "fa fa-cubes", "uniqueCode": "RSVT", "idPadre": "ADT", "active": false }//Inventarios -Administracion de Tuberias - Reserva

        //Inventario - Administracion de Tuberias - Movimientos
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[0]", "link": null, "icono": "fa fa-dolly-flatbed", "uniqueCode": "MOVST", "idPadre": "ADT", "active": false }//Inventarios - Administracion de Tuberias - Movimientos

        //Inventario - Administracion de Tuberias - Planificacion
        // , { "tag": "this.texto.inventarioModulo.planificacionTuberias[0]", "link": null, "icono": "fas fa-inbox", "uniqueCode": "PLNFT", "idPadre": "ADT", "active": false }//Inventarios - Administracion de Tuberias - Planificacion

        //Inventario - Administracion de Tuberias - Toma de Inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosTuberias[0]", "link": null, "icono": "fas fa-paste", "uniqueCode": "TINVT", "idPadre": "ADT", "active": false }//Inventarios - Administracion de Tuberias - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosTuberias[1]", "link": null, "icono": "fas fa-sign-in-alt", "uniqueCode": "TINVTI", "idPadre": "TINVT", "active": false }//Inventarios - Administracion de Tuberias - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosTuberias[2]", "link": null, "icono": "fas fa-sign-out-alt", "uniqueCode": "TINVTEX", "idPadre": "TINVT", "active": false }//Inventarios - Administracion de Tuberias - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosTuberias[3]", "link": null, "icono": "fas fa-file-upload", "uniqueCode": "TINVTEXF", "idPadre": "TINVTEX", "active": false }//Inventarios - Administracion de Tuberias - Toma de inventario
        , { "tag": "this.texto.inventarioModulo.tomaInventariosTuberias[4]", "link": null, "icono": "fas fa-exclamation-circle", "uniqueCode": "TINVTEXF", "idPadre": "TINVTEX", "active": false }//Inventarios - Administracion de Tuberias - Toma de inventario

        //Inventario - Administracion de Tuberias - Reportes
        // , { "tag": "this.texto.inventarioModulo.reportesTuberias[0]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": "TINVT", "idPadre": "ADT", "active": false }//Inventarios - Administracion de Tuberias - Reportes

        //Inventario - Administracion de Tuberias - Reserva - (Creacion - Aprobación - Atención - Reporte)
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[1]", "link": "/crearReservaTB", "icono": "fa fa-clipboard-list", "uniqueCode": "CRRT", "idPadre": "RSVT", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Creacion
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[2]", "link": "/aprobacionReservaTB", "icono": "fa fa-clipboard-check", "uniqueCode": "APRRT", "idPadre": "RSVT", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Aprobacion
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[5]", "link": "/modificacionReservaTB", "icono": "fas fa-edit", "uniqueCode": "MODFIRT", "idPadre": "RSVT", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Modificacion
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[3]", "link": "/atencionReservaTB", "icono": "fas fa-headset", "uniqueCode": "ATCRT", "idPadre": "RSVT", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva - Atencion
        , { "tag": "this.texto.inventarioModulo.reservaTuberias[4]", "link": "/reporteReservaTB", "icono": "fas fa-file-contract", "uniqueCode": "REPRT", "idPadre": "RSVT", "active": false }//Inventarios - Administracion de Activos/Herramientas - Reserva  - Reporte

        // Inventario - Administracion de Tuberias - Movimientos - (Entrada - Despacho - Devolucion - Traslado - Baja)
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[1]", "link": null, "icono": "fa fa-plus-circle", "uniqueCode": "ENTT", "idPadre": "MOVST", "active": false }//Inventarios - Administracion de Tuberias - Movimientos - Entrada
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[2]", "link": null, "icono": "fa fa-clipboard-list", "uniqueCode": "ASIGT", "idPadre": "MOVST", "active": false }//Inventarios - Administracion de Tuberias - Movimientos - Despacho
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[3]", "link": null, "icono": "fa fa-sync", "uniqueCode": "DVLCNT", "idPadre": "MOVST", "active": false }//Inventarios - Administracion de Tuberias - Movimientos - Devolucion
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[5]", "link": null, "icono": "fas fa-truck", "uniqueCode": "TRSLDOT", "idPadre": "MOVST", "active": false }//Inventarios - Administracion de Tuberias - Movimientos - Traslado
        , { "tag": "this.texto.inventarioModulo.movimientosTuberias[4]", "link": null, "icono": "fas fa-trash-alt", "uniqueCode": "BJAT", "idPadre": "MOVST", "active": false }//Inventarios - Administracion de Tuberias - Movimientos - Baja


        , { "tag": "this.texto.nombremodulos[1].reporsub[0]", "link": "/tablaLog", "icono": "fa fa-file-text", "uniqueCode": 16, "idPadre": 12, "active": false }//Administrativo - Reporte- log


        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[15]", "link": null, "icono": "fa fa-cube", "uniqueCode": 17, "idPadre": 13, "active": false }//Catalogación - Panel de control - Tipo mate.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[16]", "link": null, "icono": "fa fa-cubes", "uniqueCode": 18, "idPadre": 13, "active": false }//Catalogación - Panel de control - Grupo Art.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[19]", "link": null, "icono": "fa fa-inbox", "uniqueCode": 20, "idPadre": 13, "active": false }//Catalogación - Panel de control - Clase
        , { "tag": "this.texto.arrUMBFrms[0].general[0]", "link": null, "icono": "fa fa-balance-scale", "uniqueCode": 19, "idPadre": 13, "active": false }//Catalogación - Panel de control - Uni. Med. Base.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[18]", "link": null, "icono": "fa fa-industry", "uniqueCode": 21, "idPadre": 13, "active": false }//Catalogación - Panel de control - Fabricante
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[6]", "link": "/ajustesCatalogacion", "icono": "fa fa-indent", "uniqueCode": 22, "idPadre": 13, "active": false }//Catalogación - Panel de control - Estable. txt.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[9]", "link": null, "icono": "fa fa-list-ul", "uniqueCode": 23, "idPadre": 13, "active": false }//Catalogación - Panel de control - Camp. solic.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[0]", "link": null, "icono": "fa fa-upload", "uniqueCode": 24, "idPadre": 13, "active": false }//Catalogación - Panel de control - Cargar Toxic.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[3]", "link": null, "icono": "fa fa-list-alt", "uniqueCode": 25, "idPadre": 13, "active": false }//Catalogación - Panel de control - Asignar
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[4]",  "link": "/indicadoresCatalogacion",     "icono": "fa fa-thumb-tack",      "uniqueCode": 26,    "idPadre": 13,      "active": false }//Catalogación - Panel de control - Indicadores
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[6]",          "link": "/reportesCatalogacion",        "icono": "fas fa-file-contract",  "uniqueCode": 27,    "idPadre": 13,      "active": false }//Catalogación - Panel de control - Reporte



        // Caracteristica - (Crear - Modificar - Reporte)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[34]", "link": "/crearCaracteristica", "icono": "fa fa-plus-circle", "uniqueCode": "C-1", "idPadre": "CARAC", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[41]", "link": "/modificarCaracteristica", "icono": "fa fa-pencil-square", "uniqueCode": "C-2", "idPadre": "CARAC", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.arrImporteCaracteristica[0]", "link": "/importeCaracteristica", "icono": "fa fa-upload", "uniqueCode": "CAIM", "idPadre": "CARAC", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[35]",  "link": "/eliminarCaracteristica",      "icono": "fa fa-trash",         "uniqueCode": "C-3", "idPadre": "CARAC",    "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[40]", "link": "/reporteCaracteristica", "icono": "fa fa-line-chart", "uniqueCode": "C-4", "idPadre": "CARAC", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma

        //  Valor
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[33]", "link": null, "icono": "fas fa-receipt", "uniqueCode": "VAL", "idPadre": "CARAC", "active": false }//Catalogación - Panel de control - Valor.



        // EntradaTuberia - (Crear entrada tuberia, modificar tuberia, reporte tuberia)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[48]", "link": "/entradaTuberia", "icono": "fa fa-plus-circle", "uniqueCode": "C_ENTTB", "idPadre": "ENTT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[49]", "link": "/modificar-entradaTuberia", "icono": "fa fa-pencil-square", "uniqueCode": "M_ENTTB", "idPadre": "ENTT", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[50]", "link": "/reporte-entradaTuberia", "icono": "fa fa-line-chart", "uniqueCode": "R_ENTTB", "idPadre": "ENTT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma


        // DespachoTuberia - (Crear entrada tuberia, modificar tuberia, reporte tuberia)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[51]", "link": "/despachoTuberia", "icono": "fa fa-plus-circle", "uniqueCode": "D_ENTTB", "idPadre": "ASIGT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[52]", "link": "/modificar-despachoTuberia", "icono": "fa fa-pencil-square", "uniqueCode": "D_ENTTB", "idPadre": "ASIGT", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[53]", "link": "/reporte-despachoTuberia", "icono": "fa fa-line-chart", "uniqueCode": "D_ENTTB", "idPadre": "ASIGT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma

        // BajaTuberia - (Crear entrada tuberia, modificar tuberia, reporte tuberia)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[54]", "link": "/bajaTuberia", "icono": "fa fa-plus-circle", "uniqueCode": "B_BANTTB", "idPadre": "BJAT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[55]", "link": "/modificar-bajaTuberia", "icono": "fa fa-pencil-square", "uniqueCode": "B_BANTTB", "idPadre": "BJAT", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[56]", "link": "/reporte-bajaTuberia", "icono": "fa fa-line-chart", "uniqueCode": "B_BANTTB", "idPadre": "BJAT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma

        // TrasladoTuberia - (Crear entrada tuberia, modificar tuberia, reporte tuberia)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[57]", "link": "/trasladoTuberia", "icono": "fa fa-plus-circle", "uniqueCode": "T_TRANTTB", "idPadre": "TRSLDOT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[58]", "link": "/modificar-trasladoTuberia", "icono": "fa fa-pencil-square", "uniqueCode": "T_TRANTTB", "idPadre": "TRSLDOT", "active": false }//Catalogación - Panel de control - Caracteristica. - Reporte Caracteristica ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[59]", "link": "/reporte-trasladoTuberia", "icono": "fa fa-line-chart", "uniqueCode": "T_TRANTTB", "idPadre": "TRSLDOT", "active": false }//Catalogación - Panel de control - Caracteristica. - Crear Caracteristica ma





        // Valor - (crear - editar - eliminar - reporte)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[36]", "link": "/crearValor", "icono": "fa fa-plus-circle", "uniqueCode": "V-1", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valor. - Crear Valor 
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[42]", "link": "/editarValor", "icono": "fa fa-pencil-square", "uniqueCode": "V-2", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valor. - Modificar Valor
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[45]", "link": "/carga-masiva-valor", "icono": "fa fa-upload", "uniqueCode": "V-3", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valor. - Modificar Valor
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[43]",  "link": "/eliminarValor",            "icono": "fa fa-trash",         "uniqueCode": "V-4", "idPadre": "VAL",      "active": false }//Catalogación - Panel de control - Valor. - Eliminar valor
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[37]", "link": "/reporteValor", "icono": "fas fa-file-contract", "uniqueCode": "V-5", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valor. - Reporte Valor

        // Catalogacion - Catalogar
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[1]", "link": "/crearMaterial", "icono": "fa fa-plus-circle", "uniqueCode": 28, "idPadre": 14, "active": false }//Catalogación - Catalogar - Crear material
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[2]", "link": null, "icono": "fa fa-check", "uniqueCode": 29, "idPadre": 14, "active": false }//Catalogación - Catalogar - Calidad
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[0]", "link": null, "icono": "fas fa-edit", "uniqueCode": 30, "idPadre": 14, "active": false }//Catalogación - Catalogar - Solicitud
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[4]", "link": "/atencion", "icono": "fa fa-child", "uniqueCode": 31, "idPadre": 14, "active": false }//Catalogación - Catalogar - Atención
        , { "tag": "this.texto.nombremodulos[1].submod[4]", "link": "/consultaMateriales", "icono": "fa fa-briefcase", "uniqueCode": 32, "idPadre": 14, "active": false }//Catalogación - crear material
        , { "tag": "this.texto.nombremodulos[1].submod[7]", "link": "/indicatorsCataloging", "icono": "fas fa-newspaper", "uniqueCode": 'IC-1', "idPadre": 14, "active": false }//Catalogación - Indicadores
        , { "tag": "this.texto.nombremodulos[1].submod[8]", "link": "/reportGeb", "icono": "fas fa-list", "uniqueCode": 'RGEB', "idPadre": 14, "active": false }//Catalogación - Indicadores
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[2]",  "link": "/informe",                     "icono": "fas fa-file-contract",  "uniqueCode": 33,    "idPadre": 14,      "active": false }//Catalogación - Catalogar - Informe

        // Grupo Articulo
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[2]", "link": "/grupoArticulo", "icono": "fa fa-plus-circle", "uniqueCode": 36, "idPadre": 18, "active": false }//Catalogación - Panel de control - Grupo Art. - Crear Grup. Art.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[11]", "link": "/modificarGrupoArticulo", "icono": "fas fa-edit", "uniqueCode": 37, "idPadre": 18, "active": false }//Catalogación - Panel de control - Grupo Art. - Modf. Grup. Art.
        // Se quita por editar, { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[23]", "link": "/eliminarGrupoArticulo",       "icono": "fa fa-trash",           "uniqueCode": 60,    "idPadre": 18,      "active": false }//Catalogación - Panel de control - Grupo Art. - Eliminar. Grup. Art.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[25]", "link": "/cargaGrupoArticulo", "icono": "fa fa-upload", "uniqueCode": 62, "idPadre": 18, "active": false }//Catalogación - Panel de control - Grupo Art. - Carga. Grup. Art.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[24]", "link": "/reporteGrupoArticulo", "icono": "fas fa-file-contract", "uniqueCode": 61, "idPadre": 18, "active": false }//Catalogación - Panel de control - Grupo Art. - Reporte. Grup. Art.

        // Fabricante
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[5]", "link": "/fabricante", "icono": "fa fa-plus-circle", "uniqueCode": 40, "idPadre": 21, "active": false }//Catalogación - Panel de control - Fabricante - Crear Fab.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[14]", "link": "/modificarFabricante", "icono": "fas fa-edit", "uniqueCode": 41, "idPadre": 21, "active": false }//Catalogación - Panel de control - Fabricante - Modf. Fab
        // Se quita por editar, { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[38]", "link": "/eliminarFabricante",          "icono": "fa fa-trash",           "uniqueCode": 'FAB-1', "idPadre": 21, "active": false }//Catalogación - Panel de control - Fabricante - Eliminar. Fab
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[44]", "link": "/cargueMasivoFabricante", "icono": "fa fa-upload", "uniqueCode": 'FAB-2', "idPadre": 21, "active": false }//Catalogación - Panel de control - Fabricante - Report. Fab
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[39]", "link": "/reporteFabricante", "icono": "fas fa-file-contract", "uniqueCode": 'FAB-3', "idPadre": 21, "active": false }//Catalogación - Panel de control - Fabricante - Report. Fab


        // Clase
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[3]", "link": "/material", "icono": "fa fa-plus-circle", "uniqueCode": 'MT-1', "idPadre": 20, "active": false }//Catalogación - Panel de control - Producto - Crear Prod.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[12]", "link": "/modificarMaterial", "icono": "fas fa-edit", "uniqueCode": 'MT-2', "idPadre": 20, "active": false }//Catalogación - Panel de control - Producto - Modf. Prod.
        //Se quita por editar, { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[32]", "link": "/eliminarMaterial",            "icono": "fa fa-trash",            "uniqueCode": 'MT-5',    "idPadre": 20,      "active": false }//Catalogación - Panel de control - Producto - Reporte. Prod.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[31]", "link": "/cargueMasivoMaterial", "icono": "fa fa-upload", "uniqueCode": 'MT-4', "idPadre": 20, "active": false }//Catalogación - Panel de control - Producto - Cargue masivo. Prod.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[30]", "link": "/reporteMaterial", "icono": "fas fa-file-contract", "uniqueCode": 'MT-3', "idPadre": 20, "active": false }//Catalogación - Panel de control - Producto - Eliminar. Prod.

        // Caracteristica
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[29]", "link": null, "icono": "fas fa-box-open", "uniqueCode": "CARAC", "idPadre": 20, "active": false }//Catalogación - Panel de control - Caracteristica.


        // Empresa
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[1]", "link": "/createEmpresa", "icono": "fa fa-plus-circle", "uniqueCode": 45, "idPadre": 9, "active": false }//Administracion - empresa - Crear empresa.
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[2]", "link": "/editarEmpresa", "icono": "fas fa-edit", "uniqueCode": 46, "idPadre": 9, "active": false }//Administracion - empresa - Modificar empresa.
        // , { "tag": "this.texto.nombremodulos[2].subEmpresa[3]",         "link": "/eliminarEmpresa",             "icono": "fa fa-trash",           "uniqueCode": 47,       "idPadre": 9,       "active": false }//Administracion - empresa - Eliminar Empresa.
        // , { "tag": "this.texto.nombremodulos[2].subEmpresa[4]",         "link": "/habilitarInhabilitarEmpresa", "icono": "fa fa-refresh",         "uniqueCode": 48,       "idPadre": 9,       "active": false }//Administracion - empresa - Habili/Inhabili. empresa.
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[5]", "link": "/wizardEmpresaCarga", "icono": "fa fa-upload", "uniqueCode": 49, "idPadre": 9, "active": false }//Administracion - empresa - Carga masiva empresa.
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[6]", "link": "/asociarEmpresaIdioma", "icono": "fas fa-language", "uniqueCode": "EMH1", "idPadre": 9, "active": false }//Administracion - empresa - Carga masiva empresa.
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[7]", "link": "/add-currency-to-company", "icono": "fas fa-money-bill-alt", "uniqueCode": "EM-CRREM", "idPadre": 9, "active": false }//Administracion - empresa - Currency .
        , { "tag": "this.texto.nombremodulos[2].subEmpresa[0]", "link": "/tablaEmpresa", "icono": "fas fa-file-contract", "uniqueCode": 44, "idPadre": 9, "active": false }//Administracion - empresa - Lista empresa.


        // Usuario
        , { "tag": "this.texto.nombremodulos[3].subUsuario[1]", "link": "/createUsuario", "icono": "fa fa-plus-circle", "uniqueCode": 51, "idPadre": 10, "active": false }//Administracion - Usuario - Crear usuario.
        , { "tag": "this.texto.nombremodulos[3].subUsuario[2]", "link": "/editarUsuario", "icono": "fas fa-edit", "uniqueCode": 52, "idPadre": 10, "active": false }//Administracion - Usuario - Modificar usuario.
        //, { "tag": "this.texto.nombremodulos[3].subUsuario[3]",             "link": "/eliminarUsuario",             "icono": "fa fa-trash",           "uniqueCode": 53,       "idPadre": 10,       "active": false }//Administracion - Usuario - Eliminar usuario.

        , { "tag": "this.texto.nombremodulos[1].submod[6]", "link": '/addUsuarioEmpresa', "icono": "fas fa-user-tie", "uniqueCode": "ADDUSEM", "idPadre": 10, "active": false }//Administrativo - Usuario - Asignar usuario empresa
        //, { "tag": "this.texto.nombremodulos[3].subUsuario[4]",             "link": "/habilitarInhabilitarUsuario", "icono": "fa fa-refresh",         "uniqueCode": 54,       "idPadre": 10,       "active": false }//Administracion - Usuario - Habili/Inhabili. usuario.
        , { "tag": "this.texto.nombremodulos[3].subUsuario[5]", "link": "/wizardUsuarioCarga", "icono": "fa fa-upload", "uniqueCode": 55, "idPadre": 10, "active": false }//Administracion - Usuario - Carga masiva usuario.
        , { "tag": "this.texto.nombremodulos[3].subUsuario[0]", "link": "/tablaUser", "icono": "fas fa-file-contract", "uniqueCode": 50, "idPadre": 10, "active": false }//Administracion - Usuario - Lista usuario.

        //  Rol
        , { "tag": "this.texto.nombremodulos[4].subRol[1]", "link": "/createRol", "icono": "fa fa-plus-circle", "uniqueCode": 57, "idPadre": 11, "active": false }//Administracion - rol - Crear rol.
        , { "tag": "this.texto.nombremodulos[4].subRol[2]", "link": "/editarRol", "icono": "fas fa-edit", "uniqueCode": 58, "idPadre": 11, "active": false }//Administracion - rol - Modificar rol.
        // , { "tag": "this.texto.nombremodulos[4].subRol[3]",             "link": "/eliminarRol",                 "icono": "fa fa-trash",           "uniqueCode": 59,       "idPadre": 11,       "active": false }//Administracion - rol - Eliminar rol.
        // , { "tag": "this.texto.nombremodulos[4].subRol[4]",             "link": "/habilitarInhabilitarRol",     "icono": "fa fa-refresh",         "uniqueCode": 60,       "idPadre": 11,       "active": false }//Administracion - rol - Habili/Inhabili. rol.
        , { "tag": "this.texto.nombremodulos[4].subRol[0]", "link": "/tablaRol", "icono": "fas fa-file-contract", "uniqueCode": 56, "idPadre": 11, "active": false }//Administracion - rol - Lista rol.

        // Unidad de medida base
        , { "tag": "this.texto.arrUMBFrms[0].general[2]", "link": "/createUMB", "icono": "fa fa-plus-circle", "uniqueCode": "UMB2", "idPadre": 19, "active": false }//Catalogación - Catalogar - unidad de medida base - Crear UMB
        , { "tag": "this.texto.arrUMBFrms[0].general[3]", "link": "/modificarUMB", "icono": "fas fa-edit", "uniqueCode": "UMB3", "idPadre": 19, "active": false }//Catalogación - Catalogar - unidad de medida base - Modificar UMB
        //Se quita por editar, { "tag": "this.texto.arrUMBFrms[0].general[4]",               "link": "/eliminarUMB",                 "icono": "fa fa-trash",            "uniqueCode": "UMB4",   "idPadre": 19,    "active": false }//Catalogación - Catalogar - unidad de medida base - Modificar UMB
        , { "tag": "this.texto.arrUMBFrms[0].general[1]", "link": "/tablaUMB", "icono": "fas fa-file-contract", "uniqueCode": "UMB1", "idPadre": 19, "active": false }//Catalogación - Catalogar - unidad de medida base - Reporte UMB

        // Tipo Material
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[1]", "link": "/tipoMaterial", "icono": "fa fa-plus-circle", "uniqueCode": "TM-1", "idPadre": 17, "active": false }//Catalogación - Panel de control - Tipo mate. - Crear tipo ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[10]", "link": "/editarTipoMaterial", "icono": "fa fa-pencil-square", "uniqueCode": "TM-2", "idPadre": 17, "active": false }//Catalogación - Panel de control - Tipo mate. - Modf Tip Ma
        // Se quita por editar, { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[27]", "link": "/eliminarTipoMaterial",        "icono": "fa fa-trash",           "uniqueCode": "TM-3",    "idPadre": 17,       "active": false }//Catalogación - Panel de control - Tipo mate. - Eliminar tipo ma
        , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[28]", "link": "/reporteTipoMaterial", "icono": "fas fa-file-contract", "uniqueCode": "TM-4", "idPadre": 17, "active": false }//Catalogación - Panel de control - Tipo mate. - Reporte tipo ma


        // // Valor
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[36]", "link": "/crearValores", "icono": "fa fa-plus-circle", "uniqueCode": "V-1", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valores. - Crear Valor ma 
        // , { "tag": "this.texto.modCatalogacion[1].subMenu[1].menu[37]", "link": "/reporteValores", "icono": "fas fa-file-contract", "uniqueCode": "V-2", "idPadre": "VAL", "active": false }//Catalogación - Panel de control - Valores. - Reporte Valores ma

        // Crear campos solicitud
        , { "tag": "this.texto.arrInputFrmSolicitud[0].crearCampo[0]", "link": "/createInputFrmSolicitud", "icono": "fa fa-plus-circle", "uniqueCode": "CCS2", "idPadre": 23, "active": false }//Catalogación - Panel de control - Camp. solic. - Creación    
        , { "tag": "this.texto.arrInputFrmSolicitud[2].modificarCampos[0]", "link": "/modificarInputFrmSolicitud", "icono": "fas fa-edit", "uniqueCode": "CCS3", "idPadre": 23, "active": false }//Catalogación - Panel de control - Camp. solic. - Modificación      
        , { "tag": "this.texto.arrInputFrmSolicitud[1].tablaCampos[0]", "link": "/tablaInputFrmSolicitud", "icono": "fas fa-file-contract", "uniqueCode": "CCS1", "idPadre": 23, "active": false }//Catalogación - Panel de control - Camp. solic. - Lista       

        // Panel de Control - Cargue Toxico (individual - masivo - reporte toxicos)
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[6]", "link": "/cargeToxicoIndividual", "icono": "fa fa-upload", "uniqueCode": "CTI", "idPadre": 24, "active": false }//Catalogación - Panel de control - Cargar Toxico -Cargar Toxic Individual.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[5]", "link": "/cargeToxicoMasivo", "icono": "fa fa-upload", "uniqueCode": "CTM", "idPadre": 24, "active": false }//Catalogación - Panel de control - Cargar Toxico -Cargar Toxic Masivo.
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[7]", "link": "/reporteToxicos", "icono": "fas fa-file-contract", "uniqueCode": "RT", "idPadre": 24, "active": false }//Catalogación - Panel de control - Cargar Toxico -Reporte Toxic.


        // Solicitud catalogacion
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[2]", "link": "/solicitudiadia", "icono": "fa fa-plus-circle", "uniqueCode": "SC1", "idPadre": 30, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Crear
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[3]", "link": "/modificarSolicitudCatalogacion", "icono": "fas fa-edit", "uniqueCode": "SC2", "idPadre": 30, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Modificar
        , { "tag": "this.texto.arrImporteSolicitud[0]", "link": "/importeSolicitudCatalogacion", "icono": "fa fa-upload", "uniqueCode": "SCIM", "idPadre": 30, "active": false }//Catalogación - Catalogar - Soliitud catalogación - importe masivo
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[1]", "link": "/tablaSolicitudCatalogacion", "icono": "fas fa-file-contract", "uniqueCode": "SC", "idPadre": 30, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Lista
        , { "tag": "this.texto.modCatalogacion[1].subMenu[5].menu[6]", "link": "/tablaSolicitudCatalogacionGenerales", "icono": "fas fa-file-contract", "uniqueCode": "SCGG", "idPadre": 30, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Lista

        // Inventario - Panel de control - Estructura - (Planta - Centro - Almacen)
        , { "tag": "this.texto.inventarioModulo.estructura[1]", "link": null, "icono": "fas fa-industry", "uniqueCode": "PLNT", "idPadre": "ESTR", "active": false }//Inventarios - Panel de Control - Estructura - Planta        
        , { "tag": "this.texto.inventarioModulo.estructura[2]", "link": null, "icono": "fa fa-building", "uniqueCode": "CTR", "idPadre": "ESTR", "active": false }//Inventarios - Panel de Control - Estructura - Centro
        , { "tag": "this.texto.inventarioModulo.estructura[3]", "link": null, "icono": "fa fa-warehouse", "uniqueCode": "ALMCN", "idPadre": "ESTR", "active": false }//Inventarios - Panel de Control - Estructura - Almacen
        , { "tag": "this.texto.inventarioModulo.estructura[4]", "link": null, "icono": "fa fa-map-marker-alt", "uniqueCode": "LOCN", "idPadre": "ESTR", "active": false }//Inventarios - Panel de Control - Estructura - Almacen

        // Inventario - Panel de control - Estructura - Localizacion (Crear - Modificar - Reporte)
        , { "tag": "this.texto.inventarioModulo.estructuraLocalizacion[0]", "link": "/estructuraLocation", "icono": "fa fa-plus-circle", "uniqueCode": "CRLOLOCN", "idPadre": "LOCN", "active": false }//Inventarios - Panel de Control - Estructura - Localización ( Crear )
        , { "tag": "this.texto.inventarioModulo.estructuraLocalizacion[5]", "link": "/estructuraLocationModificar", "icono": "fas fa-edit", "uniqueCode": "MDFLLOCN", "idPadre": "LOCN", "active": false }//Inventarios - Panel de Control - Estructura - Localiazación ( Modificar )
        , { "tag": "this.texto.inventarioModulo.estructuraLocalizacion[11]", "link": "/estructuraLocationReporte", "icono": "fas fa-file-contract", "uniqueCode": "RPLLOCN", "idPadre": "LOCN", "active": false }//Inventarios - Panel de Control - Estructura - Localización ( Reporte )

        // Inventario - Panel de control - Estructura - Planta (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaPlanta[0]", "link": "/estructuraPlanta", "icono": "fa fa-plus-circle", "uniqueCode": "CRTPLNT", "idPadre": "PLNT", "active": false }//Inventarios - Panel de Control - Estructura - Planta ( Crear )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaPlanta[1]", "link": "/estructuraPlantaModificar", "icono": "fas fa-edit", "uniqueCode": "MDFPLNT", "idPadre": "PLNT", "active": false }//Inventarios - Panel de Control - Estructura - Planta ( Modificar )
        //Se quita por editar, { "tag": "this.texto.inventarioModulo.estructuraPlantaPlanta[2]",    "link": "/estructuraPlantaEliminar", "icono": "fa fa-trash",             "uniqueCode": "ELMRPLNT",     "idPadre": "PLNT",  "active": false }//Inventarios - Panel de Control - Estructura - Planta ( Eliminar )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaPlanta[3]", "link": "/estructuraPlantaReporte", "icono": "fas fa-file-contract", "uniqueCode": "RPTLNT", "idPadre": "PLNT", "active": false }//Inventarios - Panel de Control - Estructura - Planta ( Reporte )

        // Inventario - Panel de control - Estructura - Centro (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaCentro[0]", "link": "/estructuraCentro", "icono": "fa fa-plus-circle", "uniqueCode": "CRTCNTR", "idPadre": "CTR", "active": false }//Inventarios - Panel de Control - Estructura - Centro ( Crear )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaCentro[1]", "link": "/estructuraCentroModificar", "icono": "fas fa-edit", "uniqueCode": "MDFCNTR", "idPadre": "CTR", "active": false }//Inventarios - Panel de Control - Estructura - Centro ( Modificar )
        //Se quita por editar, { "tag": "this.texto.inventarioModulo.estructuraPlantaCentro[2]",    "link": "/estructuraCentroEliminar",  "icono": "fa fa-trash",               "uniqueCode": "ELMRCNTR",     "idPadre": "CTR",  "active": false }//Inventarios - Panel de Control - Estructura - Centro ( Eliminar )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaCentro[3]", "link": "/estructuraCentroReporte", "icono": "fas fa-file-contract", "uniqueCode": "RPTCNTR", "idPadre": "CTR", "active": false }//Inventarios - Panel de Control - Estructura - Centro ( Reporte )

        // Inventario - Panel de control - Estructura - Almacen (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaAlmacen[0]", "link": "/estructuraAlmacen", "icono": "fa fa-plus-circle", "uniqueCode": "CRTALMCN", "idPadre": "ALMCN", "active": false }//Inventarios - Panel de Control - Estructura - Almacen ( Crear )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaAlmacen[1]", "link": "/estructuraAlmacenModificar", "icono": "fas fa-edit", "uniqueCode": "MDFALMCN", "idPadre": "ALMCN", "active": false }//Inventarios - Panel de Control - Estructura - Almacen  ( Modificar )
        //Se quita por editar, { "tag": "this.texto.inventarioModulo.estructuraPlantaAlmacen[2]",    "link": "/estructuraAlmacenEliminar",   "icono": "fa fa-trash",               "uniqueCode": "ELMRALMCN",     "idPadre": "ALMCN",  "active": false }//Inventarios - Panel de Control - Estructura - Almacen ( Eliminar )
        , { "tag": "this.texto.inventarioModulo.estructuraPlantaAlmacen[3]", "link": "/estructuraAlmacenReporte", "icono": "fas fa-file-contract", "uniqueCode": "RPTALMCN", "idPadre": "ALMCN", "active": false }//Inventarios - Panel de Control - Estructura - Almacen ( Reporte )


        // Inventario - Panel de control - Importar Materiales - (Internos - Externos)
        , { "tag": "this.texto.inventarioModulo.panelControl[1]", "link": null, "icono": "fas fa-file-alt", "uniqueCode": "IMINT", "idPadre": "IM", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[2]", "link": null, "icono": "fas fa-file-alt", "uniqueCode": "IMEXT", "idPadre": "IM", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion


        // Internos (Crear, editar, cargue masivo, reporte)
        , { "tag": "this.texto.inventarioModulo.panelControl[3]", "link": "/importarCatalogacionIndividual", "icono": "fas fa-file-alt", "uniqueCode": "IMINDI", "idPadre": "IMINT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[4]", "link": "/editarImporteCatalogacion", "icono": "fas fa-file-import", "uniqueCode": "IMEDI", "idPadre": "IMINT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Externos
        , { "tag": "this.texto.inventarioModulo.panelControl[5]", "link": "/importarCatalogacionMasiva", "icono": "fas fa-file-alt", "uniqueCode": "IMCARMAS", "idPadre": "IMINT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[7]", "link": "/reporteImporteCatalogacion", "icono": "fas fa-file-import", "uniqueCode": "IMREPOR", "idPadre": "IMINT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Externos


        // Externos (Crear, editar, cargue masivo, reporte)
        , { "tag": "this.texto.inventarioModulo.panelControl[12]", "link": "/crearMaterialExterno", "icono": "fas fa-file-alt", "uniqueCode": "IMCREAEX", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[13]", "link": "/cargaMasivaCatalogoExterno", "icono": "fas fa-file-alt", "uniqueCode": "IMCARGEX", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[8]", "link": "/asginarExternoAAlmacen", "icono": "fas fa-file-alt", "uniqueCode": "IMEXTIN", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[9]", "link": "/editarImporteIndividualExterno", "icono": "fas fa-file-import", "uniqueCode": "IMEXTED", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Externos
        , { "tag": "this.texto.inventarioModulo.panelControl[10]", "link": "/cargueMasivaExterno", "icono": "fas fa-file-alt", "uniqueCode": "IMEXTCM", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Catalogacion
        , { "tag": "this.texto.inventarioModulo.panelControl[11]", "link": "/reporteImporteCatalogacionExterno", "icono": "fas fa-file-import", "uniqueCode": "IMEXTRP", "idPadre": "IMEXT", "active": false }//Inventarios - Panel de Control - Importar Materiales - Externos



        // Asignar
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[8]", "link": "/asignarCatalogacion", "icono": "fa fa-file-import", "uniqueCode": "ASC", "idPadre": 25, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Lista
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[9]", "link": "/asignarDescripcion", "icono": "fa fa-file-import", "uniqueCode": "ACT", "idPadre": 25, "active": false }//Catalogación - Catalogar - Soliitud catalogación - Crear

        // Inventario - Panel de control - Contabilidad - (Centro de Costo - Sucursal - Destino  - Proveedores)
        , { "tag": "this.texto.inventarioModulo.contabilidad[1]", "link": null, "icono": "fas fa-balance-scale", "uniqueCode": "CTRCTS", "idPadre": "COTBLD", "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo
        , { "tag": "this.texto.inventarioModulo.contabilidad[2]", "link": null, "icono": "fas fa-building", "uniqueCode": "SCRL", "idPadre": "COTBLD", "active": false }//Inventarios - Panel de Control - Contabilidad - Sucursal
        , { "tag": "this.texto.inventarioModulo.contabilidad[3]", "link": null, "icono": "fas fa-industry", "uniqueCode": "DSTN", "idPadre": "COTBLD", "active": false }//Inventarios - Panel de Control - Contabilidad - Destino
        , { "tag": "this.texto.inventarioModulo.contabilidad[4]", "link": null, "icono": "fas fa-truck", "uniqueCode": "PRVDRS", "idPadre": "COTBLD", "active": false }//Inventarios - Panel de Control - Contabilidad - Proveedores

        // Inventario - Panel de control - Contabilidad - Centro Costo (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.contabilidadCentroCostoCrear[0]", "link": "/crearCentroCostos", "icono": "fa fa-plus-circle", "uniqueCode": "CRECTRCTS", "idPadre": "CTRCTS", "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo - Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadCentroCostoModificar[0]", "link": "/modificarCentroCostos", "icono": "fas fa-edit", "uniqueCode": "MDFCTRCTS", "idPadre": "CTRCTS", "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo - Modificar
        // , { "tag": "this.texto.inventarioModulo.contabilidadCentroCostoEliminar[0]",    "link":  "/eliminarCentroCostos",  "icono": "fa fa-trash",           "uniqueCode": "ELMCTRCTS",   "idPadre": "CTRCTS",  "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo - Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadCentroCostoReporte[0]", "link": "/reporteCentroCostos", "icono": "fas fa-file-contract", "uniqueCode": "RPTCTRCTS", "idPadre": "CTRCTS", "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo - Reporte
        , { "tag": "this.texto.inventarioModulo.contabilidadCentroCostoReporte[2]", "link": "/importeMasivoCCostos", "icono": "fas fa-file-upload", "uniqueCode": "IMCC", "idPadre": "CTRCTS", "active": false }//Inventarios - Panel de Control - Contabilidad - Centro de Costo - Reporte

        // Inventario - Panel de control - Contabilidad - Destino (Crear - Modificar - Eliminar - Reporte)
        , { "tag": "this.texto.inventarioModulo.contabilidadDestinoCrear[0]", "link": "/crearDestino", "icono": "fa fa-plus-circle", "uniqueCode": "CREDTN", "idPadre": "DSTN", "active": false }//Inventarios - Panel de Control - Contabilidad - Destino - Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadDestinoModificar[0]", "link": "/modificarDestino", "icono": "fas fa-edit", "uniqueCode": "MDFDTN", "idPadre": "DSTN", "active": false }//Inventarios - Panel de Control - Contabilidad - Destino - Modificar
        //, { "tag": "this.texto.inventarioModulo.contabilidadDestinoEliminar[0]",    "link":  "/eliminarDestino",   "icono": "fa fa-trash",           "uniqueCode": "ELMDTN",   "idPadre": "DSTN",  "active": false }//Inventarios - Panel de Control - Contabilidad - Destino - Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadDestinoReporte[0]", "link": "/reporteDestino", "icono": "fas fa-file-contract", "uniqueCode": "RPTDTN", "idPadre": "DSTN", "active": false }//Inventarios - Panel de Control - Contabilidad - Destino - Reporte
        , { "tag": "this.texto.inventarioModulo.contabilidadDestinoImporte[0]", "link": "/importarDestino", "icono": "fas fa-file-upload", "uniqueCode": "IPTDTN", "idPadre": "DSTN", "active": false }//Inventarios - Panel de Control - Contabilidad - Destino - Reporte

        // Inventario - Panel de control - Contabilidad - Sucursal 
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[0]", "link": null, "icono": "fa fa-external-link-square-alt", "uniqueCode": "CRESCRL", "idPadre": "SCRL", "active": false }//Línea de negocio
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[1]", "link": null, "icono": "fa fa-industry", "uniqueCode": "MDFSCRL", "idPadre": "SCRL", "active": false }//Sector
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[2]", "link": null, "icono": "fas fa-building", "uniqueCode": "RPTSCRL", "idPadre": "SCRL", "active": false }//Sucursal

        // Inventario - Panel de control - Contabilidad - Sucursal - Línea de negocio
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[3]", "link": "/createLineaNegocio", "icono": "fa fa-plus-circle", "uniqueCode": "CLN", "idPadre": "CRESCRL", "active": false } //Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[4]", "link": "/modificarLineaNegocio", "icono": "fas fa-edit", "uniqueCode": "MLN", "idPadre": "CRESCRL", "active": false } //Modificar
        //, { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[5]", "link":  "/eliminarLineaNegocio",       "icono": "fa fa-trash",           "uniqueCode": "ELN",   "idPadre": "CRESCRL",  "active": false } //Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[6]", "link": "/listaLineaNegocio", "icono": "fas fa-file-contract", "uniqueCode": "LLN", "idPadre": "CRESCRL", "active": false } //Lista
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[7]", "link": "/importeLineaNegocio", "icono": "fas fa-file-upload", "uniqueCode": "ILN", "idPadre": "CRESCRL", "active": false } //Importe masivo

        // Inventario - Panel de control - Contabilidad - Sucursal - Sector
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[8]", "link": "/createSector", "icono": "fa fa-plus-circle", "uniqueCode": "CSEC", "idPadre": "MDFSCRL", "active": false } //Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[9]", "link": "/modificarSector", "icono": "fas fa-edit", "uniqueCode": "MSEC", "idPadre": "MDFSCRL", "active": false } //Modificar
        // , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[10]", "link":  "/eliminarSector",       "icono": "fa fa-trash",           "uniqueCode": "ESEC",   "idPadre": "MDFSCRL",  "active": false } //Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[11]", "link": "/listaSector", "icono": "fas fa-file-contract", "uniqueCode": "LSEC", "idPadre": "MDFSCRL", "active": false } //Lista
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[12]", "link": "/importarSector", "icono": "fas fa-file-upload", "uniqueCode": "ISEC", "idPadre": "MDFSCRL", "active": false } //Importe masivo

        // Inventario - Panel de control - Contabilidad - Sucursal - Sucursales
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[13]", "link": "/createSucursal", "icono": "fa fa-plus-circle", "uniqueCode": "CSC", "idPadre": "RPTSCRL", "active": false } //Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[14]", "link": "/modificarSucursal", "icono": "fas fa-edit", "uniqueCode": "MSC", "idPadre": "RPTSCRL", "active": false } //Modificar
        // , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[15]", "link":  "/eliminarSucursal",       "icono": "fa fa-trash",           "uniqueCode": "ESC",   "idPadre": "RPTSCRL",  "active": false } //Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[16]", "link": "/listaSucursal", "icono": "fas fa-file-contract", "uniqueCode": "LSC", "idPadre": "RPTSCRL", "active": false } //Lista
        , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[17]", "link": "/importarSucursal", "icono": "fas fa-file-upload", "uniqueCode": "ISC", "idPadre": "RPTSCRL", "active": false } //Importe masivo

        // Inventario - Panel de control - Contabilidad - proveedor
        , { "tag": "this.texto.inventarioModulo.contabilidadProveedorCrear[0]", "link": "/createProveedor", "icono": "fa fa-plus-circle", "uniqueCode": "CPRVR", "idPadre": "PRVDRS", "active": false } //Crear
        , { "tag": "this.texto.inventarioModulo.contabilidadProveedorModificar[0]", "link": "/modificarProveedor", "icono": "fas fa-edit", "uniqueCode": "MPRVR", "idPadre": "PRVDRS", "active": false } //Modificar
        // , { "tag": "this.texto.inventarioModulo.contabilidadProveedorEliminar[0]", "link": "/eliminarProveedor", "icono": "fa fa-trash", "uniqueCode": "EPRVR", "idPadre": "PRVDRS", "active": false } //Eliminar
        , { "tag": "this.texto.inventarioModulo.contabilidadProveedorImporte[0]", "link": "/importeProveedor", "icono": "fas fa-file-upload", "uniqueCode": "IPRVR", "idPadre": "PRVDRS", "active": false } //Importe masivo
        , { "tag": "this.texto.inventarioModulo.contabilidadProveedorLista[0]", "link": "/listaProveedor", "icono": "fas fa-file-contract", "uniqueCode": "LPRVR", "idPadre": "PRVDRS", "active": false } //Lista


        , { "tag": "this.texto.inventarioModulo.movimientosSalida[42]", "link": "/admProductoSalida", "icono": "fas fa-plus", "uniqueCode": "CSLDP", "idPadre": "SLD", "active": false } //Importe masivo
        , { "tag": "this.texto.inventarioModulo.movimientosSalida[33]", "link": "/admProductoSalidaModificar", "icono": "fas fa-edit", "uniqueCode": "MSLDP", "idPadre": "SLD", "active": false } //Importe masivo
        , { "tag": "this.texto.arrSalidaReporteProducto[0]", "link": "/admProductoSalidaReporte", "icono": "fas fa-file-contract", "uniqueCode": "MSLDP", "idPadre": "SLD", "active": false } //Importe masivo

        , { "tag": "this.texto.arrTextoBaja[1]", "link": "/administracion_producto_baja", "icono": "fas fa-plus", "uniqueCode": "CBJA", "idPadre": "BJA", "active": false } //Importe masivo
        , { "tag": "this.texto.arrTextoBaja[2]", "link": "/admEditarProductoBaja", "icono": "fas fa-edit", "uniqueCode": "MBJA", "idPadre": "BJA", "active": false } //Importe masivo
        , { "tag": "this.texto.arrTextoBaja[3]", "link": "/admReporteProductoBaja", "icono": "fas fa-file-contract", "uniqueCode": "RBJA", "idPadre": "BJA", "active": false } //Importe masivo

        , { "tag": "this.texto.arrTextoDevolucion[17]", "link": "/administracion_producto_devolucion", "icono": "fas fa-plus", "uniqueCode": "DVLCNC", "idPadre": "DVLCN", "active": false } //Importe masivo
        , { "tag": "this.texto.arrTextoDevolucion[18]", "link": "/admProductoDevolucionReporte", "icono": "fas fa-file-contract", "uniqueCode": "DVLCNR", "idPadre": "DVLCN", "active": false } //Importe masivo

        , { "tag": "this.texto.inventarioModulo.movInventarios[67]", "link": "/devolucionTuberia", "icono": "fas fa-plus", "uniqueCode": "DT", "idPadre": "DVLCNT", "active": false } //Importe masivo
        , { "tag": "this.texto.arrReporteActTub[12]", "link": "/reporte-devTuberia", "icono": "fas fa-file-contract", "uniqueCode": "RDT", "idPadre": "DVLCNT", "active": false } //Importe masivo        
        , { "tag": "this.texto.arrReporteActTub[14]", "link": "/devolucionAH", "icono": "fas fa-plus", "uniqueCode": "DA", "idPadre": "DVLCNH", "active": false } //Importe masivo
        , { "tag": "this.texto.arrReporteActTub[13]", "link": "/reporteDevHerramienta", "icono": "fas fa-file-contract", "uniqueCode": "RDA", "idPadre": "DVLCNH", "active": false } //Importe masivo        


        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[10]", "link": "/calidadQA", "icono": "fas fa-file-contract", "uniqueCode": "GCAL", "idPadre": 29, "active": false } //Importe masivo
        , { "tag": "this.texto.modCatalogacion[1].subMenu[3].menu[11]", "link": "/gestionCalidad", "icono": "fas fa-file-contract", "uniqueCode": "RCAL", "idPadre": 29, "active": false } //Importe masivo

        , { "tag": "this.texto.arrRepoInvtExtFile[22]", "link": "/importeInvExtTuberia", "icono": "fas fa-upload", "uniqueCode": "TIETF", "idPadre": "TINVTEXF", "active": false } //Importe masivo prod. ext. toma inv tubería con archivo
        , { "tag": "this.texto.arrTextTomaInvTubExt[1]", "link": "/conteoInvExtTuberia", "icono": "fas fa-user-edit", "uniqueCode": "CTIETF", "idPadre": "TINVTEXF", "active": false } // Conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrRepoInvtExtFile[0]", "link": "/repoContInvExtFileTuberia", "icono": "fas fa-clipboard-list", "uniqueCode": "RTIETF", "idPadre": "TINVTEXF", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrConciliacionExtTuberia[0]", "link": "/conciliacionTomaInvExtTuberia", "icono": "fas fa-user-check", "uniqueCode": "RTIETF", "idPadre": "TINVTEXF", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrReporteConciInvExtTuberia[0]", "link": "/reporteConciliacionInExtTuberia", "icono": "fas fa-list", "uniqueCode": "RCTIETF", "idPadre": "TINVTEXF", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        , { "tag": "this.texto.arrTextTomaInvTub[0]", "link": "/tomaInventarioInterna_tuberia", "icono": "fas fa-plus", "uniqueCode": "TIFI", "idPadre": "TINVTI", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrTextTomaInvTub[35]", "link": "/conteoTomaInvTuberia", "icono": "fas fa-user-edit", "uniqueCode": "CTIFI", "idPadre": "TINVTI", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrTextTomaInvTub[36]", "link": "/conciliacionTomaInvIntTuberia", "icono": "fas fa-user-check", "uniqueCode": "CSTIFI", "idPadre": "TINVTI", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrReajusteTuberia[0]", "link": "/reajusteTuberia", "icono": "fas fa-balance-scale", "uniqueCode": "RATIFI", "idPadre": "TINVTI", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.arrReporteTomaInventario[0]", "link": "/reporteConciliacionTuberia", "icono": "fas fa-list", "uniqueCode": "RTITI", "idPadre": "TINVTI", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // , { "tag": "this.texto.modMaintenance.menu.general[1]", "link": null, "icono": "fa fa-list", "uniqueCode": 'MTA', "idPadre": "MTC", "active": false }// Mantenimiento - administración
        // , { "tag": "this.texto.modMaintenance.menu.general[2]", "link": null, "icono": "fa fa-home", "uniqueCode": 'MTR', "idPadre": "MTC", "active": false }// Mantenimiento - recepción
        // , { "tag": "this.texto.modMaintenance.menu.general[3]", "link": null, "icono": "fas fa-briefcase", "uniqueCode": 'MTT', "idPadre": "MTC", "active": false }// Mantenimiento - taller
        // , { "tag": "this.texto.modMaintenance.menu.general[4]", "link": null, "icono": "fas fa-share", "uniqueCode": 'MTD', "idPadre": "MTC", "active": false }// Mantenimiento - devolución

        // ********************Maintenance***************************** //
        // ********************Maintenance***************************** //
        // ********************Maintenance***************************** //

        , { "tag": "this.texto.modMaintenance.menu.general[0]", "link": null, "icono": "fas fa-sliders-h", "uniqueCode": "MTC-PC", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.general[1]", "link": null, "icono": "fas fa-university", "uniqueCode": "MTC-MG", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.general[2]", "link": null, "icono": "fas fa-sign-in-alt", "uniqueCode": "MTC-RP", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.general[3]", "link": null, "icono": "fas fa-wrench", "uniqueCode": "MTC-WSK", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.general[4]", "link": null, "icono": "fas fa-long-arrow-alt-left", "uniqueCode": "MTC-RT", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.general[5]", "link": null, "icono": "far fa-chart-bar", "uniqueCode": "MTC-IND", "idPadre": "MTC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Panel de control
        , { "tag": "this.texto.modMaintenance.menu.panelControl[0]", "link": "/configuracionGeneralMantenimiento", "icono": "fas fa-cog", "uniqueCode": 'MTC-PC-CFC', "idPadre": "MTC-PC", "active": false }// Mantenimiento - administración - cliente - crear
        , { "tag": "this.texto.modMaintenance.menu.panelControl[1]", "link": "/usuariosMantenimiento", "icono": "fas fa-user-cog", "uniqueCode": 'MTC-PC-US', "idPadre": "MTC-PC", "active": false }// Mantenimiento - administración - cliente - crear


        // administración
        , { "tag": "this.texto.modMaintenance.menu.managment.client[0]", "link": null, "icono": "fas fa-user-tie", "uniqueCode": 'MTC-MG-CL', "idPadre": "MTC-MG", "active": false }// Mantenimiento - administración - cliente - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.equipment[0]", "link": null, "icono": "fas fa-car", "uniqueCode": "MTC-MG-EQ", "idPadre": "MTC-MG", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.checklist[0]", "link": null, "icono": "fas fa-tasks", "uniqueCode": 'MTC-MG-CKL', "idPadre": "MTC-MG", "active": false }// Mantenimiento - administración - checklist - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.price.name", "link": null, "icono": "fas fa-money-check-alt", "uniqueCode": "MTC-MG-PC", "idPadre": "MTC-MG", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.kit[0]", "link": null, "icono": "fas fa-boxes", "uniqueCode": "MTC-MG-KT", "idPadre": "MTC-MG", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.promocions[0]", "link": null, "icono": "fas fa-percentage", "uniqueCode": "MTC-MG-PMC", "idPadre": "MTC-MG", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Clients
        , { "tag": "this.texto.modMaintenance.menu.managment.client[0]", "link": null, "icono": "fas fa-user-tie", "uniqueCode": 'MTC-MG-CL-CL', "idPadre": "MTC-MG-CL", "active": false }// Mantenimiento - administración - cliente - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.agreement[0]", "link": null, "icono": "fas fa-handshake", "uniqueCode": 'MTC-MG-CL-AG', "idPadre": "MTC-MG-CL", "active": false }// Mantenimiento - administración - cliente - crear

        , { "tag": "this.texto.modMaintenance.menu.managment.client[1]", "link": "/createClient", "icono": "fa fa-plus-circle", "uniqueCode": 'MTC-MG-CL-CL-C', "idPadre": "MTC-MG-CL-CL", "active": false }// Mantenimiento - administración - cliente - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.client[2]", "link": "/updateClient", "icono": "fas fa-edit", "uniqueCode": 'MTC-MG-CL-CL-M', "idPadre": "MTC-MG-CL-CL", "active": false }// Mantenimiento - administración - cliente - modificar
        , { "tag": "this.texto.modMaintenance.menu.managment.client[3]", "link": "/reportClient", "icono": "fas fa-file-contract", "uniqueCode": 'MTC-MG-CL-CL-R', "idPadre": "MTC-MG-CL-CL", "active": false }// Mantenimiento - administración - cliente - reporte

        // Agreements
        , { "tag": "this.texto.modMaintenance.menu.managment.agreement[1]", "link": "/createAgreement", "icono": "fa fa-plus-circle ",  "uniqueCode": 'MTC-MG-CL-AG-C', "idPadre": "MTC-MG-CL-AG", "active": false }// Mantenimiento - recepción - convenio - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.agreement[2]", "link": "/updateAgreement", "icono": "fas fa-edit",  "uniqueCode": 'MTC-MG-CL-AG-M', "idPadre": "MTC-MG-CL-AG", "active": false }// Mantenimiento - recepción - convenio - modificar
        , { "tag": "this.texto.modMaintenance.menu.managment.agreement[3]", "link": "/reportAgreement", "icono": "fas fa-file-contract ",  "uniqueCode": 'MTC-MG-CL-AG-R', "idPadre": "MTC-MG-CL-AG", "active": false }// Mantenimiento - recepción - convenio - reporte

        // Equipment
        , { "tag": "this.texto.modMaintenance.menu.managment.equipment[1]", "link": '/createEquipment', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-IND-C", "idPadre": "MTC-MG-EQ", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.equipment[2]", "link": '/modifyEquipment', "icono": "fas fa-edit", "uniqueCode": "MTC-IND-M", "idPadre": "MTC-MG-EQ", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.equipment[3]", "link": '/reportEquipment', "icono": "fas fa-file-contract", "uniqueCode": "MTC-IND-R", "idPadre": "MTC-MG-EQ", "active": false } // Reporte conteo toma inv. ext. tubería con archivo


        , { "tag": "this.texto.modMaintenance.menu.managment.checklist[1]", "link": "/createCheckList", "icono": "fa fa-plus-circle", "uniqueCode": 'MTCCH', "idPadre": "MTC-MG-CKL", "active": false }// Mantenimiento - administración - checklist - crear
        , { "tag": "this.texto.modMaintenance.menu.managment.checklist[2]", "link": "/updateCheckList", "icono": "fas fa-edit", "uniqueCode": 'MTMCH', "idPadre": "MTC-MG-CKL", "active": false }// Mantenimiento - administración - checklist - modificar
        , { "tag": "this.texto.modMaintenance.menu.managment.checklist[3]", "link": "/reportCheckList", "icono": "fas fa-file-contract", "uniqueCode": 'MTRCH', "idPadre": "MTC-MG-CKL", "active": false }// Mantenimiento - administración - checklist - reporte

        // Precios
        , { "tag": "this.texto.modMaintenance.menu.managment.price.tempario[0]", "link": null, "icono": "far fa-clock", "uniqueCode": "MTC-MG-PC-TM", "idPadre": "MTC-MG-PC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.price.listPrice[0]", "link": null, "icono": "fas fa-money-check-alt", "uniqueCode": "MTC-MG-PC-LP", "idPadre": "MTC-MG-PC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Tempario
        , { "tag": "this.texto.modMaintenance.menu.managment.price.tempario[1]", "link": "/timeList", "icono": "fas fa-list", "uniqueCode": "MTC-MG-PC-TM-C", "idPadre": "MTC-MG-PC-TM", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Lista de precios
        , { "tag": "this.texto.modMaintenance.menu.managment.price.listPrice[1]", "link": '/createPriceList', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-MG-PC-LP-C", "idPadre": "MTC-MG-PC-LP", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.price.listPrice[2]", "link": '/masiveLoadPriceList', "icono": "fa fa-upload", "uniqueCode": "MTC-MG-PC-LP-M", "idPadre": "MTC-MG-PC-LP", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.price.listPrice[3]", "link": '/reportPriceList', "icono": "fas fa-file-contract", "uniqueCode": "MTC-MG-PC-LP-R", "idPadre": "MTC-MG-PC-LP", "active": false } // Reporte conteo toma inv. ext. tubería con archivo


        // Kits
        , { "tag": "this.texto.modMaintenance.menu.managment.kit[1]", "link": '/createKits', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-KT-C", "idPadre": "MTC-MG-KT", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.kit[2]", "link": '/modifyKits', "icono": "fas fa-edit", "uniqueCode": "MTC-KT-M", "idPadre": "MTC-MG-KT", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.kit[3]", "link": '/reportKits', "icono": "fas fa-file-contract", "uniqueCode": "MTC-KT-R", "idPadre": "MTC-MG-KT", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Promosiones
        , { "tag": "this.texto.modMaintenance.menu.managment.promocions[1]", "link": '/createPromotions', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-PMC-C", "idPadre": "MTC-MG-PMC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.promocions[2]", "link": '/modifyPromotions', "icono": "fas fa-edit", "uniqueCode": "MTC-PMC-M", "idPadre": "MTC-MG-PMC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.managment.promocions[3]", "link": '/reportPromotions', "icono": "fas fa-file-contract", "uniqueCode": "MTC-PMC-R", "idPadre": "MTC-MG-PMC", "active": false } // Reporte conteo toma inv. ext. tubería con archivo


        //Recepción
        , { "tag": "this.texto.modMaintenance.menu.reception.equipmentEntry.name", "link": null, "icono": "fas fa-calendar-alt", "uniqueCode": "MTC-RP-EE", "idPadre": "MTC-RP", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[0]", "link": null, "icono": "fas fa-calendar-alt", "uniqueCode": 'MTC-RP-SC', "idPadre": "MTC-RP", "active": false }// Mantenimiento - devolución - crear devolución
        , { "tag": "this.texto.modMaintenance.menu.reception.budget[0]", "link": null, "icono": "fas fa-file-contract", "uniqueCode": 'MTC-RP-BG', "idPadre": "MTC-RP", "active": false }// Mantenimiento - recepción - presupuesto - crear

        // Equipment entry
        , { "tag": "this.texto.modMaintenance.menu.reception.equipmentEntry.createEquipmentEntry[0]", "link": '/createEquipmentEntry', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-EE-C", "idPadre": "MTC-RP-EE", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.reception.equipmentEntry.modifyEquipmentEntry[0]", "link": '/modifyEquimentEntry', "icono": "fas fa-edit", "uniqueCode": "MTC-EE-M", "idPadre": "MTC-RP-EE", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.reception.equipmentEntry.resultEquipmentEntry[0]", "link": '/reportEquipmentEntry', "icono": "fas fa-file-contract", "uniqueCode": "MTC-EE-R", "idPadre": "MTC-RP-EE", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Agenda
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[0]", "link": "/createDiary", "icono": "fa fa-plus", "uniqueCode": 'MTC-RP-SC-C', "idPadre": "MTC-RP-SC", "active": false }// Mantenimiento - devolución - crear devolución
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[1]", "link": "/scheduleCalendarView", "icono": "fas fa-edit", "uniqueCode": 'MTC-RP-SC-CL', "idPadre": "MTC-RP-SC", "active": false }// Mantenimiento - devolución - editar devolución

        // Presupuesto
        , { "tag": "this.texto.modMaintenance.menu.reception.budget[1]", "link": "/createBudget", "icono": "fa fa-plus-circle", "uniqueCode": 'MTC-RP-BG-C', "idPadre": "MTC-RP-BG", "active": false }// Mantenimiento - recepción - presupuesto - crear
        , { "tag": "this.texto.modMaintenance.menu.reception.budget[2]", "link": "/updateBudget", "icono": "fa fa-upload", "uniqueCode": 'MTC-RP-BG-M', "idPadre": "MTC-RP-BG", "active": false }// Mantenimiento - recepción - presupuesto - modificar
        , { "tag": "this.texto.modMaintenance.menu.reception.budget[3]", "link": "/reportBudget", "icono": "fas fa-file-contract", "uniqueCode": 'MTC-RP-BG-R', "idPadre": "MTC-RP-BG", "active": false }// Mantenimiento - recepción - presupuesto - reporte

        // Managment
        , { "tag": "this.texto.modMaintenance.menu.workshop.maintenance[0]", "link": null, "icono": "fas fa-list", "uniqueCode": "MTC-WSK-MG", "idPadre": "MTC-WSK", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.workshop.attentionOT[0]", "link": null, "icono": "fas fa-list", "uniqueCode": "MTC-WSK-MT", "idPadre": "MTC-WSK", "active": false } // Reporte conteo toma inv. ext. tubería con archivo

        // Administración
        , { "tag": "this.texto.modMaintenance.menu.workshop.workOrder[0]", "link": null, "icono": "fas fa-list", "uniqueCode": "MTC-WSK-MG-OT", "idPadre": "MTC-WSK-MG", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.workshop.distribution[0]", "link": "/distribution", "icono": "fa fa-user-edit", "uniqueCode": 'MTC-WSK-MG-D', "idPadre": "MTC-WSK-MG", "active": false }// Mantenimiento - taller - distribución
        , { "tag": "this.texto.modMaintenance.menu.workshop.additionals[0]", "link": "/createAdditional", "icono": "fa fa-plus", "uniqueCode": 'MTC-WSK-MG-AD', "idPadre": "MTC-WSK-MG", "active": false }// Mantenimiento - taller - distribución
        , { "tag": "this.texto.modMaintenance.menu.workshop.approval[0]", "link": "/createApprovalOt", "icono": "fa fa-user-check", "uniqueCode": 'MTC-WSK-MG-APOT', "idPadre": "MTC-WSK-MG", "active": false }// Mantenimiento - taller - distribución

        // Attention OT
        , { "tag": "this.texto.modMaintenance.menu.workshop.attentionOT[1]", "link": "/createAttentionOt", "icono": "fa fa-user-check", "uniqueCode": 'MTC-WSK-MT-AOT', "idPadre": "MTC-WSK-MT", "active": false }// Mantenimiento - taller - distribución

        // OT
        , { "tag": "this.texto.modMaintenance.menu.workshop.workOrder[0]", "link": "/viewInfoWorkOrder", "icono": "fas fa-toolbox", "uniqueCode": 'MTC-WSK-MG-OT-CR', "idPadre": "MTC-WSK-MG-OT", "active": false }// Mantenimiento - taller - Orden de trabajo

        // Return
        , { "tag": "this.texto.modMaintenance.menu.return.equipmentDelivery[0]", "link": null, "icono": "fas fa-list", "uniqueCode": "MTC-RT-CRUD", "idPadre": "MTC-RT", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[0]", "link": null, "icono": "fa fa-plus", "uniqueCode": 'MTC-RT-SH', "idPadre": "MTC-RT", "active": false }// Mantenimiento - recepción - agenda - crear


        // Cronograma
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[1]", "link": "/createSchedule", "icono": "fa fa-plus", "uniqueCode": 'MTC-RT-SH-C', "idPadre": "MTC-RT-SH", "active": false }// Mantenimiento - recepción - agenda - crear
        , { "tag": "this.texto.modMaintenance.menu.return.schedule[2]", "link": "/calendarSchedule", "icono": "fa fa-calendar", "uniqueCode": 'MTC-RT-SH-R', "idPadre": "MTC-RT-SH", "active": false }// Mantenimiento - recepción - agenda - modificar

        // Equipment delivery
        , { "tag": "this.texto.modMaintenance.menu.return.equipmentDelivery[1]", "link": '/createEquipmentDelivery', "icono": "fa fa-plus-circle", "uniqueCode": "MTC-RT-CRUD-C", "idPadre": "MTC-RT-CRUD", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        // , { "tag": "this.texto.modMaintenance.menu.return.equipmentDelivery[2]", "link": '/modifyEquimentDelivery', "icono": "fas fa-edit", "uniqueCode": "MTC-RT-CRUD-M", "idPadre": "MTC-RT-CRUD", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        , { "tag": "this.texto.modMaintenance.menu.return.equipmentDelivery[3]", "link": '/reportEquipmentDelivery', "icono": "fas fa-file-contract", "uniqueCode": "MTC-RT-CRUD-R", "idPadre": "MTC-RT-CRUD", "active": false } // Reporte conteo toma inv. ext. tubería con archivo
        // ********************Maintenance***************************** //
        // ********************Maintenance***************************** //
        // ********************Maintenance***************************** //



        // Inventario - Panel de control - Contabilidad - Sucursal (Crear - Modificar - Eliminar - Reporte)
        // , { "tag": "this.texto.inventarioModulo.contabilidadSucursalCrear[0]",       "link":  "/crearSucursal",         "icono": "fa fa-plus-circle",     "uniqueCode": "CRESCRL",   "idPadre": "SCRL",  "active": false }//Inventarios - Panel de Control - Contabilidad - Sucursal - Crear
        // , { "tag": "this.texto.inventarioModulo.contabilidadDestinoCargueMasivo[0]", "link":  "/cargueMasivoSucursal",  "icono": "fa fa-upload",          "uniqueCode": "MDFSCRL",   "idPadre": "SCRL",  "active": false }//Inventarios - Panel de Control - Contabilidad - Sucursal - Modificar
        // , { "tag": "this.texto.inventarioModulo.contabilidadSucursalReporte[0]",     "link":  "/reporteSucursal",       "icono": "fas fa-file-contract",  "uniqueCode": "RPTSCRL",   "idPadre": "SCRL",  "active": false }//Inventarios - Panel de Control - Contabilidad - Sucursal - Reporte
    ];

    constructor() {
        this.permisos = {
            Rol_Ver: 1,
            Rol_Editar: 2,
            Rol_Crear: 3,
            Rol_Habilitar: 4,
            Rol_deshabilitar: 5,
            Rol_Eliminar: 18,
            Empresa_Ver: 6,
            Empresa_Editar: 7,
            Empresa_Crear: 8,
            Empresa_Habilitar: 9,
            Empresa_deshabilitar: 10,
            Empresa_Eliminar: 19,
            Usuario_Ver: 11,
            Usuario_Editar: 12,
            Usuario_Crear: 13,
            Usuario_Habilitar: 14,
            Usuario_Eliminar: 20,
            Usuario_deshabilitar: 15,
        }
    }
}
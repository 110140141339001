import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { textoModuloAdm } from '../texto';
import { AjaxService } from '../_services/ajax.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { JwtService } from '../_services/jwt.service';
import { DycpService } from '../auth/_services/dycp';


declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./theme.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./theme.component.css"]
})
export class ThemeComponent implements OnInit, AfterViewInit {
    private public: textoModuloAdm;
    private encript: DycpService = new DycpService();

    constructor(private _script: ScriptLoaderService,
        private _router: Router,
        private ajaxS: AjaxService,
        private jwt: JwtService,
        private texto: textoModuloAdm,
        private spinner: NgxSpinnerService
    ) {
    }
    ngOnInit() {
        // let reload: any = sessionStorage.getItem('reload');
        // console.log(reload);
        // console.log(sessionStorage);


        // if (reload != undefined && reload != null) {
        //     sessionStorage.removeItem('reload');
        //     console.log('reload..');

        //     window.location.reload();
        // }

        let language: any = sessionStorage.getItem('idiomaApp');
        this.texto.cambiarIdioma(language);

        this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js', 'assets/demo/default/js/validarCierre.js'], true)
            .then(result => {
                // Helpers.setLoading(false);
                this.spinner.hide();
                // optional js to be loaded once
                this._script.loadScripts('head', ['assets/vendors/custom/fullcalendar/fullcalendar.bundle.js'], true);
            });
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                (<any>mLayout).closeMobileAsideMenuOffcanvas();
                //(<any>mLayout).closeMobileHorMenuOffcanvas();  //Genera error con el router.navigation
                (<any>mApp).scrollTop();
                // Helpers.setLoading(true);
                this.spinner.show();
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                // Helpers.setLoading(false);
                this.spinner.hide();
                // content m-wrapper animation
                let animation = 'm-animate-fade-in-up';
                $('.m-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
                    $('.m-wrapper').removeClass(animation);
                }).removeClass(animation).addClass(animation);
            }
        });

    }


    ngAfterViewInit() {
        // this.sessionTimeValidation();
        // console.log('Starting session');
        this.controlSession();
    }

    async controlSession() {
        let token: any = null;
        let interval: any = null;
        let waitTime: any = null;
        let missingTime: any = null;
        let additionalTime: number = 60;
        let show: number = 0;

        interval = await setInterval(() => {
            if (navigator.onLine == false && (JSON.parse(sessionStorage.getItem('status_red')) == false || typeof sessionStorage.getItem('status_red') == 'undefined' || JSON.parse(sessionStorage.getItem('status_red')) == null)) {
                this.spinner.show();
                // this.closeSession();
                Swal({
                    text: '!Not internet connection¡'
                    , type: 'error'
                })
                show = 1;
            } else if (show == 1) {
                this.spinner.hide();
                Swal({
                    text: '!Connection reestablished¡'
                    , type: 'success'
                    , timer: 2000
                })
                show = 0;
            }
            let returnValue = this.insideOnTimer(additionalTime);
            if (returnValue == false) {
                // console.log('Finish session');
                clearInterval(interval);
                this.showAlertOutSession();
            } else if (returnValue == 'error') {
                // console.log('Finish session 2');
                clearInterval(interval);
                this.closeSession();
            }
        }, 1000);
    }

    insideOnTimer(additionalTime: number) {
        try {
            let token = this.encript.desencriptar().token;
            let waitTime = this.jwt.decodeToken(token)
            let missingTime = (waitTime.iatUnix + additionalTime) <= waitTime.expUnix ? { isValid: true, time: 1 } : { isValid: false, time: additionalTime };

            // console.log('Tiempo actual=> ', (waitTime.iatUnix + additionalTime), ' Tiempo limite => ', waitTime.expUnix);
            // console.log(missingTime);

            let isMasiveLoad: any = sessionStorage.getItem('isMasiveLoading');
            if (missingTime.isValid || isMasiveLoad == 'true') {
                return true;
            } else {
                return false;
            }
        } catch (x) {
            return 'error';
        }
    }

    showAlertOutSession() {
        let isMasiveLoad: any = sessionStorage.getItem('isMasiveLoading');
        let timerInterval: any = null;

        // console.log(isMasiveLoad);

        if (isMasiveLoad == false || isMasiveLoad == 'false' || isMasiveLoad == null) {
            Swal({
                title: this.texto.alertaInactividad[0],
                html: `${this.texto.alertaInactividad[1]} <strong></strong> ${this.texto.alertaInactividad[2]} `,
                // type: 'warning',
                timer: 30000,
                onBeforeOpen: () => {
                    // console.log(Swal.getTimerLeft());
                    Swal.showLoading()
                    let timer: any;
                    timerInterval = setInterval(() => {
                        try {
                            timer = (parseInt((Swal.getTimerLeft() / 1000).toString())).toString();
                            // console.log(timer);
                            Swal.getContent().querySelector('strong').innerHTML = timer;
                        } catch (x) {

                        }
                    }, 1000)
                },
                onClose: () => {
                    clearInterval(timerInterval);
                    this.closeSession();
                    // console.log('Se limpia el intervalo');

                }
            })

            $('body').mouseover(() => {
                clearInterval(timerInterval);
                Swal.close();
                $('body').unbind("mouseover");
                this.restoreSession()
            })
        } else {
            // console.log('is Massive');
            this.restoreSession(); // for valores
        }
    }

    async restoreSession(massive?: any) {
        let isRestored: any = null;
        if (massive) isRestored = true;
        else isRestored = await this.ajaxS.validarToken();
        // console.log(isRestored);

        if (isRestored) {
            this.controlSession();
        } else {
            this.closeSession();
        }

    }

    closeSession() {
        sessionStorage.clear();
        this._router.navigate(['login']);
    }


}
/*
Fecha creación: NA
Fecha modificación: 23/07/2018
Autor creación: Yisus 
Autor modificación: Yisus
Descripción: Esta clase se encarga enviar los mensajes de alerta a la vista
*/


import { Component, OnInit } from "@angular/core";
import { AlertService } from "../_services";

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html'
})

export class AlertComponent implements OnInit {
    message: any;
    // type:any;

    constructor(private _alertService: AlertService) {
    }

    ngOnInit() {
        this._alertService.getMessage().subscribe(message => {
            this.message = message;
            // console.log(message)
            // this.type = type;
        });
    }
}
import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
//import { JwtHelperService } from '@auth0/angular-jwt';
//import { JwtHelperService } from '@auth0/angular-jwt'
import * as moment from 'moment';
import config from '../../environments/config';



@Injectable()
export class JwtService {

    private keyToken: any = config.TOKEN_SECRET;
    constructor() {
    }

    /*codifica(data) {
      var payLoad = {
        sub: data,
        iat: moment().unix(),
        exp: moment().add(50, 'minutes'),
        tiempo: moment().add(50, 'minutes').unix()
      };
  
      return jwt.encode(payLoad, config.TOKEN_SECRET);
    }*/


    decodificar(res) {        
        let token = res.token.split(';#;')[0];
        let payLoad = decode(token);
        let data = JSON.parse(payLoad.sub);
        
        let dataUser = {
            idUsuario: data.user.idUsuario,
            // idEstadoSesion: data.user.idEstadoSesion,
            // tipoEstadoSesion: data.user.tipoEstadoSesion,
            idEstadoUsuario: data.user.idEstadoUsuario,
            // tipoEstadoUsuario: data.user.tipoEstadoUsuario,
            idRol: data.user.idRol,
            nombreRol: data.user.nombreRol,
            // idTipoDocumentoUsuario: data.user.idTipoDocumentoUsuario,
            // tipoDocumento: data.user.tipoDocumento,
            numeroDocumentoUsuario: data.user.numeroDocumentoUsuario,
            // idCodigoPostal: data.user.idCodigoPostal,
            // codigoPostal: data.user.codigoPostal,
            // idMunicipio: data.user.idMunicipio,
            // nombreMunicipio: data.user.nombreMunicipio,
            nombreUsuario: data.user.nombreUsuario,
            apellidoUsuario: data.user.apellidoUsuario,
            emailUsuario: data.user.emailUsuario,
            // contraseñaUsuario: data.user.contraseñaUsuario,
            // direccionUsuario: data.user.direccionUsuario,
            // telefonoUsuario: data.user.telefonoUsuario,
            // extUsuario: data.user.extUsuario,
            // celularUsuario: data.user.celularUsuario,
            empresaUsuario: data.user.empresaUsuario,
            cargoUsuario: data.user.cargoUsuario,
            // ultimaAccionUsuario: data.user.ultimaAccionUsuario,
            // Resultado: data.user.Resultado,
            // Permisos: data.permisos,
            // numPermisos: permit,
            token: res.token
        }
        // 
        return new Promise((resolve, reject) => {
            try {
                resolve(dataUser);
            }
            catch (err) {
                console.log(err);
                reject({ message: 'Token invalido!' })
            }
        })

    }

    decodeToken(token) {
        let info: any = decode(token.split(';#;')[0]);
        return { iat: moment().format("h:mm:ss:ssss"), exp: moment.unix(info.exp).format("h:mm:ss:ssss"), iatUnix: moment().unix(), expUnix: info.exp };
    }
}


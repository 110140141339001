import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { textoModuloAdm } from '../texto';
import { AjaxService } from './ajax.service';
import { DycpService } from '../auth/_services/dycp';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Injectable()
export class CompanyServiceService {

  public encript: DycpService = new DycpService();
  public user: any;
  public empresas: any = [];
  public idEmpresa: any = 0;
  public nomEmpresa: any = '';
  public control: boolean = false;
  public idIdioma: any = 0;
  public idiomas: any = [];
  public nomIdioma = '';
  public idAsociacion = 0;
  private urlReturnLogin: any = null;

  constructor(
    private ajax: AjaxService,
    private texto: textoModuloAdm,
    private router: Router,
    private activeRouter: ActivatedRoute
    , private spinner: NgxSpinnerService
  ) {
    this.user = this.encript.desencriptar();
  }

  validateCompany(urlReturn?: any) {
    this.urlReturnLogin = typeof urlReturn != 'undefined' ? urlReturn : null;
    if (sessionStorage.getItem('idiomaTrabajo')) {
      this.idIdioma = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).idIdioma;
      this.idEmpresa = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).idEmpresa;
      this.nomEmpresa = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).Empresa;
      this.nomIdioma = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).Idioma;
      this.control = true;
      this.obtenerEmpresa(false);
    } else {
      this.obtenerEmpresa(true);
    }
  }


  isExistsCompany() {
    return new Promise((resolve) => {
      if (sessionStorage.getItem('idiomaTrabajo')) {
        this.idIdioma = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).idIdioma;
        this.idEmpresa = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).idEmpresa;
        this.nomEmpresa = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).Empresa;
        this.nomIdioma = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).Idioma;
        this.control = true;
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }


  obtenerEmpresa(showModal) {
    // console.log('actualizo');
    this.user = this.encript.desencriptar();
    // let url = 'private/empresa_dd';
    let url = 'private/empresas/' + this.user.idUsuario;
    this.ajax.getInfo(url).subscribe((data: any) => {
      this.empresas = data.result[0];      // 
      let dataTrabajo: any = JSON.parse(sessionStorage.getItem('idiomaTrabajo'));
      if (dataTrabajo != null) {
        this.obtenerIdioma(dataTrabajo.idEmpresa).then((res) => {
          if (res) {
            if (showModal) {
              $('#modalEmpresas').modal('show');
            }
          }
        })
      } else {
        if (showModal) {
          $('#modalEmpresas').modal({ backdrop: 'static', keyboard: false });
        }
      }
    })
  }

  obtenerIdioma(id) {
    // let url = 'private/Idioma';
    return new Promise((resolve, rejects) => {
      let url = 'private/idiomaEmpresa/' + id;
      this.ajax.getInfo(url).subscribe((data: any) => {
        // console.log(data);

        if (typeof data.result[0][0].resultado == 'undefined') {
          this.idiomas = data.result[0];
          resolve(true);
        } else {
          swal({
            title: this.texto.login[1].alertBuscarIdioma[0],
            text: this.texto.login[1].alertBuscarIdioma[1],
            type: 'error',
            confirmButtonText: this.texto.login[1].alertBuscarIdioma[2]
          })
          this.idIdioma = 0;
          this.idiomas = [];
          resolve(false);
        }
      })
    })
  }

  guardarEmpresa() {
    this.redirectTo().then((res: any) => {
      if (res.status) {
        this.spinner.show();
        $('#modalEmpresas').modal('hide');
        sessionStorage.setItem('idiomaTrabajo', JSON.stringify(res.obj));
        if (typeof this.urlReturnLogin != 'undefined' && this.urlReturnLogin != null) {
          this.router.navigate([this.urlReturnLogin]);
        } else {
          sessionStorage.removeItem('info - curren - sol');
          this.router.navigate([this.router.url.split(';')[0]]);
          setTimeout(() => { window.location.reload() }, 2000);
        }
      } else {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
      }
    })
  }

  redirectTo() {
    // let dataTrabajo = { idIdioma: '', idEmpresa: '', Empresa: '', Idioma: '', idAsociacion: '' };
    return new Promise((resolve) => {
      if (this.idEmpresa > 0 && this.idEmpresa != '' && this.idEmpresa != null && typeof this.idEmpresa != 'undefined') {

        let empresa = this.empresas.filter((val) => val.idEmpresa == this.idEmpresa)[0];

        this.obtenerIdioma(empresa.idEmpresa).then((res) => {

          let idioma = this.idiomas.filter((val) => val.idIdioma == this.idIdioma)[0];

          this.nomEmpresa = empresa.nombreEmpresa;
          this.nomIdioma = idioma.idioma;

          let obj = {
            idEmpresa: empresa.idEmpresa
            , Empresa: empresa.nombreEmpresa
            , idIdioma: idioma.idIdioma
            , nitEmpresa: idioma.nitEmpresa
            , Idioma: idioma.idioma
            , idAsociacion: idioma.ididioma_empresa
          }
          $('#modalEmpresas').modal('hide');
          resolve({ status: true, obj: obj });


        })
      } else {
        resolve({ status: false, obj: null });
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ActivatedRouteSnapshot, CanActivate, Router, ActivatedRoute, RouterStateSnapshot } from "@angular/router";
import { variablesGlobales } from '../variablesGlobales'
import { textoModuloAdm } from '../texto'
import 'rxjs/add/operator/map';
import { fromPromise } from 'rxjs/observable/fromPromise';
import 'rxjs/add/operator/catch'
// import io from 'socket.io-client';
import * as socketIo from 'socket.io-client';
import { Events } from './event';
import { DycpService } from '../auth/_services/dycp';
// import {RolInterface} from './ajax.service'; 
//import { ServicesGeneralsService } from './services-generals.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

declare var $: any;
// declare var io:any;

import swal from 'sweetalert2';

const HEADER = new HttpHeaders({
    "Content-Type": "application/json", "autorization": null, 'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
});

@Injectable()
export class AjaxService {
    // private globalUrl =  + '/api/';
    // private globalUrl2 = 'http://' + window.location.host.replace(":" + window.location.port, ":3000") + '/';
    private socket;
    private globalUrl = variablesGlobales.urlGlobal + 'api/';
    private globalUrl2 = variablesGlobales.urlGlobal;
    private encript: DycpService = new DycpService();
    constructor(public http: HttpClient, public router: Router, public routeActive: ActivatedRoute, public texto: textoModuloAdm, private spinner: NgxSpinnerService) {
    }

    public initSocket(): void {
        this.socket = socketIo(this.globalUrl2);
    }


    public async initSocketAwait(): Promise<any> {
        this.socket = await socketIo(this.globalUrl2);
        return new Promise((resolve) => {
            setTimeout(() => {
                if (this.socket != undefined && this.socket != null) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }, 3000)
        })
    }

    public onEvent(event: Events): Observable<any> {
        return new Observable<Events>(observer => {
            this.socket.on(event, () => observer.next());
        });
    }

    public send(nomConecction: any, message: any): void {
        this.socket.emit(nomConecction, message);
    }

    public onMessage(nomConecction: any): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on(nomConecction, (data: any) => observer.next(data));
        }).map((data) => {
            sessionStorage.setItem('isMasiveLoading', (true).toString());
            return data;
        });
    }

    public resetSocket() {
        this.socket.close();
    }

    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     const timeoutValue = Number(req.headers.get('timeout')) || 10000;

    //     return next.handle(req).pipe(timeout(timeoutValue));
    // }

    // ------------------ BEGIN Ajax tradicional con jquery --------------------------------//
    ajaxDropDown(method, api, info, token, errorFuntion, successFuntion, options) {


        $.ajax({
            type: method,
            url: api,
            headers: {
                "Content-Type": "application/json",
                "autorization": this.getToken()
            },
            data: info,
            error: function (err) {
                errorFuntion(err);
            },
            success: function (dataSet) {
                let data = JSON.parse(JSON.stringify(dataSet.result[0]));
                let html = `<option value="0">${options.textos[0]}</option>`;

                //console.log(dataSet)
                $.each(data, (item, value) => {
                    html += '<option value="'
                    let control = false;
                    $.each(value, (i, v) => {
                        if (control) {
                            html += (v + '</option>');
                        } else {
                            html += (v + '">');
                            control = true;
                        }
                    })
                })
                successFuntion({ data: html });
            }
        })
    }

    ajaxRequesInfo(method, api, info, token, errorFuntion, successFuntion, options?) {
        $.ajax({
            type: method,
            url: api,
            headers: {
                "Content-Type": "application/json",
                "autorization": this.getToken()
            },
            data: JSON.stringify(info),
            dataType: 'json',
            error: function (err) {
                errorFuntion(err);
            },
            success: function (dataSet) {
                this.actualizarToken(dataSet);
                let data = JSON.parse(JSON.stringify(dataSet.result[0]));
                successFuntion(data);
            },
        });
    }

    ajaxNoResponse(method, api, info, token, success_) {
        //console.log(JSON.stringify(info));
        $.ajax({
            type: method,
            url: api,
            headers: {
                "Content-Type": "application/json",
                "autorization": this.getToken()
            },
            data: JSON.stringify(info),
            dataType: 'json',
            error: function (err) {
                swal({
                    title: 'Error',
                    text: 'Error general, comuniquese con el administrador: ' + err,
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            },
            success: function (dataSet) {
                this.actualizarToken(dataSet)
                success_(JSON.stringify(dataSet));
            },
        });
    }

    validarToken(token?, currentUser?) {
        return new Promise((resolve) => {
            // debugger;
            try {
                let header: any = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken(token) });
                if (!currentUser) currentUser = this.encript.desencriptar();
                let url: any = `private/getEstadoUsuario/${currentUser.idUsuario}`;


                this.http.get((this.globalUrl + url), { headers: header }).subscribe((res: any) => {
                    if (res.status == 1) {
                        this.actualizarToken(res, currentUser);
                        resolve(true);
                    } else {
                        resolve(false);
                    }

                })
            } catch (x) {
                resolve(false);
            }

        })
    }

    getInfoSinHeader(url): Observable<any> {

        return this.http.get(url, {});
        // return this.http.get((this.globalUrl + url), { headers: header });
    }


    getSocket(url): any {
        return new Promise((resolve) => {
            let header = new HttpHeaders({
                "Content-Type": "application/json", "autorization": this.getToken(), 'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            });

            this.http.get((this.globalUrl + url), { headers: header }).map((item) => {
                this.actualizarToken(item);
                return item;
            }).subscribe((result: any) => {
                resolve(result);
            })
        })
    }



    searchTerm(url: string) {
        return new Promise(resolve => {
            let url_data: any = this.globalUrl + url;
            let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken(), timeout: `${2000}` });
            this.http
                .get(url_data, { headers: header }).subscribe((res: any,) => {
                    this.actualizarToken(res);
                    if (res.status == 1 || res.status) {
                        resolve(res.result[0]);
                    } else {
                        resolve([]);
                    }
                }, ((err) => {
                    console.log((err));
                    alert("Error de conexión");
                    this.spinner.hide();
                }))
        });
    }

    searchTermPost(url: string, body: any) {
        return new Promise(resolve => {
            let url_data: any = this.globalUrl + url;
            let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken(), timeout: `${2000}` });
            this.http
                .post(url_data, body, { headers: header }).subscribe((res: any) => {
                    if (res.status == 1 || res.status) {
                        resolve(res.result);
                    } else {
                        resolve([]);
                    }
                }, ((err) => {
                    console.log((err));
                    alert("Error de conexión");
                    this.spinner.hide();
                }))
        });
    }

    convertDropDw(dataSet, options?) {
        let data = JSON.parse(JSON.stringify(dataSet));
        let html = `<option value="0">${options.textos}</option>`;

        $.each(data, (item, value) => {
            html += '<option value="'
            let control = false;
            $.each(value, (i, v) => {
                if (control) {
                    html += (v + '</option>');
                } else {
                    html += (v + '">');
                    control = true;
                }
            })
        })

        return html;
    }

    getInfo(url, token?, free?): Observable<any> {
        let header;

        if (sessionStorage.getItem('currentUser')) {
            header = new HttpHeaders({
                "Content-Type": "application/json", "autorization": this.getToken(), 'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            });
        }
        if (free) {
            return this.http.get((this.globalUrl2 + url), { headers: header });
        }

        return this.http
            .get((this.globalUrl + url), { headers: header }).map((item) => {
                this.actualizarToken(item);
                return item;
            })
    }

    postInfo(url, token, data, masivo?, currentUser?): Observable<any> {
        let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken(token), timeout: `${1000000}` });
        // return this.http.post((this.globalUrl + url), data, { headers: header });

        // return fromPromise(this.guardarLog(this.http.post((this.globalUrl + url), data, { headers: header }), 2, url, data, masivo, token, currentUser));
        return this.http.post((this.globalUrl + url), data, { headers: header }).map((item) => {
            this.actualizarToken(item);
            return item;
        })
    }


    postConsulta(url, token, data, masivo?, currentUser?): Observable<any> {
        let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken(), timeout: `${1000000}` });
        // return this.http.post((this.globalUrl + url), data, { headers: header });

        // return fromPromise(this.guardarLog(this.http.post((this.globalUrl + url), data, { headers: header }), 2, url, data, masivo, token, currentUser));
        return this.http.post((this.globalUrl + url), data, { headers: header }).map((item) => {
            this.actualizarToken(item);
            return item;
        });
    }


    putInfo(url, token, data): Observable<any> {
        let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken() });
        // this.postLog().subscribe((result)=>{
        //     console.log(result)
        // })
        // return this.http.put((this.globalUrl + url), data, { headers: header })

        // return fromPromise(this.guardarLog(this.http.put((this.globalUrl + url), data, { headers: header }), 3, url, data));
        return this.http.put((this.globalUrl + url), data, { headers: header }).map((item) => {
            this.actualizarToken(item);
            return item;
        })
    }

    deleteInfo(url): Observable<any> {

        let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken() });
        // return this.http.delete((this.globalUrl + url), { headers: header })
        // return fromPromise(this.guardarLog(this.http.delete((this.globalUrl + url), { headers: header }), 4, url));
        return this.http.delete((this.globalUrl + url), { headers: header });
    }

    setDelete(url, token): Observable<any> {

        let header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken() });


        // return fromPromise(this.guardarLog(this.http.delete((this.globalUrl + url), { headers: header }), 4, url));
        return this.http.delete((this.globalUrl + url), { headers: header });
    }
    // ------------------------ END -------------------------------------------------------//


    // ------------------ BEGIN registro del log --------------------------------//

    guardarLog(res, tipo, url, data?, masivo?, tokenOld?, currentUser?) {

        return new Promise((resolve, rejects) => {
            let token: any = this.getToken(tokenOld);
            this.validarToken(token, currentUser).then((tokenValidad) => {
                if (tokenValidad) {
                    res.subscribe((respuesta: any) => {
                        if (typeof respuesta.validado == 'undefined') {
                            try {

                                if (typeof respuesta.token != 'undefined') {
                                    this.actualizarToken(respuesta, currentUser);
                                }


                                if (respuesta.status == 1) {
                                    let urlLog: string = `private/Log`;
                                    let path: any = this.router.url.toString().substr(1, this.router.url.toString().length);

                                    let header: any;
                                    let usuaro: any;

                                    if (typeof currentUser == 'undefined') {
                                        if (sessionStorage.getItem('currentUser')) {
                                            usuaro = this.encript.desencriptar();
                                            header = new HttpHeaders({ "Content-Type": "application/json", "autorization": token });
                                        }
                                    } else {
                                        usuaro = currentUser;
                                        header = new HttpHeaders({ "Content-Type": "application/json", "autorization": token });
                                    }


                                    let data_to_log: any = {
                                        idusuario: usuaro.idUsuario,
                                        data: typeof data == 'undefined' ? false : {},
                                        // data: typeof data == 'undefined' ? false : data,
                                        path: path,
                                        tipo: tipo,
                                        urlSolicitada: url[0] == '/' ? url.substr(1, url.length - 1) : url,
                                        masivo: (sessionStorage.getItem('isMasiveLoading') == 'true')
                                    }

                                    this.http.post((this.globalUrl + urlLog), data_to_log, { headers: header }).subscribe((res: any) => {
                                        // console.log(res);
                                        if (typeof res.validado == 'undefined') {
                                            if (res.status == 1) {
                                                resolve(respuesta);
                                            } else {
                                                // console.log('Error al guardar el log');
                                                resolve(respuesta);
                                            }

                                        } else {
                                            localStorage.clear();
                                            sessionStorage.clear();
                                            // console.log('error token 3');
                                            this.router.navigate(['/login']);
                                        }
                                    })
                                } else {
                                    // console.log('por aquí?');
                                    resolve(respuesta);
                                }
                            } catch (x) {
                                console.log(x);
                                resolve(respuesta)
                            }
                        } else {
                            localStorage.clear();
                            sessionStorage.clear();
                            // console.log('error token 2');
                            this.router.navigate(['/login']);
                        }
                    })
                } else {
                    localStorage.clear();
                    sessionStorage.clear();
                    // console.log('error token 1');
                    this.router.navigate(['/login']);


                }
            })

        })
    }

    // ------------------------ END -------------------------------------------------------//

    // ------------------ BEGIN Validar acceso --------------------------------//

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let path: any = route.routeConfig.path;

        let idioma = JSON.parse(sessionStorage.getItem('idiomaTrabajo')).idIdioma;
        let url: any = `private/validaPermisosUsuarioFromDB/${path}?data=${idioma}`;
        // console.log(path, idioma);
        let header: any;


        let token: any = this.getToken();
        if (token) header = new HttpHeaders({ "Content-Type": "application/json", "autorization": this.getToken() });


        return this.http.get<boolean>((this.globalUrl + url), { headers: header }).map((response: any) => {

            if (response.status && response.token) {
                if (response.result > 0) return true;
                else {
                    let usuario = this.encript.desencriptar();
                    swal({
                        title: this.texto.rolValidacion[0],
                        text: this.texto.rolValidacion[1] + usuario.nombreUsuario + this.texto.rolValidacion[2],
                        type: 'error',
                    })
                    return false;
                }
            } else if (!response.status && response.token) {
                this.spinner.hide();
                swal({
                    title: this.texto.rolValidacion[0],
                    text: this.texto.rolValidacion[3],
                    type: 'error',
                })
                return false;
            } else {
                this.spinner.hide();
                swal({
                    title: this.texto.rolValidacion[0],
                    text: this.texto.rolValidacion[4],
                    type: 'error',
                })
                localStorage.clear();
                sessionStorage.clear();
                console.log('error token 1');
                this.router.navigate(['/login']);
            }
        })

    }

    async getNewToke(time: number, name: string) {
        let nameVariable: string = `updatingToken${name}`;
        if (sessionStorage.getItem("isMasiveLoading") && !sessionStorage.getItem(nameVariable)) {
            sessionStorage.setItem(nameVariable, "true");
            setTimeout(() => {
                let url: string = 'v/public/verify';
                let header = new HttpHeaders({
                    "Content-Type": "application/json", "autorization": this.getToken(), 'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                });
                this.http.get((this.globalUrl2 + url), { headers: header }).subscribe((res: any) => {
                    if (res.validado) this.actualizarToken({ token: res });
                })
                sessionStorage.removeItem(nameVariable);
            }, time);
        }
    }

    actualizarToken(result, currentUser?) {
        try {
            if (result.token.token) {
                let user: any;
                if (!currentUser) user = this.encript.desencriptar();
                else user = currentUser;

                user.token = result.token.token;
                this.encript.encriptar(user);
            }
        } catch (x) {
        }
    }

    getToken(token?) {
        try {
            let info = this.encript.desencriptar();
            if (info.token) return info.token
            else null
        } catch (x) {
            return token ? token : null;
        }
    }


    /**
     * 
     * @param text El texto que desea traducir
     * @param lang es-fr || es-en || en-es || en-fr || fr-en || fr-es
     */
    translateText(text: string, lang: string) {
        let url: string = `https://translate.yandex.net/api/v1.5/tr.json/translate?key=${variablesGlobales.keyYandex}&text=${text}&lang=${lang}`
        // return this.http.get(url);
        return fetch(url).then((res) => res.json());
    }

    // ------------------------ END -------------------------------------------------------//
}

import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AjaxService } from '../_services/ajax.service';

import { DataTablesModule } from 'angular-datatables';
import { AsideNavComponent } from './layouts/aside-nav/aside-nav.component';
import { BrowserModule } from '@angular/platform-browser';
// import { GraphicalAmChardComponent } from './pages/generalComponents/graphical-am-chard/graphical-am-chard.component';

const routes: Routes = [
    {
        'path': '',
        'component': ThemeComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'index',
                // 'loadChildren': '.\/pages\/default\/blank\/blank.module#BlankModule',
                'loadChildren': '.\/pages\/default\/indicators-default\/indicators-default.module#IndicatorsDefaultModule',

            },

            {
                'path': '',
                'redirectTo': 'index',
                'pathMatch': 'full',
            },

            //Rutas modulo administrativo

            {
                'path': 'tablaEmpresa',
                'loadChildren': '.\/pages\/default\/table-empresa\/table-empresa.module#TablaEmpresaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'wizardEmpresaCarga',
                'loadChildren': '.\/pages\/default\/wizardEmpresaCarga\/wizardEmpresaCarga.module#wizardEmpresaCargaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'createRol',
                'loadChildren': '.\/pages\/default\/formulario-rol\/formulario-rol.module#FormularioRolModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'createEmpresa',
                'loadChildren': '.\/pages\/default\/formulario-empresa\/formulario-empresa.module#FormularioEmpresaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'tablaRol',
                'loadChildren': '.\/pages\/default\/table-rol\/table-rol.module#TablaRolModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'createRol',
            //     'loadChildren': '.\/pages\/default\/form-rol\/form-rol.module#FormRolModule',
            //     'canActivate':[AjaxService]
            // },
            {
                'path': 'tablaUser',
                'loadChildren': '.\/pages\/default\/table-usuario\/table-user.module#TablaUserModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'createUsuario',
                'loadChildren': '.\/pages\/default\/formulario-usuario\/formulario-usuario.module#FormularioUsuarioModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'wizardUsuarioCarga',
                'loadChildren': '.\/pages\/default\/wizardUsuarioCarga\/wizardUsuarioCarga.module#wizardUsuarioCargaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'tablaLog',
                'loadChildren': '.\/pages\/default\/table-log\/table-log.module#TablaLogModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'addUsuarioEmpresa',
                'loadChildren': '.\/pages\/default\/add-usuario-empresa\/add-usuario-empresa.module#AddUsuarioEmpresaModule',
                'canActivate': [AjaxService]
            },

            // {
            //     'path': 'eliminarEmpresa',
            //     'loadChildren': '.\/pages\/default\/eliminar-empresa\/eliminar-empresa.module#EliminarEmpresaModule',
            //     'canActivate': [AjaxService]
            // },

            // {
            //     'path': 'eliminarUsuario',
            //     'loadChildren': '.\/pages\/default\/eliminar-usuario\/eliminar-usuario.module#EliminarUsuarioModule',
            //     'canActivate': [AjaxService]
            // },

            // {
            //     'path': 'eliminarRol',
            //     'loadChildren': '.\/pages\/default\/eliminar-rol\/eliminar-rol.module#EliminarRolModule',
            //     'canActivate': [AjaxService]
            // },

            {
                'path': 'createInputFrmSolicitud',
                'loadChildren': '.\/pages\/default\/input-frm-solicitud\/input-frm-solicitud.module#InputFrmSolicitudModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'tablaInputFrmSolicitud',
                'loadChildren': '.\/pages\/default\/tabla-input-frm-solicitud\/tabla-input-frm-solicitud.module#TablaInputFrmSolicitudModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'modificarInputFrmSolicitud',
                'loadChildren': '.\/pages\/default\/editar-input-frm-solicitud\/editar-input-frm-solicitud.module#EditarInputFrmSolicitudModule',
                'canActivate': [AjaxService]
            },

            // {
            //     'path': 'habilitarInhabilitarEmpresa',
            //     'loadChildren': '.\/pages\/default\/habilitar-inhabilitar-empresa\/habilitar-inhabilitar-empresa.module#HabilitarInhabilitarEmpresaModule',
            //     'canActivate': [AjaxService]
            // },

            // {
            //     'path': 'habilitarInhabilitarUsuario',
            //     'loadChildren': '.\/pages\/default\/habilitar-inhabilitar-usuario\/habilitar-inhabilitar-usuario.module#HabilitarInhabilitarUsuarioModule',
            //     'canActivate': [AjaxService]
            // },

            // {
            //     'path': 'habilitarInhabilitarRol',
            //     'loadChildren': '.\/pages\/default\/habilitar-inhabilitar-rol\/habilitar-inhabilitar-rol.module#HabilitarInhabilitarRolModule',
            //     'canActivate': [AjaxService]
            // },

            {
                'path': 'consultaMateriales',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/consulta-materiales\/consulta-materiales.module#ConsultaMaterialesModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'asociarEmpresaIdioma',
                'loadChildren': '.\/pages\/default\/asociar-idioma-empresa\/asociar-idioma-empresa.module#AsociarIdiomaEmpresaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'add-currency-to-company',
                'loadChildren': '.\/pages\/default\/add-currency-to-company\/add-currency-to-company.module#AddCurrencyToCompanyModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'clone-template-catalog',
                'loadChildren': '.\/pages\/default\/clone-template\/clone-template.module#CloneTemplateModule',
                // 'canActivate': [AjaxService]
            },

            


            //----------------------------------------------------------------------------------- RUTAS Validadas ---------------------------------------------------------------------//

            //Rutas modulo catalogación

            // ********************************* Begin Tipo Material *****************************************    
            {
                'path': 'tipoMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/tipo-material\/tipo-material.module#TipoMaterialModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'editarTipoMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-tipo-material\/modificar-tipo-material.module#ModificarTipoMaterialModule',
                'canActivate': [AjaxService]
            },

            /*{
                'path': 'eliminarTipoMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/eliminar-tipo-material\/eliminar-tipo-material.module#EliminarTipoMaterialModule',
                'canActivate': [AjaxService]
            }, Se quitó y se puso en editar */
            {
                'path': 'reporteTipoMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-tipo-material\/reporte-tipo-material.module#ReporteTipoMaterialModule',
                'canActivate': [AjaxService]
            },

            // ********************************* END Tipo Material *****************************************    

            // ********************************* Begin Grupo Articulo *****************************************    

            {
                'path': 'grupoArticulo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/grupo-articulo\/grupo-articulo.module#GrupoArticuloModule',
                'canActivate': [AjaxService]
            },

            // ********************************* END Grupo Articulo *****************************************    


            // ********************************* Begin fabricante *****************************************
            {
                'path': 'fabricante',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/fabricante\/fabricante.module#FabricanteModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'modificarFabricante',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-fabricante\/modificar-fabricante.module#ModificarFabricanteModule',
                'canActivate': [AjaxService]
            },

            /*{
                'path': 'eliminarFabricante',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/eliminar-fabricante\/eliminar-fabricante.module#EliminarFabricanteModule',
                'canActivate': [AjaxService]
            }, Se quito y se puso en editar */
            {
                'path': 'reporteFabricante',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-fabricante\/reporte-fabricante.module#ReporteFabricanteModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'cargueMasivoFabricante',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/fabricante-carga-masiva\/fabricante-carga-masiva.module#FabricanteCargaMasivaModule',
                'canActivate': [AjaxService]
            },


            // ********************************* END fabricante *****************************************

            {
                'path': 'ajustesCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/ajustes-catalogacion\/ajustes-catalogacion.module#AjustesCatalogacionModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'solicitudiadia',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/solicitud-diadia\/solicitud-diadia.module#SolicitudDiadiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarSolicitudCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/modificar-solicitud-catalogacion\/modificar-solicitud-catalogacion.module#ModificarSolicitudCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tablaSolicitudCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/tabla-solicitud-catalogacion\/tabla-solicitud-catalogacion.module#TablaSolicitudCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportGeb',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/reporte-grupo-e-bogota\/tabla-catalogacion.module#TablaCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tablaSolicitudCatalogacionGenerales',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/tabla-general-solicitudes\/tabla-general-solicitudes.module#TablaSolicitudGeneralCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'atencion',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/atencion-solicitudes\/atencion-solicitudes.module#AtencionSolicitudesModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importeSolicitudCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/importe-solicitud-catalogacion\/importe-solicitud-catalogacion.module#ImporteSolicitudCatalogacionModule',
                'canActivate': [AjaxService]
            },


            // ********************************* Begin Unidad de medida *****************************************    
            {
                'path': 'tablaUMB',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/tabla-unidad-medida-base\/tabla-unidad-medida-base.module#TablaUnidadMedidaBaseModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'createUMB',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/formulario-unidad-medida-base\/formulario-unidad-medida-base.module#FormularioUnidadMedidaBaseModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarUMB',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/modificar-unidad-medida-base\/modificar-unidad-medida-base.module#ModificarUnidadMedidaBaseModule',
                'canActivate': [AjaxService]
            },
            /*{
                'path': 'eliminarUMB',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/eliminar-unidad-medida-base\/eliminar-unidad-medida-base.module#EliminarUnidadMedidaBaseModule',
                'canActivate': [AjaxService]
            }, Se quitó y se puso en editar */

            // ********************************* End Unidad de medida *****************************************    

            // {
            //     'path': 'informe',
            //     'loadChildren': '.\/pages\/catalogacion\/catalogar\/informe-diadia\/informe-diadia.module#InformeDiadiaModule',
            //     'canActivate': [AjaxService]
            // },
            {
                'path': 'importeCaracteristica',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/cargue-caracteristica\/cargue-caracteristica.module#CargueCaracteristicaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'cargeToxicoMasivo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/cargue-toxico\/cargue-toxico.module#CargueToxicoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'cargeToxicoIndividual',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/cargue-toxico-individual\/cargue-toxico-individual.module#CargueToxicoIndividualModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteToxicos',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-toxicos\/reporte-toxicos.module#ReporteToxicosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'crearMaterial',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/crear-material\/crear-material.module#CrearMaterialModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'calidadQA',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/calidad\/calidad.module#CalidadModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'gestionCalidad', //Aquí el usuario puede ver todas sus solicitudes y tóxico en cualquier estado
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/gestion-catalogo\/gestion-catalogo.module#GestionCatalogoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarGrupoArticulo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-grupo-articulo\/modificar-grupo-articulo.module#ModificarGrupoArticuloModule',
                'canActivate': [AjaxService]
            },
            /*{
                'path': 'eliminarGrupoArticulo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/eliminar-grupo-articulo\/eliminar-grupo-articulo.module#EliminarGrupoArticuloModule',
                'canActivate': [AjaxService]
            }, Se quitó y se puso en editar */
            {
                'path': 'reporteGrupoArticulo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-grupo-articulo\/reporte-grupo-articulo.module#ReporteGrupoArticuloModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'cargaGrupoArticulo',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/carga-grupo-articulo\/carga-grupo-articulo.module#CargaGrupoArticuloModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'modificarUnidadMedida',
            //     'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-unidad-medida\/modificar-unidad-medida.module#ModificarUnidadMedidaModule',
            //     'canActivate': [AjaxService]
            // },

            {
                'path': 'editarUsuario',
                'loadChildren': '.\/pages\/default\/editar-usuario\/editar-usuario.module#EditarUsuarioModule',
                'canActivate': [AjaxService]

            },
            {
                'path': 'editarEmpresa',
                'loadChildren': '.\/pages\/default\/editar-empresa\/editar-empresa.module#EditarEmpresaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'editarRol',
                'loadChildren': '.\/pages\/default\/editar-rol\/editar-rol.module#EditarRolModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'asignarCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/asignar-catalogacion\/asignar-catalogacion.module#AsignarCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'asignarDescripcion',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/asignar-toxico\/asignar-toxico.module#AsignarToxicoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportesCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reportes-catalogacion\/reportes-catalogacion.module#ReportesCatalogacionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'dragAndDrop',
                'loadChildren': '.\/pages\/catalogacion\/catalogar\/drag-and-drop\/drag-and-drop.module#DragAndDropModule',
                'canActivate': [AjaxService]
            },


            // ********************************* Begin Material *****************************************        
            {
                'path': 'material',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/material\/material.module#MaterialModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-material\/modificar-material.module#ModificarMaterialModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-material\/reporte-material.module#ReporteMaterialModule',
                'canActivate': [AjaxService]
            },
            /*{
                'path': 'eliminarMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/eliminar-material\/eliminar-material.module#EliminarMaterialModule',
                'canActivate': [AjaxService]
            }, Se quito y se puso en editar */
            {
                'path': 'cargueMasivoMaterial',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/cargue-masivo-material\/cargue-masivo-material.module#CargueMasivoMaterialModule',
                'canActivate': [AjaxService]
            },

            // ********************************* END Material *****************************************        

            // ********************************* Begin Valor *****************************************    

            {
                'path': 'crearValor',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/valor\/valor.module#ValorModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'editarValor',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-valor\/modificar-valor.module#ModificarValorModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'carga-masiva-valor',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/carga-masiva-valor\/carga-masiva-valor.module#CargaMasivaValorModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'reporteValor',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-valor\/reporte-valor.module#ReporteValorModule',
                'canActivate': [AjaxService]
            },

            // {
            //     'path': 'eliminarValor',
            //     'loadChildren': '.\/pages\/catalogacion\/PanelControl\/eliminar-valor\/eliminar-valor.module#EliminarValorModule',
            //     'canActivate': [AjaxService]
            // },
            // ********************************* END Valor *****************************************    


            // ********************************* Begin Caracteristicas *****************************************
            {
                'path': 'crearCaracteristica',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/caracteristica\/caracteristica.module#CaracteristicaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'modificarCaracteristica',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/modificar-caracteristica\/modificar-caracteristica.module#ModificarCaracteristicaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'reporteCaracteristica',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reporte-caracteristica\/reporte-caracteristica.module#ReporteCaracteristicaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'indicatorsCataloging',
                'loadChildren': '.\/pages\/catalogacion\/PanelControl\/reportes-catalogacion\/reportes-catalogacion.module#ReportesCatalogacionModule',
                // 'canActivate': [AjaxService]
            },


            // {
            //     'path': 'eliminarCaracteristica',
            //     'loadChildren': '.\/pages\/catalogacion\/PanelControl\/elimiar-caracteristica\/elimiar-caracteristica.module#ElimiarCaracteristicaModule',
            //     'canActivate': [AjaxService]
            // },
            // ********************************* END fabricante *****************************************


            //---------------------------------- RUTAS Validadas ----------------------------------//

            //Rutas modulo Inventarios

            //Panel de Control - Estructura (Planta - Centro - Almacen)
            //Panel de Control - Estructura - Planta (Crear - Modificar - Eliminar - Reporte)

            {
                'path': 'estructuraPlanta',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/planta\/planta.module#PlantaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'estructuraPlantaModificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/planta-modificar\/planta-modificar.module#PlantaModificarModule',
                'canActivate': [AjaxService]

            },

            /*{
                'path': 'estructuraPlantaEliminar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/planta-eliminar\/planta-eliminar.module#PlantaEliminarModule',
                'canActivate': [AjaxService]
            }, Se quitó y se pueso en editar*/

            {
                'path': 'estructuraPlantaReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/planta-reporte\/planta-reporte.module#PlantaReporteModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Estructura - Centro (Crear - Modificar - Eliminar - Reporte)

            {
                'path': 'estructuraCentro',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/centro\/centro.module#CentroModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'estructuraCentroModificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/centro-modificar\/centro-modificar.module#CentroModificarModule',
                'canActivate': [AjaxService]

            },

            /*{
                'path': 'estructuraCentroEliminar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/centro-eliminar\/centro-eliminar.module#CentroEliminarModule',
                'canActivate': [AjaxService]
            }, Se quito y se pueso en editar */

            {
                'path': 'estructuraCentroReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/centro-reporte\/centro-reporte.module#CentroReporteModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Estructura - Aprobador creador
            {
                'path': 'createCreadorAprobador',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/creador-aprobador\/creador-aprobador.module#CreadorAprobadorModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Estructura - Asignar almacen
            {
                'path': 'createAsignarAlmacen',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/asignar-almacen\/asignar-almacen.module#AsignarAlmacenModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Estructura - Almacen (Crear - Modificar - Eliminar - Reporte)
            {
                'path': 'estructuraAlmacen',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/almacen\/almacen.module#AlmacenModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'estructuraAlmacenModificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/almacen-modificar\/almacen-modificar.module#AlmacenModificarModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'estructuraLocation',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/location\/location.module#LocationModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'estructuraLocationModificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/location-modificar\/location-modificar.module#LocationModificarModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'estructuraLocationReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/location-reporte\/location-reporte.module#LocationReporteModule',
                'canActivate': [AjaxService]
            },
            /*{
                'path': 'estructuraAlmacenEliminar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/almacen-eliminar\/almacen-eliminar.module#AlmacenEliminarModule',
                'canActivate': [AjaxService]
            }, Se quito y se puso en editar */
            {
                'path': 'estructuraAlmacenReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/estructura\/almacen-reporte\/almacen-reporte.module#AlmacenReporteModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Importar Materiales - Interno - Crear
            {
                'path': 'importarCatalogacionIndividual',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/importar-material-independiente\/importar-material-independiente.module#ImportarMaterialIndependienteModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Importar Materiales - Interno - Editar
            {
                'path': 'editarImporteCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/editar-importe\/editar-importe.module#EditarImporteModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Importar Materiales - Interno - Carga masiva
            {
                'path': 'importarCatalogacionMasiva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/catalogacion\/catalogacion.module#CatalogacionModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Importar Materiales - Interno - Reporte
            {
                'path': 'reporteImporteCatalogacion',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/reporte\/reporte.module#ReporteModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Importar Materiales - Externo - Crear
            {
                'path': 'crearMaterialExterno',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/cargue-individual\/cargue-individual.module#CargueIndividualModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Importar Materiales - Externo - Carga masiva
            {
                'path': 'cargaMasivaCatalogoExterno',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/carga-masiva-catalogo-externo\/carga-masiva-catalogo-externo.module#CargaMasivaCatalogoExternoModule',
                'canActivate': [AjaxService]
            },

            //Panel de Control - Importar Materiales - Externo - Importar
            {
                'path': 'asginarExternoAAlmacen',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/asginar-externo-a-almacen\/asginar-externo-a-almacen.module#AsginarExternoAAlmacenModule',
                'canActivate': [AjaxService]
            },



            //Panel de Control - Importar Materiales - Externo - editar
            {
                'path': 'editarImporteIndividualExterno',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/editar-cargue\/editar-cargue.module#EditarCargueModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Importar Materiales - Externo - Masivo 
            {
                'path': 'cargueMasivaExterno',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/cargue-masivo\/cargue-masivo.module#CargueMasivoModule',
                'canActivate': [AjaxService],
                'runGuardsAndResolvers': 'always',
            },
            //Panel de Control - Importar Materiales - Externo - Crear
            {
                'path': 'reporteImporteCatalogacionExterno',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/externos\/reporte\/reporte.module#ReporteModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Contabilidad - Centro de Costos (Crear - Modificar - Eliminar - Reporte)
            {
                'path': 'importeMasivoCCostos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/centroCostos\/importe-masivo-centro-costos\/importe-masivo-centro-costos.module#ImporteMasivoCentroCostosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'crearCentroCostos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/centroCostos\/crear-centro-costos\/crear-centro-costos.module#CrearCentroCostosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarCentroCostos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/centroCostos\/modificar-centro-costos\/modificar-centro-costos.module#ModificarCentroCostosModule',
                'canActivate': [AjaxService]
            },
            /*{
                'path': 'eliminarCentroCostos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/centroCostos\/eliminar-centro-costos\/eliminar-centro-costos.module#EliminarCentroCostosModule',
                'canActivate': [AjaxService]
            },*/
            {
                'path': 'reporteCentroCostos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/centroCostos\/reporte-centro-costos\/reporte-centro-costos.module#ReporteCentroCostosModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Contabilidad - Sucursal (Crear - Cargue masivo - Reporte)
            // {
            //     'path': 'crearSucursal',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/crear-sucursal\/crear-sucursal.module#CrearSucursalModule',
            //     //'canActivate':[AjaxService]
            // },


            // {
            //     'path': 'cargueMasivoSucursal',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/cargue-masivo-sucursal\/cargue-masivo-sucursal.module#CargueMasivoSucursalModule',
            //     //'canActivate':[AjaxService]
            // },


            // {
            //     'path': 'reporteSucursal',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/reporte-sucursal\/reporte-sucursal.module#ReporteSucursalModule',
            //     //'canActivate':[AjaxService]
            // },

            //Línea de negocio
            {
                'path': 'createLineaNegocio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/formulario-linea-negocio\/formulario-linea-negocio.module#FormularioLineaNegocioModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarLineaNegocio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/modificar-linea-negocio\/modificar-linea-negocio.module#ModificarLineaNegocioModule',
                'canActivate': [AjaxService]
            },
            /*{
                  'path': 'eliminarLineaNegocio',
                  'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/eliminar-linea-negocio\/eliminar-linea-negocio.module#EliminarLineaNegocioModule',
                  'canActivate': [AjaxService]
              },*/
            {
                'path': 'listaLineaNegocio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/lista-linea-negocio\/lista-linea-negocio.module#ListaLineaNegocioModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importeLineaNegocio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/importe-linea-negocio\/importe-linea-negocio.module#ImporteLineaNegocioModule',
                'canActivate': [AjaxService]
            },
            //Sector
            {
                'path': 'createSector',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/formulario-sector\/formulario-sector.module#FormularioSectorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarSector',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/modificar-sector\/modificar-sector.module#ModificarSectorModule',
                'canActivate': [AjaxService]
            },
            /*   {
                   'path': 'eliminarSector',
                   'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/eliminar-sector\/eliminar-sector.module#EliminarSectorModule',
                   'canActivate': [AjaxService]
               },*/
            {
                'path': 'listaSector',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/lista-sector\/lista-sector.module#ListaSectorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importarSector',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/importe-sector\/importe-sector.module#ImporteSectorModule',
                'canActivate': [AjaxService]
            },
            //Sucursal
            {
                'path': 'createSucursal',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/formulario-sucursal\/formulario-sucursal.module#FormularioSucursalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarSucursal',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/modificar-sucursal\/modificar-sucursal.module#ModificarSucursalModule',
                'canActivate': [AjaxService]
            },
            /*      {
                      'path': 'eliminarSucursal',
                      'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/eliminar-sucursal\/eliminar-sucursal.module#EliminarSucursalModule',
                      'canActivate': [AjaxService]
                  },*/
            {
                'path': 'listaSucursal',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/lista-sucursal\/lista-sucursal.module#ListaSucursalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importarSucursal',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/sucursal\/importe-sucursal\/importe-sucursal.module#ImporteSucursalModule',
                'canActivate': [AjaxService]
            },
            //Panel de Control - Contabilidad - Destino (Crear - Modificar - Eliminar - Reporte)
            {
                'path': 'crearDestino',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/destino\/crear-destino\/crear-destino.module#CrearDestinoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarDestino',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/destino\/modificar-destino\/modificar-destino.module#ModificarDestinoModule',
                'canActivate': [AjaxService]
            },
            /*     {
                      'path': 'eliminarDestino',
                      'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/destino\/eliminar-destino\/eliminar-destino.module#EliminarDestinoModule',
                      'canActivate': [AjaxService]
                  },*/
            {
                'path': 'reporteDestino',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/destino\/reporte-destino\/reporte-destino.module#ReporteDestinoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importarDestino',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/destino\/importar-destino\/importar-destino.module#ImportarDestinoModule',
                'canActivate': [AjaxService]
            },
            //Inventario - proveedor
            {
                'path': 'createProveedor',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/proveedor\/formulario-proveedor\/formulario-proveedor.module#FormularioProveedorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarProveedor',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/proveedor\/modificar-proveedor\/modificar-proveedor.module#ModificarProveedorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'eliminarProveedor',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/proveedor\/eliminar-proveedor\/eliminar-proveedor.module#EliminarProveedorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'listaProveedor',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/proveedor\/lista-proveedor\/lista-proveedor.module#ListaProveedorModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importeProveedor',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/contabilidad\/proveedor\/importe-proveedor\/importe-proveedor.module#ImporteProveedorModule',
                'canActivate': [AjaxService]
            },
            //Administracion de materiales - Reserva (Crear - Aprovacion - Modificacion - Atencion - Reporte)
            {
                'path': 'crearReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/crear-reserva\/crear-reserva.module#CrearReservaModule',
                // 'canActivate': [AjaxService]
            },
            {
                'path': 'modificacionReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/modificar-reserva\/modificar-reserva.module#ModificarReservaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'aprobacionReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/aprovar-reserva\/aprovar-reserva.module#AprovarReservaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'atencionReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/atencion-reserva\/atencion-reserva.module#AtencionReservaModule',
                'canActivate': [AjaxService]
            },

            //Administracion de materiales - Salida reserva
            {
                'path': 'salidaReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/salida-reserva\/salida-reserva.module#SalidaReservaModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'atencionReserva',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/PaneldeControl\/importar-materiales\/catalogacion\/catalogacion.module#CatalogacionModule',
            //     //'canActivate':[AjaxService]
            // },
            {
                'path': 'reporteReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/reserva\/reporte-reserva\/reporte-reserva.module#ReporteReservaModule',
                'canActivate': [AjaxService]
            },
            //Administracion de materiales - Movimientos  (Entrada - Traslado - Salida - Devolucion - Baja)
            {
                'path': 'administracion_producto_entrada',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/entrada\/entrada.module#EntradaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'administracion_producto_entrada_modificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/modificar-entrada\/modificar-entrada.module#ModificarEntradaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'administracion_producto_entrada_reporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/reporte-entrada\/reporte-entrada.module#ReporteEntradaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'administracion_producto_traslado',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/traslado\/traslado.module#TrasladoModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'administracion_producto_modificar_traslado',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/modificar-traslado\/modificar-traslado.module#ModificarTrasladoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'administracion_producto_reporte_traslado',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/reporte-traslado\/reporte-traslado.module#ReporteTrasladoModule',
                'canActivate': [AjaxService]
            },


            {
                'path': 'admProductoSalida',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/salida\/salida.module#SalidaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'admProductoSalidaModificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/modificar-salida-producto\/modificar-salida-producto.module#ModificarSalidaProductoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'admProductoSalidaReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/reporte-salida\/reporte-salida.module#ReporteSalidaModule',
                'canActivate': [AjaxService]
            },


            {
                'path': 'administracion_producto_devolucion',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/devolucion\/devolucion.module#DevolucionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'admProductoDevolucionReporte',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/reporte-devolucion\/reporte-devolucion.module#ReporteDevolucionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'administracion_producto_baja',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/baja\/baja.module#BajaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'admEditarProductoBaja',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/editar-baja\/editar-baja.module#EditarBajaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'admReporteProductoBaja',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/movimientos\/reporte-baja\/reporte-baja.module#ReporteBajaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'salidaHerramientaReserva',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/salida-reserva\/salida-reserva.module#SalidaReservaHerramientaModule',
                'canActivate': [AjaxService]
            },

            //Administracion de activos/herramientas - Reserva (Crear - Aprovacion - Modificacion - Atencion - Reporte)
            {
                'path': 'crearReservaHA',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/crear-reserva\/crear-reserva.module#CrearReservaModule',
                // 'canActivate': [AjaxService]
            },
            {
                'path': 'modificacionReservaHA',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/modificar-reserva\/modificar-reserva.module#ModificarReservaHAModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'aprobacionReservaHA', //aprovacionReservaHA
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/aprovar-reserva\/aprovar-reserva.module#AprovarReservaAHModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'atencionReservaHA',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/atencion-reserva\/atencion-reserva.module#AtencionReservaHAModule',
                //'canActivate':[AjaxService]
            },
            {
                'path': 'reporteReservaHA',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/reserva\/reporte-reserva\/reporte-reserva.module#ReporteReservaAHModule',
                'canActivate': [AjaxService]
            },
            //Administracion de activos/herramientas - Movimientos (Entrada - Asignacion - Devolucion - Traslado - Baja)            
            {
                'path': 'activos_producto_entrada',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/entrada\/entrada.module#EntradaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'activos_producto_entrada_modificar',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/modificar-entrada\/modificar-entrada.module#ModificarEntradaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'activos_producto_entrada_informe',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-entrada\/reporte-entrada.module#ReporteEntradaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'asignacionAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/asignacion\/asignacion.module#AsignacionActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'salidaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/salida\/salida.module#SalidaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarSalidaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/modificar-salida\/modificar-salida.module#ModificarSalidaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteSalidaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-salida\/reporte-salida.module#ReporteSalidaActivosModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'asignacionAH',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/asignacion\/asignacion.module#AsignacionActivosModule',
            //     'canActivate': [AjaxService]
            // },
            {
                'path': 'modificarAsignacionAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/modificar-asignacion\/modificar-asignacion.module#ModificarAsignacionActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteAsignacionAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-asignacion\/reporte-asignacion.module#ReporteAsignacionActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'bajaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/baja\/baja.module#BajaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarBajaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/modificar-baja\/modificar-baja.module#ModificarBajaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteBajaAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-baja\/reporte-baja.module#ReporteBajaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'devolucionAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/devolucion\/devolucion.module#DevolucionActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'devolucionCustodioAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/devolucion-custodio\/devolucion-custodio.module#DevolucionCustodioModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteMmCustodioAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-mm-custodia\/reporte-mm-custodia.module#ReporteMmCustodioModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'trasladoAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/traslado\/traslado.module#TrasladoActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarTrasladoAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/modificar-traslado\/modificar-traslado.module#ModificarTrasladoActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteTrasladoAH',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-traslado\/reporte-traslado.module#ReporteTrasladoActivosModule',
                'canActivate': [AjaxService]
            },


            // {
            //     'path': 'bajaAH',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/baja\/baja.module#BajaModule',
            //     'canActivate': [AjaxService]
            // },

            // Movimientos tuberia
            {
                'path': 'entradaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/entrada-tuberia\/entrada-tuberia.module#EntradaTuberiaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'modificar-entradaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/modificar-entrada-tuberia\/modificar-entrada-tuberia.module#ModificarEntradaTuberiaModule',
                'canActivate': [AjaxService]
            },

            {
                'path': 'reporte-entradaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/reporte-entrada-tuberia\/reporte-entrada-tuberia.module#ReporteEntradaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'despachoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/salida-tuberia\/salida-tuberia.module#SalidaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificar-despachoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/modificar-salida-tuberia\/modificar-salida-tuberia.module#ModificarSalidaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-despachoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/reporte-salida-tuberia\/reporte-salida-tuberia.module#ReporteSalidaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'bajaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/baja-tuberia\/baja-tuberia.module#BajaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificar-bajaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/modificar-baja-tuberia\/modificar-baja-tuberia.module#ModificarBajaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-bajaTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/reporte-baja-tuberia\/reporte-baja-tuberia.module#ReporteBajaTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'trasladoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/traslado-tuberia\/traslado-tuberia.module#TrasladoTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificar-trasladoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/modificar-traslado-tuberia\/modificar-traslado-tuberia.module#ModificarTrasladoTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-trasladoTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/reporte-traslado-tuberia\/reporte-traslado-tuberia.module#ReporteTrasladoTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'devolucionTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/devolucion-tuberia\/devolucion-tuberia.module#DevolucionTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-devTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/movimientos\/reporte-devolucion-tuberia\/reporte-devolucion-tuberia.module#ReporteDevolucionTuberiaModule',
                'canActivate': [AjaxService]
            },

            //Administracion de activos/herramientas - Custodios (Crear - Modificar - Eliminar - Reporte)
            {
                'path': 'crearCustodio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/custodios\/crear-custodios\/crear-custodios.module#CrearCustodiosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificarCustodio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/custodios\/modificar-custodios\/modificar-custodios.module#ModificarCustodiosModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'eliminarCustodio',
            //     'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/custodios\/eliminar-custodios\/eliminar-custodios.module#EliminarCustodiosModule',
            //     'canActivate': [AjaxService]
            // },
            {
                'path': 'reporteCustodio',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/custodios\/reporte-custodios\/reporte-custodios.module#TablaCustodiosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteDevHerramienta',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/reporte-devolucion-herramienta\/reporte-devolucion-herramienta.module#ReporteDevolucionHerramientaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'ConstructionModule',
                'loadChildren': '.\/pages\/constuccion\/constuccion.module#ConstuccionModule',
                // 'canActivate': [AjaxService]
            },
            //Administracion de Tuberia - Reserva (Crear - Aprobación - Modificacion - Atencion - Reporte)
            {
                'path': 'crearReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/crear-reserva\/crear-reserva.module#CrearReservaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modificacionReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/modificar-reserva\/modificar-reserva.module#ModificarReservaTBModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'aprobacionReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/aprobar-reserva\/aprobar-reserva.module#AprobarReservaTBModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'atencionReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/atencion-reserva\/atencion-reserva.module#AtencionReservaTBModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'salidaReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/salida-reserva\/salida-reserva.module#SalidaReservaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteReservaTB',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/reserva\/reporte-reserva\/reporte-reserva.module#ReporteReservaTBModule',
                'canActivate': [AjaxService]
            },
            // Toma de inventarios materiales
            {
                'path': 'tomaInventarioInterna_material',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/internos\/talking-inventary\/talking-inventary.module#TalkingInventaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'report_talking_inventory_material',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/internos\/report-talking-inventary\/report-talking-inventory.module#ReportTalkingInventoryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'count_tomaInventario_material',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/internos\/count\/count.module#CountModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'AttendInternalConciliation',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/internos\/attention-conciliation-product\/AttentionConciliationProduct_internal.module#AttentionConciliationProduct_internalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'readjustment_internalConciliation',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/internos\/readjustment\/readjustment.module#ReadjustmentModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_material',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withOutFile\/talking-external-inventary\/talking-external-inventary.module#TalkingExternalInventaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExternal_withFile_material',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withFile\/talking-external-inventary-with-file\/talking-external-inventary-with-file.module#TalkingExternalInventaryWithFileModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'countTalkingInventoryExternal_withFile',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withFile\/count\/count.module#CountModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'externalProductCounterReport',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withFile\/external-product-counter-report\/external-product-counter-report.module#ExternalProductCounterReportModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'attentionConciliation_product',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withFile\/attention-conciliation-product\/attention-conciliation-product.module#AttentionConciliationProductModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'ReportConciliation_externalProduct',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Materiales\/toma-inventario-material\/externos\/withFile\/report-conciliation-product\/report-conciliation-product.module#ReportConciliationProductModule',
                'canActivate': [AjaxService]
            },
            // Toma de inventarios herramienta
            {
                'path': 'reporte_existencia_activos',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/reporte-existencia-activos\/reporte-existencia-activos.module#ReporteExistenciaActivosModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'kardex',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/movimientos\/kardex\/kardex.module#KardexModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioInterna_herramienta',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talking-inventary\/talking-inventary.module#TalkingInventaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_herramienta',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talking-external-inventary\/talking-external-inventary.module#TalkingExternalInventaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_withFile_herramienta',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talking-external-inventary-with-file\/talking-external-inventary-with-file.module#TalkingExternalInventaryWithFileModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioInterna_caseone_conteo',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talkin-counting\/talking-internal-caseone-counting.module#TalkinCaseOneCountingModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'conciliacionIntActivo',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talking-conciliation\/talking-conciliation.module#TalkingConciliationModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reajusteInventarioActivo',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/reajuste-inventario-activo\/reajuste-inventario-activo.module#ReajusteInventarioActivoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteTomaInventarioActivo',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/reporte-toma-inventario-activo\/reporte-toma-inventario-activo.module#ReporteTomaInventarioActivoModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_withFile_herramienta_counting',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talkin-counting-external\/talking-internal-caseone-counting-external.module#TalkinCaseOneCountingExternalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_withFile_herramienta_informe_externa',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talkin-manage-inventary-external\/talkin-manage-inventary-external.module#TalkinManageInventaryExternalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_withFile_herramienta_conciliar_externa',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talkin-manage-conciliation-external\/talkin-manage-conciliaton-external.module#TalkinManageConciliatonExternalModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'tomaInventarioExterna_withFile_herramienta_reporte_conciliar_externa',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Herramientas\/toma-inventario-herramienta\/talkin-report-conciliaton-external\/talkin-report-conciliaton.module#TalkinReportConciliatonExternalModule',
                'canActivate': [AjaxService]
            },


            // Toma de inventarios tuberia
            {
                'path': 'tomaInventarioInterna_tuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/talking-inventary\/talking-inventary.module#TalkingInventaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'conteoTomaInvTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/conteo-tuberia\/conteo-tuberia.module#ConteoTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'conciliacionTomaInvIntTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/conciliacion-toma-inv-int-tuberia\/conciliacion-toma-inv-int-tuberia.module#ConciliacionTomaInvIntTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reajusteTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/reajuste-inventario-tuberia\/reajuste-inventario-tuberia.module#ReajusteInventarioTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteConciliacionTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/reporte-conciliacion-tuberia\/reporte-conciliacion-tuberia.module#ReporteConciliacionTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'importeInvExtTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/importe-inv-ext-tuberia\/importe-inv-ext-tuberia.module#ImporteInvExtTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'conteoInvExtTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/conteo-toma-inv-ext\/conteo-toma-inv-ext.module#ConteoTomaInvExtModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'repoContInvExtFileTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/repo-cont-inv-ext-file\/repo-cont-inv-ext-file.module#RepoContInvExtFileModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'conciliacionTomaInvExtTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/conciliacion-toma-inv-ext-tuberia\/conciliacion-toma-inv-ext-tuberia.module#ConciliacionTomaInvExtTuberiaModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reporteConciliacionInExtTuberia',
                'loadChildren': '.\/pages\/catalogacion\/Inventario\/Adm.Tuberias\/toma-inventario-tuberia\/repo-conci-toma-inv-ext-tuberia\/repo-conci-toma-inv-ext-tuberia.module#RepoConciTomaInvExtTuberiaModule',
                'canActivate': [AjaxService]
            },

            // Maintenance - Client
            {
                'path': 'createClient',
                'loadChildren': '.\/pages\/maintenance\/management\/client\/create-client\/create-client.module#CreateClientModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'updateClient',
                'loadChildren': '.\/pages\/maintenance\/management\/client\/update-client\/update-client.module#UpdateClientModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportClient',
                'loadChildren': '.\/pages\/maintenance\/management\/client\/report-client\/report-client.module#ReportClientModule',
                'canActivate': [AjaxService]
            },

            // Maintenance - agreement
            {
                'path': 'createAgreement',
                'loadChildren': '.\/pages\/maintenance\/management\/agreement\/create-agreement\/create-agreement.module#CreateAgreementModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'updateAgreement',
                'loadChildren': '.\/pages\/maintenance\/management\/agreement\/update-agreement\/update-agreement.module#UpdateAgreementModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportAgreement',
                'loadChildren': '.\/pages\/maintenance\/management\/agreement\/report-agreement\/report-agreement.module#ReportAgreementModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'reportClient',
            //     'loadChildren': '.\/pages\/maintenance\/management\/client\/report-client\/report-client.module#ReportClientModule',
            //     'canActivate': [AjaxService]
            // },

            // Maintenance - Check list
            {
                'path': 'createCheckList',
                'loadChildren': '.\/pages\/maintenance\/management\/check-list\/create-check-list\/create-check-list.module#CreateCheckListModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'updateCheckList',
                'loadChildren': '.\/pages\/maintenance\/management\/check-list\/update-check-list\/update-check-list.module#UpdateCheckListModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportCheckList',
                'loadChildren': '.\/pages\/maintenance\/management\/check-list\/report-check-list\/report-check-list.module#ReportCheckListModule',
                'canActivate': [AjaxService]
            },

            // Reception - Diary
            {
                'path': 'createDiary',
                'loadChildren': '.\/pages\/maintenance\/reception\/diary\/create-diary\/create-diary.module#CreateDiaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'scheduleCalendarView',
                'loadChildren': '.\/pages\/maintenance\/reception\/diary\/calendar-diary\/calendar-diary.module#CalendarDiaryModule',
                'canActivate': [AjaxService]
            },

            // Reception - Budget
            {
                'path': 'createBudget',
                'loadChildren': '.\/pages\/maintenance\/reception\/budget\/create-budget\/create-budget.module#CreateBudgetModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'updateBudget',
                'loadChildren': '.\/pages\/maintenance\/reception\/budget\/update-budget\/update-budget.module#UpdateBudgetModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportBudget',
                'loadChildren': '.\/pages\/maintenance\/reception\/budget\/report-budget\/report-budget.module#ReportBudgetModule',
                'canActivate': [AjaxService]
            },

            // Workshop - administration - additional
            {
                'path': 'createAdditional',
                'loadChildren': '.\/pages\/maintenance\/workshop\/administration\/additional\/create-additional\/create-additional.module#CreateAdditionalModule',
                'canActivate': [AjaxService]
            },

            // Workshop - administration - approval o.t.
            {
                'path': 'createApprovalOt',
                'loadChildren': '.\/pages\/maintenance\/workshop\/administration\/approval-ot\/create-approval-ot\/create-approval-ot.module#CreateApprovalOtModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'viewInfoWorkOrder',
                'loadChildren': '.\/pages\/maintenance\/workshop\/administration\/work-order\/create-work-order\/create-work-order.module#CreateWorkOrderModule',
                'canActivate': [AjaxService]
            },

            // Workshop - maintenance - attention o.t.
            {
                'path': 'createAttentionOt',
                'loadChildren': '.\/pages\/maintenance\/workshop\/maintenance\/attention-ot\/create-attention-ot\/create-attention-ot.module#CreateAttentionOtModule',
                'canActivate': [AjaxService]
            },

            // return - schedule
            {
                'path': 'createSchedule',
                'loadChildren': '.\/pages\/maintenance\/return\/schedule\/create-diary\/create-diary.module#CreateDiaryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'calendarSchedule',
                'loadChildren': '.\/pages\/maintenance\/return\/schedule\/calendar-diary\/calendar-diary.module#CalendarDiaryModule',
                'canActivate': [AjaxService]
            }
            //Rutas modulo Mantenimiento
            // Equipos
            , {
                'path': 'createEquipment',
                'loadChildren': '.\/pages\/maintenance\/management\/equipment\/create-equipment\/create-equipment.module#CreateEquipmentModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modifyEquipment',
                'loadChildren': '.\/pages\/maintenance\/management\/equipment\/modify-equipment\/modify-equipment.module#ModifyEquipmentModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportEquipment',
                'loadChildren': '.\/pages\/maintenance\/management\/equipment\/report-equipment\/report-equipment.module#ReportEquipmentModule',
                'canActivate': [AjaxService]
            },

            // Lista de precio
            {
                'path': 'createPriceList',
                'loadChildren': '.\/pages\/maintenance\/management\/prices\/priceList\/create-price-list\/create-price-list.module#CreatePriceListModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'masiveLoadPriceList',
                'loadChildren': '.\/pages\/maintenance\/management\/prices\/priceList\/masive-load-price-list\/masive-load-price-list.module#MasiveLoadPriceListModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportPriceList',
                'loadChildren': '.\/pages\/maintenance\/management\/prices\/priceList\/report-price-list\/report-price-list.module#ReportPriceListModule',
                'canActivate': [AjaxService]
            },

            // Kits
            {
                'path': 'createKits',
                'loadChildren': '.\/pages\/maintenance\/management\/kits\/create-kits\/create-kit.module#CreateKitModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modifyKits',
                'loadChildren': '.\/pages\/maintenance\/management\/kits\/modify-kits\/modify-kit.module#ModifyKitModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportKits',
                'loadChildren': '.\/pages\/maintenance\/management\/kits\/report-kits\/report-kit.module#ReportKitModule',
                'canActivate': [AjaxService]
            },

            // Promotions
            {
                'path': 'createPromotions',
                'loadChildren': '.\/pages\/maintenance\/management\/promotion\/create-promotion\/create-promotion.module#CreatePromotionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modifyPromotions',
                'loadChildren': '.\/pages\/maintenance\/management\/promotion\/modify-promotion\/modify-promotion.module#ModifyPromotionModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportPromotions',
                'loadChildren': '.\/pages\/maintenance\/management\/promotion\/report-promotion\/report-promotion.module#ReportPromotionModule',
                'canActivate': [AjaxService]
            },

            // Equipment entry
            {
                'path': 'createEquipmentEntry',
                'loadChildren': '.\/pages\/maintenance\/reception\/equipmentEntry\/create-equipment-entry\/create-equipment-entry.module#CreateEquipmentEntryModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'modifyEquimentEntry',
                'loadChildren': '.\/pages\/maintenance\/reception\/equipmentEntry\/modify-equipment-entry\/modify-equipment-entry.module#ModifyEquipmentEntrytModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'reportEquipmentEntry',
                'loadChildren': '.\/pages\/maintenance\/reception\/equipmentEntry\/report-equipment-entry\/report-equipment-entry.module#ReportEquipmentEntryModule',
                'canActivate': [AjaxService]
            },

            // Equipment delivery
            {
                'path': 'createEquipmentDelivery',
                'loadChildren': '.\/pages\/maintenance\/return\/equipment-delivery\/create-equipment-delivery\/create-equipment-delivery.module#CreateEquipmentDeliveryModule',
                'canActivate': [AjaxService]
            },
            // {
            //     'path': 'modifyEquimentDelivery',
            //     'loadChildren': '.\/pages\/maintenance\/return\/equipment-delivery\/modify-equipment-delivery\/modify-equipment-delivery.module#ModifyEquipmentDeliverytModule',
            //     'canActivate': [AjaxService]
            // },
            {
                'path': 'reportEquipmentDelivery',
                'loadChildren': '.\/pages\/maintenance\/return\/equipment-delivery\/report-equipment-delivery\/report-equipment-delivery.module#ReportEquipmentDeliveryModule',
                'canActivate': [AjaxService]
                // ********************************* Begin Configuracion General Mantenimiento *****************************************
            },
            {
                'path': 'configuracionGeneralMantenimiento',
                'loadChildren': '.\/pages\/maintenance\/panelControl\/configuration-Maintenance\/configuracion-general.module#ConfiguracionGeneralModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'usuariosMantenimiento',
                'loadChildren': '.\/pages\/maintenance\/panelControl\/usuarios-mantenimiento\/usuarios-mantenimiento.module#UsuariosMantenimientoModule',
                'canActivate': [AjaxService]
            },
            // ********************************** End Configuracion General Mantenimiento ******************************************
            {
                'path': 'timeList',
                'loadChildren': '.\/pages\/maintenance\/management\/prices\/time-list\/time-list.module#TimeListModule',
                'canActivate': [AjaxService]
            },
            {
                'path': 'distribution',
                'loadChildren': '.\/pages\/maintenance\/management\/distribution\/distribution.module#DistributionModule',
                'canActivate': [AjaxService]
            },
            // ********************************* Begin Prices *****************************************
            {
                'path': 'proforma',
                'loadChildren': '.\/pages\/maintenance\/return\/proforma\/proforma.module#ProformaModule',
                //'canActivate': [AjaxService]
            },

            // **************************************************************************************** //
            // **************************************************************************************** //
            // ********************************* DICIONARIO UNIVERSAL ********************************* //
            // **************************************************************************************** //
            // **************************************************************************************** //

            {
                'path': 'valores-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/valores-universal\/valores-form\/valor-universal.module#ValorUniversalModule',
                //'canActivate': [AjaxService]
            },
            {
                'path': 'valores-cm-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/valores-universal\/valores-cargue-masivo\/valor-cm-universal.module#ValorCMUniversalModule',
                //'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-valores-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/valores-universal\/valores-reporte\/reporte-valor-universal.module#ReporteValorUniversalModule',
                //'canActivate': [AjaxService]
            },
            {
                'path': 'caracteristicas-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/caracteristicas-universal\/caracteristicas-form\/caracteristica-universal.module#CaracteristicaUniversalModule',
                //'canActivate': [AjaxService]
            },
            {
                'path': 'caracteristicas-cm-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/caracteristicas-universal\/caracteristicas-cargue-masivo\/caracteristica-cm-universal.module#CaracteristicaCMUniversalModule',
                //'canActivate': [AjaxService]
            },
            {
                'path': 'reporte-caracteristicas-universal',
                'loadChildren': '.\/pages\/diccionario-universal\/caracteristicas-universal\/caracteristicas-reporte\/reporte-caracteristica-universal.module#ReporteCaracteristicaUniversalModule',
                //'canActivate': [AjaxService]
            },
            // **************************************************************************************** //
            // **************************************************************************************** //
            // *********************************** MAESTRO UNIVERSAL ********************************** //
            // **************************************************************************************** //
            // **************************************************************************************** //
            {
                'path': 'fabricante-universal',
                'loadChildren': '.\/pages\/maestro-universal\/fabricante-universal\/fabricante-form\/fabricante-universal.module#FabricanteUniversalModule',
                //'canActivate': [AjaxService]
            }            
        ],
    },
    {
        'path': '**',
        'redirectTo': 'index',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        FormsModule,
        BrowserModule
    ],
    declarations: [
        AsideNavComponent
    ],
    exports: [RouterModule, AsideNavComponent],
})
export class ThemeRoutingModule { }

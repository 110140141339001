
import { Injectable } from "@angular/core";
import *  as crypto from 'crypto-js';

@Injectable()
export class DycpService {

    private claveSecret: any = "ZQ{I0{TujsJP~2d|FS/7";
    constructor() {
    }

    encriptar(userjson: any) {
        var ciphertext = crypto.AES.encrypt(JSON.stringify(userjson), this.claveSecret);
        sessionStorage.setItem('currentUser', ciphertext);
    }

    desencriptar() {
        let jsonRespuesta: any;
        let userSession = sessionStorage.getItem('currentUser');
        // debugger;
        try {
            if (userSession) {
                let currentUser = crypto.AES.decrypt(userSession.toString(), this.claveSecret);
                currentUser = currentUser.toString(crypto.enc.Utf8);
                // console.log(currentUser);

                jsonRespuesta = JSON.parse(currentUser);
                if (typeof jsonRespuesta == 'string' && jsonRespuesta != null) {
                    jsonRespuesta = JSON.parse(jsonRespuesta);
                }
                return jsonRespuesta;
            }
            else {
                jsonRespuesta = null;
                return jsonRespuesta;
            }
        } catch (x) {
            console.log(x);
            return null;
        }
    }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from "./auth/logout/logout.component";

const routes: Routes = [
    { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
    { path: 'pdfPresupuesto', loadChildren: './auth/pdf/generar-pdf-to-print/generar-pdf-to-print.module#GenerarPdfToPrintModule' },
    { path: 'pdfToOt', loadChildren: './auth/pdf/pdf-to-ot/pdf-to-ot.module#PdfToOtModule' },
    { path: 'PdfToRecepcionComponent', loadChildren: './auth/pdf/pdf-to-recepcion/pdf-to-recepcion.module#PdfToRecepcionModule' },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: `reload` })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
import { Directive, HostListener, HostBinding, Input, Output } from '@angular/core';

@Directive({
  selector: '[appInputSearch]'
})

export class InputSearchDirective {
  // @HostBinding('value')
  // valueText;

  // @Input()
  // valueInput: string;

  // constructor() { }

  // ngOnChanges() {
  //   this.valueText = 'Valor de prueba'
  // }

}

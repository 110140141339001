import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Helpers } from "./helpers";
// import { textoModuloAdm } from './texto';




@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    // styleUrls:['../styles.css']
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    constructor(private _router: Router
        // , private text: textoModuloAdm
        , private spinner: NgxSpinnerService) {
        window.onbeforeunload = function (e) {
            return 'Dialog text here.';
        };
    }

    ngOnInit() {
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                // Helpers.setLoading(true);
                this.spinner.show();
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationEnd) {
                // Helpers.setLoading(false);
                this.spinner.hide();
            }
        });

        // this.spinner.show();

        // setTimeout(() => {
        //     /** spinner ends after 5 seconds */
        //     this.spinner.hide();
        // }, 5000);
    }
}